<template>
  <div class="ibox ">
    <div class="ibox-title">
      <h5>Zmień hasło</h5>
    </div>
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <form-error-panel :errors="formErrors" />

      <form @submit="submitForm">
        <div class="form-group">
          <label>Aktualne hasło</label>
          <input
            autocomplete="new-password"
            type="password"
            placeholder="Wpisz aktualne hasło"
            v-model="oldPassword"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label>Nowe hasło</label>
          <input
            autocomplete="new-password"
            type="password"
            placeholder="Wpisz nowe hasło"
            v-model="newPassword"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label>Powtórz nowe hasło</label>
          <input
            autocomplete="new-password"
            type="password"
            placeholder="Powtórz nowe hasło"
            v-model="newPasswordConfirmed"
            class="form-control"
            required
          />
        </div>
        <br />
        <div class="row">
          <div class="col-12">
            <button
              class="float-right btn btn-sm btn-primary m-t-n-xs ml-1"
              type="button"
              @click="reset"
            >
              <strong>Anuluj</strong>
            </button>
            <button
              class="float-right btn btn-sm btn-primary m-t-n-xs"
              type="submit"
            >
              <strong>Zatwierdź</strong>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormErrorPanel from "@/components/utils/FormErrorPanel";
import { RepositoryFactory } from "@/data/repositoryFactory.js";

const RepositoryAuth = RepositoryFactory.get("auth");

export default {
  name: "password-change",
  components: {
    FormErrorPanel
  },
  data() {
    return {
      formErrors: [],
      isLoading: false,
      oldPassword: null,
      newPassword: null,
      newPasswordConfirmed: null
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"])
  },
  methods: {
    async submitForm(e) {
      e.preventDefault();
      if (
        this.newPassword == null ||
        this.newPassword !== this.newPasswordConfirmed
      ) {
        this.formErrors.push("Wpisane hasła nie są identyczne.");
      } else {
        this.isLoading = true;
        var response = await RepositoryAuth.changePassword(
          this.loggedInUser.id,
          this.oldPassword,
          this.newPassword
        );

        if (response?.serverError) {
          this.$toastr.Add({
            msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
            progressbar: false,
            timeout: 3000,
            type: "error"
          });
        } else {
          if (response) this.formErrors = response;
          else {
            this.reset();

            this.$toastr.Add({
              msg: "Hasło zostało zmienione.",
              progressbar: false,
              timeout: 3000,
              type: "success"
            });
          }
        }

        this.isLoading = false;
      }
    },
    reset() {
      this.oldPassword = null;
      this.newPassword = null;
      this.newPasswordConfirmed = null;

      this.$emit("on-completed");
    }
  }
};
</script>
