import Vue from "vue";
import VueRouter from "vue-router";

//views
import ViewLogin from "@/views/ViewLogin";
import ViewDashboard from "@/views/ViewDashboard";
import ViewUsers from "@/views/ViewUsers";
import ViewForgottenPassword from "@/views/ViewForgottenPassword";
import ViewResetPassword from "@/views/ViewResetPassword";
import ViewOrders from "@/views/ViewOrders";
import ViewDictionaries from "@/views/ViewDictionaries";
import ViewTest from "@/views/ViewTest";
import OrderGeodesistMap from "@/views/OrderGeodesistMap";
import ViewTransportModule from "@/views/ViewTransportModule";
import SingleActionGallery from "@/components/actions/SingleActionGallery";

//users
import UsersTableView from "@/components/users/UsersTableView";
import UserDetails from "@/components/users/UserDetails";
import UserAddEdit from "@/components/users/UserAddEdit";

//orders
import OrdersTableView from "@/components/orders/OrdersTableView";
import OrderDetails from "@/components/orders/OrderDetails";
import OrderAddEdit from "@/components/orders/OrderAddEdit";

//order contractors
import OrderContractorDetails from "@/components/orderContractors/OrderContractorDetails";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: ViewLogin
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: ViewDashboard
  },
  {
    path: "/users",
    component: ViewUsers,
    children: [
      {
        path: "/",
        name: "usersTable",
        component: UsersTableView
      },
      {
        path: "details/:id?",
        name: "userDetails",
        component: UserDetails
      },
      {
        path: "addEdit/:id?",
        name: "userAddEdit",
        component: UserAddEdit
      }
    ]
  },
  {
    path: "/orders",
    component: ViewOrders,
    alias: "/",
    children: [
      {
        path: "/",
        name: "ordersTable",
        component: OrdersTableView,
        props: route => ({
          status: route.query.status,
          realizationPhase: route.query.realizationPhase,
          geodeticPhase: route.query.geodeticPhase
        })
      },
      {
        path: "details/:id?",
        name: "orderDetails",
        component: OrderDetails
      },
      {
        path: "addEdit/:id?",
        name: "orderAddEdit",
        component: OrderAddEdit,
        props(route) {
          return route.query || {};
        }
      }
    ]
  },
  {
    path: "/geodesistMap/:uuid?",
    name: "orderGeodesistMap",
    component: OrderGeodesistMap,
    props(route) {
      return route.query || {};
    }
  },
  {
    path: "/transport",
    name: "transportModule",
    component: ViewTransportModule
  },
  {
    path: "/orderContractorDetails/:id?",
    name: "orderContractorDetails",
    component: OrderContractorDetails,
    props(route) {
      return route.query || {};
    }
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ViewForgottenPassword
  },
  {
    path: "/resetPassword/:token?",
    name: "resetPassword",
    component: ViewResetPassword
  },
  {
    path: "/dictionaries",
    name: "dictionaries",
    component: ViewDictionaries
  },
  {
    path: "/singleActionGallery",
    name: "singleActionGallery",
    component: SingleActionGallery,
    props: route => ({
      orderId: route.query.orderId,
      actionId: route.query.actionId,
      actionTypeName: route.query.actionTypeName,
      orderNumber: route.query.orderNumber
    })
  },
  {
    path: "/test",
    name: "test",
    component: ViewTest
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "login",
    "forgotPassword",
    "resetPassword",
    "orderGeodesistMap"
  ];
  const authRequired = !publicPages.includes(to.name);

  if (authRequired && !store.getters.isAuthenticated) return next("/login");
  else next();
});

router.afterEach(to => {
  store.dispatch("reloadNotifications");

  if (to.name === "ordersTable")
    document.getElementById("ordersTableMenuElement")?.click();
});

export default router;
