import repository from "./repository.js";

export default {
  async getOrdersObject(
    page,
    itemsPerPage,
    search,
    sortBy,
    orderType,
    filter,
    userFilter
  ) {
    let url = "/order";

    if (filter && filter.subject && filter.id != null)
      url = `/${filter.subject}/${filter.id}/orders`;
    else if (filter && filter.url) url = filter.url;

    let userFilterUrlEnding = "";

    if (userFilter) {
      for (const property in userFilter) {
        if (Array.isArray(userFilter[property])) {
          userFilter[property].forEach(x => {
            userFilterUrlEnding += `&${property}[]=${x}`;
          });
        } else userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`
      )
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(error => {
        return { error };
      });
  },

  async getOrderDetailsGeneral(id) {
    return repository.get(`/order/${id}`).then(response => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async createOrder(order) {
    return repository
      .post("/order", order)
      .then(response => {
        if (response && response.data && response.data.message)
          return response.data.message;
        else
          return {
            id: response.data.data.id,
            geotimestamp: response?.data?.data?.geotimestamp
          };
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async getOrderDataToEdit(id) {
    return repository.get(`/order/${id}/edit`).then(response => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async updateOrder(order) {
    return repository
      .put(`/order/${order.id}`, order)
      .then(response => {
        if (response && response.data && response.data.message)
          return { message: response.data.message };
        else
          return {
            geotimestamp: response?.data?.data?.geotimestamp
          };
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async deleteOrder(id) {
    return repository
      .delete(`/order/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async reopenOrder(orderId) {
    return repository
      .get(`/order/${orderId}/unlockOrder`)
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async addContractorToOrder(orderId, userId) {
    return repository
      .post(`/order/${orderId}/addWorker`, { id_user: userId })
      .then(response => {
        return response?.data?.message ?? null;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async sendTransportDate(orderId, transportDate) {
    let formData = new FormData();
    formData.append("transport_date", transportDate);

    return repository
      .post(`/order/${orderId}/addTransportFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        if (response && response.data && response.data.message)
          return response.data.message;
        else return null;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async sendAcceptanceProtocol(
    orderId,
    file,
    creationDate,
    userId,
    number,
    amount_c,
    amount_w
  ) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("creation_date", creationDate);
    formData.append("created_by", userId);
    formData.append("number", number);
    formData.append("amount_c", amount_c);
    formData.append("amount_w", amount_w);

    return repository
      .post(`/order/${orderId}/addPOFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        if (response && response.data && response.data.message)
          return response.data.message;
        else return null;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async sendGeodeticProtocol(
    orderId,
    file,
    creationDate,
    userId,
    pgDate,
    orders_ids = null
  ) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("creation_date", creationDate);
    formData.append("created_by", userId);
    formData.append("pg_date", pgDate);

    if (Array.isArray(orders_ids)) {
      for (let index = 0; index < orders_ids.length; index++) {
        formData.append(`orders_ids[${index}]`, orders_ids[index]);
      }
    }

    return repository
      .post(`/order/${orderId}/addPGFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        if (response && response.data && response.data.message)
          return response.data.message;
        else return null;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async sendInvoice(orderId, file, creationDate, userId, cost) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("creation_date", creationDate);
    formData.append("created_by", userId);
    formData.append("cost", cost);

    return repository
      .post(`/order/${orderId}/addFVFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        if (response && response.data && response.data.message)
          return response.data.message;
        else return null;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async getTransportList(orderId) {
    return repository
      .get(`/order/${orderId}/transportList`)
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(error => {
        return { error };
      });
  },

  async updatePolesForGeodesist(orderId, poles) {
    return repository
      .post(`/order/${orderId}/polesUpdate`, {
        data: poles
      })
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async getPolesList(orderId) {
    return repository
      .get(`/order/${orderId}/poles`)
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(error => {
        return { error };
      });
  },

  async getPolesForPGList(orderId) {
    return repository
      .get(`/order/${orderId}/polesForPGList`)
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(error => {
        return { error };
      });
  },

  async getPolesForPGListByUUID(orderUUID) {
    return repository
      .get(`/order/${orderUUID}/polesForPGListByUUID`)
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(error => {
        return { error };
      });
  },

  async getOrdersForMapObject(
    page,
    itemsPerPage,
    search,
    sortBy,
    orderType,
    userFilter
  ) {
    let url = "/order/map";

    let userFilterUrlEnding = "";

    if (userFilter) {
      for (const property in userFilter) {
        if (Array.isArray(userFilter[property])) {
          userFilter[property].forEach(x => {
            userFilterUrlEnding += `&${property}[]=${x}`;
          });
        } else userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`
      )
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(error => {
        return { error };
      });
  },

  async getOrdersForMapObjectByOrdersIds(
    page,
    itemsPerPage,
    search,
    sortBy,
    orderType,
    userFilter,
    ordersIds
  ) {
    let url = "/order/map_array";

    let userFilterUrlEnding = "";

    if (userFilter) {
      for (const property in userFilter) {
        userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .post(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`,
        { data: ordersIds }
      )
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(error => {
        return { error };
      });
  },

  async addContractorToMultipleOrders(userId, ordersIds) {
    return repository
      .post(`/order/addWorkerToMultiple`, {
        id_user: userId,
        orders: ordersIds
      })
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async sendAttachmentNo6(orderId, poles) {
    return repository
      .post(`/order/${orderId}/addPoles`, {
        data: poles
      })
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async changeOrderStatus(orderId, statusId) {
    return repository
      .post(`/order/${orderId}/changeStatus`, { new_id: statusId })
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async changeOrderRealizationPhase(orderId, realizationPhaseId) {
    return repository
      .post(`/order/${orderId}/changeImplementationPhase`, {
        new_id: realizationPhaseId
      })
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async changeOrderGeodeticPhase(orderId, geodeticPhaseId) {
    return repository
      .post(`/order/${orderId}/changeGeodesyPhase`, { new_id: geodeticPhaseId })
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async addFileToOrder(orderId, file, creationDate, userId, context) {
    var toast = context.$toastr.Add({
      msg: `<b>${file.name}</b><br/>Plik jest przesyłany.`,
      progressbar: true,
      timeout: 0,
      clickClose: false,
      type: "info",
      progressBarValue: 0
    });

    let formData = new FormData();

    formData.append("file", file);
    formData.append("creation_date", creationDate);
    formData.append("created_by", userId);

    return repository
      .post(`/order/${orderId}/addFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: progressEvent => {
          toast.progressBarValue = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        }
      })
      .then(function(response) {
        if (response && response.data && response.data.message)
          return response.data.message;
        else return null;
      })
      .catch(serverError => {
        return { serverError };
      })
      .finally(() => {
        context.$toastr.Close(toast);
      });
  },

  async exportOrders(search, sortBy, orderType, userFilter) {
    let url = "/order/index_raport";

    let userFilterUrlEnding = "";

    if (userFilter) {
      for (const property in userFilter) {
        userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(
        `${url}?search=${search}&orderBy=${sortBy}&orderType=${orderType}${userFilterUrlEnding}`
      )
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async createPolesTypesSetForOrder(orderId, formData) {
    return repository
      .post(`/orderaddress`, {
        id_order: orderId,
        address: formData.address,
        poles: formData.parameters
      })
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async updatePolesTypesSetForOrder(orderId, setId, formData) {
    return repository
      .put(`/orderaddress/${setId}`, {
        id_order: orderId,
        address: formData.address,
        poles: formData.parameters
      })
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async removePolesTypesSetForOrder(orderAddressId) {
    return repository
      .delete(`/orderaddress/${orderAddressId}`)
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async getOrderGeodeticDates(id) {
    return repository.get(`/order/${id}/geodeticDates`).then(response => {
      if (response.data.error == null) return response.data;
    });
  },

  async updateGeodeticDates(orderId, realizationDate, sharedWithOPLDate) {
    return repository
      .post(`/order/${orderId}/updateGeodeticDates`, {
        realizationDate,
        sharedWithOPLDate
      })
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  }
};
