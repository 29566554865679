<template>
  <pdf-generator
    ref="pdfGenerator"
    :filename="transportListFilename"
    :enableDownload="true"
    :preview-modal="false"
  >
    <span v-html="transportPDFContent" />
  </pdf-generator>
</template>

<script>
import { getDateWithoutTime } from "@/helpers/dateFormatHelper.js";
import PdfGenerator from "@/components/utils/PdfGenerator";
import { getLocalFormattedDateAndTime } from "@/helpers/dateFormatHelper.js";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";

const RepositoryActions = RepositoryFactory.get("actions");

export default {
  name: "transport-pdf-generator",
  components: {
    PdfGenerator
  },
  props: {
    transportIds: null
  },
  data() {
    return {
      transportPDFContent: null
    };
  },
  computed: {
    transportListFilename() {
      return (
        "Moduł transportu - eksport " +
        getLocalFormattedDateAndTime(new Date().toJSON())
      );
    }
  },
  methods: {
    getLocalFormattedDateAndTime,
    getDateWithoutTime,
    async generatePdfFile() {
      var response = await RepositoryActions.getDataForTransportList(
        this.transportIds
      );

      handleResponseWithMessages(this, response, null, () => {
        this.transportPDFContent = this.createPdfDataForGenerator(response);
        if (this.transportPDFContent != null) {
          this.$refs.pdfGenerator.generatePdf();
        } else {
          this.$toastr.Add({
            msg: "Wystąpił błąd pliku. Spróbuj ponownie.",
            progressbar: false,
            timeout: 3000,
            type: "error"
          });
        }
      });
    },
    createPdfDataForGenerator(response) {
      if (
        !Array.isArray(response.data) ||
        !response.data.length ||
        !response.sums
      ) {
        return null;
      }

      let transportPoints = response.data;
      let sums = response.sums;

      let pdfContent = `<div class="ml-4 mr-4"><h1>Lista punktów transportu ${getLocalFormattedDateAndTime(
        new Date().toJSON()
      )}</h1><br>`;

      let tableBeginning = `
        <div class="table-responsive mt-1">
          <table class="table table-striped">
            <thead>
              <tr style="font-size: 11px">
                <th>
                  Lp
                </th>
                <th>
                  Nr zlecenia
                </th>
                <th style="min-width: 100px">
                  Data dodania
                </th>
                <th>
                  Współrzędne
                </th>
                <th>
                  Drewno
                </th>
                <th>
                  Beton
                </th>
                <th>
                  Szczudło
                </th>
                <th>
                  Adres
                </th>
                <th>
                  Notatka
                </th>
              </tr>
            </thead>
            <tbody>`;

      let tableEnding = `</tbody></table></div>`;

      pdfContent += tableBeginning;

      let lp = 1;

      transportPoints.forEach(transportPoint => {
        pdfContent += `
              <tr>
              <td>
                  ${lp}
              </td>
              <td>
                  ${transportPoint?.order_number ?? "-"}
              </td>
              <td style="min-width: 100px">
                  ${transportPoint?.date ?? "-"}
              </td>
              <td>
                  ${transportPoint?.coordinates ?? "-"}
              </td>
              <td>
                  ${transportPoint.amount_w ?? "-"}
              </td>
              <td>
                  ${transportPoint.amount_c ?? "-"}
              </td>
              <td>
                  ${transportPoint.amount_s ?? "-"}
              </td>
              <td>
                  ${transportPoint.address ?? "-"}
              </td>
               <td>
                  ${transportPoint.note ?? "-"}
              </td>
            </tr>
        `;

        lp++;
      });

      pdfContent += tableEnding;

      pdfContent += `<div class="html2pdf__page-break"></div>`;
      pdfContent += `
        <div class="m-4">
          <h3>
            Szczegóły  ${
              this.transportIds.length
            } zaznaczonych punktów transportu:
          </h3>

          <table class="mt-4 mb-2" style="width: 100%">
            <tr>
              <td><b>Suma</b></td>
            </tr>
            <tr class="pl-3">
              <td>Drewniane:</td>
              <td>
                ${sums.w != null ? sums.w : "-"}
              </td>
            </tr>
            <tr class="pl-3">
              <td>Betonowe:</td>
              <td>
                ${sums.c != null ? sums.c : "-"}
              </td>
            </tr>
            <tr class="pl-3">
              <td>Szczudła:</td>
              <td>
                ${sums.s != null ? sums.s : "-"}
              </td>
            </tr>
          </table>
          </div>`;

      pdfContent += `</div>`;
      return pdfContent;
    }
  }
};
</script>

<style scoped></style>
