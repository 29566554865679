<template>
  <div id="wrapper">
    <nav
      class="navbar-default navbar-static-side"
      style="background-color: #2f4050;"
      role="navigation"
    >
      <div class="sidebar-collapse">
        <ul class="nav metismenu" id="side-menu">
          <li class="nav-header text-center">
            <div
              v-if="
                loggedInUser && loggedInUser.firstName && loggedInUser.lastName
              "
              class="dropdown profile-element"
            >
              <img
                alt="User"
                class="rounded-circle"
                :src="
                  'https://eu.ui-avatars.com/api/?name=' +
                    loggedInUser.firstName +
                    '+' +
                    loggedInUser.lastName +
                    '&color=2f4050&background=f3f3f4'
                "
              />
              <a data-toggle="dropdown" class="dropdown-toggle" href="#">
                <span class="block m-t-xs font-bold"
                  >{{ loggedInUser.firstName }}
                  {{ loggedInUser.lastName }}
                </span>
                <span class="text-muted text-xs block">
                  {{ loggedInUser.type.name }}
                  <b class="caret"></b>
                </span>
              </a>
              <ul class="dropdown-menu animated fadeIn m-t-xs">
                <li>
                  <a class="dropdown-item" @click="showLoggedInUserDetails"
                    >Profil</a
                  >
                </li>
                <li class="dropdown-divider"></li>
                <li><a class="dropdown-item" @click="logOut">Wyloguj</a></li>
              </ul>
            </div>
          </li>

          <!-- <router-link to="/orders" v-slot="{ href, isActive }">
            <li :class="[isActive && 'active']">
              <a
                :href="href"
                @mouseover="ordersMenuItemHover = true"
                @mouseleave="ordersMenuItemHover = false"
              >
                <img
                  :src="
                    isActive || ordersMenuItemHover
                      ? '/static/img/order-white.png'
                      : '/static/img/order-gray.png'
                  "
                  alt="Zlecenia"
                  width="13"
                  height="13"
                  style="margin-right:6px;"
                />
                <span class="nav-label">Zlecenia</span>
              </a>
            </li>
          </router-link> -->

          <li :class="[isOrdersMenuItemActive && 'active active2']">
            <a
              id="ordersTableMenuElement"
              href="#"
              @mouseover="ordersMenuItemHover = true"
              @mouseleave="ordersMenuItemHover = false"
            >
              <img
                :src="
                  isOrdersMenuItemActive || ordersMenuItemHover
                    ? '/static/img/order-white.png'
                    : '/static/img/order-gray.png'
                "
                alt="Zlecenia"
                width="13"
                height="13"
                style="margin-right:6px;"
              />
              <span class="nav-label">Zlecenia</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level">
              <router-link to="/orders" v-slot="{ href, isExactActive }">
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)">Tabela</a>
                </li>
              </router-link>
              <router-link to="/orders" v-slot="{ href, isExactActive }">
                <li
                  :class="[isExactActive && initialOrdersTab === 2 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(2)">Mapa</a>
                </li>
              </router-link>
            </ul>
          </li>

          <li :class="[isOrdersStatusesMenuItemActive && 'active active2']">
            <a
              id="ordersGeodeticPhasesTableMenuElement"
              href="#"
              @mouseover="ordersStatusesMenuItemHover = true"
              @mouseleave="ordersStatusesMenuItemHover = false"
            >
              <i class="fa fa-check-square-o"></i>
              <span class="nav-label">Statusy zleceń</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level">
              <router-link
                :to="{
                  path: '/orders',
                  query: { status: 1 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)"
                    >Do dyspozycji</a
                  >
                </li>
              </router-link>

              <router-link
                :to="{
                  path: '/orders',
                  query: { status: 2 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)">W produkcji</a>
                </li>
              </router-link>

              <router-link
                :to="{
                  path: '/orders',
                  query: { status: 3 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)">Przerwane</a>
                </li>
              </router-link>
              <router-link
                :to="{
                  path: '/orders',
                  query: { status: 4 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)">Anulowane</a>
                </li>
              </router-link>
              <router-link
                :to="{
                  path: '/orders',
                  query: { status: 5 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)"
                    >Zakończone rozliczone</a
                  >
                </li>
              </router-link>
            </ul>
          </li>

          <li
            :class="[
              isOrdersRealizationPhasesMenuItemActive && 'active active2'
            ]"
          >
            <a
              id="ordersRealizationPhasesTableMenuElement"
              href="#"
              @mouseover="ordersRealizationPhasesMenuItemHover = true"
              @mouseleave="ordersRealizationPhasesMenuItemHover = false"
            >
              <i class="fa fa-road"></i>
              <span class="nav-label">Etapy realizacji</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level">
              <router-link
                :to="{
                  path: '/orders',
                  query: { realizationPhase: 1 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)"
                    >Nierozpoczęty demontaż</a
                  >
                </li>
              </router-link>
              <router-link
                :to="{
                  path: '/orders',
                  query: { realizationPhase: 2 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)"
                    >Rozpoczęty demontaż</a
                  >
                </li>
              </router-link>
              <router-link
                :to="{
                  path: '/orders',
                  query: { realizationPhase: 3 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)"
                    >Przerwany demontaż</a
                  >
                </li>
              </router-link>
              <router-link
                :to="{
                  path: '/orders',
                  query: { realizationPhase: 4 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)"
                    >Zakończony demontaż do zabrania</a
                  >
                </li>
              </router-link>
              <router-link
                :to="{
                  path: '/orders',
                  query: { realizationPhase: 5 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)"
                    >Zakończony demontaż prośba o PO</a
                  >
                </li>
              </router-link>
              <router-link
                :to="{
                  path: '/orders',
                  query: { realizationPhase: 6 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)"
                    >Zrealizowany demontaż</a
                  >
                </li>
              </router-link>
              <router-link
                :to="{
                  path: '/orders',
                  query: { realizationPhase: 7 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)">Geodezja</a>
                </li>
              </router-link>
            </ul>
          </li>

          <li
            :class="[isOrdersGeodeticPhasesMenuItemActive && 'active active2']"
          >
            <a
              id="ordersStatusesTableMenuElement"
              href="#"
              @mouseover="ordersGeodeticPhasesMenuItemHover = true"
              @mouseleave="ordersGeodeticPhasesMenuItemHover = false"
            >
              <i class="fa fa-globe"></i>
              <span class="nav-label">Etapy geodezji</span>
              <span class="fa arrow"></span>
            </a>
            <ul class="nav nav-second-level">
              <router-link
                :to="{
                  path: '/orders',
                  query: { geodeticPhase: 1 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)"
                    >Do zlecenia geodecie</a
                  >
                </li>
              </router-link>
              <router-link
                :to="{
                  path: '/orders',
                  query: { geodeticPhase: 2 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)"
                    >W realizacji</a
                  >
                </li>
              </router-link>
              <router-link
                :to="{
                  path: '/orders',
                  query: { geodeticPhase: 3 }
                }"
                v-slot="{ href, isExactActive }"
              >
                <li
                  :class="[isExactActive && initialOrdersTab === 1 && 'active']"
                  style="width: 100%;"
                >
                  <a :href="href" @click="onOrdersTabClicked(1)"
                    >Przesłano do weryfikacji Orange</a
                  >
                </li>
              </router-link>
            </ul>
          </li>

          <!-- <li :class="[isOrdersMenuItemActive && 'active']">
            <a href="#"
              ><i class="fa fa-edit"></i> <span class="nav-label">Zlecenia</span
              ><span class="fa arrow"></span
            ></a>
            <ul class="nav nav-second-level">
              <router-link to="/orders" v-slot="{ href, isActive }">
                <li :class="[isActive && 'active']">
                  <a :href="href">Wszystkie</a>
                </li>
              </router-link>
              <router-link
                :to="{
                  name: 'ordersInProgressTable'
                }"
                v-slot="{ href, isActive }"
              >
                <li :class="[isActive && 'active']">
                  <a :href="href">W trakcie demontażu</a>
                </li>
              </router-link>
            </ul>
          </li> -->

          <router-link to="/transport" v-slot="{ href, isActive }">
            <li :class="[isActive && 'active']">
              <a :href="href">
                <i class="fa fa-car"></i>
                <span class="nav-label">Słupy do zebrania</span>
              </a>
            </li>
          </router-link>

          <router-link to="/users" v-slot="{ href, isActive }">
            <li :class="[isActive && 'active']">
              <a :href="href">
                <i class="fa fa-user-o"></i>
                <span class="nav-label">Użytkownicy</span>
              </a>
            </li>
          </router-link>

          <router-link to="/dictionaries" v-slot="{ href, isActive }">
            <li :class="[isActive && 'active']">
              <a :href="href">
                <i class="fa fa-edit"></i>
                <span class="nav-label">Słowniki</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </nav>

    <div id="page-wrapper" class="gray-bg">
      <div class="row border-bottom">
        <nav
          class="navbar navbar-static-top gray-bg"
          role="navigation"
          style="margin-bottom: 0"
        >
          <div class="navbar-header">
            <a
              @click="toggleSideMenu()"
              class="navbar-minimalize minimalize-styl-2 btn btn-primary "
              href="#"
              ><i class="fa fa-bars"></i>
            </a>
          </div>
          <ul class="nav navbar-top-links navbar-right">
            <li class="dropdown mr-4">
              <a
                class="dropdown-toggle count-info"
                data-toggle="dropdown"
                href="#"
              >
                <i class="fa fa-envelope"></i>
                <span v-if="notificationsCount" class="label label-primary">{{
                  notificationsCount
                }}</span>
              </a>

              <notifications-dropdown-list
                @notifications-count="notificationsCount = $event"
              />
            </li>
          </ul>
        </nav>
      </div>

      <navigation-panel v-if="$route.name != 'dashboard'" />

      <div class="wrapper wrapper-content animated fadeIn">
        <router-view />
      </div>
      <div class="footer">
        <div class="pull-right"><strong></strong></div>
        <div><strong>Copyright</strong> DKK Development &copy; 2020</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { AUTH_LOGOUT } from "@/helpers/authConstants.js";
import NavigationPanel from "@/components/navigation/NavigationPanel.vue";
import NotificationsDropdownList from "@/components/notifications/NotificationsDropdownList.vue";

export default {
  name: "view-main",
  components: {
    NavigationPanel,
    NotificationsDropdownList
  },
  data() {
    return {
      notificationsCount: null,
      ordersMenuItemHover: false,
      ordersStatusesMenuItemHover: false,
      ordersRealizationPhasesMenuItemHover: false,
      ordersGeodeticPhasesMenuItemHover: false
    };
  },
  computed: {
    ...mapGetters(["loggedInUser", "initialOrdersTab"]),
    isOrdersMenuItemActive() {
      return (
        this.$route.fullPath.includes("orders") &&
        !this.$route.fullPath.includes("orders?status")
      );
    },
    isOrdersStatusesMenuItemActive() {
      return this.$route.fullPath.includes("orders?status");
    },
    isOrdersRealizationPhasesMenuItemActive() {
      return this.$route.fullPath.includes("orders?realizationPhase");
    },
    isOrdersGeodeticPhasesMenuItemActive() {
      return this.$route.fullPath.includes("orders?geodeticPhase");
    }
  },
  methods: {
    ...mapActions({ logOutAction: AUTH_LOGOUT }),
    ...mapActions(["changeInitialTab"]),
    logOut: function() {
      this.logOutAction().then(() => {
        this.$router.push({ name: "login" });
      });
    },
    showLoggedInUserDetails: function() {
      var loggedInUserId = this.loggedInUser?.id;

      if (
        this.$route.name === "userDetails" &&
        this.$route.params?.id != null &&
        loggedInUserId != null &&
        this.$route.params?.id.toString() === loggedInUserId.toString()
      )
        return;

      this.$router.push({
        name: "userDetails",
        params: { id: loggedInUserId }
      });
    },
    toggleSideMenu: function() {
      if (localStorage.getItem("menuCollapsed"))
        localStorage.removeItem("menuCollapsed");
      else localStorage.setItem("menuCollapsed", true);
    },
    onOrdersTabClicked(tabNumber) {
      this.changeInitialTab(tabNumber);
    }
  }
};
</script>

<style scoped>
.active2 {
  border-left: 4px solid #19aa8d;
  background: #293846;
}

.menu-level-forth-a {
  padding-left: 72px;
}
</style>
