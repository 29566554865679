import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import repository from "@/data/repository.js";
import VueToastr from "vue-toastr";
import "leaflet/dist/leaflet.css";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

Vue.use(VueSweetalert2);
Vue.use(VueToastr, { defaultPosition: "toast-top-right" });

Vue.config.productionTip = false;

Vue.component("pagination", require("laravel-vue-pagination"));

const token = localStorage.getItem("user-token");
if (token)
  repository.defaults.headers.common["Authorization"] = "Bearer " + token;

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://0bbe598f839b4909b587e86522ccd861@o438282.ingest.sentry.io/5468909",
    integrations: [
      new VueIntegration({ Vue, attachProps: true, logErrors: true })
    ]
  });
}

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
