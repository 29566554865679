export const MODAL_USERS_TABLE = "users-table";
export const MODAL_ORDERS_TABLE = "orders-table";
// export const MODAL_USER_TYPES_TABLE = "user-types-table";
export const MODAL_USER_TYPES_TABLE = "dictionary-table&userTypes";
export const MODAL_ORDER_STATUSES_TABLE = "dictionary-table&orderStatuses";
export const MODAL_ORDER_TYPES_TABLE = "dictionary-table&orderTypes";
export const MODAL_REALIZATION_PRIORITIES_TABLE =
  "dictionary-table&realizationPriorities";
export const MODAL_DIRECTIONS_TABLE = "dictionary-table&directions";
export const MODAL_REGIONS_TABLE = "dictionary-table&regions";
export const MODAL_COUNTIES_TABLE = "dictionary-table&counties";
export const MODAL_ORDER_REALIZATION_PHASES_TABLE =
  "dictionary-table&orderRealizationPhases";
export const MODAL_ORDER_GEODETIC_PHASES_TABLE =
  "dictionary-table&orderGeodeticPhases";
export const MODAL_ACTION_TYPES_TABLE = "dictionary-table&actiontype";
