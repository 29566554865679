import {
  getDateWithoutTime,
  getLocalFormattedDateAndTime
} from "@/helpers/dateFormatHelper.js";
import { mapGetters } from "vuex";
import DropAreaSingleFile from "@/components/utils/DropAreaSingleFile";
import { FILE_MAX_SIZE } from "@/helpers/appConstants.js";

export default {
  components: {
    "drop-area": DropAreaSingleFile
  },
  props: {
    orderId: Number,
    isLoading: false
  },
  data() {
    return {
      file: null,
      FILE_MAX_SIZE: FILE_MAX_SIZE
    };
  },
  computed: {
    ...mapGetters({ loggedInUser: "loggedInUser" })
  },
  methods: {
    getLocalFormattedDateAndTime,
    getDateWithoutTime,
    onFileChanged(e, fileInputFieldId) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) this.file = null;
      else {
        let fileSize = files[0].size / 1024 / 1024;

        if (fileSize > this.FILE_MAX_SIZE) {
          this.$toastr.Add({
            msg: `Zbyt duży rozmiar pliku<br/> (maks. ${this.FILE_MAX_SIZE} MB)`,
            progressbar: false,
            timeout: 3000,
            type: "warning"
          });

          let fileInput = document.getElementById(fileInputFieldId);
          if (fileInput) fileInput.value = "";
        } else this.file = files[0];
      }
    },
    onDropAreaFileChanged(file, fileInputFieldId) {
      let fileSize = file.size / 1024 / 1024;

      if (fileSize > this.FILE_MAX_SIZE) {
        this.$toastr.Add({
          msg: `Zbyt duży rozmiar pliku<br/> (maks. ${this.FILE_MAX_SIZE} MB)`,
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });

        let fileInput = document.getElementById(fileInputFieldId);
        if (fileInput) fileInput.value = "";
      } else this.file = file;
    }
  }
};
