<template>
  <div class="ibox">
    <div v-if="showHeader" class="ibox-title">
      <h5>Użytkownicy</h5>
      <div v-if="addButton" class="ibox-tools">
        <a @click="addNewUser" class="btn btn-primary btn-xs"
          >+ Nowy użytkownik</a
        >
      </div>
    </div>
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <users-table-filter-and-search
        v-if="showFilters"
        ref="usersTableFilterAndSearchChildComponent"
        :longerSearchBar="longerSearchBar"
        @fetchData="onFetchDataWithFiltersRequest"
      />

      <div v-else class="row">
        <div
          :class="
            longerSearchBar ? 'col-sm-4 offset-sm-8' : 'col-sm-3 offset-sm-9'
          "
        >
          <div class="input-group mb-3">
            <input
              placeholder="Szukaj.."
              type="text"
              class="form-control form-control-sm"
              v-model="searchBox"
              v-on:keyup.enter="search"
            />
            <span class="input-group-append">
              <button
                @click="search"
                type="button"
                class="btn btn-sm btn-primary"
              >
                <i class="fa fa-search"></i> Szukaj
              </button>
            </span>
          </div>
        </div>
      </div>

      <table-elements-info
        :metaObject="usersObject ? usersObject.meta : null"
      />

      <div
        v-if="usersObject && usersObject.data && usersObject.data.length > 0"
        class="table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th
                @click="sortBy(sortingOptions.FIRST_NAME)"
                style="cursor: pointer; white-space: nowrap;"
              >
                Imię <span v-html="firstNameSortIcon" />
              </th>
              <th
                @click="sortBy(sortingOptions.LAST_NAME)"
                style="cursor: pointer; white-space: nowrap;"
              >
                Nazwisko <span v-html="lastNameSortIcon" />
              </th>
              <th>
                Telefon
              </th>
              <th
                @click="sortBy(sortingOptions.EMAIL)"
                style="cursor: pointer; white-space: nowrap;"
              >
                E-mail <span v-html="emailSortIcon" />
              </th>
              <th v-if="hideTypeColumn === false">Typ</th>
              <th>Aktywny</th>
              <th>Kolor</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in usersObject.data"
              :key="user.id"
              @click="
                clickableRows
                  ? selectableItems
                    ? pickItem(user)
                    : showDetails(user)
                  : ''
              "
              :style="clickableRows ? 'cursor: pointer;' : ''"
            >
              <td>{{ user.firstName }}</td>
              <td>{{ user.lastName }}</td>
              <td>{{ user.phone ? user.phone : "-" }}</td>
              <td>{{ user.email ? user.email : "-" }}</td>
              <td v-if="hideTypeColumn === false">
                {{ user.type && user.type.name ? user.type.name : "-" }}
              </td>
              <td>
                <i v-if="user.active" class="fa fa-check text-navy" />
                <i
                  v-else-if="user.active === 0"
                  class="fa fa-times text-danger"
                />
                <span v-else>-</span>
              </td>
              <td>
                <div
                  v-if="user.color"
                  style="width: 40px; height: 20px; border-radius: 5px;"
                  :style="'background-color: ' + user.color"
                ></div>
                <span v-else>-</span>
              </td>
              <td>
                <div class="float-right">
                  <span v-if="showDefaultButtons">
                    <button
                      class="btn btn-outline btn-primary btn-xs mr-2"
                      type="button"
                      @click="
                        selectableItems ? pickItem(user) : showDetails(user)
                      "
                      onclick="event.stopPropagation()"
                    >
                      <span v-if="selectableItems">Wybierz</span>
                      <span v-else>Pokaż</span>
                    </button>
                    <button
                      v-if="editButton"
                      class="btn btn-outline btn-primary btn-xs"
                      type="button"
                      @click="edit(user)"
                      onclick="event.stopPropagation()"
                    >
                      <span>Edytuj</span>
                    </button>
                  </span>
                  <slot name="buttons" :user="user"></slot>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="usersObject.error">
        <server-error-panel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">{{ noItemsText }}</p>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6 offset-xl-3">
          <pagination
            v-if="usersObject && usersObject.data"
            align="center"
            :data="usersObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></pagination>
        </div>

        <div class="col-xl-3">
          <pagination-count-picker
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import {
  usersSortingOptions as sortingOptions,
  getSortingIcon,
  getSortingDirection
} from "@/helpers/sortTableHelper.js";
import { PAGINATION_LIMIT } from "@/helpers/appConstants.js";
import paginationCountPickerMixin from "@/mixins/paginationCountPickerMixin.js";
import ServerErrorPanel from "@/components/utils/ServerErrorPanel";
import TableElementsInfo from "@/components/utils/TableElementsInfo";
import UsersTableFilterAndSearch from "@/components/users/UsersTableFilterAndSearch";

const RepositoryUsers = RepositoryFactory.get("users");

export default {
  name: "users-table",
  components: {
    ServerErrorPanel,
    TableElementsInfo,
    UsersTableFilterAndSearch
  },
  mixins: [paginationCountPickerMixin],
  props: {
    filter: {
      type: Object,
      default: null
    },
    selectableItems: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    longerSearchBar: {
      type: Boolean,
      default: false
    },
    addButton: {
      type: Boolean,
      default: false
    },
    editButton: {
      type: Boolean,
      default: false
    },
    noItemsText: {
      type: String,
      default: "Brak elementów do wyświetlenia"
    },
    hideTypeColumn: {
      type: Boolean,
      default: false
    },
    showDefaultButtons: {
      type: Boolean,
      default: true
    },
    clickableRows: {
      type: Boolean,
      default: true
    },
    showFilters: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      usersObject: {},
      searchBox: "",
      currentSort: { by: null, direction: null },
      PAGINATION_LIMIT: PAGINATION_LIMIT,
      userFilter: null
    };
  },
  computed: {
    sortingOptions: () => sortingOptions,
    firstNameSortIcon() {
      return getSortingIcon(this.currentSort, sortingOptions.FIRST_NAME);
    },
    lastNameSortIcon() {
      return getSortingIcon(this.currentSort, sortingOptions.LAST_NAME);
    },
    emailSortIcon() {
      return getSortingIcon(this.currentSort, sortingOptions.EMAIL);
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(page = 1) {
      this.getUsers(page);
    },
    getUsers(page = 1) {
      this.isLoading = true;

      RepositoryUsers.getUsersObject(
        page,
        this.itemsPerPage,
        this.searchBox,
        this.currentSort.by,
        this.currentSort.direction,
        this.filter,
        this.userFilter
      )
        .then(data => {
          this.usersObject = data;
          this.$refs.usersTableFilterAndSearchChildComponent?.updateAppliedFilters();
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    addNewUser() {
      this.$router.push({
        name: "userAddEdit"
      });
    },
    showDetails(user) {
      this.$router.push({
        name: "userDetails",
        params: { id: user.id }
      });
    },
    edit(user) {
      this.$router.push({
        name: "userAddEdit",
        params: { id: user.id }
      });
    },
    search() {
      this.fetchData();
    },
    sortBy(newSortBy) {
      //set direction
      this.currentSort.direction = getSortingDirection(
        this.currentSort,
        newSortBy
      );

      //set by
      this.currentSort.by = newSortBy;

      //ask for data
      this.fetchData();
    },
    pickItem(user) {
      this.$emit("on-completed", user);
    },
    onFetchDataWithFiltersRequest({ search, filter }) {
      this.searchBox = search;
      this.userFilter = filter;
      this.fetchData();
    }
  }
};
</script>
<style></style>
