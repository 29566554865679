// converts e.g. "2020-06-22 15:09:07" to "2020-06-22"
export function getDateWithoutTime(datetime) {
  if (datetime == null) return null;

  datetime = datetime.trim();
  return datetime.substring(0, datetime.indexOf(" "));
}

// converts e.g. "2020-06-24T14:03:12.000000Z" to "2020-06-24 14:03:12"
export function getLocalFormattedDateAndTime(date) {
  if (date == null) return date;

  date = date.trim();

  var formattedDate = date.substring(0, date.indexOf("T"));
  var dateObj = new Date(date);

  return formattedDate + " " + dateObj.toLocaleTimeString();
}
