<template>
  <div class="ibox">
    <div class="ibox-content">
      <h2>Słowniki</h2>
      <div class="input-group mt-4">
        <input
          type="text"
          :placeholder="searchBarPlaceholder"
          class="input form-control"
          v-model="searchBox"
          v-on:keyup.enter="search"
        />
        <span class="input-group-append">
          <button @click="search" type="button" class="btn btn btn-primary">
            <i class="fa fa-search"></i> Szukaj
          </button>
        </span>
      </div>
      <div style="margin-top: 20px">
        <ul class="nav nav-tabs" style="border-bottom-style: none;">
          <li>
            <a
              class="nav-link active"
              data-toggle="tab"
              href="#tab-1"
              @click="activeTab = 1"
              ><i class="fa fa-check-square-o"></i> Statusy zlecenia</a
            >
          </li>
          <li>
            <a
              class="nav-link"
              data-toggle="tab"
              href="#tab-2"
              @click="activeTab = 2"
              ><img
                src="/static/img/owner-gray.png"
                alt="Typy akcji"
                width="13"
                height="13"
                style="margin-right:6px;"
              />
              Typy akcji</a
            >
          </li>
          <li>
            <a
              class="nav-link"
              data-toggle="tab"
              href="#tab-3"
              @click="activeTab = 3"
              ><img
                src="/static/img/inspection-types-gray.png"
                alt="Typy zleceń"
                width="13"
                height="13"
                style="margin-right:6px;"
              />
              Typy zleceń</a
            >
          </li>
          <li>
            <a
              class="nav-link"
              data-toggle="tab"
              href="#tab-4"
              @click="activeTab = 4"
              ><i class="fa fa-users"></i> Typy użytkowników</a
            >
          </li>
          <li>
            <a
              class="nav-link"
              data-toggle="tab"
              href="#tab-6"
              @click="activeTab = 6"
              ><i class="fa fa-clock-o" />Kategoria</a
            >
          </li>
          <li>
            <a
              class="nav-link"
              data-toggle="tab"
              href="#tab-7"
              @click="activeTab = 7"
              ><i class="fa fa-file-image-o" />Typy zasobów</a
            >
          </li>
          <li>
            <a
              class="nav-link"
              data-toggle="tab"
              href="#tab-8"
              @click="activeTab = 8"
              ><i class="fa fa-code-fork" />Rodzaje zleceń</a
            >
          </li>
          <li>
            <a
              class="nav-link"
              data-toggle="tab"
              href="#tab-9"
              @click="activeTab = 9"
              ><img
                src="/static/img/material-gray.png"
                alt="Materiały"
                width="13"
                height="13"
                style="margin-right:6px;"
              />Materiały</a
            >
          </li>
        </ul>

        <div class="tab-content">
          <div id="tab-1" class="tab-pane active">
            <div class="full-height-scroll">
              <dictionary-table
                :showHeader="false"
                :showSearchBar="false"
                :searchFromOutside="orderStatusesSearch"
                url="orderstatus"
                :columns="[{ key: 'name', header: 'Nazwa' }]"
              />
            </div>
          </div>
          <div id="tab-2" class="tab-pane">
            <dictionary-table
              :showHeader="false"
              :showSearchBar="false"
              :searchFromOutside="actionTypesSearch"
              url="actiontype"
              :columns="[{ key: 'name', header: 'Nazwa' }]"
            />
          </div>
          <div id="tab-3" class="tab-pane">
            <div class="full-height-scroll">
              <dictionary-table
                :showHeader="false"
                :showSearchBar="false"
                :searchFromOutside="orderTypesSearch"
                url="ordertype"
                :columns="[{ key: 'name', header: 'Nazwa' }]"
              />
            </div>
          </div>
          <div id="tab-4" class="tab-pane">
            <div class="full-height-scroll">
              <dictionary-table
                :showHeader="false"
                :showSearchBar="false"
                :searchFromOutside="userTypesSearch"
                url="usertype"
                :columns="[
                  { key: 'name', header: 'Nazwa' },
                  { key: 'note', header: 'Notatka' }
                ]"
              />
            </div>
          </div>
          <div id="tab-6" class="tab-pane">
            <div class="full-height-scroll">
              <dictionary-table
                :showHeader="false"
                :showSearchBar="false"
                :searchFromOutside="realizationLevelsSearch"
                url="realizationpriority"
                :columns="[
                  { key: 'name', header: 'Nazwa' },
                  { key: 'days', header: 'Dni' }
                ]"
              />
            </div>
          </div>
          <div id="tab-7" class="tab-pane">
            <div class="full-height-scroll">
              <dictionary-table
                :showHeader="false"
                :showSearchBar="false"
                :searchFromOutside="resourceTypesSearch"
                url="resourcetype"
                :columns="[
                  { key: 'name', header: 'Nazwa' },
                  { key: 'extension', header: 'Rozszerzenie' }
                ]"
              />
            </div>
          </div>
          <div id="tab-8" class="tab-pane">
            <div class="full-height-scroll">
              <dictionary-table
                :showHeader="false"
                :showSearchBar="false"
                :searchFromOutside="directionsSearch"
                url="direction"
                :columns="[{ key: 'name', header: 'Nazwa' }]"
              />
            </div>
          </div>
          <div id="tab-9" class="tab-pane">
            <div class="full-height-scroll">
              <dictionary-table
                :showHeader="false"
                :showSearchBar="false"
                :searchFromOutside="materialsSearch"
                url="material"
                :columns="[{ key: 'name', header: 'Nazwa' }]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DictionaryTable from "@/components/dictionaries/DictionaryTable.vue";
import navItems from "@/helpers/navigationPanelHelper.js";

export default {
  name: "dictionaries-table-view",
  components: {
    DictionaryTable
  },
  data() {
    return {
      activeTab: 1,
      searchBox: "",
      orderStatusesSearch: "",
      actionTypesSearch: "",
      orderTypesSearch: "",
      userTypesSearch: "",
      realizationLevelsSearch: "",
      resourceTypesSearch: "",
      directionsSearch: "",
      materialsSearch: ""
    };
  },
  computed: {
    searchBarPlaceholder() {
      switch (this.activeTab) {
        case 1:
          return "Szukaj statusu zlecenia..";
        case 2:
          return "Szukaj typu akcji..";
        case 3:
          return "Szukaj typu zlecenia..";
        case 4:
          return "Szukaj typu użytkownika..";
        case 6:
          return "Szukaj pozostałego czasu realizacji..";
        case 7:
          return "Szukaj typu zasobu..";
        case 8:
          return "Szukaj rodzaju zlecenia..";
        case 9:
          return "Szukaj materiału..";
      }

      return "";
    }
  },
  watch: {
    activeTab() {
      this.searchBox = "";

      switch (this.activeTab) {
        case 1:
          this.orderStatusesSearch = "";
          break;
        case 2:
          this.actionTypesSearch = "";
          break;
        case 3:
          this.orderTypesSearch = "";
          break;
        case 4:
          this.userTypesSearch = "";
          break;
        case 6:
          this.realizationLevelsSearch = "";
          break;
        case 7:
          this.resourceTypesSearch = "";
          break;
        case 8:
          this.directionsSearch = "";
          break;
        case 9:
          this.materialsSearch = "";
          break;
      }
    }
  },
  created() {
    this.setNavigationPanel(navItems.DICTIONARIES);
  },
  methods: {
    ...mapActions({
      setNavigationPanel: "changeNavigationPanelItems"
    }),
    search() {
      switch (this.activeTab) {
        case 1:
          this.orderStatusesSearch = this.searchBox;
          break;
        case 2:
          this.actionTypesSearch = this.searchBox;
          break;
        case 3:
          this.orderTypesSearch = this.searchBox;
          break;
        case 4:
          this.userTypesSearch = this.searchBox;
          break;
        case 6:
          this.realizationLevelsSearch = this.searchBox;
          break;
        case 7:
          this.resourceTypesSearch = this.searchBox;
          break;
        case 8:
          this.directionsSearch = this.searchBox;
          break;
        case 9:
          this.materialsSearch = this.searchBox;
          break;
      }
    }
  }
};
</script>

<style scoped></style>
