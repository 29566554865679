<template>
  <div :id="id">
    <strong class="dropAreaCaption">
      {{ caption }}
    </strong>
  </div>
</template>

<script>
export default {
  name: "drop-area",
  props: {
    id: { type: String },
    caption: {
      type: String,
      default: "Upuść plik tutaj"
    }
  },
  data() {
    return {
      dropArea: null
    };
  },
  mounted() {
    this.dropArea = document.getElementById(this.id);

    // Prevent default drag behaviors
    ["dragenter", "dragover", "dragleave", "drop"].forEach(eventName => {
      this.dropArea.addEventListener(eventName, this.preventDefaults, false);
      document.body.addEventListener(eventName, this.preventDefaults, false);
    });

    // Highlight drop area when item is dragged over it
    ["dragenter", "dragover"].forEach(eventName => {
      this.dropArea.addEventListener(eventName, this.highlight, false);
    });

    ["dragleave", "drop"].forEach(eventName => {
      this.dropArea.addEventListener(eventName, this.unhighlight, false);
    });

    // Handle dropped files
    this.dropArea.addEventListener("drop", this.handleDrop, false);
  },
  methods: {
    preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    highlight() {
      this.dropArea.classList.add("highlight");
    },
    unhighlight() {
      this.dropArea.classList.remove("highlight");
    },
    handleDrop(e) {
      var dt = e.dataTransfer;
      var files = dt.files;

      this.$emit("onFileChanged", [...files]);
    }
  }
};
</script>

<style scoped>
.dropArea {
  height: 140px;
  border: 1px dashed #1ab394;
  position: relative;
}

.dropAreaCaption {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
}

.dropArea.highlight {
  border-color: green;
}
</style>
