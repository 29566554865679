<template>
  <div class="ibox">
    <div class="ibox-content">
      <h3 class="mt-2 mb-4">
        Galeria akcji {{ actionTypeName }} ze zlecenia
        <a @click="goToOrderDetails()" :class="'text-navy'">
          {{ orderNumber }}
        </a>
      </h3>

      <files-table
        :key="actionsResourcesKey"
        :url="`action/${actionId}/resources`"
        :showBorder="false"
        :longerSearchBar="true"
        modalId="modalImageContainer1"
        :deletingEnabled="true"
        @fileDeleted="actionsResourcesKey++"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FilesTable from "@/components/fileRepositories/FilesTable";
import navItems from "@/helpers/navigationPanelHelper.js";

export default {
  name: "single-action-gallery",
  components: {
    FilesTable
  },
  props: {
    orderId: null,
    actionId: null,
    actionTypeName: null,
    orderNumber: null
  },
  data() {
    return {
      actionsResourcesKey: 0
    };
  },
  created() {
    this.setNavigationPanel(navItems.SINGLE_ACTION_GALLERY);
  },
  methods: {
    ...mapActions({
      setNavigationPanel: "changeNavigationPanelItems"
    }),
    goToOrderDetails() {
      if (this.orderId == null) return;

      this.$router.push({
        name: "orderDetails",
        params: { id: this.orderId }
      });
    }
  }
};
</script>

<style></style>
