<template>
  <div class="row">
    <div class="col-lg-4">
      <user-details-general
        :id="userIdForDetails"
        @userInfoReceived="userInfoReceived"
      />
    </div>
    <div v-if="isWorker === true" class="col-lg-8">
      <user-details-additional :id="userIdForDetails" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UserDetailsGeneral from "@/components/users/UserDetailsGeneral";
import UserDetailsAdditional from "@/components/users/UserDetailsAdditional";
import navItems from "@/helpers/navigationPanelHelper.js";

export default {
  name: "user-details",
  components: {
    UserDetailsGeneral,
    UserDetailsAdditional
  },
  data() {
    return {
      isWorker: null
    };
  },
  computed: {
    userIdForDetails() {
      return parseInt(this.$route.params.id);
    }
  },
  created() {
    this.setNavigationPanel(navItems.USER_DETAILS);
  },
  methods: {
    ...mapActions({
      setNavigationPanel: "changeNavigationPanelItems"
    }),
    userInfoReceived(userType) {
      this.isWorker = userType?.id === 2;
    }
  }
};
</script>

<style></style>
