import repository from "./repository.js";
import { getNeighbouringPolesExcludingGiven } from "@/helpers/mapHelper.js";

export default {
  async getPolesObject(
    page,
    itemsPerPage,
    search,
    sortBy,
    orderType,
    filter,
    userFilter
  ) {
    let url = "/pole";

    if (filter && filter.subject && filter.id != null)
      url = `/${filter.subject}/${filter.id}/poles`;
    else if (filter && filter.url) url = filter.url;

    let userFilterUrlEnding = "";

    if (userFilter) {
      for (const property in userFilter) {
        userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`
      )
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(error => {
        return { error };
      });
  },

  async getPoleDetailsGeneral(id) {
    return repository.get(`/slup/${id}`).then(response => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async getNeighbouringPolesByCoords({ lat, lng }, poleToExclude) {
    if (lat == null || lng == null) return null;

    return repository
      .get(`slup/getNearestFromPosition/${lng}/${lat}`)
      .then(response => {
        if (response.data.error == null)
          return getNeighbouringPolesExcludingGiven(
            response.data,
            poleToExclude
          );
      });
  },

  async deletePole(poleId) {
    return repository
      .delete(`/pole/${poleId}`)
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  }
};
