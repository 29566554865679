<template>
  <div class="ibox">
    <div class="ibox-title">
      <h5>Zlecenia, do których dodany zostanie protokół geodezji</h5>
      <div class="ibox-tools">
        <a
          class="btn btn-primary btn-xs"
          @click="
            showModal(MODAL_ORDERS_TABLE, {
              additionalProps: {
                initialUserFilter: { id_order_type: [2, 3] }
              }
            })
          "
          >+ Dodaj zlecenie do listy</a
        >
      </div>
    </div>
    <div class="ibox-content">
      <div
        v-if="orders && orders && orders.length > 0"
        class="table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th style="white-space: nowrap;">Nr zlecenia</th>
              <th style="white-space: nowrap; text-align: center;">
                Rodzaj zlecenia
              </th>
              <th style="white-space: nowrap; text-align: center;">Typ</th>
              <th style="white-space: nowrap;">Słupy do likwidacji</th>
              <th>Region</th>
              <th style="white-space: nowrap;">Lokalizacja LN</th>
              <th>Koordynator</th>
              <th>EBP</th>
              <th>Wpłynęło do RADO</th>
              <th>Termin realizacji</th>
              <th>Opóźnienia</th>
              <th style="white-space: nowrap; text-align: center;">
                Status zlecenia
              </th>
              <th style="white-space: nowrap; text-align: center;">
                Etap realizacji
              </th>
              <th style="white-space: nowrap; text-align: center;">
                Etap geodezji
              </th>
              <th>Wartość zamówienia</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders" :key="order.id">
              <td style="white-space: nowrap;">
                {{
                  order && order.order_number != null ? order.order_number : "-"
                }}
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order && order.direction && order.direction.color != null
                      ? 'background-color:' + order.direction.color
                      : ''
                  "
                >
                  {{
                    order && order.direction && order.direction.name != null
                      ? order.direction.name
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order && order.orderType && order.orderType.color != null
                      ? 'background-color:' + order.orderType.color
                      : ''
                  "
                  :data-toggle="
                    order && order.orderType && order.orderType.name
                      ? 'tooltip'
                      : ''
                  "
                  :title="
                    order && order.orderType && order.orderType.name
                      ? order.orderType.name
                      : ''
                  "
                >
                  {{
                    order && order.orderType && order.orderType.name != null
                      ? getShorthandIfExists(order.orderType.name)
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.target != null ? order.target : "-" }}
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.region && order.region.name != null
                    ? order.region.name
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.address != null ? order.address : "-" }}
              </td>

              <td style="white-space: nowrap;">
                <span
                  v-if="
                    order == null ||
                      order.coordinator == null ||
                      order.coordinator.firstName == null ||
                      order.coordinator.lastName == null
                  "
                >
                  -
                </span>
                <span v-else>
                  {{
                    order.coordinator.firstName +
                      " " +
                      order.coordinator.lastName
                  }}
                </span>
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.ebp != null ? order.ebp : "-" }}
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.receive_date != null
                    ? getDateWithoutTime(order.receive_date)
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.deadline != null
                    ? getDateWithoutTime(order.deadline)
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.delayDays != null ? order.delayDays : "-" }}
              </td>

              <td style="white-space: nowrap;">
                <span
                  class="label label-primary"
                  :style="
                    order &&
                    order.order_status &&
                    order.order_status.color != null
                      ? 'background-color:' + order.order_status.color
                      : ''
                  "
                >
                  {{
                    order &&
                    order.order_status &&
                    order.order_status.name != null
                      ? order.order_status.name
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order &&
                    order.implementation_phase &&
                    order.implementation_phase.color != null
                      ? 'background-color:' + order.implementation_phase.color
                      : ''
                  "
                >
                  {{
                    order &&
                    order.implementation_phase &&
                    order.implementation_phase.name != null
                      ? order.implementation_phase.name
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order &&
                    order.geodesy_phase &&
                    order.geodesy_phase.color != null
                      ? 'background-color:' + order.geodesy_phase.color
                      : ''
                  "
                >
                  {{
                    order &&
                    order.geodesy_phase &&
                    order.geodesy_phase.name != null
                      ? order.geodesy_phase.name
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.cost != null ? order.cost : "-" }} zł
              </td>

              <td style="white-space: nowrap;">
                <div class="float-right">
                  <button
                    v-if="order.canBeDeleted"
                    class="btn btn-outline btn-primary btn-xs"
                    type="button"
                    @click="deleteItemFromList(order)"
                    onclick="event.stopPropagation()"
                  >
                    <span>Usuń</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>
      <div></div>
    </div>

    <modal-base ref="modalBase">
      <modal-body>
        <template v-slot:title>
          {{ modal.title }}
        </template>
        <template v-slot:body>
          <component
            :is="modal.type"
            @on-completed="onModalCompleted"
            :key="modal.key"
            v-bind="modal.properties"
          ></component>
        </template>
      </modal-body>
    </modal-base>
  </div>
</template>

<script>
import {
  getLocalFormattedDateAndTime,
  getDateWithoutTime
} from "@/helpers/dateFormatHelper.js";
import { getShorthandIfExists } from "@/helpers/valueShorthandHelper.js";
import { MODAL_ORDERS_TABLE } from "@/helpers/modalConstants.js";
import modalTablesMixin from "@/mixins/modalTablesMixin.js";

export default {
  name: "geodetic-protocol-aggregate-assigning",
  mixins: [modalTablesMixin],
  props: {
    initialOrders: {
      type: Array,
      default: () => new Array()
    }
  },
  data() {
    return {
      orders: null
    };
  },
  created() {
    this.orders = this.initialOrders;
  },
  methods: {
    getLocalFormattedDateAndTime,
    getDateWithoutTime,
    getShorthandIfExists,
    onModalCompleted(data, hideModal = true) {
      if (hideModal) this.$refs.modalBase.hide();

      switch (this.modal.type) {
        case MODAL_ORDERS_TABLE:
          data.canBeDeleted = true;
          if (this.orders.some(x => x.id != null && x.id === data.id))
            this.$toastr.Add({
              msg: "Wybrane zlecenie jest już na liście.",
              progressbar: false,
              timeout: 3000,
              type: "warning"
            });
          else this.orders.push(data);
          break;
      }
    },
    deleteItemFromList(orderToDelete) {
      this.orders = this.orders.filter(
        x => x.id != null && x.id !== orderToDelete.id
      );
    }
  }
};
</script>

<style></style>
