import repositoryUsers from "./repositoryUsers.js";
import repositoryAuth from "./repositoryAuth.js";
import repositoryOrders from "./repositoryOrders.js";
import repositoryPoles from "./repositoryPoles.js";
import repositoryDictionaries from "./repositoryDictionaries.js";
import repositoryActions from "./repositoryActions.js";
import repositoryFiles from "./repositoryFiles.js";
import repositoryOrderContractors from "./repositoryOrderContractors.js";
import repositoryTransport from "./repositoryTransport.js";
import repositoryHelpers from "./repositoryHelpers.js";

const repositories = {
  auth: repositoryAuth,
  users: repositoryUsers,
  orders: repositoryOrders,
  poles: repositoryPoles,
  dictionaries: repositoryDictionaries,
  actions: repositoryActions,
  files: repositoryFiles,
  orderContractors: repositoryOrderContractors,
  transport: repositoryTransport,
  helpers: repositoryHelpers
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
