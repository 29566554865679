<template>
  <div class="col-lg-12">
    <div class="tabs-container">
      <ul class="nav nav-tabs" role="tablist">
        <li @click="onTabClicked(1)">
          <a
            class="nav-link active"
            id="tab-1-header"
            data-toggle="tab"
            href="#tab-1"
          >
            Zlecenia</a
          >
        </li>
        <li @click="onTabClicked(2)">
          <a class="nav-link" id="tab-2-header" data-toggle="tab" href="#tab-2"
            >Mapa ze zleceniami</a
          >
        </li>
      </ul>
      <div class="tab-content">
        <div role="tabpanel" id="tab-1" class="tab-pane active">
          <div class="panel-body">
            <orders-table
              v-if="alreadyLoaded.ordersTable"
              ref="ordersTableChild"
              :filter="{ url: `/order` }"
              :addButton="true"
              :editButton="false"
              :showBorder="false"
              :showTitle="false"
              :initialUserFilter="initialUserFilter"
              :globalFilterPanel="true"
              :filterFromParent="userFilter"
              :searchFromParent="searchBox"
              @afterSuccessfulFetch="updateFiltersAfterFetch"
            >
              <template v-slot:globalFilterPanel>
                <orders-table-filter-and-search-global
                  ref="filterChildForTable"
                  :hideWorkerFilter="false"
                  @fetchData="onFetchDataWithFiltersRequest"
                  :searchBox="searchBox"
                  :filterPanel="filterPanel"
                  :filterPanelDisplay="filterPanelDisplay"
                  :appliedFilter="appliedFilter"
                  @searchChanged="onSearchChangedInFilterPanel"
                />
              </template>
            </orders-table>
          </div>
        </div>
        <div role="tabpanel" id="tab-2" class="tab-pane">
          <div class="panel-body">
            <orders-map
              v-if="alreadyLoaded.ordersMap"
              ref="ordersMapChild"
              :filterFromParent="userFilter"
              :mapTabActive="mapTabActive"
              @afterSuccessfulFetch="updateFiltersAfterFetch"
            >
              <template v-slot:globalFilterPanel>
                <orders-table-filter-and-search-global
                  ref="filterChildForMap"
                  :hideSearchBar="true"
                  :oneFilterPerRow="true"
                  @fetchData="onFetchDataWithFiltersRequest"
                  :searchBox="searchBox"
                  :filterPanel="filterPanel"
                  :filterPanelDisplay="filterPanelDisplay"
                  :appliedFilter="appliedFilter"
                />
              </template>
            </orders-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { mapActions, mapGetters } from "vuex";
import OrdersTable from "@/components/orders/OrdersTable";
import OrdersMap from "@/components/orders/OrdersMap";
import navItems from "@/helpers/navigationPanelHelper.js";
import OrdersTableFilterAndSearchGlobal from "@/components/orders/OrdersTableFilterAndSearchGlobal";

export default {
  name: "orders-table-view",
  components: {
    OrdersTable,
    OrdersMap,
    OrdersTableFilterAndSearchGlobal
  },
  props: {
    status: null,
    realizationPhase: null,
    geodeticPhase: null
  },
  data() {
    return {
      alreadyLoaded: {
        ordersTable: false,
        ordersMap: false
      },
      userFilter: null,
      searchBox: "",
      filterPanel: {
        coordinator: null,
        orderStatus: null,
        orderType: null,
        priorityChange: null,
        direction: null,
        region: null,
        county: null,
        realizationPhase: null,
        geodeticPhase: null
      },
      filterPanelDisplay: {
        coordinatorDisplayName: null,
        orderStatusDisplayName: null,
        orderTypeDisplayName: null,
        directionDisplayName: null,
        regionDisplayName: null,
        countyDisplayName: null,
        orderRealizationPhaseDisplayName: null,
        orderGeodeticPhaseDisplayName: null
      },
      appliedFilter: new Object(),
      mapTabActive: false
    };
  },
  computed: {
    ...mapGetters({ initialTab: "initialOrdersTab" }),
    initialUserFilter() {
      let filter = new Object();

      if (this.status != null) filter.id_order_status = this.status;

      if (this.realizationPhase != null)
        filter.id_implementation_phase = this.realizationPhase;

      if (this.geodeticPhase != null)
        filter.id_geodesy_phase = this.geodeticPhase;

      return filter;
    }
  },
  created() {
    this.setNavigationPanel(navItems.ORDERS_LIST);

    if (this.initialTab === 1) {
      this.alreadyLoaded.ordersTable = true;
      this.mapTabActive = false;
    } else {
      this.alreadyLoaded.ordersMap = true;
      this.mapTabActive = true;
    }

    if (
      this.status != null ||
      this.realizationPhase != null ||
      this.geodeticPhase != null
    )
      this.assignInitialFilter();
  },
  mounted() {
    if (this.initialTab === 2) {
      this.alreadyLoaded.ordersMap = true;
      document.getElementById("tab-1-header").classList.remove("active");
      document.getElementById("tab-1").classList.remove("active");
      document.getElementById("tab-2-header").classList.add("active");
      document.getElementById("tab-2").classList.add("active");
    }
  },
  methods: {
    ...mapActions({
      setNavigationPanel: "changeNavigationPanelItems"
    }),
    ...mapActions(["changeInitialTab"]),
    onTabClicked(tabId) {
      switch (tabId) {
        case 1:
          this.alreadyLoaded.ordersTable = true;
          this.changeInitialTab(1);
          this.mapTabActive = false;
          break;
        case 2:
          this.alreadyLoaded.ordersMap = true;
          this.changeInitialTab(2);
          this.mapTabActive = true;
          break;
      }
    },
    showProperTab(tabNumber) {
      $(`.nav-tabs a[href="#tab-${tabNumber}"]`).tab("show");
      this.onTabClicked(tabNumber);
    },
    onFetchDataWithFiltersRequest({ filter }) {
      this.userFilter = filter;

      if (this.alreadyLoaded.ordersTable)
        this.$nextTick(() => {
          this.$refs.ordersTableChild?.fetchData();
        });

      if (this.alreadyLoaded.ordersMap)
        this.$nextTick(() => {
          this.$refs.ordersMapChild?.fetchData();
        });
    },
    assignInitialFilter() {
      this.userFilter = new Object();

      if (this.filterPanel == null) this.filterPanel = new Object();

      if (this.filterPanelDisplay == null)
        this.filterPanelDisplay = new Object();

      this.assignOrderStatusInitialFilter();
      this.assignOrderRealizationPhaseInitialFilter();
      this.assignOrderGeodeticPhaseInitialFilter();
    },
    updateFiltersAfterFetch() {
      this.appliedFilter = Object.assign({}, this.filterPanel);
    },
    onSearchChangedInFilterPanel(newSearchValue) {
      this.searchBox = newSearchValue;
    },
    assignOrderStatusInitialFilter() {
      if (this.status == null) return;

      this.userFilter.id_order_status = this.status;

      let orderStatusObj = {};
      orderStatusObj.id = this.userFilter.id_order_status;

      switch (this.userFilter.id_order_status) {
        case "1":
          orderStatusObj.name = "Do dyspozycji";
          break;
        case "2":
          orderStatusObj.name = "W produkcji";
          break;
        case "3":
          orderStatusObj.name = "Przerwane";
          break;
        case "4":
          orderStatusObj.name = "Anulowane";
          break;
        case "5":
          orderStatusObj.name = "Zakończone rozliczone";
          break;
      }

      this.filterPanel.orderStatus = orderStatusObj;
      this.filterPanelDisplay.orderStatusDisplayName =
        orderStatusObj?.name ?? "";
    },
    assignOrderRealizationPhaseInitialFilter() {
      if (this.realizationPhase == null) return;

      this.userFilter.id_implementation_phase = this.realizationPhase;

      let orderRealizationPhaseObj = {};
      orderRealizationPhaseObj.id = this.userFilter.id_implementation_phase;

      switch (this.userFilter.id_implementation_phase) {
        case "1":
          orderRealizationPhaseObj.name = "Nierozpoczęty demontaż";
          break;
        case "2":
          orderRealizationPhaseObj.name = "Rozpoczęty demontaż";
          break;
        case "3":
          orderRealizationPhaseObj.name = "Przerwany demontaż";
          break;
        case "4":
          orderRealizationPhaseObj.name = "Zakończony demontaż do zabrania";
          break;
        case "5":
          orderRealizationPhaseObj.name = "Zakończony demontaż prośba o PO";
          break;
        case "6":
          orderRealizationPhaseObj.name = "Zrealizowany demontaż";
          break;
        case "7":
          orderRealizationPhaseObj.name = "Geodezja";
          break;
      }

      this.filterPanel.realizationPhase = orderRealizationPhaseObj;
      this.filterPanelDisplay.orderRealizationPhaseDisplayName =
        orderRealizationPhaseObj?.name ?? "";
    },
    assignOrderGeodeticPhaseInitialFilter() {
      if (this.geodeticPhase == null) return;

      this.userFilter.id_geodesy_phase = this.geodeticPhase;

      let orderGeodeticPhaseObj = {};
      orderGeodeticPhaseObj.id = this.userFilter.id_geodesy_phase;

      switch (this.userFilter.id_geodesy_phase) {
        case "1":
          orderGeodeticPhaseObj.name = "Do zlecenia geodecie";
          break;
        case "2":
          orderGeodeticPhaseObj.name = "W realizacji";
          break;
        case "3":
          orderGeodeticPhaseObj.name = "Przesłano do weryfikacji Orange";
          break;
      }

      this.filterPanel.geodeticPhase = orderGeodeticPhaseObj;
      this.filterPanelDisplay.orderGeodeticPhaseDisplayName =
        orderGeodeticPhaseObj?.name ?? "";
    }
  }
};
</script>
<style></style>
