<template>
  <div class="middle-box text-center loginscreen animated fadeIn">
    <div>
      <div>
        <h1 class="logo-name" style="font-size: 100px">RADO</h1>
      </div>
      <h3>Witaj w RADO Demontaże</h3>
      <form class="m-t" @submit="logIn">
        <div class="form-group">
          <input
            v-model="username"
            type="email"
            class="form-control"
            placeholder="Wpisz email"
            required
            minlength="1"
            maxlength="100"
          />
        </div>
        <div class="form-group">
          <input
            v-model="password"
            type="password"
            class="form-control"
            placeholder="Wpisz hasło"
            required
            minlength="1"
            maxlength="100"
          />
        </div>

        <div v-if="formInfo && formInfo !== ''">
          <p style="color: red">{{ formInfo }}</p>
        </div>

        <div v-if="isLoading" class="sk-spinner sk-spinner-circle mb-3 mt-3">
          <div class="sk-circle1 sk-circle"></div>
          <div class="sk-circle2 sk-circle"></div>
          <div class="sk-circle3 sk-circle"></div>
          <div class="sk-circle4 sk-circle"></div>
          <div class="sk-circle5 sk-circle"></div>
          <div class="sk-circle6 sk-circle"></div>
          <div class="sk-circle7 sk-circle"></div>
          <div class="sk-circle8 sk-circle"></div>
          <div class="sk-circle9 sk-circle"></div>
          <div class="sk-circle10 sk-circle"></div>
          <div class="sk-circle11 sk-circle"></div>
          <div class="sk-circle12 sk-circle"></div>
        </div>

        <button type="submit" class="btn btn-primary block full-width m-b">
          Zaloguj
        </button>

        <a @click="onForgotPasswordClicked"
          ><small>Zapomniałeś hasło?</small></a
        >
      </form>
      <p class="m-t">
        <small>DKK Development &copy; 2020</small>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  AUTH_REQUEST,
  AUTH_RESET_FORM_ERROR
} from "@/helpers/authConstants.js";

export default {
  name: "view-login",
  data() {
    return {
      username: "",
      password: "",
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      formInfo: state => state.storeAuth.formInfo
    })
  },
  methods: {
    logIn: function(e) {
      e.preventDefault();
      this.isLoading = true;
      const { username, password } = this;

      this.$store
        .dispatch(AUTH_REQUEST, { username, password })
        .then(() => {
          //redirected to orders after logging in
          this.$router.push("/orders");
          this.$store.dispatch(AUTH_RESET_FORM_ERROR);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onForgotPasswordClicked: function() {
      this.$router.push({ name: "forgotPassword" });
    }
  }
};
</script>

<style scoped></style>
