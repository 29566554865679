<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="row mb-3">
            <div v-if="userFilterEnabled" class="col-sm-6">
              <a
                type="button"
                class="collapse-link-modified btn btn-w-m btn-default"
              >
                <span v-if="filtersCount > 0" class="badge badge-warning">{{
                  filtersCount
                }}</span>
                Filtry
                <i class="fa fa-chevron-down"></i>
              </a>
            </div>

            <div
              v-if="!hideSearchBar"
              :class="
                longerSearchBar
                  ? 'col-sm-4 ' +
                    (userFilterEnabled ? 'offset-sm-2' : 'offset-sm-8')
                  : 'col-sm-3 ' +
                    (userFilterEnabled ? 'offset-sm-3' : 'offset-sm-9')
              "
            >
              <div class="input-group">
                <input
                  placeholder="Szukaj.."
                  type="text"
                  class="form-control form-control-sm"
                  v-model="searchBox"
                  v-on:keyup.enter="search"
                />
                <span class="input-group-append">
                  <button
                    @click="search"
                    type="button"
                    class="btn btn-sm btn-primary"
                  >
                    <i class="fa fa-search"></i> Szukaj
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="ibox-content" style="display: none;">
            <div class="row">
              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Typ użytkownika"
                  :titleCssClass="userTypeApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.userTypeDisplayName"
                  @showModal="showModal(MODAL_USER_TYPES_TABLE)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 offset-6">
                <button
                  class="btn btn-outline btn-info btn-xs float-right"
                  @click="onApplyFiltersButtonClicked"
                >
                  Zastosuj filtry
                </button>
                <button
                  class="btn btn-outline btn-danger btn-xs float-right mr-1"
                  @click="onClearAllFiltersButtonClicked"
                >
                  Wyczyść filtry
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal-base ref="modalBase">
      <modal-body>
        <template v-slot:title>
          {{ modal.title }}
        </template>
        <template v-slot:body>
          <component
            :is="modal.component"
            @on-completed="onModalCompleted($event)"
            :key="modal.key"
            v-bind="modal.properties"
          ></component>
        </template>
        <template v-slot:footer>
          <button
            type="button"
            class="btn btn-outline btn-danger"
            @click="onModalRejected"
          >
            Usuń wybór
          </button>
        </template>
      </modal-body>
    </modal-base>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { MODAL_USER_TYPES_TABLE } from "@/helpers/modalConstants.js";
import modalTablesMixin from "@/mixins/modalTablesMixin.js";

import ModalInputField from "@/components/utils/ModalInputField";

export default {
  name: "users-table-filter-and-search",
  mixins: [modalTablesMixin],
  components: {
    ModalInputField
  },
  props: {
    longerSearchBar: {
      type: Boolean,
      default: false
    },
    userFilterEnabled: {
      type: Boolean,
      default: true
    },
    hideSearchBar: {
      type: Boolean,
      default: false
    },
    oneFilterPerRow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchBox: "",
      filterPanel: {
        userType: null
      },
      filterPanelDisplay: {
        userTypeDisplayName: null
      },
      appliedFilter: new Object()
    };
  },
  computed: {
    filtersCount() {
      let count = 0;

      for (const property in this.appliedFilter) {
        if (this.appliedFilter[property] != null) count++;
      }

      return count;
    },
    userTypeApplied() {
      return this.filterApplied(
        this.filterPanel?.userType?.id,
        this.appliedFilter?.userType?.id
      );
    }
  },
  created() {},
  mounted() {
    this.configureCollapsingFilterPanel();
  },
  methods: {
    configureCollapsingFilterPanel() {
      $(".collapse-link-modified")
        .off("click")
        .on("click", function(e) {
          e.preventDefault();
          var ibox = $(this).closest("div.ibox");
          var button = $(this).find("i");
          var content = ibox.children(".ibox-content");
          content.slideToggle(200);
          button.toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
          setTimeout(function() {
            ibox.resize();
            ibox.find("[id^=map-]").resize();
          }, 50);
        });
    },
    onModalCompleted(data) {
      this.$refs.modalBase.hide();

      switch (this.modal.type) {
        case MODAL_USER_TYPES_TABLE:
          this.filterPanel.userType = data;
          this.filterPanelDisplay.userTypeDisplayName = data?.name ?? "";
          break;
      }
    },
    onModalRejected() {
      this.$refs.modalBase.hide();

      switch (this.modal.type) {
        case MODAL_USER_TYPES_TABLE:
          this.filterPanel.userType = null;
          this.filterPanelDisplay.userTypeDisplayName = null;
          break;
      }
    },
    onClearAllFiltersButtonClicked() {
      for (const property in this.filterPanel) {
        this.filterPanel[property] = null;
      }

      for (const property in this.filterPanelDisplay) {
        this.filterPanelDisplay[property] = null;
      }

      this.triggerFetchData();
    },
    onApplyFiltersButtonClicked() {
      this.triggerFetchData();
    },
    getUserFilter() {
      let userFilter = new Object();

      if (this.filterPanel?.userType?.id != null)
        userFilter.id_user_type = this.filterPanel.userType.id;

      return userFilter;
    },
    updateAppliedFilters() {
      this.appliedFilter = Object.assign({}, this.filterPanel);
    },
    filterApplied(filterPanelProp, appliedFilterProp) {
      if (appliedFilterProp != null && filterPanelProp != null)
        return appliedFilterProp === filterPanelProp;
      else return appliedFilterProp == filterPanelProp;
    },
    search() {
      this.triggerFetchData();
    },
    triggerFetchData() {
      this.$emit("fetchData", {
        search: this.searchBox,
        filter: this.getUserFilter()
      });
    }
  }
};
</script>

<style></style>
