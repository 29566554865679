<template>
  <div>
    <ul class="dropdown-menu dropdown-alerts">
      <div
        v-if="
          notificationsObject &&
            notificationsObject.data &&
            notificationsObject.data.length > 0
        "
        class="table-responsive"
      >
        <div
          v-for="notification in notificationsObject.data"
          :key="notification.id"
        >
          <li>
            <a class="dropdown-item" @click="showOrderDetails(notification.id)">
              <div class="row">
                <div class="col-2">
                  <i
                    class="fa fa-envelope"
                    style="position: absolute; top: 50%; transform: translate(0%, -50%);"
                  ></i>
                </div>
                <div class="col-10">
                  <div class="row">
                    {{
                      notification.worker && notification.worker.firstName
                        ? notification.worker.firstName
                        : "-"
                    }}
                    {{
                      notification.worker && notification.worker.lastName
                        ? notification.worker.lastName
                        : "-"
                    }}
                  </div>
                  <div class="row">
                    <span class="float-right text-muted small">
                      {{
                        notification.poles_state &&
                        notification.poles_state.unacceptedInspections != null
                          ? notification.poles_state.unacceptedInspections
                          : "-"
                      }}
                      przeglądów do zaakceptowania</span
                    >
                  </div>
                </div>
              </div>
            </a>
          </li>
          <li class="dropdown-divider"></li>
        </div>
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <li>
        <div class="text-center link-block">
          <a @click="showAllOrders()" class="dropdown-item">
            <strong>Zobacz wszystkie zlecenia</strong>
            <i class="fa fa-angle-right ml-1"></i>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "notifications-dropdown-list",
  data() {
    return {
      notificationsObject: {}
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters({ notificationsRefreshKey: "notificationsRefreshKey" })
  },
  watch: {
    notificationsRefreshKey() {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      // RepositoryOrders.getOrdersWithUnacceptedInspectionsObject()
      //   .then(data => {
      //     this.notificationsObject = data;
      //     if (this.notificationsObject?.data)
      //       this.$emit(
      //         "notifications-count",
      //         this.notificationsObject.unacceptedCount
      //       );
      //   })
      //   .catch(error => console.log(error))
      //   .finally(() => {});
    },
    showAllOrders() {
      if (this.$route.name === "ordersTable") return;
      this.$router.push({
        name: "ordersTable"
      });
    },
    showOrderDetails(id) {
      if (
        this.$route.name === "orderDetails" &&
        this.$route.params?.id.toString() === id.toString()
      )
        return;

      this.$router.push({
        name: "orderDetails",
        params: { id }
      });
    }
  }
};
</script>

<style></style>
