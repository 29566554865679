<template>
  <div class="row">
    <div class="ibox col-lg-8 pr-1">
      <div
        class="ibox-content"
        :class="{ 'sk-loading': isLoading }"
        style="border-style: none"
      >
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <cluster-map-component
          :centerLat="mapCenter.lat"
          :centerLng="mapCenter.lon"
          :markers="markersForMap"
          height="700px"
          :areaSelecting="true"
          :disableClusteringAtZoomOverride="19"
          @selected-markers-changed="onSelectedMarkersChanged"
          @map-clicked="resetSelectedMarkers"
          @markerClicked="onMarkerClicked"
          :maxClusterRadiusOverride="1"
        />
      </div>
    </div>

    <div class="ibox col-lg-4 pl-1">
      <div
        class="ibox-content profile-content pl-4"
        :class="{ 'sk-loading': isLoading }"
        style="border-style: none"
      >
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <div v-if="pickedTransportInfo" class="mt-4">
          <h3>Szczegóły wybranego punktu transportu:</h3>

          <table class="mt-4 mb-2" style="width: 100%">
            <tr>
              <td><b>Zlecenie</b></td>
            </tr>
            <tr class="pl-3">
              <td>Numer zlecenia:</td>
              <td>
                <span
                  v-if="
                    pickedTransportInfo.order &&
                      pickedTransportInfo.order.id != null
                  "
                >
                  <a
                    @click="goToOrderDetails(pickedTransportInfo.order.id)"
                    :class="'text-navy'"
                  >
                    {{
                      pickedTransportInfo.order &&
                      pickedTransportInfo.order.order_number != null
                        ? pickedTransportInfo.order.order_number
                        : "- [link]"
                    }}
                  </a>
                </span>
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <td>Numer EBP:</td>
              <td>
                {{
                  pickedTransportInfo.order && pickedTransportInfo.order.ebp
                    ? pickedTransportInfo.order.ebp
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <td>Adres:</td>
              <td>
                {{
                  pickedTransportInfo.order && pickedTransportInfo.order.address
                    ? pickedTransportInfo.order.address
                    : "-"
                }}
              </td>
            </tr>
            <br />
            <tr>
              <td><b>Notatka:</b></td>
              <td>
                {{ pickedTransportInfo.note ? pickedTransportInfo.note : "-" }}
              </td>
            </tr>

            <br />

            <tr>
              <td><b>Utworzył(a)</b></td>
              <td>
                <span
                  v-if="
                    pickedTransportInfo.user &&
                      pickedTransportInfo.user.firstName != null &&
                      pickedTransportInfo.user.lastName != null
                  "
                >
                  <a
                    @click="goToUserDetails(pickedTransportInfo.user.id)"
                    :class="'text-navy'"
                  >
                    {{
                      pickedTransportInfo.user.firstName +
                        " " +
                        pickedTransportInfo.user.lastName
                    }}
                  </a>
                </span>
                <span v-else>-</span>
              </td>
            </tr>

            <br />

            <tr>
              <td><b>Słupy</b></td>
            </tr>
            <tr>
              <td>Betonowe:</td>
              <td>
                {{
                  pickedTransportInfo.amount_c != null
                    ? pickedTransportInfo.amount_c
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <td>Szczudła:</td>
              <td>
                {{
                  pickedTransportInfo.amount_s != null
                    ? pickedTransportInfo.amount_s
                    : "-"
                }}
              </td>
            </tr>
            <tr>
              <td>Drewniane:</td>
              <td>
                {{
                  pickedTransportInfo.amount_w != null
                    ? pickedTransportInfo.amount_w
                    : "-"
                }}
              </td>
            </tr>
          </table>
        </div>
        <div v-else-if="pickedTransportsSum" class="mt-4">
          <h3>
            Szczegóły {{ pickedTransportsSum.count }} zaznaczonych punktów
            transportu:
          </h3>

          <table class="mt-4 mb-2" style="width: 100%">
            <tr>
              <td><b>Suma</b></td>
            </tr>
            <tr class="pl-3">
              <td>Drewniane:</td>
              <td>
                {{
                  pickedTransportsSum.amountWSum != null
                    ? pickedTransportsSum.amountWSum
                    : "-"
                }}
              </td>
            </tr>
            <tr class="pl-3">
              <td>Betonowe:</td>
              <td>
                {{
                  pickedTransportsSum.amountCSum != null
                    ? pickedTransportsSum.amountCSum
                    : "-"
                }}
              </td>
            </tr>
            <tr class="pl-3">
              <td>Szczudła:</td>
              <td>
                {{
                  pickedTransportsSum.amountSSum != null
                    ? pickedTransportsSum.amountSSum
                    : "-"
                }}
              </td>
            </tr>
          </table>
        </div>
        <div v-else-if="markersForMap.length > 0" class="mt-4">
          <p class="text-center">Wybierz punkt transportu z mapy</p>
        </div>

        <div class=" mt-4">
          <button
            v-if="pickedTransportInfo"
            class="btn btn-outline btn-primary btn-xs mr-2"
            type="button"
            @click="onShowTransportGalleryButtonClicked()"
            onclick="event.stopPropagation()"
          >
            Wyświetl galerię
          </button>

          <button
            v-if="pickedTransportInfo || pickedTransportsSum"
            class="btn btn-primary btn-xs"
            type="button"
            @click="onGeneratePDFButtonClicked"
          >
            Wygeneruj plik PDF
          </button>
        </div>
      </div>
    </div>

    <transport-pdf-generator
      ref="transportPdfGenerator"
      :transportIds="selectedTransportsIds"
    />
  </div>
</template>

<script>
import ClusterMapComponent from "@/components/map/ClusterMapComponent";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import TransportPdfGenerator from "@/components/transport/TransportPdfGenerator.vue";

const RepositoryTransport = RepositoryFactory.get("transport");

export default {
  name: "transport-module",
  components: {
    ClusterMapComponent,
    TransportPdfGenerator
  },
  data() {
    return {
      isLoading: false,
      transportsObject: null,
      pickedTransportInfo: null,
      pickedTransportsSum: null,
      markersForMap: [],
      ctrlPressed: false
    };
  },
  computed: {
    mapCenter() {
      if (this.markersForMap.length > 0)
        return {
          lat: Number.parseFloat(this.markersForMap[0].lat),
          lon: Number.parseFloat(this.markersForMap[0].lng)
        };
      else
        return {
          lat: null,
          lon: null
        };
    },
    selectedTransportsIds() {
      let selectedIds = [];

      this.markersForMap.forEach(marker => {
        if (marker.selected) selectedIds.push(marker?.options?.transport?.id);
      });
      return selectedIds;
    },
    selectedTransports() {
      let selectedTransports = [];

      this.markersForMap.forEach(marker => {
        if (marker.selected)
          selectedTransports.push(marker?.options?.transport);
      });

      return selectedTransports;
    }
  },
  created() {
    window.addEventListener("keydown", event => {
      if (event.code === "ControlLeft") this.ctrlPressed = true;
    });

    window.addEventListener("keyup", event => {
      if (event.code === "ControlLeft") this.ctrlPressed = false;
    });

    this.getTransportsObject();
  },
  methods: {
    async getTransportsObject() {
      this.isLoading = true;

      RepositoryTransport.getTransportsObject()
        .then(data => {
          this.transportsObject = data;
          this.prepareMarkersForMap();
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    prepareMarkersForMap() {
      this.markersForMap = new Array();

      if (Array.isArray(this.transportsObject)) {
        this.transportsObject.forEach(transport => {
          let marker = this.getConstructedMarker(transport);

          this.markersForMap.push(marker);
        });
      }
    },
    onMarkerClicked(markerToSelect) {
      if (this.ctrlPressed) {
        this.markersForMap.forEach(marker => {
          if (
            markerToSelect?.sourceTarget?.options?.transport?.id ===
            marker?.options?.transport?.id
          )
            marker.selected = !marker.selected;
        });
      } else {
        this.markersForMap.forEach(marker => {
          if (
            markerToSelect?.sourceTarget?.options?.transport?.id ===
            marker?.options?.transport?.id
          )
            marker.selected = true;
          else marker.selected = false;
        });
      }

      if (this.selectedTransportsIds.length === 1) this.loadPickedTransport();
      else if (this.selectedTransportsIds.length > 1)
        this.loadPickedTransportsSum();
    },
    async loadPickedTransport() {
      this.pickedTransportInfo = null;
      this.pickedTransportsSum = null;
      this.isLoading = true;

      this.pickedTransportInfo = await RepositoryTransport.getTransportDetails(
        this.selectedTransportsIds[0]
      );

      if (this.pickedTransportInfo.data)
        this.pickedTransportInfo = this.pickedTransportInfo.data;

      this.isLoading = false;
    },
    loadPickedTransportsSum() {
      this.pickedTransportInfo = null;
      this.isLoading = true;

      this.pickedTransportsSum = new Object();
      this.pickedTransportsSum.count = this.selectedTransportsIds.length;
      this.pickedTransportsSum.amountWSum = 0;
      this.pickedTransportsSum.amountCSum = 0;
      this.pickedTransportsSum.amountSSum = 0;

      this.markersForMap.forEach(marker => {
        if (marker.selected) {
          if (marker?.options?.transport?.amount_w)
            this.pickedTransportsSum.amountWSum +=
              marker?.options?.transport?.amount_w;
          if (marker?.options?.transport?.amount_c)
            this.pickedTransportsSum.amountCSum +=
              marker?.options?.transport?.amount_c;
          if (marker?.options?.transport?.amount_s)
            this.pickedTransportsSum.amountSSum +=
              marker?.options?.transport?.amount_s;
        }
      });

      this.isLoading = false;
    },
    goToUserDetails(id) {
      if (id == null) return;

      let routeData = this.$router.resolve({
        name: "userDetails",
        params: { id }
      });
      window.open(routeData.href, "_blank");
    },
    goToOrderDetails(id) {
      if (id == null) return;

      let routeData = this.$router.resolve({
        name: "orderDetails",
        params: { id }
      });
      window.open(routeData.href, "_blank");
    },
    onSelectedMarkersChanged(markersToSelect) {
      this.markersForMap.forEach(marker => {
        if (
          markersToSelect.some(
            x => x?.options?.transport?.id === marker?.options?.transport?.id
          )
        )
          marker.selected = true;
        else marker.selected = false;
      });

      if (this.selectedTransportsIds.length === 1) this.loadPickedTransport();
      else if (this.selectedTransportsIds.length > 1)
        this.loadPickedTransportsSum();
    },
    resetSelectedMarkers() {
      this.pickedTransportInfo = null;
      this.pickedTransportsSum = null;

      this.markersForMap.forEach(marker => {
        marker.selected = false;
      });
    },
    onGeneratePDFButtonClicked() {
      this.$swal
        .fire({
          title: "Generowanie raportu",
          text: "Czy utworzyć raport z listą punktów transportowych?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) {
            this.$refs.transportPdfGenerator.generatePdfFile();
          }
        });
    },
    getConstructedMarker(transport) {
      let marker = {
        lat: transport.geotimestamp?.lat
          ? Number.parseFloat(transport.geotimestamp.lat)
          : null,
        lng: transport.geotimestamp?.lon
          ? Number.parseFloat(transport.geotimestamp.lon)
          : null,
        draggable: false,
        options: { transport: transport },
        selected: false
      };

      return marker;
    },
    onShowTransportGalleryButtonClicked() {
      if (!this.pickedTransportInfo) return;

      let routeData = this.$router.resolve({
        name: "singleActionGallery",
        query: {
          orderId: this.pickedTransportInfo.order.id,
          actionId: this.pickedTransportInfo.id,
          actionTypeName: this.pickedTransportInfo.type.name,
          orderNumber: this.pickedTransportInfo.order.order_number
        }
      });

      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style scoped></style>
