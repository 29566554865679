export const usersSortingOptions = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  CREATED_AT: "created_at",
  EMAIL: "email"
};

export const orderContractorsSortingOptions = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  CREATED_AT: "created_at"
};

export const ordersSortingOptions = {
  CREATED_AT: "created_at",
  COST: "cost",
  TARGET: "target",
  RECEIVE_DATE: "receive_date",
  REALIZATION_TIME: "realization_date",
  DEADLINE: "deadline"
};

export const polesSortingOptions = {
  INSPECTION_DATE: "data_pgl",
  LAST_INSPECTION_DATE: "last_pgl"
};

export const sortingDirection = {
  ASC: "asc",
  DESC: "desc"
};

export function getSortingIcon(currentSort, checkIconFor) {
  if (currentSort.by === checkIconFor) {
    if (currentSort.direction === sortingDirection.ASC)
      return "<i class='fa fa-sort-asc' aria-hidden='true' />";
    else return "<i class='fa fa-sort-desc' aria-hidden='true' />";
  } else return "<i class='fa fa-sort' aria-hidden='true'/>";
}

export function getSortingDirection(currentSort, newSortBy) {
  if (currentSort.by === newSortBy) {
    if (
      currentSort.direction == null ||
      currentSort.direction === sortingDirection.DESC
    )
      return sortingDirection.ASC;
    else if (currentSort.direction === sortingDirection.ASC)
      return sortingDirection.DESC;
  } else return sortingDirection.ASC;
}
