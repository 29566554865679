<template>
  <div class="ibox ">
    <div v-if="showHeader" class="ibox-title">
      <h5>Typy użytkowników</h5>
    </div>
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>
      <div v-if="showSearchBar" class="row">
        <div class="col-sm-3 offset-sm-9">
          <div class="input-group mb-3">
            <input
              placeholder="Szukaj.."
              type="text"
              class="form-control form-control-sm"
              v-model="searchBox"
              v-on:keyup.enter="search"
            />
            <span class="input-group-append">
              <button
                @click="search"
                type="button"
                class="btn btn-sm btn-primary"
              >
                <i class="fa fa-search"></i> Szukaj
              </button>
            </span>
          </div>
        </div>
      </div>

      <div
        v-if="
          userTypesObject &&
            userTypesObject.data &&
            userTypesObject.data.length > 0
        "
        class="table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Id</th>
              <th>Nazwa</th>
              <th>Opis</th>
              <th v-if="selectableItems"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="userType in userTypesObject.data"
              :key="userType.id"
              @click="selectableItems ? pickItem(userType) : null"
              :style="selectableItems ? 'cursor: pointer;' : ''"
            >
              <td>{{ userType.id }}</td>
              <td>{{ userType.name }}</td>
              <td>{{ userType.note }}</td>
              <td v-if="selectableItems">
                <div class="float-right">
                  <button
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="pickItem(userType)"
                    onclick="event.stopPropagation()"
                  >
                    Wybierz
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="userTypesObject.error">
        <server-error-panel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6 offset-xl-3">
          <pagination
            v-if="userTypesObject && userTypesObject.data"
            align="center"
            :data="userTypesObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></pagination>
        </div>

        <div class="col-xl-3">
          <pagination-count-picker
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import { PAGINATION_LIMIT } from "@/helpers/appConstants.js";
import paginationCountPickerMixin from "@/mixins/paginationCountPickerMixin.js";
import ServerErrorPanel from "@/components/utils/ServerErrorPanel";

const RepositoryDictionaries = RepositoryFactory.get("dictionaries");

export default {
  name: "user-types-table",
  components: {
    ServerErrorPanel
  },
  mixins: [paginationCountPickerMixin],
  props: {
    selectableItems: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    showSearchBar: {
      type: Boolean,
      default: true
    },
    searchFromOutside: { type: String, default: "" }
  },
  data() {
    return {
      isLoading: false,
      userTypesObject: {},
      searchBox: "",
      currentSort: { by: null, direction: null },
      PAGINATION_LIMIT: PAGINATION_LIMIT
    };
  },
  computed: {},
  watch: {
    searchFromOutside() {
      if (this.searchFromOutside == null) return;

      this.searchBox = this.searchFromOutside;
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(page = 1) {
      this.isLoading = true;

      RepositoryDictionaries.getUserTypesObject(
        page,
        this.itemsPerPage,
        this.searchBox,
        this.currentSort.by,
        this.currentSort.direction
      )
        .then(data => {
          this.userTypesObject = data;
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    pickItem(userType) {
      this.$emit("on-completed", userType);
    },
    search() {
      this.fetchData();
    }
  }
};
</script>
<style></style>
