import repository from "./repository.js";

export default {
  async getAddressByCoords(lat, lon) {
    return repository
      .post(`/order/addressFromGeo`, {
        lat,
        lon
      })
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  }
};
