import { AUTH_LOGOUT } from "@/helpers/authConstants.js";
import axios from "axios";
import vueInstance from "@/main.js";

let instance = axios.create({
  baseURL: process.env.VUE_APP_URL
});

instance.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (
      error.response?.config.url !== "/login" &&
      error.response?.status === 401
    ) {
      vueInstance.$store.dispatch(AUTH_LOGOUT).then(() => {
        vueInstance.$router.push({ name: "login" });
      });
    }
    return Promise.reject(error);
  }
);

export default instance;
