<template>
  <div>
    <users-table :addButton="true" :editButton="true" :showFilters="true" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import navItems from "@/helpers/navigationPanelHelper.js";
import UsersTable from "@/components/users/UsersTable";

export default {
  name: "users-table-view",
  components: {
    UsersTable
  },
  created() {
    this.setNavigationPanel(navItems.USERS_LIST);
  },
  methods: {
    ...mapActions({
      setNavigationPanel: "changeNavigationPanelItems"
    })
  }
};
</script>
<style></style>
