<template>
  <div>
    <div class="row">
      <div class="ibox col-6 mb-0">
        <div
          class="ibox-content"
          :class="{ 'sk-loading': isLoading }"
          style="border-style: none"
        >
          <div class="sk-spinner sk-spinner-three-bounce">
            <div class="sk-bounce1"></div>
            <div class="sk-bounce2"></div>
            <div class="sk-bounce3"></div>
          </div>

          <cluster-map-component
            :centerLat="mapCenter.lat"
            :centerLng="mapCenter.lon"
            :markers="markersForMap"
            height="400px"
            @markerClicked="onMarkerClicked"
          />
        </div>
      </div>

      <div class="ibox col-6 mb-0" style="height: 430px;">
        <!-- <div class="ibox-title" style="border-style: none">
        <h5>Info</h5>
      </div> -->
        <div
          class="ibox-content"
          :class="{ 'sk-loading': isLoading }"
          style="height: 100%; width: 100%; border-style: none; display: flex; flex-direction: column; justify-content: space-between;"
        >
          <div class="sk-spinner sk-spinner-three-bounce">
            <div class="sk-bounce1"></div>
            <div class="sk-bounce2"></div>
            <div class="sk-bounce3"></div>
          </div>
          <div>
            <h4>
              Słupy do eksportu:
              <b>
                {{ pickedPolesCount }} z
                {{
                  polesObject && Array.isArray(polesObject.data)
                    ? polesObject.data.length
                    : "-"
                }}
              </b>
            </h4>

            <div v-if="pickedPole" class="mt-4">
              <b>Wybrany słup: </b>
              <div class="pl-2">
                <br />
                <b>Oznaczenie:</b>
                {{
                  pickedPole.oznaczenie != null ? pickedPole.oznaczenie : "-"
                }}
                <br />
                <b>Szerokość geograficzna:</b>
                {{ pickedPole.lat != null ? pickedPole.lat : "-" }}
                <br />
                <b>Długość geograficzna:</b>
                {{ pickedPole.lon != null ? pickedPole.lon : "-" }}
                <br />
                <button
                  class="btn btn-outline btn-primary mt-2"
                  @click="
                    () => {
                      pickedPole.geodezja === 1
                        ? (pickedPole.geodezja = 0)
                        : (pickedPole.geodezja = 1);

                      prepareMarkersForMap();
                    }
                  "
                >
                  <span v-if="pickedPole.geodezja === 1">Wyłącz z eksportu</span
                  ><span v-else>Dodaj do eksportu</span>
                </button>

                <button
                  class="btn btn-outline btn-danger mt-2 ml-1"
                  @click="onDeletePoleButtonClicked"
                >
                  Usuń słup
                </button>
              </div>
            </div>
            <div v-else-if="markersForMap.length > 0" class="mt-4">
              <p class="text-center">Wybierz słup z mapy</p>
            </div>
          </div>
          <div class="row mr-3" style="display:flex; justify-content: flex-end">
            <button
              :disabled="
                polesObject &&
                  Array.isArray(polesObject.data) &&
                  polesObject.data.length <= pickedPolesCount
              "
              class="btn btn-primary mr-1"
              style="display: table-cell; vertical-align: bottom;"
              @click="activateAll"
            >
              Dodaj wszystkie
            </button>
            <button
              :disabled="polesObject && pickedPolesCount <= 0"
              class="btn btn-primary mr-1"
              style="display: table-cell; vertical-align: bottom;"
              @click="deactivateAll"
            >
              Wyłącz wszystkie
            </button>
            <button
              class="btn btn-primary"
              style="display: table-cell; vertical-align: bottom;"
              @click="updatePolesForGeodesist"
            >
              Zapisz zmiany
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 mb-3">
      <div>
        <label>Link dla geodety:</label>
        <div class="row">
          <div class="col">
            <div class="input-group">
              <input
                style="width: 90%"
                id="linkForGeodesistInputId"
                type="text"
                readonly
                :value="linkForGeodesist"
              />
              <span class="input-group-append">
                <button
                  type="button"
                  class="btn btn-primary btn-outline btn-xs"
                  @click="copyToClipboard"
                >
                  <i class="fa fa-clipboard"></i>
                </button>
              </span>
            </div>
          </div>
          <div v-if="linkForGeodesist" class="col-auto">
            <button
              class="btn btn-primary btn-xs"
              @click="showGeodesistMapInNewTab"
            >
              Otwórz w nowej karcie
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClusterMapComponent from "@/components/map/ClusterMapComponent";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";

const RepositoryOrders = RepositoryFactory.get("orders");
const RepositoryPoles = RepositoryFactory.get("poles");

export default {
  name: "order-poles-picker-for-p-g",
  components: {
    ClusterMapComponent
  },
  mixins: [],
  props: {
    orderId: Number,
    orderUUID: String
  },
  data() {
    return {
      isLoading: false,
      polesObject: null,
      pickedPole: null,
      markersForMap: []
    };
  },
  computed: {
    mapCenter() {
      if (this.markersForMap.length > 0)
        return {
          lat: Number.parseFloat(this.markersForMap[0].lat),
          lng: Number.parseFloat(this.markersForMap[0].lon)
        };
      else
        return {
          lat: null,
          lng: null
        };
    },
    pickedPolesCount() {
      if (!Array.isArray(this.polesObject?.data)) return "-";

      let activeCount = 0;

      this.polesObject.data.forEach(pole => {
        if (pole.geodezja === 1) activeCount++;
      });

      return activeCount;
    },
    linkForGeodesist() {
      return window.location.origin + "/geodesistMap/" + this.orderUUID;
    }
  },
  created() {
    this.getPoles();
  },
  methods: {
    async getPoles(afterDelete = false) {
      this.isLoading = true;

      RepositoryOrders.getPolesList(this.orderId)
        .then(data => {
          this.polesObject = data;
          this.prepareMarkersForMap();
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;

          if (afterDelete && this.polesObject?.data?.length === 0)
            this.$emit("refresh");
        });
    },
    prepareMarkersForMap() {
      this.markersForMap = new Array();

      if (Array.isArray(this.polesObject?.data)) {
        this.polesObject?.data.forEach(pole => {
          let marker = {
            lat: pole.lat,
            lng: pole.lon,
            draggable: false,
            // popupCaption: "Słup",
            options: { pole: pole },
            selected: false
          };

          if (pole?.geodezja === 0) {
            marker.icon = "red_x_marker_new";
            marker.iconSize = [25, 35];
            marker.iconAnchor = [12, 35];
          }

          this.markersForMap.push(marker);
        });
      }
    },
    onMarkerClicked(clickedMarker) {
      this.pickedPole = clickedMarker?.sourceTarget?.options?.pole;

      this.markersForMap.forEach(markerForMap => {
        markerForMap.selected =
          this.pickedPole?.id === markerForMap.options.pole.id;
      });
    },
    async updatePolesForGeodesist() {
      var response = await RepositoryOrders.updatePolesForGeodesist(
        this.orderId,
        this.polesObject?.data
      );

      handleResponseWithMessages(
        this,
        response,
        "Link został wygenerowany.",
        () => {}
      );
    },
    activateAll() {
      if (Array.isArray(this.polesObject?.data)) {
        this.polesObject?.data.forEach(pole => {
          pole.geodezja = 1;
        });

        this.prepareMarkersForMap();
      }
    },
    deactivateAll() {
      if (Array.isArray(this.polesObject?.data)) {
        this.polesObject?.data.forEach(pole => {
          pole.geodezja = 0;
        });

        this.prepareMarkersForMap();
      }
    },
    showGeodesistMapInNewTab() {
      if (this.linkForGeodesist) window.open(this.linkForGeodesist, "_blank");
    },
    copyToClipboard() {
      var copyText = document.getElementById("linkForGeodesistInputId");

      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      document.execCommand("copy");

      this.$toastr.Add({
        msg: "Link został skopiowany do schowka.",
        progressbar: false,
        timeout: 3000,
        type: "info"
      });
    },
    onDeletePoleButtonClicked() {
      this.$swal
        .fire({
          title: "Usunięcie słupa",
          text: "Czy na pewno usunąć wybrany słup ze zlecenia?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) {
            this.deletePole();
          }
        });
    },
    async deletePole() {
      var response = await RepositoryPoles.deletePole(this.pickedPole?.id);

      handleResponseWithMessages(
        this,
        response,
        "Słup został usunięty.",
        () => {
          this.pickedPole = null;
          this.getPoles(true);
        }
      );
    }
  }
};
</script>
<style></style>
