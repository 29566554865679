<template>
  <div style="display: flex">
    <div v-for="option in USER_COLORS" :key="option">
      <div
        @click="onOptionClicked(option)"
        class="color-option mr-1"
        :style="'background-color: ' + option"
      >
        <i
          v-if="option === pickedColor"
          class="fa fa-check"
          style="color: white;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { USER_COLORS } from "@/helpers/appConstants.js";

export default {
  name: "user-colors-radio-buttons",
  props: {
    initialPickedColor: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      pickedColor: null
    };
  },
  computed: {
    USER_COLORS: () => USER_COLORS
  },
  created() {
    if (!Array.isArray(USER_COLORS) || !USER_COLORS.length) return;

    if (
      this.initialPickedColor &&
      USER_COLORS.includes(this.initialPickedColor)
    )
      this.pickedColor = this.initialPickedColor;
    else this.pickedColor = USER_COLORS[0];

    this.$emit("onColorChanged", this.pickedColor);
  },
  methods: {
    onOptionClicked(option) {
      this.pickedColor = option;
      this.$emit("onColorChanged", this.pickedColor);
    }
  }
};
</script>

<style scoped>
.color-option {
  width: 50px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
