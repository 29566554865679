<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="enableDownload"
      :preview-modal="previewModal"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="false"
      :htmlToPdfOptions="pdfOptions"
      pdf-content-width="1100px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <slot></slot>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "pdf-generator",
  components: {
    VueHtml2pdf
  },
  props: {
    filename: {
      type: String,
      default: "PDF file"
    },
    enableDownload: {
      type: Boolean,
      default: true
    },
    previewModal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      pdfOptions: {
        filename: this.filename,
        margin: [2, 0],
        pagebreak: { avoid: ["tr", "td"], after: [".html2pdf__page-break"] },
        html2canvas: {
          scrollY: 0
        },
        jsPDF: {
          orientation: "landscape",
          format: "a4"
        }
      }
    };
  },
  methods: {
    generatePdf() {
      this.$refs.html2Pdf.generatePdf();
    },
    // eslint-disable-next-line no-unused-vars
    onProgress(event) {},
    hasStartedGeneration() {},
    // eslint-disable-next-line no-unused-vars
    hasGenerated(event) {}
  }
};
</script>

<style></style>
