export default {
  state: {
    notificationsState: localStorage.getItem("notificationsState")
  },
  getters: {
    notificationsRefreshKey: state => {
      let notificationsState = state.notificationsState
        ? JSON.parse(state.notificationsState)
        : {};

      if (notificationsState === null) return 0;

      return notificationsState.key !== null ? notificationsState.key : 0;
    }
  },
  mutations: {
    incrementRefreshKey(state) {
      let notificationsState = state.notificationsState
        ? JSON.parse(state.notificationsState)
        : {};

      notificationsState.key++;
      state.notificationsState = JSON.stringify(notificationsState);
      localStorage.setItem("notificationsState", state.notificationsState);
    }
  },
  actions: {
    reloadNotifications(context) {
      context.commit("incrementRefreshKey");
    }
  }
};
