<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="ibox">
        <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
          <div class="sk-spinner sk-spinner-three-bounce">
            <div class="sk-bounce1"></div>
            <div class="sk-bounce2"></div>
            <div class="sk-bounce3"></div>
          </div>

          <cluster-map-component
            ref="map"
            :centerLat="centerLat"
            :centerLng="centerLng"
            :markers="markersForMap"
            height="700px"
            :showZeroPositions="false"
            @clickableClicked="onMapClickableClicked"
          />
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="tabs-container">
        <ul class="nav nav-tabs" role="tablist">
          <li @click="onTabClicked(0)">
            <a class="nav-link active" data-toggle="tab" href="#tab-1">
              <i class="fa fa-info" />
              Informacje</a
            >
          </li>
          <li @click="onTabClicked(1)">
            <a class="nav-link" data-toggle="tab" href="#tab-2">
              <img
                src="/static/img/owner-gray.png"
                alt="Typy akcji"
                width="13"
                height="13"
                style="margin-right:6px;"
              />
              Akcje</a
            >
          </li>
          <li @click="onTabClicked(2)">
            <a class="nav-link" data-toggle="tab" href="#tab-3">
              <i class="fa fa-file-image-o" />
              Zdjęcia</a
            >
          </li>
        </ul>
        <div class="tab-content">
          <div role="tabpanel" id="tab-1" class="tab-pane active">
            <div class="panel-body">
              <order-contractor-summary
                v-if="alreadyLoaded.orderContractorSummary"
                :orderContractor="orderContractor"
                :orderLocation="newActionDefaultLocation"
                @mapFilterType0Changed="showActionsOfType0 = $event"
                @mapFilterType1Changed="showActionsOfType1 = $event"
                @mapFilterType2Changed="showActionsOfType2 = $event"
                @mapFilterType3Changed="showActionsOfType3 = $event"
                @mapFilterType4Changed="showActionsOfType4 = $event"
                @mapFilterType5Changed="showActionsOfType5 = $event"
                @refresh="onOrderContactorSummaryRefresh"
                @refreshFiles="filesTableKey++"
              />
            </div>
          </div>

          <div role="tabpanel" id="tab-2" class="tab-pane">
            <div class="panel-body">
              <actions-table
                v-if="alreadyLoaded.actionsTable"
                ref="actionsTable"
                :key="actionsTableKey"
                :filter="{ subject: 'userorder', id: orderContractorId }"
                :showHeader="false"
                :showBorder="false"
                :longerSearchBar="true"
                :columns="actionsTableColumnsConfiguration"
                :showHideOnMapButton="true"
                @actionVisibilityChanged="onActionVisibilityChanged"
              />
            </div>
          </div>

          <div role="tabpanel" id="tab-3" class="tab-pane">
            <div class="panel-body">
              <files-table
                v-if="alreadyLoaded.filesTable"
                :key="filesTableKey"
                :url="`userorder/${orderContractorId}/resources`"
                :showBorder="false"
                :longerSearchBar="true"
                :widerPaginationCountPicker="true"
                fileWidth="200px"
                modalId="modalImageContainer1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import navItems from "@/helpers/navigationPanelHelper.js";
import ClusterMapComponent from "@/components/map/ClusterMapComponent";
import OrderContractorSummary from "@/components/orderContractors/OrderContractorSummary";
import FilesTable from "@/components/fileRepositories/FilesTable";
import ActionsTable from "@/components/actions/ActionsTable";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";
import ActionsTableColumnsConfiguration from "@/tablesConfigurationClasses/ActionsTableColumnConfiguration.js";

const RepositoryOrderContractors = RepositoryFactory.get("orderContractors");
const RepositoryActions = RepositoryFactory.get("actions");
const REMOVE_ACTION_BUTTON_TYPE_NAME = "removeActionButtonType";
const HIDE_ACTION_BUTTON_TYPE_NAME = "hideActionButtonType";
const COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME = "copyToClipboardButtonType";
const SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME = "showActionGalleryButtonType";

export default {
  name: "order-contractor-details",
  components: {
    ClusterMapComponent,
    OrderContractorSummary,
    FilesTable,
    ActionsTable
  },
  data() {
    return {
      isLoading: false,
      orderContractor: null,
      alreadyLoaded: {
        orderContractorSummary: true,
        actionsTable: false,
        filesTable: false
      },
      showActionsOfType0: true,
      showActionsOfType1: true,
      showActionsOfType2: true,
      showActionsOfType3: true,
      showActionsOfType4: true,
      showActionsOfType5: true,
      actionsTableKey: 0,
      filesTableKey: 0
    };
  },
  computed: {
    orderContractorId() {
      return parseInt(this.$route.params.id);
    },
    markersForMap() {
      let markers = new Array();

      if (Array.isArray(this.orderContractor?.actions)) {
        this.orderContractor?.actions.forEach(action => {
          if (!action.map_display) return;
          if (action.geotimestamp == null || action.type == null) return;

          let marker = this.getConstructedMarker(action);

          if (
            (action.type.id === 1 && this.showActionsOfType1 === true) ||
            (action.type.id === 2 && this.showActionsOfType2 === true) ||
            (action.type.id === 3 && this.showActionsOfType3 === true) ||
            (action.type.id === 4 && this.showActionsOfType4 === true) ||
            (action.type.id === 5 && this.showActionsOfType5 === true)
          )
            markers.push(marker);
        });
      }

      //start marker
      if (
        this.showActionsOfType0 === true &&
        this.orderContractor?.start?.lat != null &&
        Number.parseFloat(this.orderContractor?.start?.lat) !== 0.0 &&
        this.orderContractor?.start?.lon != null &&
        Number.parseFloat(this.orderContractor?.start?.lon) !== 0.0
      ) {
        markers.push({
          lat: this.orderContractor.start.lat,
          lng: this.orderContractor.start.lon,
          popupCaption: "Rozpoczęcie zlecenia",
          draggable: false,
          icon: "start_marker",
          iconSize: [25, 38],
          iconAnchor: [6, 38]
        });
      }

      //stop marker
      if (
        this.showActionsOfType0 === true &&
        this.orderContractor?.stop?.lat != null &&
        Number.parseFloat(this.orderContractor?.stop?.lat) !== 0.0 &&
        this.orderContractor?.stop?.lon != null &&
        Number.parseFloat(this.orderContractor?.stop?.lon) !== 0.0
      ) {
        markers.push({
          lat: this.orderContractor.stop.lat,
          lng: this.orderContractor.stop.lon,
          popupCaption: "Zakończenie zlecenia",
          draggable: false,
          icon: "finish_marker",
          iconSize: [25, 38],
          iconAnchor: [6, 38]
        });
      }

      return markers;
    },
    centerLat() {
      if (!this.orderContractor) return null;

      if (this.orderContractor?.first_pole_position?.lat != null)
        return this.orderContractor?.first_pole_position?.lat;

      if (
        this.orderContractor?.stop?.lat != null &&
        Number.parseFloat(this.orderContractor.stop.lat) !== 0.0
      )
        return Number.parseFloat(this.orderContractor.stop.lat);

      if (
        Array.isArray(this.orderContractor.actions) &&
        this.orderContractor.actions.length > 0
      ) {
        if (
          this.orderContractor?.actions[this.orderContractor.actions.length - 1]
            ?.geotimestamp?.lat != null
        )
          return Number.parseFloat(
            this.orderContractor.actions[
              this.orderContractor.actions.length - 1
            ].geotimestamp.lat
          );
      }

      if (
        this.orderContractor?.start?.lat != null &&
        Number.parseFloat(this.orderContractor.start.lat) !== 0.0
      )
        return Number.parseFloat(this.orderContractor.start.lat);

      if (
        this.orderContractor?.order_geotimestamp?.lat != null &&
        Number.parseFloat(this.orderContractor.order_geotimestamp.lat) !== 0.0
      )
        return Number.parseFloat(this.orderContractor.order_geotimestamp.lat);

      return 50.28709;
    },
    centerLng() {
      if (!this.orderContractor) return null;

      if (this.orderContractor?.first_pole_position?.lon != null)
        return this.orderContractor?.first_pole_position?.lon;

      if (
        this.orderContractor?.stop?.lon != null &&
        Number.parseFloat(this.orderContractor.stop.lon) !== 0.0
      )
        return Number.parseFloat(this.orderContractor.stop.lon);

      if (
        Array.isArray(this.orderContractor.actions) &&
        this.orderContractor.actions.length > 0
      ) {
        if (
          this.orderContractor?.actions[this.orderContractor.actions.length - 1]
            ?.geotimestamp?.lon != null
        )
          return Number.parseFloat(
            this.orderContractor.actions[
              this.orderContractor.actions.length - 1
            ].geotimestamp.lon
          );
      }

      if (
        this.orderContractor?.start?.lon != null &&
        Number.parseFloat(this.orderContractor.start.lon) !== 0.0
      )
        return Number.parseFloat(this.orderContractor.start.lon);

      if (
        this.orderContractor?.order_geotimestamp?.lon != null &&
        Number.parseFloat(this.orderContractor.order_geotimestamp.lon) !== 0.0
      )
        return Number.parseFloat(this.orderContractor.order_geotimestamp.lon);

      return 21.4239;
    },
    newActionDefaultLocation() {
      if (!Array.isArray(this.markersForMap) || this.markersForMap.length < 1) {
        return {
          lat: 50.29044,
          lng: 21.42891
        };
      } else {
        return {
          lat: Number.parseFloat(
            this.markersForMap[this.markersForMap.length - 1].lat
          ),
          lng: Number.parseFloat(
            this.markersForMap[this.markersForMap.length - 1].lng
          )
        };
      }
    },
    actionsTableColumnsConfiguration() {
      let config = new ActionsTableColumnsConfiguration();

      config.orderEbp = false;
      config.user = false;
      config.actionType = true;
      config.note = true;
      config.amountW = true;
      config.amountC = true;
      config.amountS = true;

      return config;
    }
  },
  created() {
    // this.setOrderId(this.orderIdForDetails);
    this.setNavigationPanel(navItems.ORDER_CONTRACTOR_DETAILS);
    this.getOrderContractorDetails();
  },
  methods: {
    ...mapActions({
      setNavigationPanel: "changeNavigationPanelItems"
      // setOrderId: "setOrderId"
    }),
    async getOrderContractorDetails(refreshMap = false) {
      this.isLoading = true;

      this.orderContractor = await RepositoryOrderContractors.getOrderContractorDetailsGeneral(
        this.orderContractorId
      );

      if (refreshMap)
        setTimeout(() => {
          this.$refs.map.setBounds();
          this.isLoading = false;
        }, 100);
      else this.isLoading = false;
    },
    onTabClicked(tabId) {
      switch (tabId) {
        case 0:
          this.alreadyLoaded.orderContractorSummary = true;
          break;
        case 1:
          this.alreadyLoaded.actionsTable = true;
          break;
        case 2:
          this.alreadyLoaded.filesTable = true;
          break;
      }
    },
    onOrderContactorSummaryRefresh() {
      this.getOrderContractorDetails(true);
    },
    onMapClickableClicked(clickable) {
      let actionId = clickable.getAttribute("id_action");
      let clickActionType = clickable.getAttribute("click_action_type");

      if (actionId == undefined || clickActionType == undefined) return;

      const foundAction = this.orderContractor?.actions?.find(
        action => action.id == actionId
      );

      if (foundAction == undefined) return;

      switch (clickActionType) {
        case REMOVE_ACTION_BUTTON_TYPE_NAME:
          this.onDeleteActionButtonClicked(actionId, foundAction.type.id);
          break;
        case HIDE_ACTION_BUTTON_TYPE_NAME:
          this.onHideActionButtonClicked(actionId);
          break;
        case COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME:
          this.copyActionCoordsToClipboard(foundAction);
          break;
        case SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME:
          this.onShowActionGalleryButtonClicked(foundAction);
          break;
      }
    },

    onDeleteActionButtonClicked(actionId, actionTypeId) {
      this.$swal
        .fire({
          title: "Usuwanie akcji",
          text: "Czy na pewno chcesz usunąć wybraną akcję?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) this.deleteAction(actionId, actionTypeId);
        });
    },

    async deleteAction(actionId, actionTypeId) {
      var response = await RepositoryOrderContractors.deleteAction(
        this.orderContractor?.id_order,
        this.orderContractor?.id,
        actionId,
        actionTypeId
      );

      handleResponseWithMessages(
        this,
        response,
        "Akcja została usunięta.",
        () => {
          this.getOrderContractorDetails();
        }
      );
    },

    onHideActionButtonClicked(actionId) {
      this.$swal
        .fire({
          title: "Ukrywanie akcji",
          text: "Czy na pewno chcesz ukryć wybraną akcję?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) this.toggleActionVisibility(actionId, false);
        });
    },

    async toggleActionVisibility(actionId, visible) {
      var response = await RepositoryActions.toggleActionVisibility(
        actionId,
        visible
      );

      handleResponseWithMessages(
        this,
        response,
        "Akcja została ukryta.",
        () => {
          this.onActionVisibilityChanged();
        }
      );
    },

    copyActionCoordsToClipboard(action) {
      this.copyToClipboard(
        action.geotimestamp.lat + "," + action.geotimestamp.lon
      );
    },

    copyToClipboard(textToCopy) {
      navigator.clipboard.writeText(textToCopy);
      this.$toastr.Add({
        msg: "Skopiowano do schowka: " + textToCopy,
        progressbar: false,
        timeout: 3000,
        type: "info"
      });
    },

    onShowActionGalleryButtonClicked(action) {
      let routeData = this.$router.resolve({
        name: "singleActionGallery",
        query: {
          orderId: this.orderContractor.id_order,
          actionId: action.id,
          actionTypeName: action.type.name,
          orderNumber: this.orderContractor.order_number
        }
      });

      window.open(routeData.href, "_blank");
    },

    getConstructedMarker(action) {
      let actionNote = action.note ? `Notatka: <i>${action.note}</i><br>` : "";

      let markerPopupCaption =
        `${action.type.name}<br>` +
        `Liczba: <b>${action.sum} sztuk</b><br>` +
        `${actionNote}` +
        `${action.geotimestamp.lat}, ${action.geotimestamp.lon} ` +
        `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>` +
        `<br>` +
        `<button clickable type='button' class='btn btn-outline btn-info btn-xs mr-1 mt-1' id='${SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' click_action_type='${SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME}'>Pokaż galerię</button>` +
        `<button clickable type='button' class='btn btn-outline btn-warning btn-xs mr-1 mt-1' id='${HIDE_ACTION_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' click_action_type='${HIDE_ACTION_BUTTON_TYPE_NAME}'>Ukryj akcję</button>` +
        `<button clickable type='button' class='btn btn-outline btn-danger btn-xs mt-1' id='${REMOVE_ACTION_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' click_action_type='${REMOVE_ACTION_BUTTON_TYPE_NAME}'>Usuń akcję</button>`;

      let marker = {
        lat: action.geotimestamp.lat,
        lng: action.geotimestamp.lon,
        popupCaption: markerPopupCaption,
        draggable: false,
        icon: undefined,
        iconSize: undefined,
        iconAnchor: undefined
      };

      if (action.type.id === 1) {
        marker.icon = "fist_marker_new";
        marker.iconSize = [25, 35];
        marker.iconAnchor = [12, 35];
      } else if (action.type.id === 2) {
        marker.icon = "red_x_marker_new";
        marker.iconSize = [25, 35];
        marker.iconAnchor = [12, 35];
      } else if (action.type.id === 3) {
        marker.icon = "transport_marker_new";
        marker.iconSize = [25, 35];
        marker.iconAnchor = [12, 35];
      } else if (action.type.id === 4) {
        marker.icon = "mechanic_marker_new";
        marker.iconSize = [25, 35];
        marker.iconAnchor = [12, 35];
      } else if (action.type.id === 5) {
        marker.icon = "black_fork_marker_new";
        marker.iconSize = [25, 35];
        marker.iconAnchor = [12, 35];
      }

      return marker;
    },

    onActionVisibilityChanged() {
      this.getOrderContractorDetails(true);
      this.$refs.actionsTable.reloadCurrentPage();
    }
  }
};
</script>

<style></style>
