import repository from "@/data/repository.js";
import {
  AUTH_REQUEST,
  AUTH_LOGOUT,
  AUTH_RESET_FORM_ERROR
} from "@/helpers/authConstants.js";

export default {
  state: {
    token: localStorage.getItem("user-token") || "",
    loggedInUserState: localStorage.getItem("loggedInUserState"),
    formInfo: ""
  },
  getters: {
    isAuthenticated: state => !!state.token,
    loggedInUser: state => {
      let loggedInUserState = state.loggedInUserState
        ? JSON.parse(state.loggedInUserState)
        : {};

      return loggedInUserState ? loggedInUserState.loggedInUser : null;
    },
    isAdmin: state => {
      let loggedInUserState = state.loggedInUserState
        ? JSON.parse(state.loggedInUserState)
        : {};

      var isAdminLoggedIn = false;

      if (loggedInUserState != null)
        isAdminLoggedIn =
          loggedInUserState.loggedInUser?.type?.name === "Administrator";

      return isAdminLoggedIn;
    },
    isWorker: state => {
      let loggedInUserState = state.loggedInUserState
        ? JSON.parse(state.loggedInUserState)
        : {};

      var isWorkerLoggedIn = false;

      if (loggedInUserState != null)
        isWorkerLoggedIn = loggedInUserState.loggedInUser?.type?.id === 2;

      return isWorkerLoggedIn;
    }
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token;
    },
    setFormInfo: (state, info) => {
      state.formInfo = info;
    },
    changeLoggedInUser(state, user) {
      let loggedInUserState = state.loggedInUserState
        ? JSON.parse(state.loggedInUserState)
        : {};

      loggedInUserState.loggedInUser = user;
      state.loggedInUserState = JSON.stringify(loggedInUserState);
      localStorage.setItem("loggedInUserState", state.loggedInUserState);
    },
    changeLoggedInUserFirstName(state, newFirstName) {
      let loggedInUserState = state.loggedInUserState
        ? JSON.parse(state.loggedInUserState)
        : {};

      loggedInUserState.loggedInUser.firstName = newFirstName;
      state.loggedInUserState = JSON.stringify(loggedInUserState);
      localStorage.setItem("loggedInUserState", state.loggedInUserState);
    },
    changeLoggedInUserLastName(state, newLastName) {
      let loggedInUserState = state.loggedInUserState
        ? JSON.parse(state.loggedInUserState)
        : {};

      loggedInUserState.loggedInUser.lastName = newLastName;
      state.loggedInUserState = JSON.stringify(loggedInUserState);
      localStorage.setItem("loggedInUserState", state.loggedInUserState);
    }
  },
  actions: {
    [AUTH_REQUEST]: ({ commit }, user) => {
      return new Promise((resolve, reject) => {
        repository
          .get("/login", {
            auth: {
              username: user.username,
              password: user.password
            }
          })
          .then(response => {
            //blocking workers' access to the webpanel
            if (
              response?.data?.data?.type?.id === 2 ||
              response?.data?.data?.type?.id === 4
            ) {
              commit("setFormInfo", "Brak uprawnień.");
              reject();
            }

            const token = response.data.data.api_token;
            localStorage.setItem("user-token", token);
            commit("setToken", token);
            commit("changeLoggedInUser", response.data.data);
            repository.defaults.headers.common["Authorization"] =
              "Bearer " + token;
            resolve(response);
          })
          .catch(error => {
            commit("setFormInfo", error?.response?.data[0]);
            localStorage.removeItem("user-token");
            reject(error);
          });
      });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      return new Promise(resolve => {
        localStorage.removeItem("user-token");
        commit("changeLoggedInUser", null);
        delete repository.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    [AUTH_RESET_FORM_ERROR]: ({ commit }) => {
      commit("setFormInfo", null);
    },
    changeLoggedInUserFirstName(context, firstName) {
      context.commit("changeLoggedInUserFirstName", firstName);
    },
    changeLoggedInUserLastName(context, lastName) {
      context.commit("changeLoggedInUserLastName", lastName);
    }
  }
};
