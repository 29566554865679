<template>
  <div class="panel panel-default">
    <div
      class="panel-heading"
      style="display: flex; justify-content: space-between"
    >
      Zestaw {{ Number.parseInt(index) + 1 }}
      <i
        class="fa fa-times mt-1"
        style="color: gray;"
        @click="onDeleteSetButtonClicked"
      ></i>
    </div>
    <div class="panel-body">
      <div class="form-group">
        <label>Adres</label>
        <input
          type="text"
          placeholder="Wpisz adres"
          v-model="formData.address"
          class="form-control"
          minlength="1"
          maxlength="200"
        />
      </div>

      <div class="table-responsive mb-3" style="max-width: 500px">
        <table class="table">
          <tbody>
            <tr>
              <td></td>
              <td>Drewniane</td>
              <td>Betonowe</td>
            </tr>
            <tr>
              <td>Pojedynczy</td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.w_p"
                  min="0"
                  max="999999999"
                  required
                  @change="computeWoodenSums"
                />
              </td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.c_p"
                  min="0"
                  max="999999999"
                  required
                  @change="computeConcreteSums"
                />
              </td>
            </tr>
            <tr>
              <td>Pojedynczy z podporą</td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.w_pp"
                  min="0"
                  max="999999999"
                  required
                  @change="computeWoodenSums"
                />
              </td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.c_pp"
                  min="0"
                  max="999999999"
                  required
                  @change="computeConcreteSums"
                />
              </td>
            </tr>
            <tr>
              <td>A-owy</td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.w_a"
                  min="0"
                  max="999999999"
                  required
                  @change="computeWoodenSums"
                />
              </td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.c_a"
                  min="0"
                  max="999999999"
                  required
                  @change="computeConcreteSums"
                />
              </td>
            </tr>
            <tr>
              <td>Bliźniaczy</td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.w_b"
                  min="0"
                  max="999999999"
                  required
                  @change="computeWoodenSums"
                />
              </td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.c_b"
                  min="0"
                  max="999999999"
                  required
                  @change="computeConcreteSums"
                />
              </td>
            </tr>
            <tr>
              <td>Poczwórny</td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.w_c"
                  min="0"
                  max="999999999"
                  required
                  @change="computeWoodenSums"
                />
              </td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.c_c"
                  min="0"
                  max="999999999"
                  required
                  @change="computeConcreteSums"
                />
              </td>
            </tr>
            <tr>
              <td>Potrójny</td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.w_t"
                  min="0"
                  max="999999999"
                  required
                  @change="computeWoodenSums"
                />
              </td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.c_t"
                  min="0"
                  max="999999999"
                  required
                  @change="computeConcreteSums"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-responsive" style="max-width: 500px">
        <table class="table">
          <tbody>
            <tr>
              <td></td>
              <td>Drewniane</td>
              <td>Betonowe</td>
            </tr>
            <tr>
              <td>Suma punktów wsporczych</td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.w_points"
                  min="0"
                  max="999999999"
                  required
                />
              </td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.c_points"
                  min="0"
                  max="999999999"
                  required
                />
              </td>
            </tr>
            <tr>
              <td>Suma do demontażu</td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.w_dem"
                  min="0"
                  max="999999999"
                  required
                />
              </td>
              <td>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  class="form-control"
                  v-model.number="formData.parameters.c_dem"
                  min="0"
                  max="999999999"
                  required
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "poles-table-set-for-order-add-edit",
  props: {
    index: null,
    addMode: null,
    initialFormData: null
  },
  data() {
    return {
      formData: {
        id: null,
        address: null,
        parameters: {
          c_p: 0,
          c_pp: 0,
          c_a: 0,
          c_b: 0,
          c_c: 0,
          c_t: 0,
          w_p: 0,
          w_pp: 0,
          w_a: 0,
          w_b: 0,
          w_c: 0,
          w_t: 0,
          c_points: 0,
          c_dem: null,
          w_points: 0,
          w_dem: null
        }
      }
    };
  },
  watch: {
    initialFormData() {
      if (this.initialFormData != null)
        this.formData = Object.assign(this.formData, this.initialFormData);
    },
    formData: {
      handler() {
        this.$emit("polesTypesChanged", {
          index: this.index,
          formData: this.formData
        });
      },
      deep: true
    }
  },
  mounted() {
    if (this.initialFormData != null)
      this.formData = Object.assign(this.formData, this.initialFormData);

    this.$emit("polesTypesChanged", {
      index: this.index,
      formData: this.formData
    });
  },
  methods: {
    onDeleteSetButtonClicked() {
      this.$emit("deleteSet", this.index);
    },
    computeWoodenSums() {
      this.formData.parameters.w_points =
        this.formData.parameters.w_p +
        this.formData.parameters.w_pp +
        this.formData.parameters.w_a +
        this.formData.parameters.w_b +
        this.formData.parameters.w_c +
        this.formData.parameters.w_t;

      this.formData.parameters.w_dem =
        this.formData.parameters.w_p +
        2 * this.formData.parameters.w_pp +
        2 * this.formData.parameters.w_a +
        2 * this.formData.parameters.w_b +
        2 * this.formData.parameters.w_c +
        2 * this.formData.parameters.w_t;
    },
    computeConcreteSums() {
      this.formData.parameters.c_points =
        this.formData.parameters.c_p +
        this.formData.parameters.c_pp +
        this.formData.parameters.c_a +
        this.formData.parameters.c_b +
        this.formData.parameters.c_c +
        this.formData.parameters.c_t;

      this.formData.parameters.c_dem =
        this.formData.parameters.c_p +
        2 * this.formData.parameters.c_pp +
        2 * this.formData.parameters.c_a +
        2 * this.formData.parameters.c_b +
        2 * this.formData.parameters.c_c +
        2 * this.formData.parameters.c_t;
    }
  }
};
</script>

<style></style>
