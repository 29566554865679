<template>
  <div class="flex-container">
    <div class="mr-1">Pokaż na stronę</div>
    <select
      v-model="selected"
      class="form-control mr-1"
      style="max-width: 75px"
    >
      <option>10</option>
      <option>25</option>
      <option>50</option>
    </select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "pagination-count-picker",
  props: {},
  data() {
    return {
      selected: null,
      eventEmitBlocked: true
    };
  },
  computed: {
    ...mapGetters({ itemsPerPage: "paginationItemsCount" })
  },
  watch: {
    selected() {
      this.$store.dispatch("changePaginationItemsCount", this.selected);

      if (this.eventEmitBlocked === false) this.$emit("selectionChanged");
      else this.eventEmitBlocked = false;
    }
  },
  created() {
    this.selected = this.itemsPerPage;
  }
};
</script>

<style scoped>
.flex-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
