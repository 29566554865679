<template>
  <div class="ibox ">
    <div v-if="showHeader" class="ibox-title">
      <h5>Słupy</h5>
    </div>
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <table-elements-info
        :metaObject="polesObject ? polesObject.meta : null"
      />

      <div
        v-if="polesObject && polesObject.data && polesObject.data.length > 0"
        class="table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th>id</th>
              <th>id_order</th>
              <th>baza</th>
              <th>otk</th>
              <th>nr_slupa</th>
              <th>paszport</th>
              <th>oznaczenie</th>
              <th>producent</th>
              <th>typ</th>
              <th>rodzaj</th>
              <th>podpory</th>
              <th>material</th>
              <th>wlasciciel</th>
              <th>status</th>
              <th>lon</th>
              <th>lat</th>
              <th>wojewodztwo</th>
              <th>powiat</th>
              <th>gmina</th>
              <th>miasto</th>
              <th>ulica</th>
              <th>deleted_at</th>
              <th>created_at</th>
              <th>updated_at</th>

              <!-- <th
                @click="sortBy(sortingOptions.INSPECTION_DATE)"
                style="cursor: pointer; white-space: nowrap;"
              >
                Przegląd <span v-html="inspectionDateSortIcon" />
              </th>
              <th v-if="!hideCountyColumn">Powiat</th> -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="pole in polesObject.data"
              :key="pole.id"
              @click="selectableItems ? pickItem(pole) : showDetails(pole)"
              style="cursor: pointer;"
            >
              <td>{{ pole.id != null ? pole.id : "-" }}</td>
              <td>{{ pole.id_order != null ? pole.id_order : "-" }}</td>
              <td>{{ pole.baza != null ? pole.baza : "-" }}</td>
              <td>{{ pole.otk != null ? pole.otk : "-" }}</td>
              <td>{{ pole.nr_slupa != null ? pole.nr_slupa : "-" }}</td>
              <td>{{ pole.paszport != null ? pole.paszport : "-" }}</td>
              <td>{{ pole.oznaczenie != null ? pole.oznaczenie : "-" }}</td>
              <td>{{ pole.producent != null ? pole.producent : "-" }}</td>
              <td>{{ pole.typ != null ? pole.typ : "-" }}</td>
              <td>{{ pole.rodzaj != null ? pole.rodzaj : "-" }}</td>
              <td>{{ pole.podpory != null ? pole.podpory : "-" }}</td>
              <td>{{ pole.material != null ? pole.material : "-" }}</td>
              <td>{{ pole.wlasciciel != null ? pole.wlasciciel : "-" }}</td>
              <td>{{ pole.status != null ? pole.status : "-" }}</td>
              <td>{{ pole.lon != null ? pole.lon : "-" }}</td>
              <td>{{ pole.lat != null ? pole.lat : "-" }}</td>
              <td>{{ pole.wojewodztwo != null ? pole.wojewodztwo : "-" }}</td>
              <td>{{ pole.powiat != null ? pole.powiat : "-" }}</td>
              <td>{{ pole.gmina != null ? pole.gmina : "-" }}</td>
              <td>{{ pole.miasto != null ? pole.miasto : "-" }}</td>
              <td>{{ pole.ulica != null ? pole.ulica : "-" }}</td>
              <td>{{ pole.deleted_at != null ? pole.deleted_at : "-" }}</td>
              <td>{{ pole.created_at != null ? pole.created_at : "-" }}</td>
              <td>{{ pole.updated_at != null ? pole.updated_at : "-" }}</td>

              <!-- <td v-if="!hideCountyColumn">
                {{ pole.powiat ? pole.powiat : "-" }}
              </td> -->

              <td>
                <div class="float-right" style="white-space: nowrap">
                  <button
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="
                      selectableItems ? pickItem(pole) : showDetails(pole)
                    "
                    onclick="event.stopPropagation()"
                  >
                    <span v-if="selectableItems">Wybierz</span>
                    <span v-else>Pokaż</span>
                  </button>
                  <slot name="buttons" :pole="pole"></slot>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="polesObject.error">
        <server-error-panel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6 offset-xl-3">
          <pagination
            v-if="polesObject && polesObject.data"
            align="center"
            :data="polesObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></pagination>
        </div>

        <div class="col-xl-3">
          <pagination-count-picker
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import { PAGINATION_LIMIT } from "@/helpers/appConstants.js";
import { getShorthandIfExists } from "@/helpers/valueShorthandHelper.js";
import {
  polesSortingOptions as sortingOptions,
  getSortingIcon,
  getSortingDirection
} from "@/helpers/sortTableHelper.js";
import paginationCountPickerMixin from "@/mixins/paginationCountPickerMixin.js";
import ServerErrorPanel from "@/components/utils/ServerErrorPanel";
import TableElementsInfo from "@/components/utils/TableElementsInfo";

const RepositoryPoles = RepositoryFactory.get("poles");

export default {
  name: "poles-table",
  components: {
    ServerErrorPanel,
    TableElementsInfo
  },
  mixins: [paginationCountPickerMixin],
  props: {
    filter: {
      type: Object,
      default: null
    },
    selectableItems: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    longerSearchBar: {
      type: Boolean,
      default: false
    },
    userFilterEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: false,
      polesObject: {},
      searchBox: "",
      currentSort: { by: null, direction: null },
      PAGINATION_LIMIT: PAGINATION_LIMIT,
      userFilter: null
    };
  },
  computed: {
    sortingOptions: () => sortingOptions,
    inspectionDateSortIcon() {
      return getSortingIcon(this.currentSort, sortingOptions.INSPECTION_DATE);
    },
    lastInspectionDateSortIcon() {
      return getSortingIcon(
        this.currentSort,
        sortingOptions.LAST_INSPECTION_DATE
      );
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    getShorthandIfExists,
    fetchData(page = 1) {
      this.isLoading = true;

      RepositoryPoles.getPolesObject(
        page,
        this.itemsPerPage,
        this.searchBox,
        this.currentSort.by,
        this.currentSort.direction,
        this.filter,
        this.userFilter
      )
        .then(data => {
          this.polesObject = data;
          this.$refs.polesTableFilterAndSearchChildComponent?.updateAppliedFilters();
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    sortBy(newSortBy) {
      //set direction
      this.currentSort.direction = getSortingDirection(
        this.currentSort,
        newSortBy
      );

      //set by
      this.currentSort.by = newSortBy;

      //ask for data
      this.fetchData();
    },
    showDetails(pole) {
      this.$router.push({
        name: "poleDetails",
        params: { id: pole.id_elem }
      });
    },
    pickItem(pole) {
      this.$emit("on-completed", pole);
    },
    onFetchDataWithFiltersRequest({ search, filter }) {
      this.searchBox = search;
      this.userFilter = filter;
      this.fetchData();
    }
  }
};
</script>
<style></style>
