var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ibox"},[_c('div',{staticClass:"ibox-title"},[_c('h5',[_vm._v("Zlecenia, do których dodany zostanie protokół geodezji")]),_c('div',{staticClass:"ibox-tools"},[_c('a',{staticClass:"btn btn-primary btn-xs",on:{"click":function($event){return _vm.showModal(_vm.MODAL_ORDERS_TABLE, {
            additionalProps: {
              initialUserFilter: { id_order_type: [2, 3] }
            }
          })}}},[_vm._v("+ Dodaj zlecenie do listy")])])]),_c('div',{staticClass:"ibox-content"},[(_vm.orders && _vm.orders && _vm.orders.length > 0)?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped"},[_vm._m(0),_c('tbody',_vm._l((_vm.orders),function(order){return _c('tr',{key:order.id},[_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(order && order.order_number != null ? order.order_number : "-")+" ")]),_c('td',{staticStyle:{"white-space":"nowrap","text-align":"center"}},[_c('span',{staticClass:"label label-primary",style:(order && order.direction && order.direction.color != null
                    ? 'background-color:' + order.direction.color
                    : '')},[_vm._v(" "+_vm._s(order && order.direction && order.direction.name != null ? order.direction.name : "-"))])]),_c('td',{staticStyle:{"white-space":"nowrap","text-align":"center"}},[_c('span',{staticClass:"label label-primary",style:(order && order.orderType && order.orderType.color != null
                    ? 'background-color:' + order.orderType.color
                    : ''),attrs:{"data-toggle":order && order.orderType && order.orderType.name
                    ? 'tooltip'
                    : '',"title":order && order.orderType && order.orderType.name
                    ? order.orderType.name
                    : ''}},[_vm._v(" "+_vm._s(order && order.orderType && order.orderType.name != null ? _vm.getShorthandIfExists(order.orderType.name) : "-"))])]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(order && order.target != null ? order.target : "-")+" ")]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(order && order.region && order.region.name != null ? order.region.name : "-")+" ")]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(order && order.address != null ? order.address : "-")+" ")]),_c('td',{staticStyle:{"white-space":"nowrap"}},[(
                  order == null ||
                    order.coordinator == null ||
                    order.coordinator.firstName == null ||
                    order.coordinator.lastName == null
                )?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(" "+_vm._s(order.coordinator.firstName + " " + order.coordinator.lastName)+" ")])]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(order && order.ebp != null ? order.ebp : "-")+" ")]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(order && order.receive_date != null ? _vm.getDateWithoutTime(order.receive_date) : "-")+" ")]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(order && order.deadline != null ? _vm.getDateWithoutTime(order.deadline) : "-")+" ")]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(order && order.delayDays != null ? order.delayDays : "-")+" ")]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_c('span',{staticClass:"label label-primary",style:(order &&
                  order.order_status &&
                  order.order_status.color != null
                    ? 'background-color:' + order.order_status.color
                    : '')},[_vm._v(" "+_vm._s(order && order.order_status && order.order_status.name != null ? order.order_status.name : "-"))])]),_c('td',{staticStyle:{"white-space":"nowrap","text-align":"center"}},[_c('span',{staticClass:"label label-primary",style:(order &&
                  order.implementation_phase &&
                  order.implementation_phase.color != null
                    ? 'background-color:' + order.implementation_phase.color
                    : '')},[_vm._v(" "+_vm._s(order && order.implementation_phase && order.implementation_phase.name != null ? order.implementation_phase.name : "-"))])]),_c('td',{staticStyle:{"white-space":"nowrap","text-align":"center"}},[_c('span',{staticClass:"label label-primary",style:(order &&
                  order.geodesy_phase &&
                  order.geodesy_phase.color != null
                    ? 'background-color:' + order.geodesy_phase.color
                    : '')},[_vm._v(" "+_vm._s(order && order.geodesy_phase && order.geodesy_phase.name != null ? order.geodesy_phase.name : "-"))])]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(order && order.cost != null ? order.cost : "-")+" zł ")]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_c('div',{staticClass:"float-right"},[(order.canBeDeleted)?_c('button',{staticClass:"btn btn-outline btn-primary btn-xs",attrs:{"type":"button","onclick":"event.stopPropagation()"},on:{"click":function($event){return _vm.deleteItemFromList(order)}}},[_c('span',[_vm._v("Usuń")])]):_vm._e()])])])}),0)])]):_c('div',[_c('p',{staticClass:"text-center mt-3"},[_vm._v("Brak elementów do wyświetlenia")])]),_c('div')]),_c('modal-base',{ref:"modalBase"},[_c('modal-body',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.modal.title)+" ")]},proxy:true},{key:"body",fn:function(){return [_c(_vm.modal.type,_vm._b({key:_vm.modal.key,tag:"component",on:{"on-completed":_vm.onModalCompleted}},'component',_vm.modal.properties,false))]},proxy:true}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"white-space":"nowrap"}},[_vm._v("Nr zlecenia")]),_c('th',{staticStyle:{"white-space":"nowrap","text-align":"center"}},[_vm._v(" Rodzaj zlecenia ")]),_c('th',{staticStyle:{"white-space":"nowrap","text-align":"center"}},[_vm._v("Typ")]),_c('th',{staticStyle:{"white-space":"nowrap"}},[_vm._v("Słupy do likwidacji")]),_c('th',[_vm._v("Region")]),_c('th',{staticStyle:{"white-space":"nowrap"}},[_vm._v("Lokalizacja LN")]),_c('th',[_vm._v("Koordynator")]),_c('th',[_vm._v("EBP")]),_c('th',[_vm._v("Wpłynęło do RADO")]),_c('th',[_vm._v("Termin realizacji")]),_c('th',[_vm._v("Opóźnienia")]),_c('th',{staticStyle:{"white-space":"nowrap","text-align":"center"}},[_vm._v(" Status zlecenia ")]),_c('th',{staticStyle:{"white-space":"nowrap","text-align":"center"}},[_vm._v(" Etap realizacji ")]),_c('th',{staticStyle:{"white-space":"nowrap","text-align":"center"}},[_vm._v(" Etap geodezji ")]),_c('th',[_vm._v("Wartość zamówienia")]),_c('th')])])}]

export { render, staticRenderFns }