<template>
  <div class="ibox ">
    <div
      v-if="showHeader"
      class="ibox-title"
      :style="showBorder ? '' : 'border-style: none'"
    >
      <h5 v-if="showTitle">Zlecenia</h5>
      <div v-if="addButton" class="ibox-tools">
        <a @click="addNewOrder" class="btn btn-primary btn-xs"
          >+ Nowe zlecenie</a
        >
        <a @click="exportOrders" class="btn btn-primary btn-xs">Wyeksportuj</a>
      </div>
    </div>
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <slot v-if="globalFilterPanel" name="globalFilterPanel"></slot>

      <orders-table-filter-and-search
        v-else
        ref="ordersTableFilterAndSearchChildComponent"
        :longerSearchBar="longerSearchBar"
        :userFilterEnabled="userFilterEnabled"
        :hideWorkerFilter="hideWorkerFilter"
        @fetchData="onFetchDataWithFiltersRequest"
        :initialFilter="initialUserFilter"
      />

      <table-elements-info
        :metaObject="ordersObject ? ordersObject.meta : null"
      />

      <div
        v-if="ordersObject && ordersObject.data && ordersObject.data.length > 0"
        class="table-responsive"
      >
        <table class="table table-striped first-column-fixed-table">
          <thead>
            <tr>
              <th style="white-space: nowrap;">
                Nr zlecenia
              </th>
              <th style="white-space: nowrap;">
                Rodzaj zlecenia
              </th>
              <th style="white-space: nowrap; text-align: center;">Typ</th>
              <th
                @click="sortBy(sortingOptions.TARGET)"
                style="cursor: pointer; white-space: nowrap;"
              >
                Słupy do likwidacji <span v-html="targetSortIcon" />
              </th>
              <th style="white-space: nowrap;">
                Region
              </th>
              <th style="white-space: nowrap;">
                Lokalizacja LN
              </th>
              <th>Koordynator</th>
              <th>EBP</th>
              <th
                @click="sortBy(sortingOptions.RECEIVE_DATE)"
                style="cursor: pointer; white-space: nowrap;"
              >
                Wpłynęło do RADO <span v-html="receiveDateSortIcon" />
              </th>
              <th
                @click="sortBy(sortingOptions.DEADLINE)"
                style="cursor: pointer; white-space: nowrap;"
              >
                Termin realizacji <span v-html="deadlineSortIcon" />
              </th>
              <th>
                Opóźnienia
              </th>
              <th style="white-space: nowrap;">Nowy termin realizacji</th>
              <th style="white-space: nowrap; text-align: center;">
                Status zlecenia
              </th>
              <th style="white-space: nowrap; text-align: center;">
                Etap realizacji
              </th>
              <th style="white-space: nowrap; text-align: center;">
                Etap geodezji
              </th>
              <th style="white-space: nowrap;">Notatka</th>
              <th style="white-space: nowrap;">Numer PO</th>
              <th>Rozliczone drewniane</th>
              <th>Rozliczone betonowe</th>
              <th
                @click="sortBy(sortingOptions.COST)"
                style="cursor: pointer; white-space: nowrap;"
              >
                Wartość zamówienia <span v-html="costSortIcon" />
              </th>
              <th>Kwota faktury</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in ordersObject.data"
              :key="order.id"
              @click="selectableItems ? pickItem(order) : showDetails(order)"
              style="cursor: pointer;"
            >
              <td style="white-space: nowrap;">
                {{
                  order && order.order_number != null ? order.order_number : "-"
                }}
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order && order.direction && order.direction.color != null
                      ? 'background-color:' + order.direction.color
                      : ''
                  "
                >
                  {{
                    order && order.direction && order.direction.name != null
                      ? order.direction.name
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order && order.orderType && order.orderType.color != null
                      ? 'background-color:' + order.orderType.color
                      : ''
                  "
                  :data-toggle="
                    order && order.orderType && order.orderType.name
                      ? 'tooltip'
                      : ''
                  "
                  :title="
                    order && order.orderType && order.orderType.name
                      ? order.orderType.name
                      : ''
                  "
                >
                  {{
                    order && order.orderType && order.orderType.name != null
                      ? getShorthandIfExists(order.orderType.name)
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.target != null ? order.target : "-" }}
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.region && order.region.name != null
                    ? order.region.name
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.address != null ? order.address : "-" }}
              </td>

              <td style="white-space: nowrap;">
                <span
                  v-if="
                    order == null ||
                      order.coordinator == null ||
                      order.coordinator.firstName == null ||
                      order.coordinator.lastName == null
                  "
                >
                  -
                </span>
                <span v-else>
                  {{
                    order.coordinator.firstName +
                      " " +
                      order.coordinator.lastName
                  }}
                </span>
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.ebp != null ? order.ebp : "-" }}
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.receive_date != null
                    ? getDateWithoutTime(order.receive_date)
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.deadline != null
                    ? getDateWithoutTime(order.deadline)
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.delayDays != null ? order.delayDays : "-" }}
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.deadline_offset != null
                    ? getDateWithoutTime(order.deadline_offset)
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order &&
                    order.order_status &&
                    order.order_status.color != null
                      ? 'background-color:' + order.order_status.color
                      : ''
                  "
                >
                  {{
                    order &&
                    order.order_status &&
                    order.order_status.name != null
                      ? order.order_status.name
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order &&
                    order.implementation_phase &&
                    order.implementation_phase.color != null
                      ? 'background-color:' + order.implementation_phase.color
                      : ''
                  "
                >
                  {{
                    order &&
                    order.implementation_phase &&
                    order.implementation_phase.name != null
                      ? order.implementation_phase.name
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order &&
                    order.geodesy_phase &&
                    order.geodesy_phase.color != null
                      ? 'background-color:' + order.geodesy_phase.color
                      : ''
                  "
                >
                  {{
                    order &&
                    order.geodesy_phase &&
                    order.geodesy_phase.name != null
                      ? order.geodesy_phase.name
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap;">
                {{
                  order &&
                  order.note_actions_count &&
                  order.note_actions_count > 0
                    ? order.note_actions_count
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.po_number != null ? order.po_number : "-" }}
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.poles_settled_w != null
                    ? order.poles_settled_w
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.poles_settled_c != null
                    ? order.poles_settled_c
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.cost != null ? order.cost : "-" }} zł
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.fv_last_sum != null
                    ? order.fv_last_sum + " zł"
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap;">
                <div class="float-right">
                  <button
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="
                      selectableItems ? pickItem(order) : showDetails(order)
                    "
                    onclick="event.stopPropagation()"
                  >
                    <span v-if="selectableItems">Wybierz</span>
                    <span v-else>Pokaż</span>
                  </button>
                  <button
                    v-if="editButton"
                    class="btn btn-outline btn-primary btn-xs"
                    type="button"
                    @click="edit(order)"
                    onclick="event.stopPropagation()"
                  >
                    Edytuj
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="ordersObject.error">
        <server-error-panel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6 offset-xl-3">
          <pagination
            v-if="ordersObject && ordersObject.data"
            align="center"
            :data="ordersObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></pagination>
        </div>

        <div class="col-xl-3">
          <pagination-count-picker
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import {
  ordersSortingOptions as sortingOptions,
  getSortingIcon,
  getSortingDirection
} from "@/helpers/sortTableHelper.js";
import { PAGINATION_LIMIT } from "@/helpers/appConstants.js";
import {
  getLocalFormattedDateAndTime,
  getDateWithoutTime
} from "@/helpers/dateFormatHelper.js";
import paginationCountPickerMixin from "@/mixins/paginationCountPickerMixin.js";
import OrdersTableFilterAndSearch from "@/components/orders/OrdersTableFilterAndSearch";
import ServerErrorPanel from "@/components/utils/ServerErrorPanel";
import { getShorthandIfExists } from "@/helpers/valueShorthandHelper.js";
import TableElementsInfo from "@/components/utils/TableElementsInfo";
import { exportXlsx } from "@/helpers/xlsxExporter.js";

const RepositoryOrders = RepositoryFactory.get("orders");

export default {
  name: "orders-table",
  components: {
    OrdersTableFilterAndSearch,
    ServerErrorPanel,
    TableElementsInfo
  },
  mixins: [paginationCountPickerMixin],
  props: {
    filter: {
      type: Object,
      default: null
    },
    selectableItems: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    longerSearchBar: {
      type: Boolean,
      default: false
    },
    addButton: {
      type: Boolean,
      default: false
    },
    editButton: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    userFilterEnabled: {
      type: Boolean,
      default: true
    },
    hideWorkerFilter: {
      type: Boolean,
      default: false
    },
    initialUserFilter: {
      type: Object,
      default: null
    },
    globalFilterPanel: {
      type: Boolean,
      default: false
    },
    filterFromParent: {
      type: Object,
      default: null
    },
    searchFromParent: {
      type: String,
      default: ""
    }
    // initialSort: {
    //   type: Object,
    //   default: null
    // }
  },
  data() {
    return {
      isLoading: false,
      ordersObject: {},
      searchBox: "",
      currentSort: { by: null, direction: null },
      PAGINATION_LIMIT: PAGINATION_LIMIT,
      userFilter: null
    };
  },
  computed: {
    sortingOptions: () => sortingOptions,
    deadlineSortIcon() {
      return getSortingIcon(this.currentSort, sortingOptions.DEADLINE);
    },
    costSortIcon() {
      return getSortingIcon(this.currentSort, sortingOptions.COST);
    },
    targetSortIcon() {
      return getSortingIcon(this.currentSort, sortingOptions.TARGET);
    },
    receiveDateSortIcon() {
      return getSortingIcon(this.currentSort, sortingOptions.RECEIVE_DATE);
    }
  },
  created() {
    if (this.initialUserFilter != null)
      this.userFilter = this.initialUserFilter;

    //if (this.initialSort != null) this.currentSort = this.initialSort;

    this.fetchData();
  },
  methods: {
    getLocalFormattedDateAndTime,
    getDateWithoutTime,
    getShorthandIfExists,
    fetchData(page = 1) {
      this.isLoading = true;

      RepositoryOrders.getOrdersObject(
        page,
        this.itemsPerPage,
        this.globalFilterPanel ? this.searchFromParent : this.searchBox,
        this.currentSort.by,
        this.currentSort.direction,
        this.filter,
        this.globalFilterPanel ? this.filterFromParent : this.userFilter
      )
        .then(data => {
          this.ordersObject = data;
          this.$emit("afterSuccessfulFetch");
          this.$refs.ordersTableFilterAndSearchChildComponent?.updateAppliedFilters();
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
          //eslint-disable-next-line no-undef
          $('[data-toggle="tooltip"]').tooltip({ placement: "right" });
        });
    },
    sortBy(newSortBy) {
      //set direction
      this.currentSort.direction = getSortingDirection(
        this.currentSort,
        newSortBy
      );

      //set by
      this.currentSort.by = newSortBy;

      //ask for data
      this.fetchData();
    },
    addNewOrder() {
      this.$router.push({
        name: "orderAddEdit"
      });
    },
    showDetails(order) {
      let routeData = this.$router.resolve({
        name: "orderDetails",
        params: { id: order.id }
      });
      window.open(routeData.href, "_blank");
    },
    edit(order) {
      this.$router.push({
        name: "orderAddEdit",
        params: { id: order.id }
      });
    },
    onFetchDataWithFiltersRequest({ search, filter }) {
      this.searchBox = search;
      this.userFilter = filter;
      this.fetchData();
    },
    exportOrders() {
      this.isLoading = true;

      RepositoryOrders.exportOrders(
        this.globalFilterPanel ? this.searchFromParent : this.searchBox,
        this.currentSort.by,
        this.currentSort.direction,
        this.globalFilterPanel ? this.filterFromParent : this.userFilter
      )
        .then(data => {
          data = data.replace("ID", "Id");
          exportXlsx(
            this.convertCsvToHeadersAndData(data),
            "Eksport zleceń",
            "Zlecenia"
          );
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    pickItem(order) {
      this.$emit("on-completed", order);
    },
    convertCsvToHeadersAndData(csv) {
      let rows = [];
      let csvRows = csv.toString().split("\n");
      csvRows = csvRows.filter(csvRow => csvRow !== "");

      csvRows.forEach(csvRow => {
        csvRow = csvRow.replace(/"/g, "");
        let row = csvRow.toString().split("&");

        let rowConverted = row.map(el => {
          if (isNaN(el)) return el;
          let parsed = parseFloat(el);

          if (!isNaN(parsed)) return parsed;
          else return el;
        });

        rows.push(rowConverted);
      });

      return rows;
    }
  }
};
</script>
<style scoped>
.first-column-fixed-table th:first-child,
td:first-child {
  position: sticky;
  left: 0px;
}

.first-column-fixed-table > tbody > tr:nth-child(odd) > td {
  background-color: rgb(242, 242, 242);
}

.first-column-fixed-table > tbody > tr:nth-child(even) > td,
.first-column-fixed-table > thead > tr:first-child > th {
  background-color: white;
}
</style>
