<template>
  <div
    ref="modalBaseRef"
    class="modal inmodal fade"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" :style="'max-width:' + maxWidth + ';'">
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
  name: "modal-base",
  props: {
    maxWidth: {
      type: String,
      default: "80%"
    }
  },
  methods: {
    show() {
      $(this.$refs.modalBaseRef).modal("show");
    },
    hide() {
      $(this.$refs.modalBaseRef).modal("hide");
    }
  }
};
</script>
