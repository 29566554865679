<template>
  <div class="ibox ">
    <div
      class="ibox-content pl-0"
      :class="{ 'sk-loading': isLoading }"
      style="border-style: none;"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div
        v-if="Array.isArray(transportObject) && transportObject.length > 0"
        class="table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th>
                Data
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="transportElement in transportObject"
              :key="transportElement.id"
            >
              <td>
                {{ getDateWithoutTime(transportElement.transport_date) }}
              </td>
              <td>
                <div class="float-right">
                  <button
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="onDeleteDateButtonClicked(transportElement)"
                    onclick="event.stopPropagation()"
                  >
                    Usuń
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getDateWithoutTime } from "@/helpers/dateFormatHelper.js";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";

const RepositoryFiles = RepositoryFactory.get("files");

export default {
  name: "transport-dates-table",
  props: {
    transportObject: null,
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getDateWithoutTime,
    onDeleteDateButtonClicked(dateToDelete) {
      this.$swal
        .fire({
          title: "Usuwanie daty transportu",
          text: "Czy na pewno chcesz usunąć wybraną datę transportu?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) {
            this.deleteDate(dateToDelete);
          }
        });
    },
    async deleteDate(dateToDelete) {
      var response = await RepositoryFiles.deleteTransportDate(
        dateToDelete?.id
      );

      handleResponseWithMessages(
        this,
        response,
        "Data transportu została usunięta.",
        () => {
          this.$emit("dateDeleted");
        }
      );
    }
  }
};
</script>
<style></style>
