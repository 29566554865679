<template>
  <div v-if="filesArray && Array.isArray(filesArray) && filesArray.length > 0">
    <h5 class="mt-3 mb-3">Dodane pliki</h5>
    <div style="display: flex; flex-direction: column">
      <div
        v-for="fileObj in filesArray"
        :key="fileObj.id"
        class="file"
        style="margin-bottom: 10px"
      >
        <div
          class="row m-0"
          style="display: flex; justify-content: center; align-items: center;"
        >
          <div class="col-auto" style="height: 20px; ">
            <span v-html="getFileIcon(fileObj.file)" />
          </div>

          <div
            class="file-name col"
            style="overflow-wrap: break-word; width: 200px; border-top: none;"
          >
            {{
              fileObj.file && fileObj.file.name != null
                ? fileObj.file.name
                : "-"
            }}
          </div>
          <div style="background-color: #f8f8f8">
            <button
              type="button"
              class="btn btn-white btn-xs"
              style="margin-right: 2px; margin-top: 2px;"
              title="Usuń plik"
              @click="deleteFile(fileObj)"
              onclick="event.stopPropagation()"
            >
              <i class="fa fa-trash-o" style="color: gray"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "files-for-actions",
  props: {
    filesArray: null
  },
  computed: {},
  methods: {
    getFileIcon(file) {
      if (file.type === "application/pdf")
        return `<i class='fa fa-file-pdf-o' style='color: #1ab39477; font-size: 20px'/>`;

      if (file.type.includes("image"))
        return `<i class='fa fa-file-image-o' style='color: #1ab39477; font-size: 20px'/>`;

      return `<i class='fa fa-file' style='color: #1ab39477; font-size: 20px'/>`;
    },
    async deleteFile(fileToDelete) {
      this.$emit("fileDeleted", fileToDelete.id);
    }
  }
};
</script>

<style></style>
