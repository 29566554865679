export default {
  state: {
    navigationPanelState: localStorage.getItem("navigationPanelState")
  },
  getters: {
    navigationPanelItems: state => {
      let navigationPanelState = state.navigationPanelState
        ? JSON.parse(state.navigationPanelState)
        : {};

      return navigationPanelState ? navigationPanelState.items : null;
    },
    orderId: state => {
      let navigationPanelState = state.navigationPanelState
        ? JSON.parse(state.navigationPanelState)
        : {};

      return navigationPanelState ? navigationPanelState.orderId : null;
    }
  },
  mutations: {
    changeNavigationPanelItems(state, value) {
      let navigationPanelState = state.navigationPanelState
        ? JSON.parse(state.navigationPanelState)
        : {};

      let valueStr = JSON.stringify(value);
      valueStr = valueStr.replace("#orderId", this.getters.orderId);
      value = JSON.parse(valueStr);

      navigationPanelState.items = value;
      state.navigationPanelState = JSON.stringify(navigationPanelState);
      localStorage.setItem("navigationPanelState", state.navigationPanelState);
    },
    changeOrderId(state, value) {
      let navigationPanelState = state.navigationPanelState
        ? JSON.parse(state.navigationPanelState)
        : {};

      navigationPanelState.orderId = value;
      state.navigationPanelState = JSON.stringify(navigationPanelState);
      localStorage.setItem("navigationPanelState", state.navigationPanelState);
    }
  },
  actions: {
    changeNavigationPanelItems(context, value) {
      context.commit("changeNavigationPanelItems", value);
    },
    setOrderId(context, value) {
      context.commit("changeOrderId", value);
    }
  }
};
