//quick template: import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";
export function handleResponseWithMessages(
  context,
  response,
  successMsg,
  successAction
) {
  if (response?.serverError) {
    context.$toastr.Add({
      msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
      progressbar: false,
      timeout: 3000,
      type: "error"
    });
  } else {
    if (response?.error != null) {
      if (response?.message != null && Array.isArray(response?.message)) {
        response.message.forEach(element => {
          context.$toastr.Add({
            msg: element,
            progressbar: false,
            timeout: 3000,
            type: "warning"
          });
        });
      } else
        context.$toastr.Add({
          msg: "Wystąpił błąd.",
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });
    } else {
      if (successMsg) {
        context.$toastr.Add({
          msg: successMsg,
          progressbar: false,
          timeout: 3000,
          type: "success"
        });
      }

      successAction();
    }
  }
}
