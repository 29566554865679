import ModalBase from "@/components/utils/ModalBase";
import ModalBody from "@/components/utils/ModalBody";
import UserTypesTable from "@/components/dictionaries/UserTypesTable";
import DictionaryTable from "@/components/dictionaries/DictionaryTable";
import {
  MODAL_USERS_TABLE,
  MODAL_ORDERS_TABLE,
  MODAL_USER_TYPES_TABLE,
  MODAL_ORDER_STATUSES_TABLE,
  MODAL_ORDER_TYPES_TABLE,
  MODAL_REALIZATION_PRIORITIES_TABLE,
  MODAL_DIRECTIONS_TABLE,
  MODAL_REGIONS_TABLE,
  MODAL_COUNTIES_TABLE,
  MODAL_ORDER_REALIZATION_PHASES_TABLE,
  MODAL_ORDER_GEODETIC_PHASES_TABLE,
  MODAL_ACTION_TYPES_TABLE
} from "@/helpers/modalConstants.js";

export default {
  name: "modalTablesMixin",
  components: {
    ModalBase,
    ModalBody,
    UserTypesTable,
    UsersTable: () => import("@/components/users/UsersTable"),
    OrdersTable: () => import("@/components/orders/OrdersTable"),
    DictionaryTable
  },
  data() {
    return {
      modal: {
        type: null,
        key: 0,
        title: "",
        properties: null
      }
    };
  },
  computed: {
    MODAL_USERS_TABLE: () => MODAL_USERS_TABLE,
    MODAL_ORDERS_TABLE: () => MODAL_ORDERS_TABLE,
    MODAL_USER_TYPES_TABLE: () => MODAL_USER_TYPES_TABLE,
    MODAL_ORDER_STATUSES_TABLE: () => MODAL_ORDER_STATUSES_TABLE,
    MODAL_ORDER_TYPES_TABLE: () => MODAL_ORDER_TYPES_TABLE,
    MODAL_REALIZATION_PRIORITIES_TABLE: () =>
      MODAL_REALIZATION_PRIORITIES_TABLE,
    MODAL_DIRECTIONS_TABLE: () => MODAL_DIRECTIONS_TABLE,
    MODAL_REGIONS_TABLE: () => MODAL_REGIONS_TABLE,
    MODAL_COUNTIES_TABLE: () => MODAL_COUNTIES_TABLE,
    MODAL_ORDER_REALIZATION_PHASES_TABLE: () =>
      MODAL_ORDER_REALIZATION_PHASES_TABLE,
    MODAL_ORDER_GEODETIC_PHASES_TABLE: () => MODAL_ORDER_GEODETIC_PHASES_TABLE,
    MODAL_ACTION_TYPES_TABLE: () => MODAL_ACTION_TYPES_TABLE
  },
  methods: {
    showModal(modalType, payload) {
      switch (modalType) {
        case MODAL_USERS_TABLE:
          if (payload?.additionalProps?.filter?.url != null) {
            if (payload.additionalProps.filter.url.includes("coordinator"))
              this.modal.title = "Wybierz koordynatora: ";
            else if (payload.additionalProps.filter.url.includes("worker"))
              this.modal.title = "Wybierz pracownika: ";
          } else this.modal.title = "Wybierz użytkownika: ";
          break;
        case MODAL_ORDERS_TABLE:
          this.modal.title = "Wybierz zlecenie: ";
          break;
        case MODAL_USER_TYPES_TABLE:
          this.modal.title = "Wybierz typ użytkownika: ";
          break;
        case MODAL_ORDER_STATUSES_TABLE:
          this.modal.title = "Wybierz status zlecenia: ";
          break;
        case MODAL_ORDER_TYPES_TABLE:
          this.modal.title = "Wybierz typ zlecenia: ";
          break;
        case MODAL_REALIZATION_PRIORITIES_TABLE:
          this.modal.title = "Wybierz termin realizacji: ";
          break;
        case MODAL_DIRECTIONS_TABLE:
          this.modal.title = "Wybierz rodzaj zlecenia: ";
          break;
        case MODAL_REGIONS_TABLE:
          this.modal.title = "Wybierz region: ";
          break;
        case MODAL_COUNTIES_TABLE:
          this.modal.title = "Wybierz powiat: ";
          break;
        case MODAL_ORDER_REALIZATION_PHASES_TABLE:
          this.modal.title = "Wybierz etap realizacji: ";
          break;
        case MODAL_ORDER_GEODETIC_PHASES_TABLE:
          this.modal.title = "Wybierz etap geodezji: ";
          break;
        case MODAL_ACTION_TYPES_TABLE:
          this.modal.title = "Wybierz typ akcji: ";
          break;
      }

      this.modal.properties = {
        selectableItems: true,
        showHeader: false,
        showBorder: false,
        userFilterEnabled: false
      };

      //for dictionaries
      switch (modalType) {
        case MODAL_USER_TYPES_TABLE:
          this.modal.properties.url = "usertype";
          this.modal.properties.columns = [
            { key: "name", header: "Nazwa" },
            { key: "note", header: "Notatka" }
          ];
          break;
        case MODAL_ORDER_STATUSES_TABLE:
          this.modal.properties.url = "orderstatus";
          this.modal.properties.columns = [{ key: "name", header: "Nazwa" }];
          break;
        case MODAL_ORDER_TYPES_TABLE:
          this.modal.properties.url = "ordertype";
          this.modal.properties.columns = [{ key: "name", header: "Nazwa" }];
          break;
        case MODAL_REALIZATION_PRIORITIES_TABLE:
          this.modal.properties.url = "realizationpriority";
          this.modal.properties.columns = [
            { key: "name", header: "Nazwa" },
            { key: "color", header: "Kolor" }
          ];
          break;
        case MODAL_DIRECTIONS_TABLE:
          this.modal.properties.url = "direction";
          this.modal.properties.columns = [{ key: "name", header: "Nazwa" }];
          break;
        case MODAL_REGIONS_TABLE:
          this.modal.properties.url = "region";
          this.modal.properties.columns = [{ key: "name", header: "Nazwa" }];
          break;
        case MODAL_COUNTIES_TABLE:
          this.modal.properties.url = "powiat";
          this.modal.properties.columns = [{ key: "name", header: "Nazwa" }];
          break;
        case MODAL_ORDER_REALIZATION_PHASES_TABLE:
          this.modal.properties.url = "implementationphase";
          this.modal.properties.columns = [{ key: "name", header: "Nazwa" }];
          break;
        case MODAL_ORDER_GEODETIC_PHASES_TABLE:
          this.modal.properties.url = "geodesyphase";
          this.modal.properties.columns = [{ key: "name", header: "Nazwa" }];
          break;
        case MODAL_ACTION_TYPES_TABLE:
          this.modal.properties.url = "actiontype";
          this.modal.properties.columns = [{ key: "name", header: "Nazwa" }];
          break;
      }

      if (payload?.additionalProps)
        this.modal.properties = Object.assign(
          this.modal.properties,
          payload.additionalProps
        );

      this.modal.type = modalType;

      if (modalType.toString().includes("dictionary-table")) {
        switch (modalType) {
          case MODAL_USER_TYPES_TABLE:
            this.modal.component = "user-types-table";
            break;
          case MODAL_ORDER_STATUSES_TABLE:
          case MODAL_ORDER_TYPES_TABLE:
          case MODAL_REALIZATION_PRIORITIES_TABLE:
          case MODAL_DIRECTIONS_TABLE:
          case MODAL_REGIONS_TABLE:
          case MODAL_COUNTIES_TABLE:
          case MODAL_ORDER_REALIZATION_PHASES_TABLE:
          case MODAL_ORDER_GEODETIC_PHASES_TABLE:
          case MODAL_ACTION_TYPES_TABLE:
            this.modal.component = "dictionary-table";
            break;
        }
      } else this.modal.component = modalType;

      this.modal.key++;
      this.$refs.modalBase.show();
    }
  }
};
