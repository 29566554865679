<template>
  <div>
    <h4 class="text-center mb-3">
      Adres: {{ address && address.address ? address.address : "-" }}
    </h4>

    <div class="table-responsive mb-3 ml-auto mr-auto" style="max-width: 500px">
      <table class="table table-striped text-center">
        <tbody>
          <tr>
            <td></td>
            <td>Drewniane</td>
            <td>Betonowe</td>
          </tr>
          <tr>
            <td>Pojedynczy</td>
            <td>
              {{
                address && address.parameters && address.parameters.w_p != null
                  ? address.parameters.w_p
                  : "-"
              }}
            </td>
            <td>
              {{
                address && address.parameters && address.parameters.c_p != null
                  ? address.parameters.c_p
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td>Pojedynczy z podporą</td>
            <td>
              {{
                address && address.parameters && address.parameters.w_pp != null
                  ? address.parameters.w_pp
                  : "-"
              }}
            </td>
            <td>
              {{
                address && address.parameters && address.parameters.c_pp != null
                  ? address.parameters.c_pp
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td>A-owy</td>
            <td>
              {{
                address && address.parameters && address.parameters.w_a != null
                  ? address.parameters.w_a
                  : "-"
              }}
            </td>
            <td>
              {{
                address && address.parameters && address.parameters.c_a != null
                  ? address.parameters.c_a
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td>Bliźniaczy</td>
            <td>
              {{
                address && address.parameters && address.parameters.w_b != null
                  ? address.parameters.w_b
                  : "-"
              }}
            </td>
            <td>
              {{
                address && address.parameters && address.parameters.c_b != null
                  ? address.parameters.c_b
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td>Poczwórny</td>
            <td>
              {{
                address && address.parameters && address.parameters.w_c != null
                  ? address.parameters.w_c
                  : "-"
              }}
            </td>
            <td>
              {{
                address && address.parameters && address.parameters.c_c != null
                  ? address.parameters.c_c
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td>Potrójny</td>
            <td>
              {{
                address && address.parameters && address.parameters.w_t != null
                  ? address.parameters.w_t
                  : "-"
              }}
            </td>
            <td>
              {{
                address && address.parameters && address.parameters.c_t != null
                  ? address.parameters.c_t
                  : "-"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive mb-4 ml-auto mr-auto" style="max-width: 500px">
      <table class="table table-striped text-center">
        <tbody>
          <tr>
            <td><b>Suma</b></td>
            <td>Drewniane</td>
            <td>Betonowe</td>
          </tr>
          <tr>
            <td>Punkty wsporcze</td>
            <td>
              {{
                address &&
                address.parameters &&
                address.parameters.w_points != null
                  ? address.parameters.w_points
                  : "-"
              }}
            </td>
            <td>
              {{
                address &&
                address.parameters &&
                address.parameters.c_points != null
                  ? address.parameters.c_points
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td>Do demontażu</td>
            <td>
              {{
                address &&
                address.parameters &&
                address.parameters.w_dem != null
                  ? address.parameters.w_dem
                  : "-"
              }}
            </td>
            <td>
              {{
                address &&
                address.parameters &&
                address.parameters.c_dem != null
                  ? address.parameters.c_dem
                  : "-"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "poles-table-set-for-order-details",
  props: {
    address: null
  }
};
</script>

<style></style>
