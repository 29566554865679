<template>
  <div class="row m-3">
    <div class="ibox col-lg-8 pr-1">
      <div
        class="ibox-content"
        :class="{ 'sk-loading': isLoading }"
        style="border-style: none"
      >
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <cluster-map-component
          :centerLat="mapCenter.lat"
          :centerLng="mapCenter.lon"
          :markers="markersForMap"
          height="700px"
          @markerClicked="onMarkerClicked"
        />
      </div>
    </div>

    <div class="ibox col-lg-4 pl-1">
      <div
        class="ibox-content profile-content pl-4"
        :class="{ 'sk-loading': isLoading }"
        style="border-style: none"
      >
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <div v-if="pickedPole" class="mt-4">
          <b>Wybrany słup: </b>
          <div class="pl-2">
            <br />
            <b>Id:</b> {{ pickedPole.id != null ? pickedPole.id : "-" }}
            <br />
            <b>Oznaczenie:</b>
            {{ pickedPole.oznaczenie != null ? pickedPole.oznaczenie : "-" }}
            <br />
            <b>Szerokość geograficzna:</b>
            {{ pickedPole.lat != null ? pickedPole.lat : "-" }}
            <br />
            <b>Długość geograficzna:</b>
            {{ pickedPole.lon != null ? pickedPole.lon : "-" }}
            <br />
            <button
              class="btn btn-outline btn-primary btn-xs mr-2 mt-2"
              type="button"
              @click="onClickNavigateTo(pickedPole)"
            >
              Nawiguj
            </button>
          </div>
        </div>
        <div v-else-if="markersForMap.length > 0" class="mt-4">
          <p class="text-center">Wybierz słup z mapy</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClusterMapComponent from "@/components/map/ClusterMapComponent";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import { navigateTo } from "@/helpers/navigator.js";

const RepositoryOrders = RepositoryFactory.get("orders");

export default {
  name: "order-geodesist-map",
  components: {
    ClusterMapComponent
  },
  data() {
    return {
      isLoading: false,
      orderGeodesistMapObject: null,
      pickedPole: null
    };
  },
  computed: {
    orderUUID() {
      return this.$route.params.uuid;
    },
    markersForMap() {
      let markers = new Array();

      if (Array.isArray(this.orderGeodesistMapObject?.data)) {
        this.orderGeodesistMapObject?.data.forEach(pole => {
          let marker = {
            lat: pole.lat,
            lng: pole.lon,
            draggable: false,
            // popupCaption: "Słup",
            options: { pole: pole },
            selected: pole == this.pickedPole
          };

          markers.push(marker);
        });
      }

      return markers;
    },
    mapCenter() {
      if (this.markersForMap.length > 0)
        return {
          lat: Number.parseFloat(this.markersForMap[0].lat),
          lon: Number.parseFloat(this.markersForMap[0].lng)
        };
      else
        return {
          lat: null,
          lon: null
        };
    }
  },
  created() {
    this.getPolesForPGList();
  },
  methods: {
    async getPolesForPGList() {
      this.isLoading = true;

      RepositoryOrders.getPolesForPGListByUUID(this.orderUUID)
        .then(data => {
          this.orderGeodesistMapObject = data;
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    onMarkerClicked(marker) {
      this.pickedPole = marker?.sourceTarget?.options?.pole;
    },
    onClickNavigateTo({ lat, lon }) {
      navigateTo(lat, lon);
    }
  }
};
</script>

<style scoped></style>
