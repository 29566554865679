var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ibox"},[_c('div',{staticClass:"ibox-content",class:{ 'sk-loading': _vm.isLoading },style:(_vm.showBorder ? '' : 'border-style: none')},[_vm._m(0),_c('div',[(
          _vm.files &&
            _vm.files.data &&
            Array.isArray(_vm.files.data) &&
            _vm.files.data.length > 0
        )?_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},_vm._l((_vm.files.data),function(file){return _c('div',{key:file.id,staticClass:"file-box",style:(("width: " + _vm.fileWidth)),attrs:{"data-toggle":"modal","data-target":file.id_resource_type === 3 ||
            file.id_resource_type === 4 ||
            file.id_resource_type === 5
              ? ("#" + _vm.modalId)
              : ''},on:{"click":function($event){file.id_resource_type === 3 ||
            file.id_resource_type === 4 ||
            file.id_resource_type === 5
              ? _vm.showModal(file.path)
              : _vm.showFileInNewTab(file)}}},[_c('div',{staticClass:"file",staticStyle:{"cursor":"pointer","margin-right":"10px","margin-left":"10px"}},[(_vm.mobileAppSharingEnabled)?_c('button',{staticClass:"btn btn-white btn-xs",staticStyle:{"position":"absolute","left":"5px","top":"5px"},style:(file && file.shared === 1
                  ? 'background-color: #1ab39477;'
                  : ''),attrs:{"type":"button","title":file && file.shared === 1
                  ? 'Ukryj plik w aplikacji mobilnej'
                  : 'Pokaż plik w aplikacji mobilnej',"onclick":"event.stopPropagation()"},on:{"click":function($event){return _vm.onShareFileToMobileAppButtonClicked(file)}}},[_c('i',{staticClass:"fa fa-users",style:(file && file.shared === 1 ? 'color: green' : 'color: gray')})]):_vm._e(),(_vm.deletingEnabled)?_c('button',{staticClass:"btn btn-white btn-xs",staticStyle:{"position":"absolute","right":"5px","top":"5px"},attrs:{"type":"button","title":"Usuń plik","onclick":"event.stopPropagation()"},on:{"click":function($event){return _vm.onDeleteFileButtonClicked(file)}}},[_c('i',{staticClass:"fa fa-trash-o",staticStyle:{"color":"gray"}})]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getFileIcon(file))}}),_c('div',{staticClass:"file-name",staticStyle:{"overflow-wrap":"break-word"}},[_vm._v(" "+_vm._s(file.path != null ? file.path.substring(file.path.lastIndexOf("/") + 1) : "-")+" "),_c('br'),_c('small',[_vm._v("Dodane przez: "+_vm._s(file.created_by && file.created_by.firstName && file.created_by.lastName ? file.created_by.firstName + " " + file.created_by.lastName : "-"))])])])])}),0):(_vm.files && _vm.files.error)?_c('div',[_c('server-error-panel',{on:{"onRetry":_vm.fetchData}})],1):_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-center mt-3"},[_vm._v(_vm._s(_vm.noElementsCaption))])])]),_c('div',{staticClass:"row mt-3"},[_c('div',{class:_vm.widerPaginationCountPicker
            ? 'col-xl-6 offset-xl-1'
            : 'col-xl-6 offset-xl-3'},[(_vm.files && _vm.files.data)?_c('pagination',{attrs:{"align":"center","data":_vm.files,"limit":_vm.PAGINATION_LIMIT},on:{"pagination-change-page":_vm.fetchData}}):_vm._e()],1),_c('div',{class:_vm.widerPaginationCountPicker ? 'col-xl-5' : 'col-xl-3'},[_c('pagination-count-picker',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],on:{"selectionChanged":_vm.onItemsPerPageChanged}})],1)])]),_c('modal-image-container',{attrs:{"source":_vm.imgSource,"id":_vm.modalId},on:{"on-completed":function($event){return _vm.onModalCompleted($event)}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sk-spinner sk-spinner-three-bounce"},[_c('div',{staticClass:"sk-bounce1"}),_c('div',{staticClass:"sk-bounce2"}),_c('div',{staticClass:"sk-bounce3"})])}]

export { render, staticRenderFns }