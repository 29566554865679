<template>
  <div>
    <view-login v-if="$route.name === 'login'" />
    <view-forgotten-password v-else-if="$route.name === 'forgotPassword'" />
    <view-reset-password v-else-if="$route.name === 'resetPassword'" />
    <order-geodesist-map v-else-if="$route.name === 'orderGeodesistMap'" />
    <view-main v-else />
  </div>
</template>

<script>
import ViewMain from "@/views/ViewMain.vue";
import ViewLogin from "@/views/ViewLogin.vue";
import ViewForgottenPassword from "@/views/ViewForgottenPassword.vue";
import ViewResetPassword from "@/views/ViewResetPassword.vue";
import OrderGeodesistMap from "@/views/OrderGeodesistMap.vue";

export default {
  name: "app",
  components: {
    ViewMain,
    ViewLogin,
    ViewForgottenPassword,
    ViewResetPassword,
    OrderGeodesistMap
  }
};
</script>

<style></style>
