<template>
  <transition name="slide">
    <div v-if="opened">
      <div class="panel panel-danger">
        <div class="panel-heading">
          {{ title }}
        </div>
        <div class="panel-body">
          <ul v-if="errors.length > 1">
            <li v-for="error in errors" :key="error.id">
              <p>{{ error.toString() }}</p>
            </li>
          </ul>
          <p v-else-if="errors.length === 1">{{ errors.toString() }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "form-error-panel",
  props: {
    title: {
      type: String,
      default: "Błąd formularza!"
    },
    errors: Array
  },
  computed: {
    opened() {
      return this.errors && this.errors.length !== 0;
    }
  }
};
</script>

<style scoped>
.slide-enter-active {
  transition: all 0.5s;
}
.slide-leave-active {
  transition: all 0.5s;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
