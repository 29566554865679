<template>
  <div
    class="ibox-content profile-content"
    :class="{ 'sk-loading': isLoading }"
    style="border-style: none"
  >
    <div class="sk-spinner sk-spinner-three-bounce">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>

    <table class="mt-3" style="width:100%">
      <tr>
        <td>Status:</td>
        <td>
          <span
            class="label label-primary"
            :style="
              orderContractor &&
              orderContractor.status &&
              orderContractor.status.color != null
                ? 'background-color:' + orderContractor.status.color
                : ''
            "
          >
            {{
              orderContractor &&
              orderContractor.status &&
              orderContractor.status.name != null
                ? orderContractor.status.name
                : "-"
            }}</span
          >
        </td>
      </tr>
      <tr>
        <td>Wykonawca:</td>
        <td>
          <span
            v-if="
              orderContractor &&
                orderContractor.user &&
                orderContractor.user.id != null &&
                orderContractor.user.firstName != null &&
                orderContractor.user.lastName != null
            "
          >
            <a
              @click="goToUserDetails(orderContractor.user.id)"
              :class="'text-navy'"
            >
              {{
                orderContractor.user.firstName +
                  " " +
                  orderContractor.user.lastName
              }}
            </a>
          </span>
          <span v-else>-</span>
        </td>
      </tr>
      <tr>
        <td>Realizacja rozpoczęta:</td>
        <td>
          {{
            orderContractor &&
            orderContractor.start &&
            orderContractor.start.date != null
              ? getDateWithoutTime(orderContractor.start.date)
              : "-"
          }}
        </td>
      </tr>
      <tr>
        <td>Realizacja zakończona:</td>
        <td>
          {{
            orderContractor &&
            orderContractor.stop &&
            orderContractor.stop.date != null
              ? getDateWithoutTime(orderContractor.stop.date)
              : "-"
          }}
        </td>
      </tr>
      <tr>
        <td>Demontaże:</td>
        <td>
          {{
            orderContractor && orderContractor.general_amount
              ? orderContractor.general_amount
              : "-"
          }}
        </td>
      </tr>
      <tr>
        <td>Demontaże mechaniczne:</td>
        <td>
          {{
            orderContractor && orderContractor.mech_amount
              ? orderContractor.mech_amount
              : "-"
          }}
        </td>
      </tr>
      <tr>
        <td>Do wykonania ogółem:</td>
        <td>
          {{
            orderContractor && orderContractor.target
              ? orderContractor.target
              : "-"
          }}
        </td>
      </tr>
    </table>

    <div class="ibox mt-3" style="border-top: 1px dashed #1ab394;">
      <div class="ibox-content" style="border-style: none">
        <h4>Podsumowanie:</h4>

        <div
          v-if="
            orderContractor &&
              orderContractor.results &&
              Array.isArray(orderContractor.results)
          "
          class="mt-2"
        >
          <div
            v-for="result in orderContractor.results"
            :key="result.name"
            class="pl-3 mb-2"
          >
            <span v-if="result.id !== 5">
              {{ result.name }}:
              <div class="pl-3">- Drewniane: {{ result.amount_w_sum }}</div>
              <div class="pl-3">- Betonowe: {{ result.amount_c_sum }}</div>
              <div class="pl-3">- Szczudła: {{ result.amount_s_sum }}</div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox mt-3" style="border-top: 1px dashed #1ab394;">
      <div class="ibox-content" style="border-style: none">
        <h4>Konfiguracja mapy:</h4>

        <div class="row ml-2 mt-3">
          <div class="switch mr-2">
            <div class="onoffswitch">
              <input
                type="checkbox"
                class="onoffswitch-checkbox"
                id="example0"
                v-model="mapFilterType0"
              />
              <label class="onoffswitch-label" for="example0">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
          <label>Start/stop zlecenia</label>
        </div>

        <div class="row ml-2">
          <div class="switch mr-2">
            <div class="onoffswitch">
              <input
                type="checkbox"
                class="onoffswitch-checkbox"
                id="example1"
                v-model="mapFilterType1"
              />
              <label class="onoffswitch-label" for="example1">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
          <label>Demontaż ręczny</label>
        </div>

        <div class="row ml-2">
          <div class="switch mr-2">
            <div class="onoffswitch">
              <input
                type="checkbox"
                class="onoffswitch-checkbox"
                id="example4"
                v-model="mapFilterType4"
              />
              <label class="onoffswitch-label" for="example4">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
          <label>Demontaż mechaniczny</label>
        </div>

        <div class="row ml-2">
          <div class="switch mr-2">
            <div class="onoffswitch">
              <input
                type="checkbox"
                class="onoffswitch-checkbox"
                id="example2"
                v-model="mapFilterType2"
              />
              <label class="onoffswitch-label" for="example2">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
          <label>Niepowodzenie demontażu</label>
        </div>

        <div class="row ml-2">
          <div class="switch mr-2">
            <div class="onoffswitch">
              <input
                type="checkbox"
                class="onoffswitch-checkbox"
                id="example3"
                v-model="mapFilterType3"
              />
              <label class="onoffswitch-label" for="example3">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
          <label>Zgłoszenie miejsca składowania</label>
        </div>

        <div class="row ml-2">
          <div class="switch mr-2">
            <div class="onoffswitch">
              <input
                type="checkbox"
                class="onoffswitch-checkbox"
                id="example5"
                v-model="mapFilterType5"
              />
              <label class="onoffswitch-label" for="example5">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
              </label>
            </div>
          </div>
          <label>Rozgałęzienie linii</label>
        </div>
      </div>
    </div>

    <div
      class="user-button mt-2"
      v-if="orderContractor && !orderContractor.stop"
    >
      <button
        class="btn btn-outline btn-info btn-sm btn-block"
        @click="onAddActionButtonClicked"
      >
        Dodaj akcję
      </button>
    </div>

    <div
      class="user-button mt-2"
      v-if="orderContractor && !orderContractor.stop"
    >
      <button
        class="btn btn-outline btn-info btn-sm btn-block"
        @click="onCancelOrderForContractorButtonClicked"
      >
        Cofnij zlecenie dla wykonawcy
      </button>
    </div>

    <div
      class="user-button mt-2"
      v-if="orderContractor && !orderContractor.stop"
    >
      <button
        class="btn btn-outline btn-info btn-sm btn-block"
        @click="onEndOrderForContractorButtonClicked"
      >
        Zakończ zlecenie dla wykonawcy
      </button>
    </div>

    <modal-base ref="modalBase">
      <modal-body>
        <template v-slot:title>
          Nowa akcja dla zlecenia
          <a
            @click="goToOrderDetails(orderContractor.id_order)"
            :class="'text-navy'"
          >
            {{
              orderContractor && orderContractor.order_number
                ? orderContractor.order_number
                : "-"
            }}
          </a>
        </template>
        <template v-slot:body>
          <action-add-edit
            v-if="actionAddEditKey > 0"
            ref="actionAddEditChild"
            :key="actionAddEditKey"
            :orderContractor="orderContractor"
            :orderLocation="orderLocation"
            @hideModal="hideModal"
            @refresh="$emit('refresh')"
            @refreshFiles="$emit('refreshFiles')"
          />
        </template>
        <!-- <template v-slot:footer>
        </template> -->
      </modal-body>
    </modal-base>
  </div>
</template>

<script>
import {
  getDateWithoutTime,
  getLocalFormattedDateAndTime
} from "@/helpers/dateFormatHelper.js";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import ActionAddEdit from "../actions/ActionAddEdit.vue";
import ModalBase from "@/components/utils/ModalBase";
import ModalBody from "@/components/utils/ModalBody";
import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";

const RepositoryOrderContractors = RepositoryFactory.get("orderContractors");

export default {
  components: {
    ModalBase,
    ModalBody,
    ActionAddEdit
  },
  name: "order-contractor-summary",
  props: {
    orderContractor: {
      type: Object,
      default: null
    },
    orderLocation: {
      type: Object,
      default: () => {
        return {
          lat: 50.29044,
          lng: 21.42891
        };
      }
    }
  },
  data() {
    return {
      isLoading: false,
      mapFilterType0: true,
      mapFilterType1: true,
      mapFilterType2: true,
      mapFilterType3: true,
      mapFilterType4: true,
      mapFilterType5: true,
      actionAddEditKey: 0
    };
  },
  watch: {
    orderContractor() {
      this.isLoading = this.orderContractor == null;
    },
    mapFilterType0() {
      this.$emit("mapFilterType0Changed", this.mapFilterType0);
    },
    mapFilterType1() {
      this.$emit("mapFilterType1Changed", this.mapFilterType1);
    },
    mapFilterType2() {
      this.$emit("mapFilterType2Changed", this.mapFilterType2);
    },
    mapFilterType3() {
      this.$emit("mapFilterType3Changed", this.mapFilterType3);
    },
    mapFilterType4() {
      this.$emit("mapFilterType4Changed", this.mapFilterType4);
    },
    mapFilterType5() {
      this.$emit("mapFilterType5Changed", this.mapFilterType5);
    }
  },
  methods: {
    getDateWithoutTime,
    getLocalFormattedDateAndTime,
    goToUserDetails(id) {
      if (id == null) return;

      this.$router.push({
        name: "userDetails",
        params: { id }
      });
    },
    goToOrderDetails(id) {
      if (id == null) return;

      let routeData = this.$router.resolve({
        name: "orderDetails",
        params: { id }
      });
      window.open(routeData.href, "_blank");
    },
    onCancelOrderForContractorButtonClicked() {
      this.$swal
        .fire({
          title: "Cofnięcie zlecenia",
          text: "Czy na pewno chcesz cofnąć zlecenie dla wykonawcy?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) {
            this.cancelOrder();
          }
        });
    },
    onEndOrderForContractorButtonClicked() {
      this.$swal
        .fire({
          title: "Zakończenie zlecenia",
          text: "Czy na pewno chcesz zakończyć zlecenie dla wykonawcy?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) {
            this.endOrder();
          }
        });
    },

    async cancelOrder() {
      let creationDate = this.getDateWithoutTime(
        this.getLocalFormattedDateAndTime(new Date().toJSON())
      );

      var response = await RepositoryOrderContractors.cancelOrderForContractor(
        this.orderContractor?.id_order,
        this.orderContractor?.id,
        creationDate
      );

      handleResponseWithMessages(
        this,
        response,
        "Cofnięto zlecenie dla wykonawcy.",
        () => {
          if (typeof this.$parent.getOrderContractorDetails == "function")
            this.$parent.getOrderContractorDetails();
        }
      );
    },

    async endOrder() {
      let creationDate = this.getDateWithoutTime(
        this.getLocalFormattedDateAndTime(new Date().toJSON())
      );

      var response = await RepositoryOrderContractors.endOrderForContractor(
        this.orderContractor?.id_order,
        this.orderContractor?.id,
        creationDate
      );

      handleResponseWithMessages(
        this,
        response,
        "Zakończono zlecenie dla wykonawcy.",
        () => {
          if (typeof this.$parent.getOrderContractorDetails == "function")
            this.$parent.getOrderContractorDetails();
        }
      );
    },
    onAddActionButtonClicked() {
      this.actionAddEditKey++;
      this.$refs.modalBase.show();

      setTimeout(() => {
        this.$refs.actionAddEditChild.reloadMap();
      }, 500);
    },
    hideModal() {
      this.$refs.modalBase.hide();
    }
  }
};
</script>

<style scoped>
td {
  padding-bottom: 0.75rem;
}

tr :first-child {
  text-align: right;
  padding-right: 0.5rem;
}
</style>
