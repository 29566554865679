export function exportXlsx(
  rows = [],
  filename = "Excel",
  sheetname = "Sheetname"
) {
  if (rows.length === 0) {
    console.log("exportXlsx(): No rows.");
    return;
  }

  let createXLSLFormatObj = [];

  rows.forEach(row => {
    let innerRowData = [];

    row.forEach(rowElement => {
      innerRowData.push(rowElement);
    });

    createXLSLFormatObj.push(innerRowData);
  });

  let XLSX = require("xlsx");
  let wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(
    wb,
    XLSX.utils.aoa_to_sheet(createXLSLFormatObj),
    sheetname
  );
  XLSX.writeFile(wb, filename + ".xlsx");
}
