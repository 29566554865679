<template>
  <div class="passwordBox animated fadeInDown">
    <div class="row">
      <div class="col-md-12">
        <div class="ibox-content">
          <h2 class="font-bold">Resetowanie hasła</h2>

          <form-error-panel :errors="formErrors" />

          <div class="row">
            <div class="col-lg-12">
              <form class="m-t" @submit="submitForm">
                <div class="form-group">
                  <input
                    autocomplete="new-password"
                    type="password"
                    placeholder="Wpisz nowe hasło"
                    v-model="newPassword"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                </div>
                <div class="form-group">
                  <input
                    autocomplete="new-password"
                    type="password"
                    placeholder="Powtórz hasło"
                    v-model="newPasswordConfirmed"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                </div>

                <div
                  v-if="isLoading"
                  class="sk-spinner sk-spinner-circle mb-3 mt-3"
                >
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>

                <button
                  type="submit"
                  class="btn btn-primary block full-width m-b"
                >
                  Zresetuj hasło
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6">
        DKK Development
      </div>
      <div class="col-md-6 text-right">
        <small>© 2020</small>
      </div>
    </div>
  </div>
</template>

<script>
import FormErrorPanel from "@/components/utils/FormErrorPanel";
import { RepositoryFactory } from "@/data/repositoryFactory.js";

const RepositoryAuth = RepositoryFactory.get("auth");

export default {
  name: "view-reset-password",
  components: {
    FormErrorPanel
  },
  data() {
    return {
      formErrors: [],
      newPassword: null,
      newPasswordConfirmed: null,
      isLoading: false
    };
  },
  methods: {
    submitForm: async function(e) {
      e.preventDefault();

      var path = this.$route.path;
      var indexOfToken = path.indexOf("resetPassword") + 14;

      if (indexOfToken > path.length) {
        this.formErrors.push("Błąd w adresie.");
        return;
      }

      var token = path.substring(indexOfToken, path.length);

      if (
        this.newPassword == null ||
        this.newPassword !== this.newPasswordConfirmed
      ) {
        this.formErrors.push("Wpisane hasła nie są identyczne.");
      } else {
        this.isLoading = true;

        var response = await RepositoryAuth.resetPassword(
          token,
          this.newPassword
        );

        if (response?.serverError) {
          this.$toastr.Add({
            msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
            progressbar: false,
            timeout: 3000,
            type: "error"
          });
        } else {
          if (response) this.formErrors = response;
          else {
            this.$toastr.Add({
              msg: "Hasło zostało zmienione.",
              progressbar: false,
              timeout: 3000,
              type: "success"
            });

            this.$router.push({ name: "login" });
          }
        }

        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped></style>
