<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      style="border-style: none"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <h5>I. Pobierz listę punktów zbioru</h5>
      <button
        ref="ladda"
        class="ladda-button btn btn-primary mt-2"
        @click="onListDownloadButtonClicked"
        data-style="zoom-in"
      >
        Pobierz listę
      </button>

      <div class="mt-4">
        <h5>II. Uzupełnij datę zebrania</h5>

        <h4
          class="mt-3"
          v-if="
            transportObject &&
              Array.isArray(transportObject) &&
              transportObject.length > 0 &&
              transportObject[transportObject.length - 1].transport_date != null
          "
        >
          Zapisane daty zebrania
        </h4>

        <transport-dates-table
          style="width: 250px"
          :transportObject="transportObject"
          :isLoading="isLoading"
          @dateDeleted="$emit('refresh')"
        />

        <div class="form-group mt-3" id="data_1">
          <h4
            v-if="
              transportObject &&
                Array.isArray(transportObject) &&
                transportObject.length > 0 &&
                transportObject[transportObject.length - 1].transport_date !=
                  null
            "
          >
            Dodaj kolejną datę:
          </h4>
          <div class="input-group date" style="width: 230px">
            <span class="input-group-addon" :style="'background-color: #e9ecef'"
              ><i class="fa fa-calendar"></i></span
            ><input
              id="transportDateId"
              type="text"
              class="form-control"
              v-model="transport_date"
              required
            />
          </div>
        </div>
        <button
          class="btn btn-primary mt-2"
          @click="onSendTransportDateButtonClicked"
        >
          Prześlij datę
        </button>
      </div>
    </div>

    <pdf-generator
      ref="pdfGenerator"
      :filename="transportListFilename"
      :enableDownload="true"
      :preview-modal="false"
    >
      <span v-html="transportPDFContent" />
    </pdf-generator>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import PdfGenerator from "@/components/utils/PdfGenerator";
import {
  getLocalFormattedDateAndTime,
  getDateWithoutTime
} from "@/helpers/dateFormatHelper.js";
import TransportDatesTable from "@/components/orders/TransportDatesTable.vue";

const RepositoryOrders = RepositoryFactory.get("orders");

export default {
  name: "order-transport-phase",
  components: {
    PdfGenerator,
    TransportDatesTable
  },
  props: {
    orderId: Number,
    isLoading: {
      type: Boolean,
      default: false
    },
    transportObject: null
  },
  data() {
    return {
      transportPDFContent: null,
      isLoadingButton: false,
      laddaButton: null,
      transport_date: null
    };
  },
  computed: {
    transportListFilename() {
      return (
        "Lista punktów zbioru " +
        getLocalFormattedDateAndTime(new Date().toJSON())
      );
    }
  },
  watch: {
    isLoadingButton(newValue) {
      newValue ? this.ladda.start() : this.ladda.stop();
    }
  },
  mounted() {
    this.ladda = Ladda.create(this.$refs.ladda);
    this.loading ? this.ladda.start() : this.ladda.stop();

    $("#data_1 .input-group.date").datepicker({
      todayBtn: "linked",
      keyboardNavigation: true,
      forceParse: true,
      calendarWeeks: true,
      autoclose: true,
      weekStart: 1
    });

    let vm = this;
    $(document.body).on("change", 'input[id^="transportDateId"]', function() {
      vm.updateTransportDateFromPicker();
    });
  },
  created() {
    if (
      this.transportObject &&
      Array.isArray(this.transportObject) &&
      this.transportObject.length > 0 &&
      this.transportObject[this.transportObject.length - 1].transport_date !=
        null
    )
      this.transport_date = getDateWithoutTime(
        this.transportObject[this.transportObject.length - 1].transport_date
      );
    else this.setInitialTransportDate();
  },
  beforeDestroy() {
    this.ladda.remove();
    delete this.ladda;
  },
  methods: {
    getDateWithoutTime,
    getLocalFormattedDateAndTime,
    updateTransportDateFromPicker() {
      this.transport_date = document.getElementById("transportDateId").value;
    },
    setInitialTransportDate() {
      let now = new Date();
      let month = now.getMonth() + 1;
      let monthString = month.toString().length === 1 ? "0" + month : month;

      this.transport_date =
        now.getFullYear() + "-" + monthString + "-" + now.getDate();
    },
    async onSendTransportDateButtonClicked() {
      if (!this.transport_date) {
        this.$toastr.Add({
          msg: "Proszę wybrać datę.",
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });

        return;
      }

      var response = await RepositoryOrders.sendTransportDate(
        this.orderId,
        this.transport_date
      );

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (response) {
          let msg = "Coś poszło nie tak.";
          if (Array.isArray(response)) {
            msg = "";
            response.forEach(element => {
              msg += element + ". ";
            });
          }
          this.$toastr.Add({
            msg,
            progressbar: false,
            timeout: 3000,
            type: "warning"
          });
        } else {
          this.$emit("refresh");

          this.$toastr.Add({
            msg: "Data transportu została przesłana.",
            progressbar: false,
            timeout: 3000,
            type: "success"
          });

          this.transport_date = null;
        }
      }
    },
    async onListDownloadButtonClicked() {
      this.isLoadingButton = true;

      RepositoryOrders.getTransportList(this.orderId)
        .then(data => {
          if (Array.isArray(data?.data) && data.data.length === 0) {
            this.$toastr.Add({
              msg: "Brak punktów zbioru.",
              progressbar: false,
              timeout: 3000,
              type: "warning"
            });
          } else {
            this.transportPDFContent = this.createPdfDataForGenerator(data);

            if (this.transportPDFContent != null)
              this.$refs?.pdfGenerator?.generatePdf();
            else {
              this.$toastr.Add({
                msg: "Wystąpił błąd pliku. Spróbuj ponownie.",
                progressbar: false,
                timeout: 3000,
                type: "error"
              });
            }
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoadingButton = false;
        });
    },
    createPdfDataForGenerator(data) {
      if (!Array.isArray(data?.data)) return null;

      if (data.data.length === 0)
        return `<p class="text-center mt-3">Brak elementów do wyświetlenia</p>`;

      let pdfContent = `<h1>Lista punktów zbioru ${getLocalFormattedDateAndTime(
        new Date().toJSON()
      )}</h1><br>`;

      pdfContent += `<div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>
                Id
              </th>
              <th>
                Notatka
              </th>
              <th>
                Suma
              </th>
              <th>
                Drewniane
              </th>
              <th>
                Betonowe
              </th>
              <th>
                Szczudła
              </th>
              <th>
                Data
              </th>
              <th>
                Szerokość geog.
              </th>
              <th>
                Długość geog.
              </th>
              <th>
                Adres
              </th>
            </tr>
          </thead>
          <tbody>`;

      data.data.forEach(transportPoint => {
        pdfContent += `
              <tr>
              <td>
                  ${transportPoint.id ?? "-"}
              </td>
              <td>
                  ${transportPoint.note ?? "-"}
              </td>
              <td>
                  ${transportPoint.sum ?? "-"}
              </td>
              <td>
                  ${transportPoint.amount_w ?? "-"}
              </td>
              <td>
                  ${transportPoint.amount_c ?? "-"}
              </td>
              <td>
                  ${transportPoint.amount_s ?? "-"}
              </td>
              <td>
                  ${transportPoint?.geotimestamp?.date ?? "-"}
              </td>
              <td>
                  ${transportPoint?.geotimestamp?.lat ?? "-"}
              </td>
              <td>
                  ${transportPoint?.geotimestamp?.lon ?? "-"}
              </td>
              <td>
                  ${transportPoint?.geotimestamp?.geocoding ?? "-"}
              </td>
            </tr>
        `;
      });

      pdfContent += `</tbody>
        </table>
      </div>`;

      return pdfContent;
    }
  }
};
</script>

<style scoped></style>
