<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import L from "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";

const mapEvents = [
  L.Draw.Event.CREATED,
  L.Draw.Event.EDITED,
  L.Draw.Event.DELETED,
  L.Draw.Event.DRAWSTART,
  L.Draw.Event.DRAWSTOP,
  L.Draw.Event.DRAWVERTEX,
  L.Draw.Event.EDITSTART,
  L.Draw.Event.EDITMOVE,
  L.Draw.Event.EDITRESIZE,
  L.Draw.Event.EDITVERTEX,
  L.Draw.Event.EDITSTOP,
  L.Draw.Event.DELETESTART,
  L.Draw.Event.DELETESTOP
];
const toolbarEvents = [L.Draw.Event.TOOLBAROPENED, L.Draw.Event.TOOLBARCLOSED];

export default {
  name: "leaflet-draw",
  props: {
    position: {
      type: String,
      default: "topleft"
    },
    polyline: {
      type: Boolean,
      default: false
    },
    polygon: {
      type: Boolean,
      default: false
    },
    rectangle: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    marker: {
      type: Boolean,
      default: false
    },
    circleMarker: {
      type: Boolean,
      default: false
    },
    polylineOptions: {
      type: Object,
      default: () => ({})
    },
    polygonOptions: {
      type: Object,
      default: () => ({})
    },
    rectangleOptions: {
      type: Object,
      default: () => ({})
    },
    circleOptions: {
      type: Object,
      default: () => ({})
    },
    markerOptions: {
      type: Object,
      default: () => ({})
    },
    circleMarkerOptions: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    var drawOptions = {};

    Object.assign(drawOptions, {
      polyline: this.polyline ? this.polylineOptions : false,
      polygon: this.polygon ? this.polygonOptions : false,
      rectangle: this.rectangle ? this.rectangleOptions : false,
      circle: this.circle ? this.circleOptions : false,
      marker: this.marker ? this.markerOptions : false,
      circlemarker: this.circleMarker ? this.circleMarkerOptions : false
    });

    var options = {
      position: this.position,
      draw: drawOptions
    };

    this.mapObject = new L.Control.Draw(options);

    L.drawLocal.draw.toolbar.actions.text = "Anuluj";
    // L.drawLocal.draw.toolbar.actions.title = "Annule l'ajout";
    L.drawLocal.draw.toolbar.finish.text = "Zakończ";
    // L.drawLocal.draw.toolbar.finish.title = "Termine l'ajout";
    L.drawLocal.draw.toolbar.undo.text = "Usuń ostatni punkt";
    // L.drawLocal.draw.toolbar.undo.title = "Supprime le dernier sommet ajouté";
    // L.drawLocal.draw.handlers.simpleshape.tooltip.end =
    //   "Relâcher le bouton de la souris pour terminer";
    L.drawLocal.draw.toolbar.buttons.polygon = "Zaznacz obszar";
    // L.drawLocal.draw.handlers.polygon.tooltip.start = "Kliknij, aby rozpocząć zaznaczanie obszaru";
    // L.drawLocal.draw.handlers.polygon.tooltip.cont = "Kliknij, aby dodać kolejny wierzchołek";
    // L.drawLocal.draw.handlers.polygon.tooltip.end = "Kliknij pierwszy wierzchołek, aby zakończyć zaznaczanie";
    L.drawLocal.draw.handlers.rectangle.tooltip.start =
      "Kliknij, aby rozpocząć zaznaczanie obszaru";
    L.drawLocal.draw.handlers.simpleshape.tooltip.end =
      "Puść przycisk, aby zatwierdzić obszar";

    if (this.$parent._isMounted) this.deferredMountedTo(this.$parent.mapObject);
  },
  methods: {
    deferredMountedTo(parent) {
      this.parent = parent;
      parent.addControl(this.mapObject);

      for (var drawEvent of mapEvents) {
        parent.on(drawEvent, ev => {
          this.$parent.$emit(`l-${ev.type.replace(/:/g, "-")}`, ev);
        });
      }

      for (drawEvent of toolbarEvents) {
        parent.on(drawEvent, ev => {
          this.$emit(`l-${ev.type.replace(/:/g, "-")}`, ev);
        });
      }

      // const editableLayers = new window.L.FeatureGroup().addTo(parent);
      // parent.on(window.L.Draw.Event.CREATED, e => {
      //   // const type = e.layerType;
      //   const layer = e.layer;

      //   // Do whatever else you need to. (save to db, add to map etc)
      //   editableLayers.addLayer(layer);
      // });
    }
  }
};
</script>
