<template>
  <p v-if="metaObject && metaObject.from != null && metaObject.to != null">
    Wyświetlono od {{ metaObject.from }} do {{ metaObject.to }} z
    {{ metaObject.total != null ? metaObject.total : "-" }} elementów
  </p>
</template>

<script>
export default {
  name: "table-elements-info",
  props: {
    metaObject: {
      type: Object,
      default: null
    }
  }
};
</script>

<style></style>
