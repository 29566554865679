var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"ibox col-6 mb-0"},[_c('div',{staticClass:"ibox-content",class:{ 'sk-loading': _vm.isLoading },staticStyle:{"border-style":"none"}},[_vm._m(0),_c('cluster-map-component',{attrs:{"centerLat":_vm.mapCenter.lat,"centerLng":_vm.mapCenter.lon,"markers":_vm.markersForMap,"height":"400px"},on:{"markerClicked":_vm.onMarkerClicked}})],1)]),_c('div',{staticClass:"ibox col-6 mb-0",staticStyle:{"height":"430px"}},[_c('div',{staticClass:"ibox-content",class:{ 'sk-loading': _vm.isLoading },staticStyle:{"height":"100%","width":"100%","border-style":"none","display":"flex","flex-direction":"column","justify-content":"space-between"}},[_vm._m(1),_c('div',[_c('h4',[_vm._v(" Słupy do eksportu: "),_c('b',[_vm._v(" "+_vm._s(_vm.pickedPolesCount)+" z "+_vm._s(_vm.polesObject && Array.isArray(_vm.polesObject.data) ? _vm.polesObject.data.length : "-")+" ")])]),(_vm.pickedPole)?_c('div',{staticClass:"mt-4"},[_c('b',[_vm._v("Wybrany słup: ")]),_c('div',{staticClass:"pl-2"},[_c('br'),_c('b',[_vm._v("Oznaczenie:")]),_vm._v(" "+_vm._s(_vm.pickedPole.oznaczenie != null ? _vm.pickedPole.oznaczenie : "-")+" "),_c('br'),_c('b',[_vm._v("Szerokość geograficzna:")]),_vm._v(" "+_vm._s(_vm.pickedPole.lat != null ? _vm.pickedPole.lat : "-")+" "),_c('br'),_c('b',[_vm._v("Długość geograficzna:")]),_vm._v(" "+_vm._s(_vm.pickedPole.lon != null ? _vm.pickedPole.lon : "-")+" "),_c('br'),_c('button',{staticClass:"btn btn-outline btn-primary mt-2",on:{"click":function () {
                    _vm.pickedPole.geodezja === 1
                      ? (_vm.pickedPole.geodezja = 0)
                      : (_vm.pickedPole.geodezja = 1);

                    _vm.prepareMarkersForMap();
                  }}},[(_vm.pickedPole.geodezja === 1)?_c('span',[_vm._v("Wyłącz z eksportu")]):_c('span',[_vm._v("Dodaj do eksportu")])]),_c('button',{staticClass:"btn btn-outline btn-danger mt-2 ml-1",on:{"click":_vm.onDeletePoleButtonClicked}},[_vm._v(" Usuń słup ")])])]):(_vm.markersForMap.length > 0)?_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"text-center"},[_vm._v("Wybierz słup z mapy")])]):_vm._e()]),_c('div',{staticClass:"row mr-3",staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('button',{staticClass:"btn btn-primary mr-1",staticStyle:{"display":"table-cell","vertical-align":"bottom"},attrs:{"disabled":_vm.polesObject &&
                Array.isArray(_vm.polesObject.data) &&
                _vm.polesObject.data.length <= _vm.pickedPolesCount},on:{"click":_vm.activateAll}},[_vm._v(" Dodaj wszystkie ")]),_c('button',{staticClass:"btn btn-primary mr-1",staticStyle:{"display":"table-cell","vertical-align":"bottom"},attrs:{"disabled":_vm.polesObject && _vm.pickedPolesCount <= 0},on:{"click":_vm.deactivateAll}},[_vm._v(" Wyłącz wszystkie ")]),_c('button',{staticClass:"btn btn-primary",staticStyle:{"display":"table-cell","vertical-align":"bottom"},on:{"click":_vm.updatePolesForGeodesist}},[_vm._v(" Zapisz zmiany ")])])])])]),_c('div',{staticClass:"col-6 mb-3"},[_c('div',[_c('label',[_vm._v("Link dla geodety:")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"input-group"},[_c('input',{staticStyle:{"width":"90%"},attrs:{"id":"linkForGeodesistInputId","type":"text","readonly":""},domProps:{"value":_vm.linkForGeodesist}}),_c('span',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-primary btn-outline btn-xs",attrs:{"type":"button"},on:{"click":_vm.copyToClipboard}},[_c('i',{staticClass:"fa fa-clipboard"})])])])]),(_vm.linkForGeodesist)?_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary btn-xs",on:{"click":_vm.showGeodesistMapInNewTab}},[_vm._v(" Otwórz w nowej karcie ")])]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sk-spinner sk-spinner-three-bounce"},[_c('div',{staticClass:"sk-bounce1"}),_c('div',{staticClass:"sk-bounce2"}),_c('div',{staticClass:"sk-bounce3"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sk-spinner sk-spinner-three-bounce"},[_c('div',{staticClass:"sk-bounce1"}),_c('div',{staticClass:"sk-bounce2"}),_c('div',{staticClass:"sk-bounce3"})])}]

export { render, staticRenderFns }