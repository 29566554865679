<template>
  <div
    class="modal inmodal fade"
    :id="id"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" style="max-width: 80%; height: 80%">
      <div
        class="modal-content"
        style="background-color: transparent; box-shadow: none; height: 100%"
      >
        <div
          class="modal-body text-center"
          style="background-color: transparent; height: 100%"
          @click="hide"
        >
          <img :src="source" height="100%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-image-container",
  props: {
    id: { type: String, default: "modalImageContainer" },
    source: { type: String, default: null }
  },
  data() {
    return {};
  },
  methods: {
    hide() {
      // eslint-disable-next-line no-undef
      $(`#${this.id}`).modal("hide");
    }
  }
};
</script>
