<template>
  <div
    :style="'height: ' + height + ';'"
    style="width: 100%; position: relative;"
  >
    <l-map
      @ready="mapReady"
      ref="map"
      v-if="coordsValid"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @l-draw-created="emitNewGeometry"
      @click="onMapClicked"
    >
      <l-tile-layer :url="url" attribution="" />
      <l-feature-group ref="features">
        <l-geo-json v-if="geom" :geojson="geom" @ready="geomReady" />
        <l-marker-cluster v-else :options="clusterOptions">
          <span v-for="marker in markers" :key="marker.caption">
            <l-marker
              v-if="
                showZeroPositions ||
                  (Number.parseFloat(marker.lat) !== 0.0 &&
                    Number.parseFloat(marker.lng) !== 0.0)
              "
              :lat-lng="tooltipCoords(marker)"
              :draggable="marker.draggable ? marker.draggable : false"
              @moveend="onMarkerMoveEnd"
              @ready="markerReady"
              @click="onMarkerClick"
              :options="marker.options"
            >
              <l-icon
                v-if="marker.selected"
                :icon-size="[25, 40]"
                :icon-anchor="[12, 40]"
                icon-url="/static/img/yellow_marker.png"
              />
              <l-icon
                v-else-if="marker.draggable"
                :icon-size="[25, 40]"
                :icon-anchor="[12, 40]"
                icon-url="/static/img/red_marker.png"
              />
              <l-icon
                v-else-if="marker.grayCircle"
                :icon-size="[10, 10]"
                :icon-anchor="[5, 5]"
                :icon-url="'/static/img/gray_circle_marker.png'"
              />
              <l-icon
                v-else-if="marker.icon"
                :icon-size="
                  marker.iconSize != null ? marker.iconSize : [25, 40]
                "
                :icon-anchor="
                  marker.iconAnchor != null ? marker.iconAnchor : [12, 40]
                "
                :icon-url="'/static/img/' + marker.icon + '.png'"
              />

              <l-tooltip
                v-if="marker.caption || marker.draggable"
                :options="{ permanent: true, interactive: true }"
              >
                <div v-if="marker.draggable">
                  Przemieść marker
                </div>
                <div v-else>
                  {{ marker.caption }}
                </div>
              </l-tooltip>
              <span v-if="marker.popupCaption">
                <l-popup :content="marker.popupCaption"></l-popup>
              </span>
            </l-marker>
          </span>
        </l-marker-cluster>
      </l-feature-group>
      <leaflet-draw v-if="areaSelecting" :rectangle="true"></leaflet-draw>
    </l-map>
    <div
      v-else
      style="position:absolute; height:100%; width:100%; top:0; left:0;"
    >
      <h4 id="no-location-data-header">
        Brak danych o lokalizacji
      </h4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { latLng, Icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LFeatureGroup,
  LTooltip,
  LPopup,
  LIcon,
  LGeoJson
  //   LIconDefault
} from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import { getLocalFormattedDateAndTime } from "@/helpers/dateFormatHelper.js";
import LeafletDraw from "@/components/utils/LeafletDraw";

export default {
  name: "cluster-map-component",
  components: {
    LMap,
    LTileLayer,
    // LIconDefault,
    LMarker,
    LPopup,
    LFeatureGroup,
    LIcon,
    LTooltip,
    "l-marker-cluster": Vue2LeafletMarkerCluster,
    LGeoJson,
    LeafletDraw
  },
  props: {
    centerLat: {
      type: Number,
      default: 50.29044
    },
    centerLng: {
      type: Number,
      default: 21.42891
    },
    zoomLvl: {
      type: Number,
      default: 13
    },
    markers: {
      type: Array,
      default: () => []
    },
    refreshable: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "400px"
    },
    geom: {
      type: Object,
      default: null
    },
    addScreenShooter: {
      type: Boolean,
      default: false
    },
    showZeroPositions: {
      type: Boolean,
      default: true
    },
    areaSelecting: {
      type: Boolean,
      default: false
    },
    disableClusteringAtZoomOverride: {
      type: Number,
      default: null
    },
    maxClusterRadiusOverride: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      currentZoom: 0,
      currentCenter: 0,
      mapOptions: {
        zoomSnap: 0.5
      },
      clusterOptions: {
        disableClusteringAtZoom: 11
      },
      markersReady: 0,
      screenShooter: null,
      mapClickBlocked: false
    };
  },
  computed: {
    center() {
      return this.geom == null ? latLng(this.centerLat, this.centerLng) : null;
    },
    zoom() {
      return this.zoomLvl;
    },
    coordsValid() {
      if (this.geom && this.geom.type && this.geom.coordinates) return true;

      if (this.centerLat == null || this.centerLng == null) return false;

      var valid = true;
      this.markers.forEach(marker => {
        if (marker.lat == null || marker.lng == null) valid = false;
      });
      return valid;
    },
    ...mapGetters({ refreshKey: "mapRefreshKey" })
  },
  watch: {
    refreshKey() {
      if (!this.refreshable) return;

      this.refresh();
    },
    markers() {
      this.markersReady = 0;
    }
  },
  created() {
    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });

    if (this.disableClusteringAtZoomOverride)
      this.clusterOptions.disableClusteringAtZoom = this.disableClusteringAtZoomOverride;

    if (this.maxClusterRadiusOverride)
      this.clusterOptions.maxClusterRadius = this.maxClusterRadiusOverride;

    this.addClickListener();
  },
  methods: {
    getLocalFormattedDateAndTime,
    // click: e => console.log("clusterclick", e),
    markerReady() {
      this.markersReady++;

      if (this.markers.length > 0 && this.markersReady === this.markers.length)
        this.refresh();
    },
    geomReady() {
      this.setBounds();
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    tooltipCoords(marker) {
      return latLng(marker.lat, marker.lng);
    },
    setBounds(padValue = 0.2) {
      let group = this.$refs.features.mapObject;
      this.$refs.map.mapObject.fitBounds(group.getBounds().pad(padValue));
    },
    onMarkerMoveEnd(marker) {
      this.$emit("on-marker-dragged", marker.target._latlng);
    },
    mapReady() {
      if (this.addScreenShooter) this.addScreenShooterToMap();
    },
    addScreenShooterToMap() {
      let pluginOptions = {
        cropImageByInnerWH: true, // crop blank opacity from image borders
        hidden: false, // hide screen icon
        domtoimageOptions: {}, // see options for dom-to-image
        position: "topright", // position of take screen icon
        screenName:
          "Zrzut mapy " +
          this.getLocalFormattedDateAndTime(new Date().toJSON()), // string or function
        //iconUrl: ICON_SVG_BASE64, // screen btn icon base64 or url
        hideElementsWithSelectors: [".leaflet-control-container"], // by default hide map controls All els must be child of _map._container
        mimeType: "image/png", // used if format == image,
        caption: null, // string or function, added caption to bottom of screen
        captionFontSize: 15,
        captionFont: "Arial",
        captionColor: "black",
        captionBgColor: "white",
        captionOffset: 5
      };

      this.screenShooter = new SimpleMapScreenshoter(pluginOptions).addTo(
        this.$refs.map.mapObject
      );
    },
    onMarkerClick(marker) {
      this.$emit("markerClicked", marker);
    },
    refresh() {
      this.$refs.map.mapObject.invalidateSize();
      this.setBounds();
    },
    emitNewGeometry(ev) {
      let area = ev.layer;
      let selectedMarkers = [];

      this.markers.forEach(marker => {
        if (area.getBounds().contains(latLng(marker.lat, marker.lng))) {
          selectedMarkers.push(marker);
        }
      });

      this.$emit("selected-markers-changed", selectedMarkers);

      this.mapClickBlocked = true;
      setTimeout(() => {
        this.mapClickBlocked = false;
      }, 500);
    },
    onMapClicked() {
      if (!this.mapClickBlocked) this.$emit("map-clicked");
    },
    addClickListener() {
      document.addEventListener("click", ev => {
        if (ev?.target == undefined) return;

        let clickable = undefined;

        if (ev.target.getAttribute("clickable") != undefined) {
          clickable = ev.target;
        } else if (ev.target.getAttribute("clickable_child") != undefined) {
          clickable = ev.target.parentElement;
        }

        if (clickable != undefined) {
          this.$emit("clickableClicked", clickable);
        }
      });
    }
  }
};
</script>

<style scoped>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

#no-location-data-header {
  text-align: center;
  vertical-align: middle;
  line-height: 400px;
}
</style>
