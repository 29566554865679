export default {
  state: {
    ordersNavigationState: localStorage.getItem("ordersNavigationState")
  },
  getters: {
    initialOrdersTab: state => {
      let ordersNavigationState = state.ordersNavigationState
        ? JSON.parse(state.ordersNavigationState)
        : {};

      return ordersNavigationState?.initialTab
        ? ordersNavigationState.initialTab
        : 1;
    }
  },
  mutations: {
    changeInitialTab(state, value) {
      let ordersNavigationState = state.ordersNavigationState
        ? JSON.parse(state.ordersNavigationState)
        : {};

      ordersNavigationState.initialTab = value;
      state.ordersNavigationState = JSON.stringify(ordersNavigationState);
      localStorage.setItem(
        "ordersNavigationState",
        state.ordersNavigationState
      );
    }
  },
  actions: {
    changeInitialTab(context, value) {
      context.commit("changeInitialTab", value);
    }
  }
};
