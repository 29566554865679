var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"tabs-container"},[_c('ul',{staticClass:"nav nav-tabs",attrs:{"role":"tablist"}},[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.order && _vm.order.orderType && _vm.order.orderType.id !== 3),expression:"order && order.orderType && order.orderType.id !== 3"}],on:{"click":function($event){return _vm.onTabClicked(3)}}},[_c('a',{staticClass:"nav-link active",attrs:{"data-toggle":"tab","href":"#tab-3"}},[_c('i',{staticClass:"fa fa-users",style:(_vm.contractorsTabCompleted ? 'color: #1ab394' : '')}),_c('span',{style:(_vm.contractorsTabCompleted ? 'color: #1ab394' : '')},[_vm._v("Wykonawcy")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.order && _vm.order.orderType && _vm.order.orderType.id !== 3),expression:"order && order.orderType && order.orderType.id !== 3"}],on:{"click":function($event){return _vm.onTabClicked(4)}}},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#tab-4"}},[_c('i',{staticClass:"fa fa-truck",style:(_vm.transportTabCompleted ? 'color: #1ab394' : '')}),_c('span',{style:(_vm.transportTabCompleted ? 'color: #1ab394' : '')},[_vm._v("Transport")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.order &&
                _vm.order.orderType &&
                (_vm.order.orderType.id === 2 || _vm.order.orderType.id === 3)
            ),expression:"\n              order &&\n                order.orderType &&\n                (order.orderType.id === 2 || order.orderType.id === 3)\n            "}],on:{"click":function($event){return _vm.onTabClicked(6)}}},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#tab-6"}},[_c('img',{staticStyle:{"margin-right":"6px"},attrs:{"src":_vm.geodeticProtocolTabCompleted
                    ? '/static/img/map-icon-1ab394.png'
                    : '/static/img/map-icon-gray.png',"alt":"Protokół geodezji","width":"13","height":"13"}}),_c('span',{style:(_vm.geodeticProtocolTabCompleted ? 'color: #1ab394' : '')},[_vm._v(" Protokół geodezji ")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.order &&
                _vm.order.orderType &&
                (_vm.order.orderType.id === 1 || _vm.order.orderType.id === 2)
            ),expression:"\n              order &&\n                order.orderType &&\n                (order.orderType.id === 1 || order.orderType.id === 2)\n            "}],on:{"click":function($event){return _vm.onTabClicked(5)}}},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#tab-5"}},[_c('i',{staticClass:"fa fa-check-square-o",style:(_vm.acceptanceProtocolTabCompleted ? 'color: #1ab394' : '')}),_c('span',{style:(_vm.acceptanceProtocolTabCompleted ? 'color: #1ab394' : '')},[_vm._v("Protokół odbioru")])])]),_c('li',{on:{"click":function($event){return _vm.onTabClicked(7)}}},[_c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#tab-7"}},[_c('i',{staticClass:"fa fa-file-text-o",style:(_vm.invoiceTabCompleted ? 'color: #1ab394' : '')}),_c('span',{style:(_vm.invoiceTabCompleted ? 'color: #1ab394' : '')},[_vm._v("Fakturowanie")])])]),_c('li',{staticClass:"ml-auto",on:{"click":function($event){return _vm.onTabClicked(1)}}},[_vm._m(0)])]),_c('div',{staticClass:"tab-content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.order && _vm.order.orderType && _vm.order.orderType.id !== 3),expression:"order && order.orderType && order.orderType.id !== 3"}],staticClass:"tab-pane active",attrs:{"role":"tabpanel","id":"tab-3"}},[_c('div',{staticClass:"panel-body"},[_c('button',{staticClass:"btn btn-primary btn-xs",on:{"click":function($event){return _vm.showModal(_vm.MODAL_USERS_TABLE, {
                    additionalProps: {
                      filter: { url: 'user/workers' }
                    }
                  })}}},[_vm._v(" Dodaj wykonawcę ")]),(_vm.alreadyLoaded.orderContractors)?_c('order-contractors-table',{key:_vm.contractorsTableKey,attrs:{"filter":{ url: ("/order/" + _vm.id + "/users") },"showBorder":false,"longerSearchBar":true}}):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.order && _vm.order.orderType && _vm.order.orderType.id !== 3),expression:"order && order.orderType && order.orderType.id !== 3"}],staticClass:"tab-pane",attrs:{"role":"tabpanel","id":"tab-4"}},[_c('div',{staticClass:"panel-body"},[(_vm.alreadyLoaded.transport)?_c('order-transport-phase',{attrs:{"orderId":_vm.id,"transportObject":_vm.order && _vm.order.transport ? _vm.order.transport : null,"isLoading":_vm.isLoading},on:{"refresh":_vm.onRefreshEvent}}):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.order &&
                _vm.order.orderType &&
                (_vm.order.orderType.id === 2 || _vm.order.orderType.id === 3)
            ),expression:"\n              order &&\n                order.orderType &&\n                (order.orderType.id === 2 || order.orderType.id === 3)\n            "}],staticClass:"tab-pane",attrs:{"role":"tabpanel","id":"tab-6"}},[_c('div',{staticClass:"panel-body"},[(_vm.alreadyLoaded.geodeticProtocol)?_c('order-geodetic-protocol-phase',{key:_vm.geodeticProtocolPhase,attrs:{"orderId":_vm.id,"order":_vm.order,"orderUUID":_vm.order && _vm.order.uuid ? _vm.order.uuid : null,"geodeticProtocolObject":_vm.order && _vm.order.pg ? _vm.order.pg : null,"isLoading":_vm.isLoading},on:{"refresh":_vm.onRefreshEvent,"refreshme":function($event){_vm.geodeticProtocolPhase++}}}):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.order &&
                _vm.order.orderType &&
                (_vm.order.orderType.id === 1 || _vm.order.orderType.id === 2)
            ),expression:"\n              order &&\n                order.orderType &&\n                (order.orderType.id === 1 || order.orderType.id === 2)\n            "}],staticClass:"tab-pane",attrs:{"role":"tabpanel","id":"tab-5"}},[_c('div',{staticClass:"panel-body"},[(_vm.alreadyLoaded.acceptanceProtocol)?_c('order-acceptance-protocol-phase',{attrs:{"orderId":_vm.id,"acceptanceProtocolObject":_vm.order && _vm.order.po ? _vm.order.po : null,"isLoading":_vm.isLoading},on:{"refresh":_vm.onRefreshEvent}}):_vm._e()],1)]),_c('div',{staticClass:"tab-pane",attrs:{"role":"tabpanel","id":"tab-7"}},[_c('div',{staticClass:"panel-body"},[(_vm.alreadyLoaded.invoicing)?_c('order-invoicing-phase',{attrs:{"orderId":_vm.id,"invoiceObject":_vm.order && _vm.order.fv ? _vm.order.fv : null,"isLoading":_vm.isLoading},on:{"refresh":_vm.onRefreshEvent}}):_vm._e()],1)]),_c('div',{staticClass:"tab-pane",attrs:{"role":"tabpanel","id":"tab-1"}},[_c('div',{staticClass:"panel-body"},[(_vm.alreadyLoaded.fileRepositories)?_c('file-repositories-view-for-order',{key:_vm.fileRepoKey,attrs:{"orderId":_vm.id},on:{"refresh":function($event){_vm.fileRepoKey++}}}):_vm._e()],1)])])])])]),_c('modal-base',{ref:"modalBase"},[_c('modal-body',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.modal.title)+" ")]},proxy:true},{key:"body",fn:function(){return [_c(_vm.modal.component,_vm._b({key:_vm.modal.key,tag:"component",on:{"on-completed":_vm.onModalCompleted}},'component',_vm.modal.properties,false))]},proxy:true}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"nav-link",attrs:{"data-toggle":"tab","href":"#tab-1"}},[_c('i',{staticClass:"fa fa-files-o"}),_vm._v(" Repozytorium")])}]

export { render, staticRenderFns }