import repository from "./repository.js";

export default {
  async getFilesObject(page, itemsPerPage, search, sortBy, orderType, url) {
    return repository
      .get(
        `/${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}`
      )
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(error => {
        return { error };
      });
  },

  async getOrderMajorResourcesGeneral(id) {
    return repository.get(`/order/${id}/majorResources`).then(response => {
      if (response.data.error == null) return response.data;
    });
  },

  async addFileToOrder(orderId, file) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("creation_date", "2020-10-02");
    formData.append("created_by", 3);

    return repository
      .post(`/order/${orderId}/addFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(function(response) {
        if (response && response.data && response.data.message)
          return response.data.message;
        else return null;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async deleteFile(resourceId) {
    return repository
      .delete(`/resource/${resourceId}`)
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async shareFile(fileId) {
    return repository
      .get(`/resource/${fileId}/share`)
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async deleteTransportDate(transportId) {
    return repository
      .delete(`/transport/${transportId}`)
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async deleteInvoice(invoiceId) {
    return repository
      .delete(`/fv/${invoiceId}`)
      .then(response => {
        return response.data;
      })
      .catch(serverError => {
        return { serverError };
      });
  }
};
