import { mapGetters } from "vuex";
import PaginationCountPicker from "@/components/utils/PaginationCountPicker";

export default {
  components: {
    PaginationCountPicker
  },
  computed: {
    ...mapGetters({ itemsPerPage: "paginationItemsCount" })
  },
  methods: {
    onItemsPerPageChanged() {
      this.fetchData();
    }
  }
};
