import Vue from "vue";
import Vuex from "vuex";
import storeNavigationPanel from "./modules/storeNavigationPanel";
import storeAuth from "./modules/storeAuth";
import storeMap from "./modules/storeMap";
import storeNotificationsDropdown from "./modules/storeNotificationsDropdown";
import storePagination from "./modules/storePagination";
import storeOrdersNavigation from "./modules/storeOrdersNavigation";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    storeNavigationPanel,
    storeAuth,
    storeMap,
    storeNotificationsDropdown,
    storePagination,
    storeOrdersNavigation
  }
});
