<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      style="border-style: none"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div class="row">
        <div class="col-12 mt-2" style="border-bottom: 1px dashed #1ab394;">
          <h5>I. Wypełnij daty dotyczące geodezji</h5>
          <geodetic-dates-for-order :orderId="orderId" />
        </div>

        <form
          @submit="onSendAttachmentNo6ButtonClicked"
          class="col-12 mt-2"
          style="border-bottom: 1px dashed #1ab394;"
        >
          <h5>II. Prześlij zawartość załącznika nr 6</h5>
          <div class="row">
            <div class="col-4 p-1">
              <h5 class="text-center mb-3">Oznaczenie</h5>
              <textarea
                style="width: 100%; height: 100px;"
                placeholder="Wklej zawartość kolumny z oznaczeniem słupa"
                v-model="attachmentNo6Content.poleName"
              ></textarea>
            </div>

            <div class="col-4 p-1">
              <h5 class="text-center mb-3">Szerokość geograficzna</h5>
              <textarea
                style="width: 100%; height: 100px;"
                placeholder="Wklej zawartość kolumny z szerokością geograficzną słupa"
                v-model="attachmentNo6Content.lat"
              ></textarea>
            </div>

            <div class="col-4 p-1">
              <h5 class="text-center mb-3">Długość geograficzna</h5>
              <textarea
                style="width: 100%; height: 100px;"
                placeholder="Wklej zawartość kolumny z długością geograficzną słupa"
                v-model="attachmentNo6Content.lon"
              ></textarea>
            </div>
          </div>
          <div class="row mb-3" style="float: right">
            <button type="submit" class="btn btn-primary mt-2 mr-3">
              Prześlij
            </button>
          </div>
        </form>

        <div class="col-12 mt-2" style="border-bottom: 1px dashed #1ab394;">
          <h5>III. Wygeneruj link dla geodety</h5>
          <order-poles-picker-for-p-g
            v-if="showPicker"
            :orderId="orderId"
            :orderUUID="orderUUID"
            @refresh="$emit('refresh')"
          />
        </div>

        <div class="col-12">
          <div class="row mt-2">
            <div
              class="col-auto mt-3"
              style="border-right: 1px dashed #1ab394;"
            >
              <file-or-files-for-order-phases
                :fileObject="geodeticProtocolObject"
                @fileDeleted="$emit('refresh')"
              />
            </div>
            <div class="col-8">
              <h5>IV. Wczytaj protokół geodezji</h5>

              <form v-show="showFileInput" @submit="onDataUpload">
                <div class="form-group" id="data_1">
                  <label>Data realizacji</label>
                  <div class="input-group date">
                    <span
                      class="input-group-addon"
                      :style="'background-color: #e9ecef'"
                      ><i class="fa fa-calendar"></i></span
                    ><input
                      id="geodeticRealizationDateId"
                      type="text"
                      class="form-control"
                      v-model="geodetic_relization_date"
                      required
                    />
                  </div>
                </div>
                <div class="row mt-2 ml-1">
                  <div class="switch mr-2">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        class="onoffswitch-checkbox"
                        id="example1"
                        v-model="addToMultipleOrdersEnabled"
                      />
                      <label class="onoffswitch-label" for="example1">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                  <label>Dodaj do wielu zleceń</label>
                </div>
                <div
                  v-show="showFileInput && addToMultipleOrdersEnabled"
                  class="col-12 mt-2 p-0"
                >
                  <geodetic-protocol-aggregate-assigning
                    ref="additionalOrdersComponent"
                    :initialOrders="[order]"
                  />
                </div>
                <drop-area
                  id="dropArea3"
                  class="dropArea"
                  caption="Upuść plik tutaj albo kliknij 'Wybierz plik' poniżej"
                  fileInputId="fileInputField3"
                  @onFileChanged="
                    onDropAreaFileChanged($event, 'fileInputField3')
                  "
                />
                <input
                  id="fileInputField3"
                  name="file"
                  type="file"
                  class="mt-2"
                  @change="onFileChanged($event, 'fileInputField3')"
                />
                <button class="btn btn-primary mt-2 float-right" type="submit">
                  Prześlij plik
                </button>
              </form>
              <div v-show="!showFileInput">
                <button
                  class="btn btn-outline btn-primary mt-2"
                  @click="showFileInput = true"
                >
                  Dodaj kolejny plik
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import orderPhaseMixin from "@/mixins/orderPhaseMixin.js";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import OrderPolesPickerForPG from "@/components/orders/OrderPolesPickerForPG";
import FileOrFilesForOrderPhases from "@/components/utils/FileOrFilesForOrderPhases";
import { getDateWithoutTime } from "@/helpers/dateFormatHelper.js";
import GeodeticProtocolAggregateAssigning from "@/components/orders/GeodeticProtocolAggregateAssigning";
import GeodeticDatesForOrder from "@/components/orders/GeodeticDatesForOrder.vue";
import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";

const RepositoryOrders = RepositoryFactory.get("orders");

export default {
  name: "order-geodetic-protocol-phase",
  components: {
    OrderPolesPickerForPG,
    FileOrFilesForOrderPhases,
    GeodeticProtocolAggregateAssigning,
    GeodeticDatesForOrder
  },
  mixins: [orderPhaseMixin],
  props: {
    geodeticProtocolObject: null,
    orderId: null,
    order: null,
    orderUUID: null
  },
  data() {
    return {
      showPicker: false,
      showFileInput: true,
      attachmentNo6Content: {
        poleName: null,
        lat: null,
        lon: null
      },
      geodetic_relization_date: null,
      addToMultipleOrdersEnabled: false
    };
  },
  computed: {
    multipleOrdersCount() {
      if (Array.isArray(this.$refs.additionalOrdersComponent.$data.orders))
        return this.$refs.additionalOrdersComponent.$data.orders.length;
      else return "-";
    }
  },
  watch: {
    geodeticProtocolObject() {
      this.addAdditionalInfo();

      this.showFileInput =
        Array.isArray(this.geodeticProtocolObject) &&
        this.geodeticProtocolObject.length === 0;
    }
  },
  created() {
    this.addAdditionalInfo();

    if (
      Array.isArray(this.geodeticProtocolObject) &&
      this.geodeticProtocolObject.length > 0
    )
      this.showFileInput = false;

    setInterval(() => {
      this.showPicker = true;
    }, 50);
  },
  mounted() {
    $("#data_1 .input-group.date").datepicker({
      todayBtn: "linked",
      keyboardNavigation: true,
      forceParse: true,
      calendarWeeks: true,
      autoclose: true,
      weekStart: 1
    });

    let vm = this;
    $(document.body).on(
      "change",
      'input[id^="geodeticRealizationDateId"]',
      function() {
        vm.updateGeodeticRealizationDateFromPicker();
      }
    );
  },
  methods: {
    getDateWithoutTime,
    onDataUpload(e) {
      e.preventDefault();

      this.onFileUpload();
    },
    updateGeodeticRealizationDateFromPicker() {
      this.geodetic_relization_date = document.getElementById(
        "geodeticRealizationDateId"
      ).value;
    },
    async onFileUpload() {
      if (!this.file) {
        this.$toastr.Add({
          msg: "Proszę załączyć plik.",
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });

        return;
      }

      let returnFromFunction = false;

      if (this.addToMultipleOrdersEnabled) {
        let ordersCountText = this.multipleOrdersCount.toString();

        if (this.multipleOrdersCount === 1) ordersCountText += " zlecenia";
        else ordersCountText += " zleceń";

        await this.$swal
          .fire({
            title: "Dodawanie pliku",
            text: `Czy na pewno chcesz dodać plik do ${ordersCountText}?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#1ab394",
            cancelButtonColor: "#f8ac59",
            confirmButtonText: "Tak",
            cancelButtonText: "Nie",
            customClass: {
              title: "swal2-title"
            }
          })
          .then(result => {
            if (!result.value) returnFromFunction = true;
          });
      }

      if (returnFromFunction) return;

      let creationDate = this.getDateWithoutTime(
        this.getLocalFormattedDateAndTime(new Date().toJSON())
      );

      let orders_ids = null;

      if (
        this.addToMultipleOrdersEnabled &&
        Array.isArray(this.$refs.additionalOrdersComponent.$data.orders)
      ) {
        orders_ids = this.$refs.additionalOrdersComponent.$data.orders.map(
          x => x.id
        );

        orders_ids = orders_ids.filter(x => x !== this.orderId);
        if (orders_ids.length === 0) orders_ids = null;
      }

      var response = await RepositoryOrders.sendGeodeticProtocol(
        this.orderId,
        this.file,
        creationDate,
        this.loggedInUser?.id,
        this.geodetic_relization_date,
        orders_ids
      );

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (response) {
          let msg = "Coś poszło nie tak.";
          if (Array.isArray(response)) {
            msg = "";
            response.forEach(element => {
              msg += element + ". ";
            });
          }
          this.$toastr.Add({
            msg,
            progressbar: false,
            timeout: 3000,
            type: "warning"
          });
        } else {
          this.$emit("refresh");

          this.$toastr.Add({
            msg: "Protokół geodezji został przesłany.",
            progressbar: false,
            timeout: 3000,
            type: "success"
          });

          this.file = null;
          this.geodetic_relization_date = null;
        }
      }
    },
    onSendAttachmentNo6ButtonClicked(e) {
      e.preventDefault();

      //validate presence
      if (
        this.attachmentNo6Content.poleName == null ||
        this.attachmentNo6Content.poleName === "" ||
        this.attachmentNo6Content.lat == null ||
        this.attachmentNo6Content.lat === "" ||
        this.attachmentNo6Content.lon == null ||
        this.attachmentNo6Content.lon === ""
      ) {
        this.$toastr.Add({
          msg: "Uzupełnij wszystkie pola.",
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });
        return;
      }

      let poleNameTextRows = this.attachmentNo6Content.poleName
        .split("\n")
        .filter(x => x.trim() !== "");
      let latTextRows = this.attachmentNo6Content.lat
        .split("\n")
        .map(x => x.replaceAll(",", "."))
        .filter(x => x.trim() !== "");
      let lonTextRows = this.attachmentNo6Content.lon
        .split("\n")
        .map(x => x.replaceAll(",", "."))
        .filter(x => x.trim() !== "");
      let objRows = [];

      //validate length
      if (
        poleNameTextRows.length !== latTextRows.length ||
        poleNameTextRows.length !== lonTextRows.length
      ) {
        this.$toastr.Add({
          msg: "Liczba wierszy w poszczególnych polach różni się",
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });
        return;
      }

      //validate pole names duplications
      if (new Set(poleNameTextRows).size !== poleNameTextRows.length) {
        this.$toastr.Add({
          msg: "Powtórzenia w oznaczeniach słupów",
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });
        return;
      }

      //validate type
      if (
        latTextRows.find(lat => lat == null || isNaN(lat)) !== undefined ||
        lonTextRows.find(lon => lon == null || isNaN(lon)) !== undefined
      ) {
        this.$toastr.Add({
          msg: "Nieprawidłowy format danych",
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });
        return;
      }

      for (let index = 0; index < poleNameTextRows.length; index++) {
        let objRow = {
          oznaczenie: poleNameTextRows[index],
          lat: Number.parseFloat(latTextRows[index]),
          lon: Number.parseFloat(lonTextRows[index])
        };

        objRows.push(objRow);
      }

      this.sendAttachmentNo6(objRows);
    },
    async sendAttachmentNo6(polesArray) {
      var response = await RepositoryOrders.sendAttachmentNo6(
        this.orderId,
        polesArray
      );

      handleResponseWithMessages(
        this,
        response,
        "Dane zostały przesłane.",
        () => {
          this.$emit("refresh");
          this.$emit("refreshme");

          this.attachmentNo6Content.poleName = "";
          this.attachmentNo6Content.lat = "";
          this.attachmentNo6Content.lon = "";
        }
      );
    },
    addAdditionalInfo() {
      if (this.geodeticProtocolObject?.pg_date != null)
        this.geodeticProtocolObject.additionalInfo = [
          `Data: ${getDateWithoutTime(this.geodeticProtocolObject.pg_date)}`
        ];
      else if (Array.isArray(this.geodeticProtocolObject)) {
        this.geodeticProtocolObject.forEach(geodeticProtocol => {
          if (geodeticProtocol?.pg_date != null)
            geodeticProtocol.additionalInfo = [
              `Data: ${getDateWithoutTime(geodeticProtocol.pg_date)}`
            ];
        });
      }
    }
  }
};
</script>

<style scoped></style>
