<template>
  <div class="ibox">
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="m-b-md">
            <button
              class="btn btn-outline btn-primary btn-xs float-right"
              @click="editUser"
            >
              Edytuj użytkownika
            </button>
            <button
              v-if="ifCurrentUserLoggedIn()"
              class="btn btn-outline btn-primary btn-xs float-right mr-1"
              @click="changePassword"
            >
              Zmień hasło
            </button>
            <h2>{{ user.firstName }} {{ user.lastName }}</h2>
          </div>
        </div>
      </div>
      <!-- commented user icon <div class="row">
        <img
          alt="User"
          class="rounded-circle"
          :src="
            'https://eu.ui-avatars.com/api/?name=' +
              user.firstName +
              '+' +
              user.lastName +
              '&color=2f4050&background=f3f3f4'
          "
        />
      </div> -->
      <div class="row">
        <div class="col-lg-12">
          <dl class="row mb-0">
            <div class="col-sm-4 text-sm-right">
              <dt>Numer telefonu:</dt>
            </div>
            <div class="col-sm-8 text-sm-left">
              <dd class="mb-1">{{ user.phone ? user.phone : "-" }}</dd>
            </div>
          </dl>
          <dl class="row mb-0">
            <div class="col-sm-4 text-sm-right">
              <dt>Adres e-mail:</dt>
            </div>
            <div class="col-sm-8 text-sm-left">
              <dd class="mb-1">{{ user.email ? user.email : "-" }}</dd>
            </div>
          </dl>
          <dl class="row mb-0">
            <div class="col-sm-4 text-sm-right">
              <dt>Typ użytkownika:</dt>
            </div>
            <div class="col-sm-8 text-sm-left">
              <dd class="mb-1">
                {{ user.type && user.type.name ? user.type.name : "-" }}
              </dd>
            </div>
          </dl>
          <dl class="row mb-0">
            <div class="col-sm-4 text-sm-right">
              <dt>Aktywny:</dt>
            </div>
            <div class="col-sm-8 text-sm-left">
              <dd class="mb-1">
                <i v-if="user.active" class="fa fa-check text-navy" />
                <i
                  v-else-if="user.active === 0"
                  class="fa fa-times text-danger"
                />
                <span v-else>-</span>
              </dd>
            </div>
          </dl>
          <dl v-if="user.color" class="row mb-0">
            <div class="col-sm-4 text-sm-right">
              <dt>Kolor użytkownika:</dt>
            </div>
            <div class="col-sm-8 text-sm-left">
              <dd class="mb-1">
                <div
                  style="width: 40px; height: 20px; border-radius: 5px;"
                  :style="'background-color: ' + user.color"
                ></div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <modal-base ref="modalBase" maxWidth="40%">
      <password-change @on-completed="hideChangePasswordModal()" />
    </modal-base>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import ModalBase from "@/components/utils/ModalBase.vue";
import PasswordChange from "@/components/auth/PasswordChange.vue";

const RepositoryUsers = RepositoryFactory.get("users");

export default {
  name: "user-details-general",
  components: {
    ModalBase,
    PasswordChange
  },
  props: {
    id: Number
  },
  data() {
    return {
      isLoading: false,
      user: {
        id: null,
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        type: null
      }
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"])
  },
  watch: {
    id() {
      this.getUserDetails();
    }
  },
  created() {
    this.getUserDetails();
  },
  methods: {
    async getUserDetails() {
      this.isLoading = true;
      this.user = await RepositoryUsers.getUserDetailsGeneral(this.id);
      this.$emit("userInfoReceived", this.user?.type);
      this.isLoading = false;
    },
    editUser() {
      this.$router.push({
        name: "userAddEdit",
        params: { id: this.id }
      });
    },
    ifCurrentUserLoggedIn() {
      return this.user?.id && this.user?.id === this.loggedInUser?.id;
    },
    changePassword() {
      this.$swal
        .fire({
          title: "Czy chcesz zmienić hasło?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) {
            this.showChangePasswordModal();
          }
        });
    },
    showChangePasswordModal() {
      this.$refs.modalBase.show();
    },
    hideChangePasswordModal() {
      this.$refs.modalBase.hide();
    }
  }
};
</script>

<style></style>
