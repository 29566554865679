<template>
  <div>
    <transport-module />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import navItems from "@/helpers/navigationPanelHelper.js";
import TransportModule from "@/components/transport/TransportModule";

export default {
  name: "view-transport-module",
  components: {
    TransportModule
  },
  created() {
    this.setNavigationPanel(navItems.TRANSPORT_MODULE);
  },
  methods: {
    ...mapActions({
      setNavigationPanel: "changeNavigationPanelItems"
    })
  }
};
</script>

<style></style>
