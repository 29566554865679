<template>
  <div class="">
    [Dashboard]
  </div>
</template>

<script>
export default {
  name: "view-dashboard"
};
</script>

<style></style>
