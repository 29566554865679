<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs" role="tablist">
            <li
              v-show="order && order.orderType && order.orderType.id !== 3"
              @click="onTabClicked(3)"
            >
              <a class="nav-link active" data-toggle="tab" href="#tab-3">
                <i
                  class="fa fa-users"
                  :style="contractorsTabCompleted ? 'color: #1ab394' : ''"
                />
                <span :style="contractorsTabCompleted ? 'color: #1ab394' : ''"
                  >Wykonawcy</span
                ></a
              >
            </li>
            <li
              v-show="order && order.orderType && order.orderType.id !== 3"
              @click="onTabClicked(4)"
            >
              <a class="nav-link" data-toggle="tab" href="#tab-4">
                <i
                  class="fa fa-truck"
                  :style="transportTabCompleted ? 'color: #1ab394' : ''"
                />
                <span :style="transportTabCompleted ? 'color: #1ab394' : ''"
                  >Transport</span
                ></a
              >
            </li>
            <li
              v-show="
                order &&
                  order.orderType &&
                  (order.orderType.id === 2 || order.orderType.id === 3)
              "
              @click="onTabClicked(6)"
            >
              <a class="nav-link" data-toggle="tab" href="#tab-6">
                <img
                  :src="
                    geodeticProtocolTabCompleted
                      ? '/static/img/map-icon-1ab394.png'
                      : '/static/img/map-icon-gray.png'
                  "
                  alt="Protokół geodezji"
                  width="13"
                  height="13"
                  style="margin-right:6px;"
                />
                <span
                  :style="geodeticProtocolTabCompleted ? 'color: #1ab394' : ''"
                >
                  Protokół geodezji
                </span>
              </a>
            </li>
            <li
              v-show="
                order &&
                  order.orderType &&
                  (order.orderType.id === 1 || order.orderType.id === 2)
              "
              @click="onTabClicked(5)"
            >
              <a class="nav-link" data-toggle="tab" href="#tab-5">
                <!-- <i class="fa fa-check-circle-o" /> -->
                <i
                  class="fa fa-check-square-o"
                  :style="
                    acceptanceProtocolTabCompleted ? 'color: #1ab394' : ''
                  "
                />
                <span
                  :style="
                    acceptanceProtocolTabCompleted ? 'color: #1ab394' : ''
                  "
                  >Protokół odbioru</span
                ></a
              >
            </li>
            <li @click="onTabClicked(7)">
              <a class="nav-link" data-toggle="tab" href="#tab-7">
                <i
                  class="fa fa-file-text-o"
                  :style="invoiceTabCompleted ? 'color: #1ab394' : ''"
                />
                <span :style="invoiceTabCompleted ? 'color: #1ab394' : ''"
                  >Fakturowanie</span
                ></a
              >
            </li>
            <li class="ml-auto" @click="onTabClicked(1)">
              <a class="nav-link" data-toggle="tab" href="#tab-1">
                <i class="fa fa-files-o" />
                Repozytorium</a
              >
            </li>
            <!-- <li @click="onTabClicked(2)">
              <a class="nav-link" data-toggle="tab" href="#tab-2">
                <img
                  src="/static/img/inspection-gray.png"
                  alt="Słupy"
                  width="13"
                  height="13"
                  style="margin-right:6px;"
                />
                Słupy</a
              >
            </li> -->
          </ul>
          <div class="tab-content">
            <div
              v-show="order && order.orderType && order.orderType.id !== 3"
              role="tabpanel"
              id="tab-3"
              class="tab-pane active"
            >
              <div class="panel-body">
                <button
                  class="btn btn-primary btn-xs"
                  @click="
                    showModal(MODAL_USERS_TABLE, {
                      additionalProps: {
                        filter: { url: 'user/workers' }
                      }
                    })
                  "
                >
                  Dodaj wykonawcę
                </button>

                <order-contractors-table
                  v-if="alreadyLoaded.orderContractors"
                  :key="contractorsTableKey"
                  :filter="{ url: `/order/${id}/users` }"
                  :showBorder="false"
                  :longerSearchBar="true"
                />
              </div>
            </div>

            <div
              v-show="order && order.orderType && order.orderType.id !== 3"
              role="tabpanel"
              id="tab-4"
              class="tab-pane"
            >
              <div class="panel-body">
                <order-transport-phase
                  v-if="alreadyLoaded.transport"
                  :orderId="id"
                  :transportObject="
                    order && order.transport ? order.transport : null
                  "
                  @refresh="onRefreshEvent"
                  :isLoading="isLoading"
                />
              </div>
            </div>

            <div
              v-show="
                order &&
                  order.orderType &&
                  (order.orderType.id === 2 || order.orderType.id === 3)
              "
              role="tabpanel"
              id="tab-6"
              class="tab-pane"
            >
              <div class="panel-body">
                <order-geodetic-protocol-phase
                  v-if="alreadyLoaded.geodeticProtocol"
                  :key="geodeticProtocolPhase"
                  :orderId="id"
                  :order="order"
                  :orderUUID="order && order.uuid ? order.uuid : null"
                  :geodeticProtocolObject="order && order.pg ? order.pg : null"
                  @refresh="onRefreshEvent"
                  @refreshme="geodeticProtocolPhase++"
                  :isLoading="isLoading"
                />
              </div>
            </div>

            <div
              v-show="
                order &&
                  order.orderType &&
                  (order.orderType.id === 1 || order.orderType.id === 2)
              "
              role="tabpanel"
              id="tab-5"
              class="tab-pane"
            >
              <div class="panel-body">
                <order-acceptance-protocol-phase
                  v-if="alreadyLoaded.acceptanceProtocol"
                  :orderId="id"
                  :acceptanceProtocolObject="
                    order && order.po ? order.po : null
                  "
                  @refresh="onRefreshEvent"
                  :isLoading="isLoading"
                />
              </div>
            </div>

            <div role="tabpanel" id="tab-7" class="tab-pane">
              <div class="panel-body">
                <order-invoicing-phase
                  v-if="alreadyLoaded.invoicing"
                  :orderId="id"
                  :invoiceObject="order && order.fv ? order.fv : null"
                  @refresh="onRefreshEvent"
                  :isLoading="isLoading"
                />
              </div>
            </div>

            <div role="tabpanel" id="tab-1" class="tab-pane">
              <div class="panel-body">
                <file-repositories-view-for-order
                  v-if="alreadyLoaded.fileRepositories"
                  :orderId="id"
                  :key="fileRepoKey"
                  @refresh="fileRepoKey++"
                />
              </div>
            </div>

            <!-- <div role="tabpanel" id="tab-2" class="tab-pane">
              <div class="panel-body">
                <poles-table
                  v-if="alreadyLoaded.poles"
                  :filter="{ subject: 'order', id }"
                  :showHeader="false"
                  :showBorder="false"
                  :longerSearchBar="true"
                />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <modal-base ref="modalBase">
      <modal-body>
        <template v-slot:title>
          {{ modal.title }}
        </template>
        <template v-slot:body>
          <component
            :is="modal.component"
            @on-completed="onModalCompleted"
            :key="modal.key"
            v-bind="modal.properties"
          ></component>
        </template>
      </modal-body>
    </modal-base>
  </div>
</template>

<script>
import UsersTable from "@/components/users/UsersTable";
import OrderContractorsTable from "@/components/orders/OrderContractorsTable";
import FileRepositoriesViewForOrder from "@/components/fileRepositories/FileRepositoriesViewForOrder";
import PolesTable from "@/components/poles/PolesTable";
import { MODAL_USERS_TABLE } from "@/helpers/modalConstants.js";
import modalTablesMixin from "@/mixins/modalTablesMixin.js";
import ModalBase from "@/components/utils/ModalBase.vue";
import ModalBody from "@/components/utils/ModalBody.vue";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import OrderTransportPhase from "@/components/orders/OrderTransportPhase";
import OrderAcceptanceProtocolPhase from "@/components/orders/OrderAcceptanceProtocolPhase";
import OrderGeodeticProtocolPhase from "@/components/orders/OrderGeodeticProtocolPhase";
import OrderInvoicingPhase from "@/components/orders/OrderInvoicingPhase";

const RepositoryOrders = RepositoryFactory.get("orders");

/* eslint-disable no-undef */
export default {
  name: "order-details-additional",
  components: {
    UsersTable,
    OrderContractorsTable,
    FileRepositoriesViewForOrder,
    PolesTable,
    ModalBase,
    ModalBody,
    OrderTransportPhase,
    OrderAcceptanceProtocolPhase,
    OrderGeodeticProtocolPhase,
    OrderInvoicingPhase
  },
  mixins: [modalTablesMixin],
  props: {
    id: Number,
    order: Object,
    isLoading: Boolean
  },
  data() {
    return {
      alreadyLoaded: {
        orderContractors: true,
        transport: false,
        acceptanceProtocol: false,
        geodeticProtocol: false,
        invoicing: false,
        fileRepositories: false,
        poles: false
      },
      modal: {
        key: 0,
        component: null,
        visible: false,
        properties: null
      },
      contractorsTableKey: 0,
      fileRepoKey: 100,
      geodeticProtocolPhase: 200
    };
  },
  computed: {
    contractorsTabCompleted() {
      if (
        !Array.isArray(this.order?.usersOrders) ||
        this.order?.usersOrders.length < 1
      )
        return false;

      let completed = true;
      this.order.usersOrders.forEach(userOrder => {
        if (userOrder?.stop == null) completed = false;
      });

      return completed;
    },
    transportTabCompleted() {
      return (
        Array.isArray(this.order?.transport) && this.order.transport.length > 0
      );
    },
    acceptanceProtocolTabCompleted() {
      return Array.isArray(this.order?.po) && this.order.po.length > 0;
    },
    geodeticProtocolTabCompleted() {
      return Array.isArray(this.order?.pg) && this.order.pg.length > 0;
    },
    invoiceTabCompleted() {
      return Array.isArray(this.order?.fv) && this.order.fv.length > 0;
    }
  },
  created() {
    this.showProperTab();
  },
  watch: {
    order() {
      this.showProperTab();
    }
  },
  methods: {
    showProperTab() {
      if (this.invoiceTabCompleted) {
        $('.nav-tabs a[href="#tab-7"]').tab("show");
        this.onTabClicked(7);
      } else if (this.acceptanceProtocolTabCompleted) {
        $('.nav-tabs a[href="#tab-5"]').tab("show");
        this.onTabClicked(5);
      } else if (this.geodeticProtocolTabCompleted) {
        $('.nav-tabs a[href="#tab-6"]').tab("show");
        this.onTabClicked(6);
      } else if (this.transportTabCompleted) {
        $('.nav-tabs a[href="#tab-4"]').tab("show");
        this.onTabClicked(4);
      } else if (this.order?.orderType?.id === 3) {
        $('.nav-tabs a[href="#tab-6"]').tab("show");
        this.onTabClicked(6);
      }
    },
    onTabClicked(tabId) {
      switch (tabId) {
        case 1:
          this.alreadyLoaded.fileRepositories = true;
          this.fileRepoKey++;
          break;
        case 2:
          this.alreadyLoaded.poles = true;
          break;
        case 3:
          this.alreadyLoaded.orderContractors = true;
          break;
        case 4:
          this.alreadyLoaded.transport = true;
          break;
        case 5:
          this.alreadyLoaded.acceptanceProtocol = true;
          break;
        case 6:
          this.alreadyLoaded.geodeticProtocol = true;
          break;
        case 7:
          this.alreadyLoaded.invoicing = true;
          break;
      }
    },
    onModalCompleted(data) {
      this.$refs.modalBase.hide();

      switch (this.modal.type) {
        case MODAL_USERS_TABLE:
          this.addContractorToOrder(data);
          break;
      }
    },
    async addContractorToOrder(contractor) {
      var response = await RepositoryOrders.addContractorToOrder(
        this.id,
        contractor?.id
      );

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (response) {
          let msg = "Coś poszło nie tak.";
          if (Array.isArray(response)) {
            msg = "";
            response.forEach(element => {
              msg += element + ". ";
            });
          }
          this.$toastr.Add({
            msg,
            progressbar: false,
            timeout: 3000,
            type: "warning"
          });
        } else {
          this.contractorsTableKey++;
          this.$toastr.Add({
            msg: "Wykonawca został przypisany do zlecenia.",
            progressbar: false,
            timeout: 3000,
            type: "success"
          });

          this.onRefreshEvent();
        }
      }
    },
    onRefreshEvent() {
      this.$emit("refresh-general");
    }
  }
};
</script>

<style></style>
