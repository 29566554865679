<template>
  <div class="col-lg-12">
    <div class="tabs-container">
      <ul class="nav nav-tabs" role="tablist">
        <li>
          <a class="nav-link active" data-toggle="tab" href="#tab-1">
            <img
              src="/static/img/order-gray.png"
              alt="Zlecenia"
              width="13"
              height="13"
              style="margin-right:6px"
            />
            Zlecenia</a
          >
        </li>
      </ul>
      <div class="tab-content">
        <div role="tabpanel" id="tab-1" class="tab-pane active">
          <div class="panel-body">
            <orders-table-for-user
              :filter="{ subject: 'user', id }"
              :showBorder="false"
              :longerSearchBar="true"
            />
            <!-- :initialSort="{
                by: ordersSortingOptions.TARGET,
                direction: sortingDirection.ASC
              }" -->
          </div>
        </div>
      </div>
    </div>

    <!-- <modal-base ref="modalBase">
      <modal-body>
        <template v-slot:title>
          {{ modal.title }}
        </template>
        <template v-slot:body>
          <component
            :is="modal.component"
            @on-completed="onModalCompleted"
            :key="modal.key"
            v-bind="modal.properties"
          ></component>
        </template>
      </modal-body>
    </modal-base> -->
  </div>
</template>

<script>
// import ModalBase from "@/components/utils/ModalBase.vue";
// import ModalBody from "@/components/utils/ModalBody.vue";
import OrdersTableForUser from "@/components/orders/OrdersTableForUser";
// import {
//   ordersSortingOptions,
//   sortingDirection
// } from "@/helpers/sortTableHelper.js";
// import { RepositoryFactory } from "@/data/repositoryFactory.js";
// import modalTablesMixin from "@/mixins/modalTablesMixin.js";

// const RepositoryUsers = RepositoryFactory.get("users");

export default {
  name: "user-details-additional",
  components: {
    // ModalBase,
    // ModalBody,
    OrdersTableForUser
  },
  // mixins: [modalTablesMixin],
  props: {
    id: Number
  },
  //data() {
  // return {
  //   sortingDirection,
  //   ordersSortingOptions
  // };
  //},
  methods: {}
};
</script>

<style></style>
