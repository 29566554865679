<template>
  <div class="row">
    <div class="col-xl-4 col-lg-auto">
      <order-details-general
        :key="orderDetailsGeneralComponentKey"
        :id="orderIdForDetails"
        @refresh="orderDetailsGeneralComponentKey++"
        @onIsLoadingChanged="isLoading = $event"
        @onOrderLoaded="order = $event"
      />
    </div>
    <div class="col-xl-8">
      <order-details-additional
        :key="orderDetailsAdditionalComponentKey"
        :id="orderIdForDetails"
        :isLoading="isLoading"
        :order="order"
        @refresh-general="orderDetailsGeneralComponentKey++"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import OrderDetailsGeneral from "./OrderDetailsGeneral";
import OrderDetailsAdditional from "./OrderDetailsAdditional";
import navItems from "@/helpers/navigationPanelHelper.js";

export default {
  name: "order-details",
  components: {
    OrderDetailsGeneral,
    OrderDetailsAdditional
  },
  data() {
    return {
      orderDetailsGeneralComponentKey: 0,
      orderDetailsAdditionalComponentKey: 0,
      isLoading: false,
      order: null
    };
  },
  computed: {
    orderIdForDetails() {
      return parseInt(this.$route.params.id);
    }
  },
  watch: {
    orderIdForDetails() {
      this.orderDetailsGeneralComponentKey++;
      this.orderDetailsAdditionalComponentKey++;
    }
  },
  created() {
    this.setOrderId(this.orderIdForDetails);
    this.setNavigationPanel(navItems.ORDER_DETAILS);
  },
  methods: {
    ...mapActions({
      setNavigationPanel: "changeNavigationPanelItems",
      setOrderId: "setOrderId"
    })
  }
};
</script>

<style></style>
