<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="ibox">
        <div
          class="ibox-content"
          :class="{ 'sk-loading': isLoading }"
          style="border-style: none"
        >
          <div class="sk-spinner sk-spinner-three-bounce">
            <div class="sk-bounce1"></div>
            <div class="sk-bounce2"></div>
            <div class="sk-bounce3"></div>
          </div>

          <cluster-map-component
            v-if="
              ordersObject && ordersObject.data && ordersObject.data.length > 0
            "
            :centerLat="centerLat"
            :centerLng="centerLng"
            :markers="markersForMap"
            height="700px"
            :areaSelecting="true"
            :disableClusteringAtZoomOverride="19"
            :maxClusterRadiusOverride="1"
            @selected-markers-changed="onSelectedMarkersChanged"
            @map-clicked="resetSelectedMarkers"
            @markerClicked="onSingleMarkerSelected"
          />
          <div v-else-if="ordersObject.error">
            <server-error-panel @onRetry="fetchData" />
          </div>
          <div v-else>
            <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 pt-3">
      <slot name="globalFilterPanel"></slot>

      <button
        :disabled="
          !Array.isArray(selectedOrdersIds) || selectedOrdersIds.length === 0
        "
        class="btn btn-primary btn-xs mt-1"
        @click="
          showModal(MODAL_USERS_TABLE, {
            additionalProps: {
              filter: { url: 'user/workers' }
            }
          })
        "
      >
        Dodaj wykonawcę
      </button>

      <button
        class="btn btn-primary btn-xs ml-1 mt-1"
        :disabled="allSelected"
        @click="selectAllOrders"
      >
        Zaznacz wszystkie
      </button>
      <button
        class="btn btn-primary btn-xs ml-1 mt-1"
        :disabled="noneSelected"
        @click="resetSelectedMarkers"
      >
        Odznacz wszystkie
      </button>

      <h2>
        {{ selectedOrdersText }}
      </h2>

      <orders-table-for-map
        class="mt-4"
        :selectedOrdersIds="selectedOrdersIds"
      />
    </div>

    <modal-base ref="modalBase">
      <modal-body>
        <template v-slot:title>
          {{ modal.title }}
        </template>
        <template v-slot:body>
          <component
            :is="modal.component"
            @on-completed="onModalCompleted"
            :key="modal.key"
            v-bind="modal.properties"
          ></component>
        </template>
      </modal-body>
    </modal-base>
  </div>
</template>

<script>
import ClusterMapComponent from "@/components/map/ClusterMapComponent";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import ServerErrorPanel from "@/components/utils/ServerErrorPanel";
import { MODAL_USERS_TABLE } from "@/helpers/modalConstants.js";
import modalTablesMixin from "@/mixins/modalTablesMixin.js";
import ModalBase from "@/components/utils/ModalBase.vue";
import ModalBody from "@/components/utils/ModalBody.vue";
import OrdersTableForMap from "@/components/orders/OrdersTableForMap";

const RepositoryOrders = RepositoryFactory.get("orders");

export default {
  name: "orders-map",
  components: {
    ClusterMapComponent,
    ServerErrorPanel,
    ModalBase,
    ModalBody,
    OrdersTableForMap
  },
  mixins: [modalTablesMixin],
  props: {
    filterFromParent: {
      type: Object,
      default: null
    },
    mapTabActive: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: false,
      ordersObject: {},
      markersForMap: [],
      modal: {
        key: 0,
        component: null,
        visible: false,
        properties: null
      },
      ctrlPressed: false
    };
  },
  computed: {
    centerLat() {
      if (
        Array.isArray(this.ordersObject?.data) &&
        this.ordersObject?.data.length > 0 &&
        this.ordersObject.data[0]?.geotimestamp?.lat != null
      )
        return Number.parseFloat(this.ordersObject.data[0].geotimestamp.lat);

      return 50.28709;
    },
    centerLng() {
      if (
        Array.isArray(this.ordersObject?.data) &&
        this.ordersObject?.data.length > 0 &&
        this.ordersObject.data[0]?.geotimestamp?.lon != null
      )
        return Number.parseFloat(this.ordersObject.data[0].geotimestamp.lon);

      return 21.4239;
    },
    selectedOrdersIds() {
      let selectedIds = [];

      this.markersForMap.forEach(marker => {
        if (marker.selected) selectedIds.push(marker.orderId);
      });
      return selectedIds;
    },
    selectedOrdersText() {
      if (!Array.isArray(this.selectedOrdersIds)) return "";

      let text = `Zaznaczono ${this.selectedOrdersIds.length} `;

      if (this.selectedOrdersIds.length === 1) text += "zlecenie";
      else if (
        this.selectedOrdersIds.length.toString().endsWith(2) ||
        this.selectedOrdersIds.length.toString().endsWith(3) ||
        this.selectedOrdersIds.length.toString().endsWith(4)
      )
        text += "zlecenia";
      else text += "zleceń";

      return text;
    },
    allSelected() {
      let allSelected = true;

      this.markersForMap.forEach(marker => {
        if (!marker.selected) allSelected = false;
      });

      return allSelected;
    },
    noneSelected() {
      let noneSelected = true;

      this.markersForMap.forEach(marker => {
        if (marker.selected) noneSelected = false;
      });

      return noneSelected;
    }
  },
  created() {
    window.addEventListener("keydown", event => {
      if (event.code === "ControlLeft") this.ctrlPressed = true;
    });

    window.addEventListener("keyup", event => {
      if (event.code === "ControlLeft") this.ctrlPressed = false;
    });

    this.fetchData();
  },
  methods: {
    fetchData(page = 1) {
      this.isLoading = true;

      RepositoryOrders.getOrdersForMapObject(
        page,
        1000,
        "",
        null,
        null,
        this.filterFromParent
      )
        .then(data => {
          this.ordersObject = data;
          this.prepareMarkersForMap();
          this.$emit("afterSuccessfulFetch");
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    prepareMarkersForMap() {
      this.markersForMap = new Array();

      if (Array.isArray(this.ordersObject?.data)) {
        this.ordersObject?.data.forEach(order => {
          let lat = order?.first_pole_position?.lat;
          let lon = order?.first_pole_position?.lon;

          if (lat == null) lat = order?.geotimestamp?.lat;
          if (lon == null) lon = order?.geotimestamp?.lon;

          if (lat == null || lon == null) {
            if (this.mapTabActive)
              this.$toastr.Add({
                msg:
                  "Dla zlecenia <b>" +
                  (order?.order_number ?? "-") +
                  "</b> nie udało się uzyskać pozycji i wyświetlić go na mapie. Edytuj adres zlecenia, aby to naprawić.",
                progressbar: false,
                timeout: 0,
                type: "warning",
                preventDuplicates: true
              });
            return;
          }

          let popupCaption = "<b>" + order?.orderType?.name + "</b>";

          let notesTitleAdded = false;
          let notesIndex = 1;

          if (order?.note_actions?.length) {
            order.note_actions.forEach(note => {
              if (note.note) {
                if (!notesTitleAdded) {
                  notesTitleAdded = true;
                  popupCaption += "<br/>Notatki:";
                }
                popupCaption += "<br/>" + notesIndex + ". " + note.note;
                notesIndex++;
              }
            });
          }

          let marker = {
            lat: lat,
            lng: lon,
            popupCaption,
            draggable: false,
            orderId: order.id,
            options: { orderId: order.id },
            selected: false
          };

          marker = Object.assign(marker, this.getIconForMarker(order));

          if (marker?.icon) this.markersForMap.push(marker);
        });
      }
    },
    onSelectedMarkersChanged(markersToSelect) {
      this.markersForMap.forEach(marker => {
        if (markersToSelect.some(x => x.orderId === marker.orderId))
          marker.selected = true;
        else marker.selected = false;
      });
    },
    onSingleMarkerSelected(markerToSelect) {
      if (this.ctrlPressed) {
        this.markersForMap.forEach(marker => {
          if (markerToSelect?.sourceTarget?.options?.orderId === marker.orderId)
            marker.selected = !marker.selected;
        });
      } else {
        this.markersForMap.forEach(marker => {
          if (markerToSelect?.sourceTarget?.options?.orderId === marker.orderId)
            marker.selected = true;
          else marker.selected = false;
        });
      }
    },
    resetSelectedMarkers() {
      this.markersForMap.forEach(marker => {
        marker.selected = false;
      });
    },
    selectAllOrders() {
      this.markersForMap.forEach(marker => {
        marker.selected = true;
      });
    },
    onModalCompleted(data) {
      this.$refs.modalBase.hide();

      switch (this.modal.type) {
        case MODAL_USERS_TABLE:
          this.onContractorChose(data);
          break;
      }
    },
    onContractorChose(contractor) {
      this.$swal
        .fire({
          title: "Przypisanie wykonawcy",
          text:
            "Czy na pewno chcesz przypisać wykonawcę do " +
            (this.selectedOrdersIds.length === 1
              ? "wybranego zlecenia?"
              : `${this.selectedOrdersIds.length} wybranych zleceń?`),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) {
            this.addContractorToSelectedOrders(contractor);
          }
        });
    },
    async addContractorToSelectedOrders(contractor) {
      var response = await RepositoryOrders.addContractorToMultipleOrders(
        contractor.id,
        this.selectedOrdersIds
      );

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (response?.error != null) {
          if (response?.message != null && Array.isArray(response?.message)) {
            response.message.forEach(element => {
              this.$toastr.Add({
                msg: element,
                progressbar: false,
                timeout: 3000,
                type: "warning"
              });
            });
          } else
            this.$toastr.Add({
              msg: "Wystąpił błąd.",
              progressbar: false,
              timeout: 3000,
              type: "warning"
            });
        } else {
          let message = "";
          message += `<div class="text-left" style="font-size: 16">Przypisano: <b>${response?.success}</b><br/>`;
          message += `Pominięto: <b>${response?.user_already_had +
            response?.other_worker_in_progress}</b>, w tym:<br/>`;
          message += `  - wcześniej przypisanych: <b>${response?.user_already_had}</b><br/>`;
          message += `  - zajętych przez innego wykonawcę: <b>${response?.other_worker_in_progress}</b></div>`;

          this.$swal.fire({
            icon: "info",
            title: "Wynik operacji",
            html: message
          });

          this.fetchData();
        }
      }
    },
    getIconForMarker(order) {
      if (
        order?.worker &&
        order?.worker?.user_order_status?.id !== 3 && // nie jest cofnięte (cofnięte, jeśli nie będą mieć notatek ani przesunięcia, polecą do byDeadline)
        order?.worker?.user_order_status?.id !== 4 // nie jest zakończone (zakończone, jeśli nie będą mieć notatek ani przesunięcia, dostaną blue_marker)
      )
        return {
          icon:
            "worker_outlined_dot_marker_" + order.worker.color.replace("#", ""),
          iconSize: [16, 16],
          iconAnchor: [8, 8]
        };

      if (order?.deadline_offset)
        return {
          icon: "deadline_offset_icon",
          iconSize: [26, 26],
          iconAnchor: [13, 13]
        };

      if (order?.note_actions?.length > 0)
        return {
          icon: "exclamation_mark_circle_icon",
          iconSize: [26, 26],
          iconAnchor: [13, 13]
        };

      if (
        order?.worker?.user_order_status?.id === 4 &&
        order?.order_status?.id !== 1
      )
        // zakończony i nie do dyspozycji
        return {
          icon: "blue_marker",
          iconSize: [12, 20],
          iconAnchor: [6, 20]
        };

      return this.getIconByDeadline(order);
    },
    getIconByDeadline(order) {
      let result = {};
      let diff = this.getDaysDifference(
        new Date(),
        order.deadline_offset
          ? new Date(order.deadline_offset)
          : new Date(order.deadline)
      );

      if (diff != null) {
        if (diff < -1) result.icon = "red_marker";
        else if (diff <= 21) result.icon = "orange_marker";
        else result.icon = "green_marker";

        result.iconSize = [12, 20];
        result.iconAnchor = [6, 20];
      }

      return result;
    },
    getDaysDifference(date1, date2) {
      if (!date1 || !date2) return null;

      let differenceInDays =
        (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);

      return differenceInDays;
    }
  }
};
</script>

<style></style>
