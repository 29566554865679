export const PAGINATION_LIMIT = 5;
export const PAGINATION_INITIAL_ITEMS_PER_PAGE = 10;
export const MODIFIED_AFTER_INSPECTION_COLOR = "#f8ac59";
export const FILE_MAX_SIZE = 50;
export const USER_COLORS = [
  "#428df5",
  "#ad323b",
  "#42eff5",
  "#4f4694",
  "#61ffbb",
  "#0e4a2a",
  "#f556ef",
  "#ffc800",
  "#78acff",
  "#6e400b"
];
export const ORDER_TYPES_IDS_WITHOUT_GEODESY = [1];
