<template>
  <div
    class="ibox-content p-0"
    :class="{ 'sk-loading': isLoading }"
    style="border-style: none"
  >
    <div class="sk-spinner sk-spinner-three-bounce">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>

    <form @submit="onDatesSubmit">
      <div class="form-group" id="geoRealizationDateFGId">
        <label>Termin realizacji dla geodety</label>
        <div class="input-group date">
          <span class="input-group-addon" :style="'background-color: #e9ecef'"
            ><i class="fa fa-calendar"></i></span
          ><input
            id="geoRealizationDateIFId"
            type="text"
            class="form-control"
            :class="{ 'input-modified': hasRealizationDateChanged }"
            v-model="geodeticRealizationDate"
          />
        </div>
      </div>

      <div class="form-group" id="sharedWithOPLDateFGId">
        <label>Data wysłania do weryfikacji do Orange</label>
        <div class="input-group date">
          <span class="input-group-addon" :style="'background-color: #e9ecef'"
            ><i class="fa fa-calendar"></i></span
          ><input
            id="sharedWithOPLDateIFId"
            type="text"
            class="form-control"
            :class="{ 'input-modified': hasSharedWithOPLDateChanged }"
            v-model="sharedWithOPLForVerificationDate"
          />
        </div>
      </div>

      <div class="row mb-3" style="float: right">
        <button
          type="submit"
          class="btn btn-primary mt-2 mr-3"
          :disabled="!hasRealizationDateChanged && !hasSharedWithOPLDateChanged"
        >
          Zapisz
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";

const RepositoryOrders = RepositoryFactory.get("orders");

/* eslint-disable no-undef */
export default {
  name: "geodetic-dates-for-order",
  props: {
    orderId: null
  },
  data() {
    return {
      isLoading: false,
      geodeticRealizationDate: null,
      sharedWithOPLForVerificationDate: null,
      lastRealizationDate: null,
      lastSharedWithOPLDate: null
    };
  },
  created() {
    this.getGeodeticDates();
  },
  mounted() {
    $("#geoRealizationDateFGId .input-group.date").datepicker({
      todayBtn: "linked",
      keyboardNavigation: true,
      forceParse: true,
      calendarWeeks: true,
      autoclose: true,
      weekStart: 1
    });

    $("#sharedWithOPLDateFGId .input-group.date").datepicker({
      todayBtn: "linked",
      keyboardNavigation: true,
      forceParse: true,
      calendarWeeks: true,
      autoclose: true,
      weekStart: 1
    });

    let vm = this;
    $(document.body).on(
      "change",
      'input[id^="geoRealizationDateIFId"]',
      function() {
        vm.geodeticRealizationDate = document.getElementById(
          "geoRealizationDateIFId"
        ).value;
      }
    );

    $(document.body).on(
      "change",
      'input[id^="sharedWithOPLDateIFId"]',
      function() {
        vm.sharedWithOPLForVerificationDate = document.getElementById(
          "sharedWithOPLDateIFId"
        ).value;
      }
    );
  },
  computed: {
    hasRealizationDateChanged() {
      if (
        (this.lastRealizationDate == null &&
          this.geodeticRealizationDate === "") ||
        (this.lastRealizationDate === "" &&
          this.geodeticRealizationDate == null)
      )
        return false;

      return this.lastRealizationDate != this.geodeticRealizationDate;
    },
    hasSharedWithOPLDateChanged() {
      if (
        (this.lastSharedWithOPLDate == null &&
          this.sharedWithOPLForVerificationDate === "") ||
        (this.lastSharedWithOPLDate === "" &&
          this.sharedWithOPLForVerificationDate == null)
      )
        return false;

      return (
        this.lastSharedWithOPLDate != this.sharedWithOPLForVerificationDate
      );
    }
  },
  methods: {
    async getGeodeticDates() {
      this.isLoading = true;

      let geodeticDates = await RepositoryOrders.getOrderGeodeticDates(
        this.orderId
      );

      this.geodeticRealizationDate = geodeticDates?.realizationDate?.split(
        " "
      )[0];
      this.sharedWithOPLForVerificationDate = geodeticDates?.sharedWithOPLDate?.split(
        " "
      )[0];

      this.lastRealizationDate = this.geodeticRealizationDate;
      this.lastSharedWithOPLDate = this.sharedWithOPLForVerificationDate;

      this.isLoading = false;
    },
    async onDatesSubmit(e) {
      e.preventDefault();

      this.isLoading = true;

      var response = await RepositoryOrders.updateGeodeticDates(
        this.orderId,
        this.geodeticRealizationDate == null ||
          this.geodeticRealizationDate === ""
          ? null
          : this.geodeticRealizationDate,
        this.sharedWithOPLForVerificationDate == null ||
          this.sharedWithOPLForVerificationDate === ""
          ? null
          : this.sharedWithOPLForVerificationDate
      );

      this.isLoading = false;

      handleResponseWithMessages(
        this,
        response,
        "Daty zostały zaktualizowane.",
        () => {}
      );

      this.getGeodeticDates();
    }
  }
};
</script>

<style scoped>
.input-modified {
  border-color: #fcba03;
}
</style>
