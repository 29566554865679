<template>
  <div v-if="fv || po || pg" class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      style="border-style: none"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div class="row m-1">
        <div v-if="fv">
          <h4 class="text-center mb-3">Faktura VAT</h4>
          <file-or-files-for-order-phases
            :fileObject="fv"
            :deleteFileOption="false"
          />
        </div>

        <div v-if="po">
          <h4 class="text-center mb-3">PO</h4>
          <file-or-files-for-order-phases
            :fileObject="po"
            :deleteFileOption="false"
          />
        </div>

        <div v-if="pg">
          <h4 class="text-center mb-3">PG</h4>
          <file-or-files-for-order-phases
            :fileObject="pg"
            :deleteFileOption="false"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h4 id="no-files-header">
      Brak głównych dokumentów
    </h4>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import FileOrFilesForOrderPhases from "@/components/utils/FileOrFilesForOrderPhases";

const RepositoryFiles = RepositoryFactory.get("files");

export default {
  name: "major-order-files",
  components: {
    FileOrFilesForOrderPhases
  },
  props: {
    orderId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      fv: null,
      po: null,
      pg: null
    };
  },
  watch: {
    url() {
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;

      RepositoryFiles.getOrderMajorResourcesGeneral(this.orderId)
        .then(data => {
          this.fv = data?.fv;
          this.po = data?.po;
          this.pg = data?.pg;

          this.restructureObjects();
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    restructureObjects() {
      if (this.fv != null) {
        this.fv.resource = {};
        this.fv.resource.path = this.fv.path;
        delete this.fv.path;

        if (this.fv.path_min != null) {
          this.fv.resource.path_min = this.fv.path_min;
          delete this.fv.path_min;
        }

        if (this.fv.id_resource_type != null) {
          this.fv.resource.id_resource_type = this.fv.id_resource_type;
          delete this.fv.id_resource_type;
        }

        if (this.fv.created_by != null) {
          this.fv.resource.created_by = this.fv.created_by;
          delete this.fv.created_by;
        }

        if (this.fv.id != null) {
          this.fv.resource.id = this.fv.id;
          delete this.fv.id;
        }

        if (this.fv.geotimestamp != null) {
          this.fv.resource.geotimestamp = this.fv.geotimestamp;
          delete this.fv.geotimestamp;
        }
      }

      if (this.po != null) {
        this.po.resource = {};
        this.po.resource.path = this.po.path;
        delete this.po.path;

        if (this.po.path_min != null) {
          this.po.resource.path_min = this.po.path_min;
          delete this.po.path_min;
        }

        if (this.po.id_resource_type != null) {
          this.po.resource.id_resource_type = this.po.id_resource_type;
          delete this.po.id_resource_type;
        }

        if (this.po.created_by != null) {
          this.po.resource.created_by = this.po.created_by;
          delete this.po.created_by;
        }

        if (this.po.id != null) {
          this.po.resource.id = this.po.id;
          delete this.po.id;
        }

        if (this.po.geotimestamp != null) {
          this.po.resource.geotimestamp = this.po.geotimestamp;
          delete this.po.geotimestamp;
        }
      }

      if (this.pg != null) {
        this.pg.resource = {};
        this.pg.resource.path = this.pg.path;
        delete this.pg.path;

        if (this.pg.path_min != null) {
          this.pg.resource.path_min = this.pg.path_min;
          delete this.pg.path_min;
        }

        if (this.pg.id_resource_type != null) {
          this.pg.resource.id_resource_type = this.pg.id_resource_type;
          delete this.pg.id_resource_type;
        }

        if (this.pg.created_by != null) {
          this.pg.resource.created_by = this.pg.created_by;
          delete this.pg.created_by;
        }

        if (this.pg.id != null) {
          this.pg.resource.id = this.pg.id;
          delete this.pg.id;
        }

        if (this.pg.geotimestamp != null) {
          this.pg.resource.geotimestamp = this.pg.geotimestamp;
          delete this.pg.geotimestamp;
        }
      }
    }
  }
};
</script>

<style scoped>
#no-files-header {
  text-align: center;
  vertical-align: middle;
  line-height: 100px;
}
</style>
