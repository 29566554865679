import repository from "./repository.js";

export default {
  async getTransportsObject() {
    return repository
      .get("/order/allTransports")
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(error => {
        return { error };
      });
  },
  async getTransportDetails(id) {
    return repository
      .get(`/action/${id}`)
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(error => {
        return { error };
      });
  }
};
