import { render, staticRenderFns } from "./DictionariesTableView.vue?vue&type=template&id=168e5189&scoped=true&"
import script from "./DictionariesTableView.vue?vue&type=script&lang=js&"
export * from "./DictionariesTableView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "168e5189",
  null
  
)

export default component.exports