<template>
  <div>
    <form-error-panel :errors="formErrors" />
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox ">
          <div class="ibox-title">
            <h5 v-if="addMode">Dodaj użytkownika</h5>
            <h5 v-else>Edytuj użytkownika</h5>
          </div>
          <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
            <div class="sk-spinner sk-spinner-three-bounce">
              <div class="sk-bounce1"></div>
              <div class="sk-bounce2"></div>
              <div class="sk-bounce3"></div>
            </div>

            <form @submit="submitForm">
              <modal-input-field
                title="Typ użytkownika"
                :displayText="type"
                nullText="Wybierz typ użytkownika"
                :required="true"
                :disabled="!addMode"
                @showModal="showModal(MODAL_USER_TYPES_TABLE)"
              />
              <div class="form-group">
                <label>Imię</label>
                <input
                  type="text"
                  placeholder="Wpisz imię"
                  v-model="user.firstName"
                  class="form-control"
                  required
                  minlength="1"
                  maxlength="100"
                />
              </div>
              <div class="form-group">
                <label>Nazwisko</label>
                <input
                  type="text"
                  placeholder="Wpisz nazwisko"
                  v-model="user.lastName"
                  class="form-control"
                  required
                  minlength="1"
                  maxlength="100"
                />
              </div>
              <div class="form-group">
                <label>Numer telefonu</label>
                <input
                  type="tel"
                  placeholder="Wpisz numer telefonu"
                  v-model="user.phone"
                  class="form-control"
                  minlength="1"
                  maxlength="30"
                  required
                />
              </div>

              <div v-if="user && user.type_id !== 4" class="form-group">
                <label>E-mail</label>
                <input
                  type="email"
                  placeholder="Wpisz adres e-mail"
                  v-model="user.email"
                  class="form-control"
                  :disabled="!addMode"
                  maxlength="100"
                />
              </div>

              <div
                v-if="addMode && user && user.type_id !== 4"
                class="form-group"
              >
                <label>Hasło</label>
                <input
                  autocomplete="new-password"
                  type="password"
                  placeholder="Wpisz hasło"
                  v-model="user.password"
                  class="form-control"
                  required
                  minlength="1"
                  maxlength="100"
                />
              </div>

              <div v-if="!addMode" class="form-group">
                <label>Aktywny</label>
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      id="example1"
                      v-model="user.active"
                    />
                    <label class="onoffswitch-label" for="example1">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div
                v-if="
                  user &&
                    (user.type_id === 2 || (user.type && user.type.id === 2))
                "
                class="form-group"
              >
                <label>Kolor oznaczenia</label>
                <user-colors-radio-buttons
                  :initialPickedColor="initialPickedColor"
                  @onColorChanged="user.color = $event"
                />
              </div>

              <br />
              <div class="row">
                <div class="col-12">
                  <button
                    class="float-right btn btn-sm btn-primary m-t-n-xs"
                    type="submit"
                  >
                    <strong v-if="addMode">Dodaj</strong>
                    <strong v-else>Zapisz</strong>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <modal-base ref="modalBase">
      <modal-body>
        <template v-slot:title>
          {{ modal.title }}
        </template>
        <template v-slot:body>
          <component
            :is="modal.component"
            @on-completed="onModalCompleted($event)"
            :key="modal.key"
            v-bind="modal.properties"
          ></component>
        </template>
        <template v-slot:footer>
          <button
            type="button"
            class="btn btn-outline btn-danger"
            @click="onModalRejected"
          >
            Usuń wybór
          </button>
        </template>
      </modal-body>
    </modal-base>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import navItems from "@/helpers/navigationPanelHelper.js";
import FormErrorPanel from "@/components/utils/FormErrorPanel";
import modalTablesMixin from "@/mixins/modalTablesMixin.js";
import { MODAL_USER_TYPES_TABLE } from "@/helpers/modalConstants.js";
import ModalInputField from "@/components/utils/ModalInputField";
import UserColorsRadioButtons from "@/components/utils/UserColorsRadioButtons";

const RepositoryUsers = RepositoryFactory.get("users");

export default {
  name: "user-add-edit",
  components: {
    FormErrorPanel,
    ModalInputField,
    UserColorsRadioButtons
  },
  mixins: [modalTablesMixin],
  data() {
    return {
      formErrors: [],
      isLoading: false,
      userTypeOptions: [],
      user: {
        id: null,
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        type_id: null,
        password: null,
        active: null,
        color: null
      },
      type: null,
      initialPickedColor: null
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
    addMode() {
      return isNaN(parseInt(this.$route.params.id));
    },
    idForEdit() {
      return parseInt(this.$route.params.id);
    }
  },
  created() {
    this.setNavigationPanel(
      this.addMode ? navItems.USER_ADD : navItems.USER_EDIT
    );

    if (!this.addMode) this.getUserDataToEdit();
  },
  methods: {
    ...mapActions({
      setNavigationPanel: "changeNavigationPanelItems",
      changeLoggedInUserFirstName: "changeLoggedInUserFirstName",
      changeLoggedInUserLastName: "changeLoggedInUserLastName"
    }),
    submitForm(e) {
      e.preventDefault();

      if (this.addMode) this.sendAddRequest();
      else this.sendEditRequest();
    },
    async getUserDataToEdit() {
      this.isLoading = true;
      this.user = await RepositoryUsers.getUserDataToEdit(this.idForEdit);
      this.type = this.user.type.name;
      this.initialPickedColor = this.user.color;
      this.isLoading = false;
    },
    async sendAddRequest() {
      this.isLoading = true;

      if (this.user?.type_id !== 2) delete this.user.color;
      if (this.user?.type_id === 4) delete this.user.email;

      var response = await RepositoryUsers.createUser(this.user);

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (response) this.formErrors = response;
        else {
          this.$toastr.Add({
            msg: "Użytkownik został utworzony.",
            progressbar: false,
            timeout: 3000,
            type: "success"
          });
          this.$router.push({ name: "usersTable" });
        }
      }

      this.isLoading = false;
    },
    async sendEditRequest() {
      this.isLoading = true;

      if (this.user?.type_id === 2) delete this.user.color;
      delete this.user.password;

      if (this.user?.active) this.user.active = 1;
      else if (this.user?.active === false) this.user.active = 0;

      var response = await RepositoryUsers.updateUser(this.user);

      if (response?.serverError)
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      else {
        if (response) this.formErrors = response;
        else {
          if (this.user?.id != null && this.user.id === this.loggedInUser?.id) {
            this.changeLoggedInUserFirstName(this.user.firstName);
            this.changeLoggedInUserLastName(this.user.lastName);
          }

          this.$toastr.Add({
            msg: "Użytkownik został zaktualizowany.",
            progressbar: false,
            timeout: 3000,
            type: "success"
          });

          this.$router.push({ name: "usersTable" });
        }
      }

      this.isLoading = false;
    },
    onModalCompleted(data) {
      this.$refs.modalBase.hide();

      switch (this.modal.type) {
        case MODAL_USER_TYPES_TABLE:
          this.user.type_id = data?.id ?? null;
          this.type = data?.name ?? null;
          break;
      }
    },
    onModalRejected() {
      this.$refs.modalBase.hide();

      switch (this.modal.type) {
        case MODAL_USER_TYPES_TABLE:
          this.user.type_id = null;
          this.type = "Wybierz typ użytkownika";
          break;
      }
    }
  }
};
</script>

<style></style>
