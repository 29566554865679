<template>
  <div>
    <major-order-files :orderId="orderId" />

    <div class="row">
      <div class="mt-1 pl-3">
        <radio-toggle
          :initialOptions="[
            { id: 0, title: `Dokumenty`, checked: true },
            { id: 1, title: `Zdjęcia z akcji`, checked: false }
          ]"
          @onChanged="onTabChanged"
        />
      </div>
    </div>

    <!-- span elements for avoiding duplicating children keys -->
    <span v-if="alreadyLoaded.documents" v-show="activeTabId === 0">
      <div v-if="showFileInput" class="mt-4">
        <drop-area
          id="dropArea"
          class="dropArea"
          caption="Upuść plik tutaj albo kliknij 'Wybierz plik' poniżej"
          @onFileChanged="onDropAreaFileChanged"
        />
        <input
          id="fileInputField"
          name="file"
          type="file"
          class="mt-2"
          multiple
          @change="onFileChanged"
        />
        <button class="btn btn-primary mt-2 float-right" @click="onFileUpload">
          Prześlij plik
        </button>
      </div>
      <div v-else class="mt-3">
        <button
          class="btn btn-outline btn-primary mt-2"
          @click="showFileInput = true"
        >
          Dodaj plik
        </button>
      </div>

      <files-table
        class="mt-4"
        :key="documentsKey"
        :url="`order/${orderId}/resources`"
        :showBorder="false"
        :longerSearchBar="false"
        modalId="modalImageContainer2"
        :deletingEnabled="true"
        @fileDeleted="documentsKey++"
        @fileShared="documentsKey++"
        :mobileAppSharingEnabled="true"
      />
    </span>

    <span v-if="alreadyLoaded.actionsResources" v-show="activeTabId === 1">
      <files-table
        :key="actionsResourcesKey"
        :url="`order/${orderId}/actionsResources`"
        :showBorder="false"
        :longerSearchBar="false"
        modalId="modalImageContainer1"
        :deletingEnabled="true"
        @fileDeleted="actionsResourcesKey++"
      />
    </span>
  </div>
</template>

<script>
import FilesTable from "@/components/fileRepositories/FilesTable";
import MajorOrderFiles from "@/components/fileRepositories/MajorOrderFiles";
import RadioToggle from "@/components/utils/RadioToggle";
import DropArea from "@/components/utils/DropArea";
import { mapGetters } from "vuex";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import {
  getDateWithoutTime,
  getLocalFormattedDateAndTime
} from "@/helpers/dateFormatHelper.js";
import { FILE_MAX_SIZE } from "@/helpers/appConstants.js";

const RepositoryOrders = RepositoryFactory.get("orders");

export default {
  name: "file-repositories-view-for-order",
  props: {
    orderId: {
      type: Number,
      default: null
    }
  },
  components: {
    FilesTable,
    MajorOrderFiles,
    RadioToggle,
    DropArea
  },
  data() {
    return {
      activeTabId: 0,
      actionsResourcesKey: 0,
      documentsKey: 0,
      alreadyLoaded: {
        documents: true,
        actionsResources: false
      },
      files: [],
      showFileInput: false,
      FILE_MAX_SIZE: FILE_MAX_SIZE
    };
  },
  computed: {
    ...mapGetters({ loggedInUser: "loggedInUser" })
  },
  methods: {
    getLocalFormattedDateAndTime,
    getDateWithoutTime,
    onTabChanged(activeId) {
      this.activeTabId = activeId;

      switch (activeId) {
        case 0:
          this.alreadyLoaded.documents = true;
          break;
        case 1:
          this.alreadyLoaded.actionsResources = true;
          break;
      }
    },
    onDropAreaFileChanged(files) {
      let approvedFiles = new DataTransfer();
      let fileInput = document.getElementById("fileInputField");

      fileInput.files.forEach(fileToCopy => {
        approvedFiles.items.add(fileToCopy);
      });

      for (let i = 0; i < files.length; i++) {
        let fileSize = files[i].size / 1024 / 1024;

        if (fileSize > this.FILE_MAX_SIZE) {
          this.$toastr.Add({
            msg: `<b>${files[i].name}</b><br/>Zbyt duży rozmiar pliku<br/> (maks. ${this.FILE_MAX_SIZE} MB)`,
            progressbar: false,
            timeout: 3000,
            type: "warning"
          });
        } else {
          approvedFiles.items.add(files[i]);
          this.files.push(files[i]);
        }
      }

      if (fileInput) fileInput.files = approvedFiles.files;
    },
    onFileChanged(e) {
      let files = e.target.files || e.dataTransfer.files;

      let approvedFiles = new DataTransfer();
      this.files = [];

      for (let i = 0; i < files.length; i++) {
        let fileSize = files[i].size / 1024 / 1024;

        if (fileSize > this.FILE_MAX_SIZE) {
          this.$toastr.Add({
            msg: `<b>${files[i].name}</b><br/>Zbyt duży rozmiar pliku<br/> (maks. ${this.FILE_MAX_SIZE} MB)`,
            progressbar: false,
            timeout: 3000,
            type: "warning"
          });
        } else {
          approvedFiles.items.add(files[i]);
          this.files.push(files[i]);
        }
      }

      let fileInput = document.getElementById("fileInputField");
      if (fileInput) fileInput.files = approvedFiles.files;
    },
    async onFileUpload() {
      if (!this.files || !this.files.length) {
        this.$toastr.Add({
          msg: "Proszę załączyć plik.",
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });

        return;
      }

      let creationDate = this.getDateWithoutTime(
        this.getLocalFormattedDateAndTime(new Date().toJSON())
      );

      this.files.forEach(async file => {
        var response = await RepositoryOrders.addFileToOrder(
          this.orderId,
          file,
          creationDate,
          this.loggedInUser?.id,
          this
        );

        if (response?.serverError) {
          this.$toastr.Add({
            msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
            progressbar: false,
            timeout: 3000,
            type: "error"
          });
        } else {
          if (response) {
            let msg = "Coś poszło nie tak.";
            if (Array.isArray(response)) {
              msg = "";
              response.forEach(element => {
                msg += element + ". ";
              });
            }
            this.$toastr.Add({
              msg,
              progressbar: false,
              timeout: 3000,
              type: "warning"
            });
          } else {
            this.$emit("refresh");

            this.$toastr.Add({
              msg: `<b>${file.name}</b><br/>Plik został przesłany.`,
              progressbar: false,
              timeout: 3000,
              type: "success"
            });
          }
        }
      });

      this.files = [];
    }
  }
};
</script>
<style></style>
