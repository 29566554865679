var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"wrapper"}},[_c('nav',{staticClass:"navbar-default navbar-static-side",staticStyle:{"background-color":"#2f4050"},attrs:{"role":"navigation"}},[_c('div',{staticClass:"sidebar-collapse"},[_c('ul',{staticClass:"nav metismenu",attrs:{"id":"side-menu"}},[_c('li',{staticClass:"nav-header text-center"},[(
              _vm.loggedInUser && _vm.loggedInUser.firstName && _vm.loggedInUser.lastName
            )?_c('div',{staticClass:"dropdown profile-element"},[_c('img',{staticClass:"rounded-circle",attrs:{"alt":"User","src":'https://eu.ui-avatars.com/api/?name=' +
                  _vm.loggedInUser.firstName +
                  '+' +
                  _vm.loggedInUser.lastName +
                  '&color=2f4050&background=f3f3f4'}}),_c('a',{staticClass:"dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#"}},[_c('span',{staticClass:"block m-t-xs font-bold"},[_vm._v(_vm._s(_vm.loggedInUser.firstName)+" "+_vm._s(_vm.loggedInUser.lastName)+" ")]),_c('span',{staticClass:"text-muted text-xs block"},[_vm._v(" "+_vm._s(_vm.loggedInUser.type.name)+" "),_c('b',{staticClass:"caret"})])]),_c('ul',{staticClass:"dropdown-menu animated fadeIn m-t-xs"},[_c('li',[_c('a',{staticClass:"dropdown-item",on:{"click":_vm.showLoggedInUserDetails}},[_vm._v("Profil")])]),_c('li',{staticClass:"dropdown-divider"}),_c('li',[_c('a',{staticClass:"dropdown-item",on:{"click":_vm.logOut}},[_vm._v("Wyloguj")])])])]):_vm._e()]),_c('li',{class:[_vm.isOrdersMenuItemActive && 'active active2']},[_c('a',{attrs:{"id":"ordersTableMenuElement","href":"#"},on:{"mouseover":function($event){_vm.ordersMenuItemHover = true},"mouseleave":function($event){_vm.ordersMenuItemHover = false}}},[_c('img',{staticStyle:{"margin-right":"6px"},attrs:{"src":_vm.isOrdersMenuItemActive || _vm.ordersMenuItemHover
                  ? '/static/img/order-white.png'
                  : '/static/img/order-gray.png',"alt":"Zlecenia","width":"13","height":"13"}}),_c('span',{staticClass:"nav-label"},[_vm._v("Zlecenia")]),_c('span',{staticClass:"fa arrow"})]),_c('ul',{staticClass:"nav nav-second-level"},[_c('router-link',{attrs:{"to":"/orders"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Tabela")])])]}}])}),_c('router-link',{attrs:{"to":"/orders"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 2 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(2)}}},[_vm._v("Mapa")])])]}}])})],1)]),_c('li',{class:[_vm.isOrdersStatusesMenuItemActive && 'active active2']},[_c('a',{attrs:{"id":"ordersGeodeticPhasesTableMenuElement","href":"#"},on:{"mouseover":function($event){_vm.ordersStatusesMenuItemHover = true},"mouseleave":function($event){_vm.ordersStatusesMenuItemHover = false}}},[_c('i',{staticClass:"fa fa-check-square-o"}),_c('span',{staticClass:"nav-label"},[_vm._v("Statusy zleceń")]),_c('span',{staticClass:"fa arrow"})]),_c('ul',{staticClass:"nav nav-second-level"},[_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { status: 1 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Do dyspozycji")])])]}}])}),_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { status: 2 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("W produkcji")])])]}}])}),_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { status: 3 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Przerwane")])])]}}])}),_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { status: 4 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Anulowane")])])]}}])}),_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { status: 5 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Zakończone rozliczone")])])]}}])})],1)]),_c('li',{class:[
            _vm.isOrdersRealizationPhasesMenuItemActive && 'active active2'
          ]},[_c('a',{attrs:{"id":"ordersRealizationPhasesTableMenuElement","href":"#"},on:{"mouseover":function($event){_vm.ordersRealizationPhasesMenuItemHover = true},"mouseleave":function($event){_vm.ordersRealizationPhasesMenuItemHover = false}}},[_c('i',{staticClass:"fa fa-road"}),_c('span',{staticClass:"nav-label"},[_vm._v("Etapy realizacji")]),_c('span',{staticClass:"fa arrow"})]),_c('ul',{staticClass:"nav nav-second-level"},[_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { realizationPhase: 1 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Nierozpoczęty demontaż")])])]}}])}),_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { realizationPhase: 2 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Rozpoczęty demontaż")])])]}}])}),_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { realizationPhase: 3 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Przerwany demontaż")])])]}}])}),_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { realizationPhase: 4 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Zakończony demontaż do zabrania")])])]}}])}),_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { realizationPhase: 5 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Zakończony demontaż prośba o PO")])])]}}])}),_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { realizationPhase: 6 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Zrealizowany demontaż")])])]}}])}),_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { realizationPhase: 7 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Geodezja")])])]}}])})],1)]),_c('li',{class:[_vm.isOrdersGeodeticPhasesMenuItemActive && 'active active2']},[_c('a',{attrs:{"id":"ordersStatusesTableMenuElement","href":"#"},on:{"mouseover":function($event){_vm.ordersGeodeticPhasesMenuItemHover = true},"mouseleave":function($event){_vm.ordersGeodeticPhasesMenuItemHover = false}}},[_c('i',{staticClass:"fa fa-globe"}),_c('span',{staticClass:"nav-label"},[_vm._v("Etapy geodezji")]),_c('span',{staticClass:"fa arrow"})]),_c('ul',{staticClass:"nav nav-second-level"},[_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { geodeticPhase: 1 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Do zlecenia geodecie")])])]}}])}),_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { geodeticPhase: 2 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("W realizacji")])])]}}])}),_c('router-link',{attrs:{"to":{
                path: '/orders',
                query: { geodeticPhase: 3 }
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && _vm.initialOrdersTab === 1 && 'active'],staticStyle:{"width":"100%"}},[_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.onOrdersTabClicked(1)}}},[_vm._v("Przesłano do weryfikacji Orange")])])]}}])})],1)]),_c('router-link',{attrs:{"to":"/transport"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active']},[_c('a',{attrs:{"href":href}},[_c('i',{staticClass:"fa fa-car"}),_c('span',{staticClass:"nav-label"},[_vm._v("Słupy do zebrania")])])])]}}])}),_c('router-link',{attrs:{"to":"/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active']},[_c('a',{attrs:{"href":href}},[_c('i',{staticClass:"fa fa-user-o"}),_c('span',{staticClass:"nav-label"},[_vm._v("Użytkownicy")])])])]}}])}),_c('router-link',{attrs:{"to":"/dictionaries"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active']},[_c('a',{attrs:{"href":href}},[_c('i',{staticClass:"fa fa-edit"}),_c('span',{staticClass:"nav-label"},[_vm._v("Słowniki")])])])]}}])})],1)])]),_c('div',{staticClass:"gray-bg",attrs:{"id":"page-wrapper"}},[_c('div',{staticClass:"row border-bottom"},[_c('nav',{staticClass:"navbar navbar-static-top gray-bg",staticStyle:{"margin-bottom":"0"},attrs:{"role":"navigation"}},[_c('div',{staticClass:"navbar-header"},[_c('a',{staticClass:"navbar-minimalize minimalize-styl-2 btn btn-primary ",attrs:{"href":"#"},on:{"click":function($event){return _vm.toggleSideMenu()}}},[_c('i',{staticClass:"fa fa-bars"})])]),_c('ul',{staticClass:"nav navbar-top-links navbar-right"},[_c('li',{staticClass:"dropdown mr-4"},[_c('a',{staticClass:"dropdown-toggle count-info",attrs:{"data-toggle":"dropdown","href":"#"}},[_c('i',{staticClass:"fa fa-envelope"}),(_vm.notificationsCount)?_c('span',{staticClass:"label label-primary"},[_vm._v(_vm._s(_vm.notificationsCount))]):_vm._e()]),_c('notifications-dropdown-list',{on:{"notifications-count":function($event){_vm.notificationsCount = $event}}})],1)])])]),(_vm.$route.name != 'dashboard')?_c('navigation-panel'):_vm._e(),_c('div',{staticClass:"wrapper wrapper-content animated fadeIn"},[_c('router-view')],1),_vm._m(0)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"pull-right"},[_c('strong')]),_c('div',[_c('strong',[_vm._v("Copyright")]),_vm._v(" DKK Development © 2020")])])}]

export { render, staticRenderFns }