<template>
  <div>
    <file-upload />
  </div>
</template>

<script>
import FileUpload from "@/components/utils/FileUpload";

export default {
  name: "view-test",
  components: {
    FileUpload
  },
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>

<style></style>
