<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ title }}</h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">Dashboard</router-link>
        </li>

        <li
          v-for="(item, index) in items"
          :key="item.name"
          class="breadcrumb-item"
        >
          <strong v-if="index == items.length - 1">{{ item.name }}</strong>
          <router-link v-else :to="item.link">{{ item.name }}</router-link>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "navigation-panel",
  computed: {
    title() {
      var items = this.$store.getters.navigationPanelItems;
      if (!items || items.length <= 0) return "";
      return items[items.length - 1].name;
    },
    items() {
      return this.$store.getters.navigationPanelItems;
    }
  }
};
</script>

<style></style>
