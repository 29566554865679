<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div>
        <div
          style="display: flex; flex-wrap: wrap;"
          v-if="
            files &&
              files.data &&
              Array.isArray(files.data) &&
              files.data.length > 0
          "
        >
          <div
            class="file-box"
            v-for="file in files.data"
            :key="file.id"
            @click="
              file.id_resource_type === 3 ||
              file.id_resource_type === 4 ||
              file.id_resource_type === 5
                ? showModal(file.path)
                : showFileInNewTab(file)
            "
            data-toggle="modal"
            :data-target="
              file.id_resource_type === 3 ||
              file.id_resource_type === 4 ||
              file.id_resource_type === 5
                ? `#${modalId}`
                : ''
            "
            :style="`width: ${fileWidth}`"
          >
            <div
              class="file"
              style="cursor: pointer; margin-right: 10px; margin-left: 10px;"
            >
              <button
                v-if="mobileAppSharingEnabled"
                type="button"
                class="btn btn-white btn-xs"
                style="position: absolute; left: 5px; top: 5px;"
                :style="
                  file && file.shared === 1
                    ? 'background-color: #1ab39477;'
                    : ''
                "
                :title="
                  file && file.shared === 1
                    ? 'Ukryj plik w aplikacji mobilnej'
                    : 'Pokaż plik w aplikacji mobilnej'
                "
                @click="onShareFileToMobileAppButtonClicked(file)"
                onclick="event.stopPropagation()"
              >
                <i
                  class="fa fa-users"
                  :style="
                    file && file.shared === 1 ? 'color: green' : 'color: gray'
                  "
                ></i>
              </button>

              <button
                v-if="deletingEnabled"
                type="button"
                class="btn btn-white btn-xs"
                style="position: absolute; right: 5px; top: 5px;"
                title="Usuń plik"
                @click="onDeleteFileButtonClicked(file)"
                onclick="event.stopPropagation()"
              >
                <i class="fa fa-trash-o" style="color: gray"></i>
              </button>

              <span v-html="getFileIcon(file)" />

              <div class="file-name" style="overflow-wrap: break-word;">
                {{
                  file.path != null
                    ? file.path.substring(file.path.lastIndexOf("/") + 1)
                    : "-"
                }}
                <br />
                <small
                  >Dodane przez:
                  {{
                    file.created_by &&
                    file.created_by.firstName &&
                    file.created_by.lastName
                      ? file.created_by.firstName +
                        " " +
                        file.created_by.lastName
                      : "-"
                  }}</small
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="files && files.error">
          <server-error-panel @onRetry="fetchData" />
        </div>
        <div v-else class="col-12">
          <p class="text-center mt-3">{{ noElementsCaption }}</p>
        </div>
      </div>

      <div class="row mt-3">
        <div
          :class="
            widerPaginationCountPicker
              ? 'col-xl-6 offset-xl-1'
              : 'col-xl-6 offset-xl-3'
          "
        >
          <pagination
            v-if="files && files.data"
            align="center"
            :data="files"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></pagination>
        </div>

        <div :class="widerPaginationCountPicker ? 'col-xl-5' : 'col-xl-3'">
          <pagination-count-picker
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
    <modal-image-container
      @on-completed="onModalCompleted($event)"
      :source="imgSource"
      :id="modalId"
    />
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import ModalImageContainer from "@/components/utils/ModalImageContainer";
import { PAGINATION_LIMIT } from "@/helpers/appConstants.js";
import paginationCountPickerMixin from "@/mixins/paginationCountPickerMixin.js";
import ServerErrorPanel from "@/components/utils/ServerErrorPanel";
import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";

const RepositoryFiles = RepositoryFactory.get("files");

export default {
  name: "files-table",
  components: {
    ModalImageContainer,
    ServerErrorPanel
  },
  props: {
    url: { type: String, default: null },
    showBorder: {
      type: Boolean,
      default: true
    },
    widerPaginationCountPicker: {
      type: Boolean,
      default: false
    },
    noElementsCaption: { type: String, default: "Brak plików do wyświetlenia" },
    modalId: { type: String, default: "abcasd" },
    fileWidth: { type: String, default: "220px" },
    deletingEnabled: {
      type: Boolean,
      default: false
    },
    mobileAppSharingEnabled: {
      type: Boolean,
      default: false
    }
  },
  mixins: [paginationCountPickerMixin],
  data() {
    return {
      isLoading: false,
      files: null,
      imgSource: null,
      currentSort: { by: null, direction: null },
      PAGINATION_LIMIT: PAGINATION_LIMIT
    };
  },
  watch: {
    url() {
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData(page = 1) {
      this.isLoading = true;

      RepositoryFiles.getFilesObject(
        page,
        this.itemsPerPage,
        "", //this.searchBox,
        this.currentSort.by,
        this.currentSort.direction,
        this.url
      )
        .then(data => {
          this.files = data;

          this.files?.data.forEach(file => {
            if (file.path != null)
              file.path = process.env.VUE_APP_IMAGES_URL + file.path;

            if (file.path_min != null)
              file.path_min = process.env.VUE_APP_IMAGES_URL + file.path_min;
          });
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    showModal(pickedImgSource) {
      this.imgSource = pickedImgSource;
    },
    getFileIcon(file) {
      if (!file) return "<div class='icon'><i class='fa fa-file'></i></div>";

      if (file?.id_resource_type == null)
        return "<div class='icon'><i class='fa fa-file' style='color: #1ab39477;'></i></div>";

      switch (file?.id_resource_type) {
        case 2:
          return "<div class='icon'><i class='fa fa-file-pdf-o' style='color: #1ab39477;'></i></div>";
        case 3:
        case 4:
        case 5:
          if (file?.path_min != null)
            return `<div class='image text-center'><img alt='image' class='img-fluid' src='${file.path_min}' /></div>`;
          else
            return `<div class='image text-center'><img alt='image' class='img-fluid' style='max-height: 100%;' src='${file.path}' /></div>`;
        case 7:
          return "<div class='icon'><i class='fa fa-file-zip-o' style='color: #1ab39477;'></i></div>";
        default:
          return "<div class='icon'><i class='fa fa-file' style='color: #1ab39477;'></i></div>";
      }
    },
    showFileInNewTab(fileToShow) {
      if (fileToShow?.path != null) window.open(fileToShow.path, "_blank");
    },
    onDeleteFileButtonClicked(fileToDelete) {
      this.$swal
        .fire({
          title: "Usuwanie pliku",
          text: "Czy na pewno chcesz usunąć wybrany plik?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) {
            this.deleteFile(fileToDelete);
          }
        });
    },
    async deleteFile(fileToDelete) {
      var response = await RepositoryFiles.deleteFile(fileToDelete?.id);

      handleResponseWithMessages(
        this,
        response,
        "Plik został usunięty.",
        () => {
          this.$emit("fileDeleted");
        }
      );
    },
    onShareFileToMobileAppButtonClicked(file) {
      this.$swal
        .fire({
          title: "Udostępnianie pliku",
          text:
            file.shared === 1
              ? "Czy na pewno chcesz ukryć wybrany plik w aplikacji mobilnej?"
              : "Czy na pewno chcesz pokazać wybrany plik w aplikacji mobilnej?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) {
            this.shareFileToMobileApp(file);
          }
        });
    },
    async shareFileToMobileApp(file) {
      var response =
        file.shared === 1
          ? await RepositoryFiles.shareFile(file?.id, false)
          : await RepositoryFiles.shareFile(file?.id, true);

      handleResponseWithMessages(
        this,
        response,
        file.shared === 1
          ? "Plik będzie ukryty w aplikacji mobilnej."
          : "Plik będzie widoczny w aplikacji mobilnej.",
        () => {
          this.$emit("fileShared");
        }
      );
    }
  }
};
</script>
