<template>
  <div class="btn-group btn-group-toggle">
    <label
      v-for="option in options"
      :key="option.id"
      class="btn btn-sm btn-white"
      :class="option.checked ? `active` : ``"
      @click="onRadioButtonClicked(option)"
    >
      <input type="radio" autocomplete="off" :checked="option.checked" />
      {{ option.title }}
    </label>
  </div>
</template>

<script>
export default {
  name: "radio-toggle",
  props: {
    initialOptions: {
      type: Array,
      default: () => new Array()
    }
  },
  data() {
    return {
      options: null,
      activeOption: null
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    initialOptions() {
      this.initialize();
    }
  },
  methods: {
    onRadioButtonClicked(clickedOption) {
      this.options.forEach(option => {
        if (option.id === clickedOption.id) {
          option.checked = true;
          this.activeOption = option;
        } else option.checked = false;
      });

      this.$emit("onChanged", this.activeOption.id);
    },
    initialize() {
      if (this.options) return;

      this.options = this.initialOptions;
      this.options.forEach(option => {
        if (option.checked) this.activeOption = option;
      });
    }
  }
};
</script>

<style></style>
