export function getShorthandIfExists(fullName) {
  //materials
  switch (fullName) {
    case "DREWNIANY":
      return "DREWN.";
    case "BETONOWY":
      return "BETON.";
    case "STALOWY":
      return "STAL.";
    case "Demontaż":
      return "D";
    case "Demontaż z aktualizacją geodezyjną":
      return "D+G";
  }

  return fullName;
}
