<template>
  <div
    v-if="fileObject && Array.isArray(fileObject) && fileObject.length > 1"
    style="display: flex; flex-direction: column"
  >
    <div
      v-for="file in fileObject"
      :key="file.id"
      class="file"
      @click="showFileInNewTab(file)"
      :style="
        file.resource && file.resource.path != null ? 'cursor:pointer' : ''
      "
    >
      <div class="row m-0">
        <div class="icon col-auto" style="height: 30px; overflow: initial;">
          <span v-html="getFileIcon(file, true)" />
        </div>

        <div
          class="file-name col"
          style="overflow-wrap: break-word; width: 200px; border-top: none;"
        >
          {{
            file.resource && file.resource.path != null
              ? file.resource.path.substring(
                  file.resource.path.lastIndexOf("/") + 1
                )
              : "[Brak pliku]"
          }}
          <br />
          <small
            >Dodane przez:
            {{
              file.resource &&
              file.resource.created_by &&
              file.resource.created_by.firstName &&
              file.resource.created_by.lastName
                ? file.resource.created_by.firstName +
                  " " +
                  file.resource.created_by.lastName
                : "-"
            }}</small
          >
          <span
            v-if="
              file &&
                Array.isArray(file.additionalInfo) &&
                file.additionalInfo.length > 0
            "
          >
            <div
              class="mt-2 mb-2"
              style="border-bottom: 1px dashed gray;"
            ></div>
            <small v-for="infoRow in file.additionalInfo" :key="infoRow"
              ><span style="display: block;">{{ infoRow }}</span></small
            >
          </span>
        </div>
        <div v-if="deleteFileOption" style="background-color: #f8f8f8">
          <button
            type="button"
            class="btn btn-white btn-xs"
            style="margin-right: 2px; margin-top: 2px;"
            title="Usuń plik"
            @click="onDeleteFileButtonClicked(file)"
            onclick="event.stopPropagation()"
          >
            <i class="fa fa-trash-o" style="color: gray"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="file-box">
    <div
      class="file"
      style="margin-right: 10px; margin-left: 10px;"
      @click="showFileInNewTab(firstFileIfExists)"
      :style="isResourcePresent ? 'cursor:pointer' : ''"
    >
      <button
        v-if="isFilePresent && deleteFileOption"
        type="button"
        class="btn btn-white btn-xs"
        style="position: absolute; right: 5px; top: 5px;"
        title="Usuń fakturę"
        @click="onDeleteFileButtonClicked(firstFileIfExists)"
        onclick="event.stopPropagation()"
      >
        <i class="fa fa-trash-o" style="color: gray"></i>
      </button>
      <div class="icon">
        <span v-html="getFileIcon(firstFileIfExists)" />
      </div>

      <div class="file-name" style="overflow-wrap: break-word;">
        {{
          isResourcePresent
            ? firstFileIfExists.resource.path.substring(
                firstFileIfExists.resource.path.lastIndexOf("/") + 1
              )
            : "[Brak pliku]"
        }}
        <br />
        <small
          >Dodane przez:
          {{
            firstFileIfExists &&
            firstFileIfExists.resource &&
            firstFileIfExists.resource.created_by &&
            firstFileIfExists.resource.created_by.firstName &&
            firstFileIfExists.resource.created_by.lastName
              ? firstFileIfExists.resource.created_by.firstName +
                " " +
                firstFileIfExists.resource.created_by.lastName
              : "-"
          }}</small
        >
        <span
          v-if="
            firstFileIfExists &&
              Array.isArray(firstFileIfExists.additionalInfo) &&
              firstFileIfExists.additionalInfo.length > 0
          "
        >
          <div class="mt-2 mb-2" style="border-bottom: 1px dashed gray;"></div>
          <small
            v-for="infoRow in firstFileIfExists.additionalInfo"
            :key="infoRow"
            ><span style="display: block;">{{ infoRow }}</span></small
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";

const RepositoryFiles = RepositoryFactory.get("files");

export default {
  name: "file-or-files-for-invoiving-phase",
  props: {
    fileObject: null,
    deleteFileOption: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isFilePresent() {
      if (!this.fileObject) return false;

      if (this.fileObject?.id != null) return true;

      if (
        Array.isArray(this.fileObject) &&
        this.fileObject.length > 0 &&
        this.fileObject[0].id != null
      )
        return true;

      return false;
    },
    isResourcePresent() {
      if (!this.fileObject) return false;

      if (this.fileObject?.resource?.path != null) return true;

      if (
        Array.isArray(this.fileObject) &&
        this.fileObject.length > 0 &&
        this.fileObject[0].resource &&
        this.fileObject[0].resource.path != null
      )
        return true;

      return false;
    },
    firstFileIfExists() {
      if (!this.fileObject) return null;

      if (this.fileObject?.id != null) return this.fileObject;

      if (
        Array.isArray(this.fileObject) &&
        this.fileObject.length > 0 &&
        this.fileObject[0].id != null
      )
        return this.fileObject[0];

      return null;
    }
  },
  methods: {
    getFileIcon(file, smallIcon = false) {
      if (!file)
        return `<i class='fa fa-file' ${
          smallIcon ? 'style="font-size: 30px"' : ""
        } />`;

      if (file?.resource?.id_resource_type == null)
        return `<i class='fa fa-file' style='color: #1ab39477; ${
          smallIcon ? "font-size: 30px" : ""
        }' />`;

      switch (file?.resource?.id_resource_type) {
        case 2:
          return `<i class='fa fa-file-pdf-o' style='color: #1ab39477; ${
            smallIcon ? "font-size: 30px" : ""
          }'/>`;
        case 3:
        case 4:
        case 5:
          if (file?.path_min != null)
            return `<div class='image text-center' ><img alt='image' class='img-fluid' src='${
              file.path_min
            }' ${
              smallIcon ? 'style="height: 30px; width: 30px;"' : ""
            }/></div>`;
          else
            return `<i class='fa fa-file-image-o' style='color: #1ab39477; ${
              smallIcon ? "font-size: 30px" : ""
            }'/>`;
        case 7:
          return `<i class='fa fa-file-zip-o' style='color: #1ab39477; ${
            smallIcon ? "font-size: 30px" : ""
          }/>`;
        default:
          return `<i class='fa fa-file' style='color: #1ab39477; ${
            smallIcon ? "font-size: 30px" : ""
          }/>`;
      }
    },
    showFileInNewTab(fileToShow) {
      if (fileToShow?.resource?.path != null)
        window.open(
          process.env.VUE_APP_IMAGES_URL + fileToShow.resource.path,
          "_blank"
        );
    },
    onDeleteFileButtonClicked(fileToDelete) {
      if (fileToDelete?.resource?.id != null) {
        this.$swal
          .fire({
            title: "Usuwanie pliku",
            text: "Czy na pewno chcesz usunąć wybrany plik?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#1ab394",
            cancelButtonColor: "#f8ac59",
            confirmButtonText: "Tak",
            cancelButtonText: "Nie",
            customClass: {
              title: "swal2-title"
            }
          })
          .then(result => {
            if (result.value) {
              this.deleteFile(fileToDelete);
            }
          });
      } else {
        this.$swal
          .fire({
            title: "Usuwanie faktury",
            text: "Czy na pewno chcesz usunąć wybraną fakturę?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#1ab394",
            cancelButtonColor: "#f8ac59",
            confirmButtonText: "Tak",
            cancelButtonText: "Nie",
            customClass: {
              title: "swal2-title"
            }
          })
          .then(result => {
            if (result.value) {
              this.deleteInvoice(fileToDelete);
            }
          });
      }
    },
    async deleteFile(fileToDelete) {
      var response = await RepositoryFiles.deleteFile(
        fileToDelete?.resource?.id
      );

      handleResponseWithMessages(
        this,
        response,
        "Plik został usunięty.",
        () => {
          this.$emit("fileDeleted");
        }
      );
    },
    async deleteInvoice(fileToDelete) {
      var response = await RepositoryFiles.deleteInvoice(fileToDelete?.id);

      handleResponseWithMessages(
        this,
        response,
        "Faktura została usunięta.",
        () => {
          this.$emit("fileDeleted");
        }
      );
    }
  }
};
</script>

<style></style>
