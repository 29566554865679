var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ibox"},[_c('div',{staticClass:"ibox-content p-0",class:{ 'sk-loading': _vm.isLoading },staticStyle:{"border-style":"none"}},[_vm._m(0),_c('table-elements-info',{attrs:{"metaObject":_vm.ordersObject ? _vm.ordersObject.meta : null}}),(_vm.ordersObject && _vm.ordersObject.data && _vm.ordersObject.data.length > 0)?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped"},[_vm._m(1),_c('tbody',_vm._l((_vm.ordersObject.data),function(order){return _c('tr',{key:order.id,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showDetails(order)}}},[_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(order && order.order_number != null ? order.order_number : "-")+" ")]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.woodenCount(order))+" / "+_vm._s(_vm.concreteCount(order))+" ")]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.polesLeftCount(order))+" ")]),_c('td',{staticStyle:{"white-space":"nowrap","text-align":"center"}},[_c('span',{staticClass:"label label-primary",style:(order &&
                  order.order_status &&
                  order.order_status.color != null
                    ? 'background-color:' + order.order_status.color
                    : '')},[_vm._v(" "+_vm._s(order && order.order_status && order.order_status.name != null ? order.order_status.name : "-"))])]),_c('td',{staticStyle:{"white-space":"nowrap","text-align":"center"}},[_c('span',{staticClass:"label label-primary",style:(order && order.orderType && order.orderType.color != null
                    ? 'background-color:' + order.orderType.color
                    : ''),attrs:{"data-toggle":order && order.orderType && order.orderType.name
                    ? 'tooltip'
                    : '',"title":order && order.orderType && order.orderType.name
                    ? order.orderType.name
                    : ''}},[_vm._v(" "+_vm._s(order && order.orderType && order.orderType.name != null ? _vm.getShorthandIfExists(order.orderType.name) : "-"))])]),_c('td',{staticStyle:{"white-space":"nowrap","text-align":"center"}},[_c('span',{staticClass:"label label-primary",style:(order && order.direction && order.direction.color != null
                    ? 'background-color:' + order.direction.color
                    : '')},[_vm._v(" "+_vm._s(order && order.direction && order.direction.name != null ? order.direction.name : "-"))])]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(order && order.ebp != null ? order.ebp : "-")+" ")]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn-outline btn-primary btn-xs mr-2",attrs:{"type":"button","onclick":"event.stopPropagation()"},on:{"click":function($event){return _vm.showDetails(order)}}},[_vm._v(" Pokaż ")]),_c('button',{staticClass:"btn btn-outline btn-primary btn-xs",attrs:{"type":"button","onclick":"event.stopPropagation()"},on:{"click":function($event){return _vm.edit(order)}}},[_vm._v(" Edytuj ")])])])])}),0)])]):(_vm.ordersObject && _vm.ordersObject.error)?_c('div',[_c('server-error-panel',{on:{"onRetry":_vm.fetchData}})],1):_c('div',[_c('p',{staticClass:"text-center mt-3"},[_vm._v("Brak elementów do wyświetlenia")])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-xl-6"},[(_vm.ordersObject && _vm.ordersObject.data)?_c('pagination',{attrs:{"align":"center","data":_vm.ordersObject,"limit":_vm.PAGINATION_LIMIT},on:{"pagination-change-page":_vm.fetchData}}):_vm._e()],1),_c('div',{staticClass:"col-xl-6"},[_c('pagination-count-picker',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],on:{"selectionChanged":_vm.onItemsPerPageChanged}})],1)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sk-spinner sk-spinner-three-bounce"},[_c('div',{staticClass:"sk-bounce1"}),_c('div',{staticClass:"sk-bounce2"}),_c('div',{staticClass:"sk-bounce3"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Nr")]),_c('th',[_vm._v("Liczba sł. drew./bet.")]),_c('th',[_vm._v("Pozostało do zdemontowania")]),_c('th',[_vm._v("Status zlecenia")]),_c('th',[_vm._v("Typ")]),_c('th',[_vm._v("Rodzaj zlecenia")]),_c('th',[_vm._v("EBP")]),_c('th')])])}]

export { render, staticRenderFns }