<template>
  <div>
    <input type="file" @change="onFileChanged" /><button @click="onFileUpload">
      Upload!
    </button>
    <img :src="image" />
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";

const RepositoryFiles = RepositoryFactory.get("files");

export default {
  name: "file-upload",
  data() {
    return {
      image: "",
      file: null
    };
  },
  methods: {
    onFileChanged(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;

      this.createImage(files[0]);
      this.file = files[0];
    },
    createImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = e => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async onFileUpload() {
      var response = await RepositoryFiles.addFileToOrder(1, this.file);

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (response) {
          let msg = "Coś poszło nie tak.";
          if (Array.isArray(response)) {
            msg = "";
            response.forEach(element => {
              msg += element + ". ";
            });
          }
          this.$toastr.Add({
            msg,
            progressbar: false,
            timeout: 3000,
            type: "warning"
          });
        } else {
          this.$toastr.Add({
            msg: "Plik został przesłany.",
            progressbar: false,
            timeout: 3000,
            type: "success"
          });
        }
      }
    }
  }
};
</script>

<style></style>
