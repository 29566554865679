<template>
  <div>
    <dictionaries-table-view />
  </div>
</template>

<script>
import DictionariesTableView from "@/components/dictionaries/DictionariesTableView.vue";

export default {
  name: "view-dictionaries",
  components: {
    DictionariesTableView
  }
};
</script>

<style></style>
