<template>
  <div>
    <form-error-panel :errors="formErrors" />
    <div id="row-main" class="row">
      <div id="content" class="col-12">
        <div class="ibox ">
          <div class="ibox-title">
            <h5 v-if="addMode">Dodaj zlecenie</h5>
            <h5 v-else>Edytuj zlecenie</h5>
          </div>
          <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
            <div class="sk-spinner sk-spinner-three-bounce">
              <div class="sk-bounce1"></div>
              <div class="sk-bounce2"></div>
              <div class="sk-bounce3"></div>
            </div>

            <form role="form" @submit="submitForm">
              <modal-input-field
                :disabled="!addMode"
                title="Typ zlecenia"
                :displayText="orderTypeDisplayName"
                nullText="Wybierz typ zlecenia"
                :required="true"
                @showModal="showModal(MODAL_ORDER_TYPES_TABLE)"
              />

              <div class="form-group">
                <label>Numer zlecenia</label>

                <div style="display: flex">
                  <input
                    style="width: 100px"
                    type="text"
                    v-model="order.order_number_1"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                  <span class="ml-1 mr-1" style="font-size: 24px">/</span>
                  <input
                    type="text"
                    style="width: 100px"
                    v-model="order.order_number_2"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                  <span class="ml-1 mr-1" style="font-size: 24px">/</span>
                  <input
                    type="text"
                    style="width: 100px"
                    v-model="order.order_number_3"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                  <span class="ml-1 mr-1" style="font-size: 24px">/</span>
                  <input
                    type="text"
                    style="width: 100px"
                    v-model="order.order_number_4"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                </div>
              </div>

              <div class="form-group">
                <label>Priorytet</label>
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      id="example1"
                      v-model="order.priority"
                    />
                    <label class="onoffswitch-label" for="example1">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div>

              <modal-input-field
                title="Rodzaj zlecenia"
                :displayText="directionDisplayName"
                nullText="Wybierz rodzaj zlecenia"
                :required="true"
                @showModal="showModal(MODAL_DIRECTIONS_TABLE)"
              />

              <modal-input-field
                title="Region"
                :displayText="regionDisplayName"
                nullText="Wybierz region"
                :required="true"
                @showModal="showModal(MODAL_REGIONS_TABLE)"
              />

              <modal-input-field
                title="Powiat"
                :displayText="countyDisplayName"
                nullText="Wybierz powiat"
                :required="true"
                @showModal="showModal(MODAL_COUNTIES_TABLE)"
              />

              <div class="form-group">
                <label>Adres</label>
                <input
                  type="text"
                  placeholder="Wpisz adres"
                  v-model="order.address"
                  class="form-control"
                  required
                  minlength="1"
                  maxlength="200"
                />
              </div>

              <div class="form-group">
                <label>Opis</label>
                <input
                  type="text"
                  placeholder="Wpisz opis"
                  v-model="order.description"
                  class="form-control"
                  maxlength="500"
                />
              </div>

              <modal-input-field
                title="Koordynator"
                :displayText="coordinatorDisplayName"
                nullText="Wybierz koordynatora"
                :required="true"
                @showModal="
                  showModal(MODAL_USERS_TABLE, {
                    additionalProps: {
                      filter: { url: 'user/coordinators' }
                    }
                  })
                "
              />

              <div class="form-group">
                <label>Numer telefonu właściciela</label>
                <input
                  type="tel"
                  placeholder="Wpisz numer telefonu właściciela"
                  v-model="order.owner_phone"
                  class="form-control"
                  maxlength="30"
                />
              </div>

              <div class="form-group" id="data_1">
                <label>Data wpływu zlecenia</label>
                <div class="input-group date">
                  <span
                    class="input-group-addon"
                    :style="'background-color: #e9ecef'"
                    ><i class="fa fa-calendar"></i></span
                  ><input
                    id="receiveDateId"
                    type="text"
                    class="form-control"
                    v-model="order.receive_date"
                  />
                </div>
              </div>

              <div class="form-group" id="data_2">
                <label>Termin realizacji</label>
                <div class="input-group date">
                  <span
                    class="input-group-addon"
                    :style="'background-color: #e9ecef'"
                    ><i class="fa fa-calendar"></i></span
                  ><input
                    id="deadlineId"
                    type="text"
                    class="form-control"
                    v-model="order.deadline"
                  />
                </div>
              </div>

              <div class="form-group" id="data_4">
                <label>Nowy termin realizacji</label>
                <div class="input-group date">
                  <span
                    class="input-group-addon"
                    :style="'background-color: #e9ecef'"
                    ><i class="fa fa-calendar"></i></span
                  ><input
                    id="deadlineOffsetId"
                    type="text"
                    class="form-control"
                    v-model="order.deadline_offset"
                  />
                </div>
              </div>

              <div
                v-show="
                  !(
                    order &&
                    ORDER_TYPES_IDS_WITHOUT_GEODESY.includes(
                      order.id_order_type
                    )
                  )
                "
                class="form-group"
                id="data_3"
              >
                <label>Data wymaganego zakończenia dla geodety</label>
                <div class="input-group date">
                  <span
                    class="input-group-addon"
                    :style="'background-color: #e9ecef'"
                    ><i class="fa fa-calendar"></i></span
                  ><input
                    id="surveyorDeadlineId"
                    type="text"
                    class="form-control"
                    v-model="order.surveyor_deadline"
                  />
                </div>
              </div>

              <poles-table-set-for-order-add-edit
                v-for="polesTypesSet in polesTypesSets"
                :key="polesTypesSet.index"
                :index="polesTypesSet.index"
                :addMode="addMode"
                :initialFormData="polesTypesSet.initialFormData"
                :canBeDeleted="polesTypesSet.canBeDeleted"
                @polesTypesChanged="onPolesTypesSetChanged"
                @deleteSet="onPolesTypesSetDeleted"
              />

              <button
                class="btn btn-outline btn-info btn-lg mt-3 mb-4"
                @click="addAnotherSet"
                type="button"
              >
                Dodaj zestaw słupów
              </button>

              <div class="form-group">
                <label>Numer EBP</label>
                <input
                  type="text"
                  placeholder="Wpisz numer EBP"
                  v-model="order.ebp"
                  class="form-control"
                  required
                  minlength="1"
                  maxlength="100"
                />
              </div>

              <div class="form-group">
                <label>Wartość zamówienia</label>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  placeholder="Wpisz wartość zamówienia [zł]"
                  v-model="order.cost"
                  class="form-control"
                  min="1"
                  max="999999999"
                  required
                />
              </div>

              <div class="row">
                <div class="col-12">
                  <button
                    class="float-right btn btn-sm btn-primary m-t-n-xs"
                    type="submit"
                  >
                    <strong v-if="addMode">Dodaj</strong>
                    <strong v-else>Zapisz</strong>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <modal-base ref="modalBase">
      <modal-body>
        <template v-slot:title>
          {{ modal.title }}
        </template>
        <template v-slot:body>
          <component
            :is="modal.component"
            @on-completed="onModalCompleted($event)"
            :key="modal.key"
            v-bind="modal.properties"
          ></component>
        </template>
        <template v-slot:footer>
          <button
            type="button"
            class="btn btn-outline btn-danger"
            @click="onModalRejected"
          >
            Usuń wybór
          </button>
        </template>
      </modal-body>
    </modal-base>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import { getDateWithoutTime } from "@/helpers/dateFormatHelper.js";
import navItems from "@/helpers/navigationPanelHelper.js";
import FormErrorPanel from "@/components/utils/FormErrorPanel";
import modalTablesMixin from "@/mixins/modalTablesMixin.js";
import {
  MODAL_ORDER_TYPES_TABLE,
  MODAL_DIRECTIONS_TABLE,
  MODAL_REGIONS_TABLE,
  MODAL_COUNTIES_TABLE,
  MODAL_USERS_TABLE
} from "@/helpers/modalConstants.js";
import ModalInputField from "@/components/utils/ModalInputField";
import PolesTableSetForOrderAddEdit from "./PolesTableSetForOrderAddEdit.vue";
import { ORDER_TYPES_IDS_WITHOUT_GEODESY } from "@/helpers/appConstants.js";
import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";

const RepositoryOrders = RepositoryFactory.get("orders");

export default {
  name: "order-add-edit",
  components: {
    FormErrorPanel,
    ModalInputField,
    PolesTableSetForOrderAddEdit
  },
  props: {},
  mixins: [modalTablesMixin],
  data() {
    return {
      formErrors: [],
      isLoading: false,
      orderTypeDisplayName: null,
      directionDisplayName: null,
      regionDisplayName: null,
      countyDisplayName: null,
      coordinatorDisplayName: null,
      order: {
        id: null,
        id_order_type: null,
        priority: false,
        id_direction: null,
        order_number: null,
        ebp: null,
        cost: null,
        id_region: null,
        id_powiat: null,
        address: null,
        description: null,
        target: null,
        id_coordinator_opl: null,
        owner_phone: null,
        receive_date: null,
        deadline: null,
        deadline_offset: null,
        surveyor_deadline: null,
        addresses: []
      },
      polesTypesSets: [],
      ORDER_TYPES_IDS_WITHOUT_GEODESY: ORDER_TYPES_IDS_WITHOUT_GEODESY
    };
  },
  computed: {
    addMode() {
      return isNaN(parseInt(this.$route.params.id));
    },
    idForEdit() {
      return parseInt(this.$route.params.id);
    }
  },
  created() {
    this.setNavigationPanel(
      this.addMode ? navItems.ORDER_ADD : navItems.ORDER_EDIT
    );

    this.setInitialDates();

    if (!this.addMode) this.getOrderDataToEdit();
  },
  mounted() {
    /* eslint-disable no-undef */
    $("#data_1 .input-group.date").datepicker({
      todayBtn: "linked",
      keyboardNavigation: true,
      forceParse: true,
      calendarWeeks: true,
      autoclose: true,
      weekStart: 1
    });

    $("#data_2 .input-group.date").datepicker({
      todayBtn: "linked",
      keyboardNavigation: true,
      forceParse: true,
      calendarWeeks: true,
      autoclose: true,
      weekStart: 1
    });

    $("#data_3 .input-group.date").datepicker({
      todayBtn: "linked",
      keyboardNavigation: true,
      forceParse: true,
      calendarWeeks: true,
      autoclose: true,
      weekStart: 1
    });

    $("#data_4 .input-group.date").datepicker({
      todayBtn: "linked",
      keyboardNavigation: true,
      forceParse: true,
      calendarWeeks: true,
      autoclose: true,
      weekStart: 1
    });

    var vm = this;

    $(document.body).on("change", 'input[id^="receiveDateId"]', function() {
      vm.updateReceiveDateFromPicker();
    });

    $(document.body).on("change", 'input[id^="deadlineId"]', function() {
      vm.updateDeadlineFromPicker();
    });

    $(document.body).on("change", 'input[id^="deadlineOffsetId"]', function() {
      vm.updateDeadlineOffsetFromPicker();
    });

    $(document.body).on(
      "change",
      'input[id^="surveyorDeadlineId"]',
      function() {
        vm.updateSurveyorDeadlineFromPicker();
      }
    );
  },
  methods: {
    ...mapActions({
      setNavigationPanel: "changeNavigationPanelItems"
    }),
    updateReceiveDateFromPicker() {
      this.order.receive_date = document.getElementById("receiveDateId").value;
    },
    updateDeadlineFromPicker() {
      this.order.deadline = document.getElementById("deadlineId").value;
    },
    updateSurveyorDeadlineFromPicker() {
      this.order.surveyor_deadline = document.getElementById(
        "surveyorDeadlineId"
      ).value;
    },
    updateDeadlineOffsetFromPicker() {
      this.order.deadline_offset = document.getElementById(
        "deadlineOffsetId"
      ).value;
    },
    submitForm(e) {
      e.preventDefault();

      if (this.addMode) this.sendAddRequest();
      else this.sendEditRequest();
    },
    async getOrderDataToEdit() {
      this.isLoading = true;

      let fetchedOrder = await RepositoryOrders.getOrderDataToEdit(
        this.idForEdit
      );

      if (fetchedOrder == null) return;

      this.order = {
        id: fetchedOrder.id,
        id_order_type: fetchedOrder?.orderType?.id,
        priority: fetchedOrder?.priority,
        id_direction: fetchedOrder?.direction?.id,
        order_number: fetchedOrder?.order_number,
        ebp: fetchedOrder?.ebp,
        cost: fetchedOrder?.cost,
        id_region: fetchedOrder?.region?.id,
        id_powiat: fetchedOrder?.powiat?.id,
        address: fetchedOrder?.address,
        description: fetchedOrder?.description,
        target: fetchedOrder?.target,
        id_coordinator_opl: fetchedOrder?.coordinator?.id,
        owner_phone: fetchedOrder?.owner_phone,
        receive_date: getDateWithoutTime(fetchedOrder?.receive_date),
        deadline: getDateWithoutTime(fetchedOrder?.deadline),
        deadline_offset: getDateWithoutTime(fetchedOrder?.deadline_offset),
        surveyor_deadline: getDateWithoutTime(fetchedOrder?.surveyor_deadline),
        addresses: fetchedOrder?.addresses
      };

      this.createTablesFromParameters();

      if (this.order.order_number == null) {
        this.order.order_number_1 = null;
        this.order.order_number_2 = null;
        this.order.order_number_3 = null;
        this.order.order_number_4 = null;
      } else {
        let order_number_array = this.order.order_number.split("/");
        this.order.order_number_1 = order_number_array[0];
        this.order.order_number_2 = order_number_array[1];
        this.order.order_number_3 = order_number_array[2];
        this.order.order_number_4 = order_number_array[3];
      }

      //receive date
      $("#data_1 .input-group.date").datepicker(
        "setDate",
        this.order.receive_date
      );

      //deadline
      $("#data_2 .input-group.date").datepicker("setDate", this.order.deadline);

      //deadline offset
      $("#data_4 .input-group.date").datepicker(
        "setDate",
        this.order.deadline_offset
      );

      //surveyor deadline
      $("#data_3 .input-group.date").datepicker(
        "setDate",
        this.order.surveyor_deadline
      );

      //order type
      this.orderTypeDisplayName =
        fetchedOrder?.orderType?.name != null
          ? fetchedOrder.orderType.name
          : "-";

      //direction
      this.directionDisplayName =
        fetchedOrder?.direction?.name != null
          ? fetchedOrder.direction.name
          : "-";

      //region
      this.regionDisplayName =
        fetchedOrder?.region?.name != null ? fetchedOrder.region.name : "-";

      //county
      this.countyDisplayName =
        fetchedOrder?.powiat?.name != null ? fetchedOrder.powiat.name : "-";

      //coordinator
      this.coordinatorDisplayName =
        (fetchedOrder?.coordinator?.firstName ?? "") +
        " " +
        (fetchedOrder?.coordinator?.lastName ?? "");

      this.isLoading = false;
    },
    async sendAddRequest() {
      if (!this.polesTypesSets.length) {
        this.$toastr.Add({
          msg: "Dodaj zestaw słupów.",
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });

        return;
      }

      this.isLoading = true;

      delete this.order.id;

      if (ORDER_TYPES_IDS_WITHOUT_GEODESY.includes(this.order?.id_order_type))
        delete this.order.surveyor_deadline;

      this.order.order_number =
        this.order.order_number_1 +
        "/" +
        this.order.order_number_2 +
        "/" +
        this.order.order_number_3 +
        "/" +
        this.order.order_number_4;

      this.order.target = 0;
      this.order.parameters = [];

      this.polesTypesSets.forEach(polesTypesSet => {
        polesTypesSet.formData.parameters.total_points =
          polesTypesSet.formData.parameters.c_points +
          polesTypesSet.formData.parameters.w_points;
        polesTypesSet.formData.parameters.total_dem =
          polesTypesSet.formData.parameters.c_dem +
          polesTypesSet.formData.parameters.w_dem;

        polesTypesSet.formData.poles = polesTypesSet.formData.parameters;
        //delete polesTypesSet.formData.parameters;

        this.order.parameters.push(polesTypesSet.formData);
        this.order.target += polesTypesSet.formData.poles.total_dem;
      });

      var response = await RepositoryOrders.createOrder(this.order);

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (response) {
          if (response.id != null) {
            this.$toastr.Add({
              msg: "Zlecenie zostało utworzone.",
              progressbar: false,
              timeout: 3000,
              type: "success"
            });

            if (!response.geotimestamp) {
              this.$toastr.Add({
                msg:
                  "Nie udało się uzyskać pozycji z podanego adresu. Edytuj adres zlecenia, aby to naprawić.",
                progressbar: false,
                imeout: 3000,
                type: "warning"
              });
            }

            this.showDetails(response.id);
          } else this.formErrors = response;
        }
      }

      this.isLoading = false;
    },
    async sendEditRequest() {
      if (!this.polesTypesSets.length) {
        this.$toastr.Add({
          msg: "Dodaj zestaw słupów.",
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });

        return;
      }

      this.isLoading = true;

      //this.addNewPolesTypesSetsAndEditOldOnes();

      if (ORDER_TYPES_IDS_WITHOUT_GEODESY.includes(this.order?.id_order_type))
        delete this.order.surveyor_deadline;

      this.order.order_number =
        this.order.order_number_1 +
        "/" +
        this.order.order_number_2 +
        "/" +
        this.order.order_number_3 +
        "/" +
        this.order.order_number_4;

      this.order.target = 0;
      this.order.parameters = [];

      this.polesTypesSets.forEach(polesTypesSet => {
        polesTypesSet.formData.parameters.total_points =
          polesTypesSet.formData.parameters.c_points +
          polesTypesSet.formData.parameters.w_points;
        polesTypesSet.formData.parameters.total_dem =
          polesTypesSet.formData.parameters.c_dem +
          polesTypesSet.formData.parameters.w_dem;

        polesTypesSet.formData.poles = polesTypesSet.formData.parameters;
        //delete polesTypesSet.formData.parameters;

        this.order.parameters.push(polesTypesSet.formData);
        this.order.target += polesTypesSet.formData.poles.total_dem;
      });

      var response = await RepositoryOrders.updateOrder(this.order);

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (response?.message) this.formErrors = response.message;
        else {
          await this.addNewPolesTypesSetsAndEditOldOnes();

          this.$toastr.Add({
            msg: "Zlecenie zostało zaktualizowane.",
            progressbar: false,
            timeout: 3000,
            type: "success"
          });

          if (!response.geotimestamp) {
            this.$toastr.Add({
              msg:
                "Nie udało się uzyskać pozycji z podanego adresu. Edytuj adres zlecenia, aby to naprawić.",
              progressbar: false,
              imeout: 3000,
              type: "warning"
            });
          }

          // this.$router.go(-1);
        }
      }

      this.isLoading = false;
    },
    setInitialDates() {
      let now = new Date();
      let month = now.getMonth() + 1;
      let monthString = month.toString().length === 1 ? "0" + month : month;

      this.order.receive_date =
        now.getFullYear() + "-" + monthString + "-" + now.getDate();

      this.order.deadline =
        now.getFullYear() + "-" + monthString + "-" + now.getDate();

      this.order.surveyor_deadline =
        now.getFullYear() + "-" + monthString + "-" + now.getDate();
    },
    onModalCompleted(data) {
      this.$refs.modalBase.hide();

      switch (this.modal.type) {
        case MODAL_ORDER_TYPES_TABLE:
          this.order.id_order_type = data?.id ?? null;
          this.orderTypeDisplayName = data?.name ?? "";
          break;
        case MODAL_DIRECTIONS_TABLE:
          this.order.id_direction = data?.id ?? null;
          this.directionDisplayName = data?.name ?? "";
          break;
        case MODAL_REGIONS_TABLE:
          this.order.id_region = data?.id ?? null;
          this.regionDisplayName = data?.name ?? "";
          break;
        case MODAL_COUNTIES_TABLE:
          this.order.id_powiat = data?.id ?? null;
          this.countyDisplayName = data?.name ?? "";
          break;
        case MODAL_USERS_TABLE:
          this.order.id_coordinator_opl = data?.id ?? null;
          this.coordinatorDisplayName =
            (data?.firstName ?? "") + " " + (data?.lastName ?? "");
          break;
      }
    },
    onModalRejected() {
      this.$refs.modalBase.hide();

      switch (this.modal.type) {
        case MODAL_ORDER_TYPES_TABLE:
          this.order.id_order_type = null;
          this.orderTypeDisplayName = "Wybierz typ zlecenia";
          break;
        case MODAL_DIRECTIONS_TABLE:
          this.order.id_direction = null;
          this.directionDisplayName = "Wybierz rodzaj zlecenia";
          break;
        case MODAL_REGIONS_TABLE:
          this.order.id_region = null;
          this.regionDisplayName = "Wybierz region";
          break;
        case MODAL_COUNTIES_TABLE:
          this.order.id_powiat = null;
          this.countyDisplayName = "Wybierz powiat";
          break;
        case MODAL_USERS_TABLE:
          this.order.id_coordinator_opl = null;
          this.coordinatorDisplayName = "Wybierz koordynatora";
          break;
      }
    },
    showDetails(id) {
      this.$router.push({
        name: "orderDetails",
        params: { id }
      });
    },
    onPolesTypesSetChanged(set) {
      let foundSet = this.polesTypesSets.find(x => x.index === set.index);
      foundSet.formData = set.formData;
    },
    async onPolesTypesSetDeleted(index) {
      if (this.polesTypesSets.length == 1) {
        this.$toastr.Add({
          msg:
            "<b>Przynajmniej jeden zestaw słupów wymagany</b><br/>Dodaj nowy zestaw słupów przed usunięciem ostatniego.",
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });

        return;
      }

      let foundTypesSet = this.polesTypesSets.find(set => set.index === index);

      if (foundTypesSet && foundTypesSet?.formData?.id != null) {
        this.$swal
          .fire({
            title: "Usunięcie zestawu słupów",
            text: "Czy na pewno usunąć wybrany zestaw słupów ze zlecenia?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#1ab394",
            cancelButtonColor: "#f8ac59",
            confirmButtonText: "Tak",
            cancelButtonText: "Nie",
            customClass: {
              title: "swal2-title"
            }
          })
          .then(result => {
            if (result.value) {
              this.deletePolesTypesSetFromDb(index, foundTypesSet);
            }
          });
      } else {
        this.polesTypesSets = this.polesTypesSets.filter(
          set => set.index !== index
        );
      }
    },
    async deletePolesTypesSetFromDb(index, foundTypesSet) {
      var response = await RepositoryOrders.removePolesTypesSetForOrder(
        foundTypesSet.formData.id
      );

      handleResponseWithMessages(
        this,
        response,
        "Usunięto zestaw słupów.",
        () => {
          this.polesTypesSets = this.polesTypesSets.filter(
            set => set.index !== index
          );
        }
      );
    },
    addAnotherSet() {
      let newIndex = this.polesTypesSets.length
        ? this.polesTypesSets[this.polesTypesSets.length - 1].index + 1
        : 0;

      this.polesTypesSets.push({
        index: newIndex,
        formData: null,
        canBeDeleted: true
      });
    },
    createTablesFromParameters() {
      this.polesTypesSets = [];

      if (Array.isArray(this.order.addresses)) {
        let index = 0;

        this.order.addresses.forEach(polesTypesSet => {
          this.polesTypesSets.push({
            index,
            formData: polesTypesSet,
            initialFormData: Object.assign({}, polesTypesSet)
          });
          index++;
        });
      }
    },
    async addNewPolesTypesSetsAndEditOldOnes() {
      let count = 0;

      this.polesTypesSets.forEach(async polesTypesSet => {
        count++;
        if (polesTypesSet?.formData?.id == null) {
          //Add new poles types set
          let response = await RepositoryOrders.createPolesTypesSetForOrder(
            this.idForEdit,
            polesTypesSet.formData
          );

          if (response?.serverError) {
            this.$toastr.Add({
              msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
              progressbar: false,
              timeout: 3000,
              type: "error"
            });
          } else if (count === this.polesTypesSets.length) this.$router.go(-1);
          // else {
          //   if (response?.data?.id != null) {
          //     this.$toastr.Add({
          //       msg: "Zestaw słupów został dodany.",
          //       progressbar: false,
          //       timeout: 3000,
          //       type: "success"
          //     });
          //   }
          // }
        } else {
          //Update existing poles types set
          let response = await RepositoryOrders.updatePolesTypesSetForOrder(
            this.idForEdit,
            polesTypesSet.formData.id,
            polesTypesSet.formData
          );

          if (response?.serverError) {
            this.$toastr.Add({
              msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
              progressbar: false,
              timeout: 3000,
              type: "error"
            });
          } else if (count === this.polesTypesSets.length) this.$router.go(-1);

          // else {
          //   if (response?.data?.id != null) {
          //     this.$toastr.Add({
          //       msg: "Zestaw słupów został zaktualizowany.",
          //       progressbar: false,
          //       timeout: 3000,
          //       type: "success"
          //     });
          //   }
          // }
        }
      });
    }
  }
};
</script>

<style scoped></style>
