import { PAGINATION_INITIAL_ITEMS_PER_PAGE } from "@/helpers/appConstants.js";

export default {
  state: {
    paginationState: localStorage.getItem("paginationState")
  },
  getters: {
    paginationItemsCount: state => {
      let paginationState = state.paginationState
        ? JSON.parse(state.paginationState)
        : {};

      if (paginationState == null) return PAGINATION_INITIAL_ITEMS_PER_PAGE;

      return paginationState.paginationCount != null
        ? paginationState.paginationCount
        : PAGINATION_INITIAL_ITEMS_PER_PAGE;
    }
  },
  mutations: {
    changePaginationItemsCount(state, value) {
      let paginationState = state.paginationState
        ? JSON.parse(state.paginationState)
        : {};

      paginationState.paginationCount = value;
      state.paginationState = JSON.stringify(paginationState);
      localStorage.setItem("paginationState", state.paginationState);
    }
  },
  actions: {
    changePaginationItemsCount(context, value) {
      context.commit("changePaginationItemsCount", value);
    }
  }
};
