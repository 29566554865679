// gets map markers array from neighbouring poles
export function getNeighbouringMarkers(neighbouringPoles) {
  if (!Array.isArray(neighbouringPoles)) return null;

  let neighboursMarkers = new Array();

  neighbouringPoles.forEach(neighbour => {
    let neighbourMarker = {
      lat: neighbour?.geom?.coordinates[1] ?? null,
      lng: neighbour.geom.coordinates[0] ?? null,
      grayCircle: true
    };

    neighboursMarkers.push(neighbourMarker);
  });

  return neighboursMarkers;
}

// concatenates qgis and internal poles arrays excluding given pole
export function getNeighbouringPolesExcludingGiven(
  neighbouringPolesArrays,
  poleToExclude
) {
  if (
    !Array.isArray(neighbouringPolesArrays?.qgis) ||
    !Array.isArray(neighbouringPolesArrays?.internal)
  ) {
    console.log(
      "getNeighbouringPolesExcludingGiven(): neighbouringPolesArrays doesn't contain needed arrays."
    );
    return null;
  }

  if (poleToExclude?.poleId == null && poleToExclude?.internalPoleId == null)
    return neighbouringPolesArrays.qgis.concat(
      neighbouringPolesArrays.internal
    );

  let filteredQgis = new Array();
  let filteredInternal = new Array();

  if (poleToExclude?.poleId != null) {
    neighbouringPolesArrays.qgis.forEach(qgisPole => {
      if (qgisPole?.id_elem !== poleToExclude.poleId) {
        filteredQgis.push(qgisPole);
      }
    });
  } else filteredQgis = neighbouringPolesArrays.qgis;

  if (poleToExclude?.internalPoleId != null) {
    neighbouringPolesArrays.internal.forEach(internalPole => {
      if (internalPole?.id !== poleToExclude.internalPoleId)
        filteredInternal.push(internalPole);
    });
  } else filteredInternal = neighbouringPolesArrays.internal;

  return filteredQgis.concat(filteredInternal);
}
