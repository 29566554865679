<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      style="border-style: none"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <form @submit="onDataUpload">
        <div class="row">
          <div class="col-12" style="border-bottom: 1px dashed #1ab394;">
            <div class="form-group">
              <label>Numer</label>
              <input
                type="text"
                placeholder="Wpisz numer protokołu"
                v-model="PONumber"
                class="form-control"
                required
                minlength="1"
                maxlength="100"
              />
            </div>
            <div class="form-group">
              <label>Liczba słupów drewnianych</label>
              <input
                type="number"
                @mousewheel.prevent=""
                placeholder="Wpisz liczbę słupów drewnianych"
                v-model="amountW"
                class="form-control"
                required
                min="0"
                max="999999999"
              />
            </div>
            <div class="form-group">
              <label>Liczba słupów betonowych</label>
              <input
                type="number"
                @mousewheel.prevent=""
                placeholder="Wpisz liczbę słupów betonowych"
                v-model="amountC"
                class="form-control"
                required
                min="0"
                max="999999999"
              />
            </div>
          </div>

          <div class="col-12">
            <div class="row mt-2">
              <div
                class="col-auto mt-3"
                style="border-right: 1px dashed #1ab394;"
              >
                <file-or-files-for-order-phases
                  :fileObject="acceptanceProtocolObject"
                  @fileDeleted="$emit('refresh')"
                />
              </div>
              <div class="col-8">
                <h5>Wczytaj protokół odbioru</h5>

                <div v-show="showFileInput">
                  <drop-area
                    id="dropArea2"
                    class="dropArea"
                    caption="Upuść plik tutaj albo kliknij 'Wybierz plik' poniżej"
                    fileInputId="fileInputField2"
                    @onFileChanged="
                      onDropAreaFileChanged($event, 'fileInputField2')
                    "
                  />
                  <input
                    id="fileInputField2"
                    name="file"
                    type="file"
                    class="mt-2"
                    @change="onFileChanged($event, 'fileInputField2')"
                  />
                  <button
                    class="btn btn-primary mt-2 float-right"
                    type="submit"
                  >
                    Prześlij dane
                  </button>
                </div>
                <div v-if="!showFileInput">
                  <button
                    class="btn btn-outline btn-primary mt-2"
                    @click="showFileInput = true"
                  >
                    Dodaj kolejny plik
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import orderPhaseMixin from "@/mixins/orderPhaseMixin.js";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import FileOrFilesForOrderPhases from "@/components/utils/FileOrFilesForOrderPhases";

const RepositoryOrders = RepositoryFactory.get("orders");

export default {
  name: "order-acceptance-protocol-phase",
  components: {
    FileOrFilesForOrderPhases
  },
  mixins: [orderPhaseMixin],
  props: {
    acceptanceProtocolObject: null
  },
  data() {
    return {
      showFileInput: true,
      PONumber: null,
      amountC: null,
      amountW: null
    };
  },
  watch: {
    acceptanceProtocolObject() {
      this.addAdditionalInfo();

      this.showFileInput =
        Array.isArray(this.acceptanceProtocolObject) &&
        this.acceptanceProtocolObject.length === 0;
    }
  },
  created() {
    this.addAdditionalInfo();

    if (
      Array.isArray(this.acceptanceProtocolObject) &&
      this.acceptanceProtocolObject.length > 0
    )
      this.showFileInput = false;
  },
  methods: {
    onDataUpload(e) {
      e.preventDefault();
      this.onFileUpload();
    },
    async onFileUpload() {
      if (!this.file) {
        this.$toastr.Add({
          msg: "Proszę załączyć plik.",
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });

        return;
      }

      let creationDate = this.getDateWithoutTime(
        this.getLocalFormattedDateAndTime(new Date().toJSON())
      );

      var response = await RepositoryOrders.sendAcceptanceProtocol(
        this.orderId,
        this.file,
        creationDate,
        this.loggedInUser?.id,
        this.PONumber,
        this.amountC,
        this.amountW
      );

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (response) {
          let msg = "Coś poszło nie tak.";
          if (Array.isArray(response)) {
            msg = "";
            response.forEach(element => {
              msg += element + ". ";
            });
          }
          this.$toastr.Add({
            msg,
            progressbar: false,
            timeout: 3000,
            type: "warning"
          });
        } else {
          this.$emit("refresh");

          this.$toastr.Add({
            msg: "Protokół odbioru został przesłany.",
            progressbar: false,
            timeout: 3000,
            type: "success"
          });

          this.file = null;
          this.PONumber = null;
          this.amountC = null;
          this.amountW = null;
        }
      }
    },
    addAdditionalInfo() {
      if (Array.isArray(this.acceptanceProtocolObject)) {
        this.acceptanceProtocolObject.forEach(acceptanceProtocol => {
          acceptanceProtocol.additionalInfo = new Array();

          if (acceptanceProtocol?.number != null)
            acceptanceProtocol.additionalInfo.push(
              `Numer: ${acceptanceProtocol.number}`
            );

          if (acceptanceProtocol?.amount_w != null)
            acceptanceProtocol.additionalInfo.push(
              `Drewniane: ${acceptanceProtocol.amount_w}`
            );

          if (acceptanceProtocol?.amount_c != null)
            acceptanceProtocol.additionalInfo.push(
              `Betonowe: ${acceptanceProtocol.amount_c}`
            );
        });
      } else {
        this.acceptanceProtocolObject.additionalInfo = new Array();

        if (this.acceptanceProtocolObject?.number != null)
          this.acceptanceProtocolObject.additionalInfo.push(
            `Numer: ${this.acceptanceProtocolObject.number}`
          );

        if (this.acceptanceProtocolObject?.amount_w != null)
          this.acceptanceProtocolObject.additionalInfo.push(
            `Drewniane: ${this.acceptanceProtocolObject.amount_w}`
          );

        if (this.acceptanceProtocolObject?.amount_c != null)
          this.acceptanceProtocolObject.additionalInfo.push(
            `Betonowe: ${this.acceptanceProtocolObject.amount_c}`
          );
      }
    }
  }
};
</script>

<style scoped></style>
