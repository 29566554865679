<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      style="border-style: none"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <form @submit="onDataUpload">
        <div class="row">
          <div class="col-12" style="border-bottom: 1px dashed #1ab394;">
            <div class="form-group">
              <label>Wartość zamówienia [zł]</label>
              <input
                @mousewheel.prevent=""
                type="number"
                placeholder="Wpisz wartość zamówienia"
                v-model="cost"
                class="form-control"
                min="0"
                max="999999999"
                required
              />
            </div>
          </div>

          <div class="col-12">
            <div class="row mt-2">
              <div
                class="col-auto mt-3"
                style="border-right: 1px dashed #1ab394;"
              >
                <file-or-files-for-invoicing-phase
                  :fileObject="invoiceObject"
                  @fileDeleted="$emit('refresh')"
                />
              </div>
              <div class="col-8">
                <h5>Wczytaj fakturę VAT</h5>
                <div v-show="showFileInput">
                  <drop-area
                    id="dropArea4"
                    class="dropArea"
                    caption="Upuść plik tutaj albo kliknij 'Wybierz plik' poniżej"
                    fileInputId="fileInputField4"
                    @onFileChanged="
                      onDropAreaFileChanged($event, 'fileInputField4')
                    "
                  />
                  <input
                    id="fileInputField4"
                    name="file"
                    type="file"
                    class="mt-2"
                    @change="onFileChanged($event, 'fileInputField4')"
                  />
                  <button
                    class="btn btn-primary mt-2 float-right"
                    type="submit"
                  >
                    Prześlij dane
                  </button>
                </div>
                <div v-if="!showFileInput">
                  <button
                    class="btn btn-outline btn-primary mt-2"
                    @click="showFileInput = true"
                  >
                    Dodaj kolejną fakturę
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import orderPhaseMixin from "@/mixins/orderPhaseMixin.js";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import FileOrFilesForInvoicingPhase from "@/components/utils/FileOrFilesForInvoicingPhase";

const RepositoryOrders = RepositoryFactory.get("orders");

export default {
  name: "order-invoicing-phase",
  components: {
    FileOrFilesForInvoicingPhase
  },
  mixins: [orderPhaseMixin],
  props: {
    invoiceObject: null
  },
  data() {
    return {
      cost: null,
      showFileInput: true
    };
  },
  watch: {
    invoiceObject() {
      this.addAdditionalInfo();

      this.showFileInput =
        Array.isArray(this.invoiceObject) && this.invoiceObject.length === 0;
    }
  },
  created() {
    this.addAdditionalInfo();

    if (Array.isArray(this.invoiceObject) && this.invoiceObject.length > 0)
      this.showFileInput = false;
  },
  methods: {
    onDataUpload(e) {
      e.preventDefault();

      this.onFileUpload();
    },
    async onFileUpload() {
      let creationDate = this.getDateWithoutTime(
        this.getLocalFormattedDateAndTime(new Date().toJSON())
      );

      var response = await RepositoryOrders.sendInvoice(
        this.orderId,
        this.file,
        creationDate,
        this.loggedInUser?.id,
        this.cost
      );

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (response) {
          let msg = "Coś poszło nie tak.";
          if (Array.isArray(response)) {
            msg = "";
            response.forEach(element => {
              msg += element + ". ";
            });
          }
          this.$toastr.Add({
            msg,
            progressbar: false,
            timeout: 3000,
            type: "warning"
          });
        } else {
          this.$emit("refresh");

          this.$toastr.Add({
            msg: "Faktura VAT została przesłana.",
            progressbar: false,
            timeout: 3000,
            type: "success"
          });

          this.file = null;
          this.cost = null;
        }
      }
    },
    addAdditionalInfo() {
      if (this.invoiceObject?.cost != null)
        this.invoiceObject.additionalInfo = [
          `Wartość zamówienia: ${this.invoiceObject.cost} zł`
        ];
      else if (Array.isArray(this.invoiceObject)) {
        this.invoiceObject.forEach(invoice => {
          if (invoice?.cost != null)
            invoice.additionalInfo = [`Wartość zamówienia: ${invoice.cost} zł`];
        });
      }
    }
  }
};
</script>

<style scoped></style>
