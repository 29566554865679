import repository from "./repository.js";

export default {
  async getActionsObject(
    page,
    itemsPerPage,
    search,
    sortBy,
    orderType,
    filter,
    userFilter
  ) {
    let url = "/action";

    if (filter && filter.subject && filter.id != null)
      url = `/${filter.subject}/${filter.id}/actions`;

    let userFilterUrlEnding = "";

    if (userFilter) {
      for (const property in userFilter) {
        userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`
      )
      .then(response => {
        if (response.data.error == null) return response.data;
      })
      .catch(error => {
        return { error };
      });
  },

  async getActionDetailsGeneral(id) {
    return repository.get(`/action/${id}`).then(response => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async addAction(orderId, userOrderId, userId, action, creationDate) {
    let formData = {
      data: [
        {
          id_local: 1,
          id_user: userId,
          id_order: orderId,
          id_user_order: userOrderId,
          id_action_type: action.actionTypeId,
          lat: action?.position?.lat,
          lon: action?.position?.lng,
          note: action.note,
          amount_w: action?.amount?.w,
          amount_c: action?.amount?.c,
          amount_s: action?.amount?.s,
          creation_date: creationDate
        }
      ],
      id_order: orderId
    };

    return repository
      .post(`/order/${orderId}/addAction`, formData)
      .then(response => {
        if (response && response.data) return response.data;
        else return null;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async addFileToAction(orderId, actionId, userId, file, creationDate) {
    let formData = new FormData();

    formData.append("id_action", actionId);
    formData.append("file", file);
    formData.append("creation_date", creationDate);
    formData.append("created_by", userId);

    return repository
      .post(`/order/${orderId}/addFileToAction`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        if (response && response.data && response.data.message)
          return response.data.message;
        else return null;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async toggleActionVisibility(actionId, visible) {
    let formData = new FormData();
    formData.append("display_state", visible ? 1 : 0);

    return repository
      .post(`/action/${actionId}/changeMapDisplayState`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        if (response && response.data && response.data.message)
          return response.data.message;
        else return null;
      })
      .catch(serverError => {
        return { serverError };
      });
  },

  async getDataForTransportList(actionsIds) {
    return repository
      .post(`/action/getDataForTransportList`, {
        data: actionsIds
      })
      .then(response => {
        if (response && response.data) return response.data;
        else return null;
      })
      .catch(serverError => {
        return { serverError };
      });
  }
};
