<template>
  <div class="ibox">
    <div
      class="ibox-content p-0"
      :class="{ 'sk-loading': isLoading }"
      style="border-style: none"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <table-elements-info
        :metaObject="ordersObject ? ordersObject.meta : null"
      />

      <div
        v-if="ordersObject && ordersObject.data && ordersObject.data.length > 0"
        class="table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Nr</th>
              <th>Liczba sł. drew./bet.</th>
              <th>Pozostało do zdemontowania</th>
              <th>Status zlecenia</th>
              <th>Typ</th>
              <th>Rodzaj zlecenia</th>
              <th>EBP</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in ordersObject.data"
              :key="order.id"
              @click="showDetails(order)"
              style="cursor: pointer;"
            >
              <td style="white-space: nowrap;">
                {{
                  order && order.order_number != null ? order.order_number : "-"
                }}
              </td>
              <td style="white-space: nowrap;">
                {{ woodenCount(order) }} / {{ concreteCount(order) }}
              </td>
              <td style="white-space: nowrap;">
                {{ polesLeftCount(order) }}
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order &&
                    order.order_status &&
                    order.order_status.color != null
                      ? 'background-color:' + order.order_status.color
                      : ''
                  "
                >
                  {{
                    order &&
                    order.order_status &&
                    order.order_status.name != null
                      ? order.order_status.name
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order && order.orderType && order.orderType.color != null
                      ? 'background-color:' + order.orderType.color
                      : ''
                  "
                  :data-toggle="
                    order && order.orderType && order.orderType.name
                      ? 'tooltip'
                      : ''
                  "
                  :title="
                    order && order.orderType && order.orderType.name
                      ? order.orderType.name
                      : ''
                  "
                >
                  {{
                    order && order.orderType && order.orderType.name != null
                      ? getShorthandIfExists(order.orderType.name)
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order && order.direction && order.direction.color != null
                      ? 'background-color:' + order.direction.color
                      : ''
                  "
                >
                  {{
                    order && order.direction && order.direction.name != null
                      ? order.direction.name
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.ebp != null ? order.ebp : "-" }}
              </td>

              <td style="white-space: nowrap;">
                <div class="float-right">
                  <button
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="showDetails(order)"
                    onclick="event.stopPropagation()"
                  >
                    Pokaż
                  </button>
                  <button
                    class="btn btn-outline btn-primary btn-xs"
                    type="button"
                    @click="edit(order)"
                    onclick="event.stopPropagation()"
                  >
                    Edytuj
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="ordersObject && ordersObject.error">
        <server-error-panel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6">
          <pagination
            v-if="ordersObject && ordersObject.data"
            align="center"
            :data="ordersObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></pagination>
        </div>

        <div class="col-xl-6">
          <pagination-count-picker
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import { PAGINATION_LIMIT } from "@/helpers/appConstants.js";
import paginationCountPickerMixin from "@/mixins/paginationCountPickerMixin.js";
import ServerErrorPanel from "@/components/utils/ServerErrorPanel";
import { getShorthandIfExists } from "@/helpers/valueShorthandHelper.js";
import TableElementsInfo from "@/components/utils/TableElementsInfo";

const RepositoryOrders = RepositoryFactory.get("orders");

export default {
  name: "orders-table-for-map",
  components: {
    ServerErrorPanel,
    TableElementsInfo
  },
  mixins: [paginationCountPickerMixin],
  props: {
    selectedOrdersIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoading: false,
      ordersObject: {},
      searchBox: "",
      currentSort: { by: null, direction: null },
      PAGINATION_LIMIT: PAGINATION_LIMIT,
      userFilter: null
    };
  },
  computed: {},
  watch: {
    selectedOrdersIds() {
      this.fetchData();
    }
  },
  created() {},
  methods: {
    getShorthandIfExists,
    fetchData(page = 1) {
      this.isLoading = true;

      RepositoryOrders.getOrdersForMapObjectByOrdersIds(
        page,
        this.itemsPerPage,
        this.searchBox,
        this.currentSort.by,
        this.currentSort.direction,
        this.userFilter,
        this.selectedOrdersIds
      )
        .then(data => {
          this.ordersObject = data;
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
          //eslint-disable-next-line no-undef
          $('[data-toggle="tooltip"]').tooltip({ placement: "right" });
        });
    },
    showDetails(order) {
      let routeData = this.$router.resolve({
        name: "orderDetails",
        params: { id: order.id }
      });
      window.open(routeData.href, "_blank");
    },
    edit(order) {
      this.$router.push({
        name: "orderAddEdit",
        params: { id: order.id }
      });
    },
    woodenCount(order) {
      if (!Array.isArray(order?.addresses) || order.addresses.length < 1)
        return "-";

      let sum = 0;

      order.addresses.forEach(address => {
        if (typeof address?.parameters?.w_dem === "number")
          sum += address.parameters.w_dem;
      });

      return sum;
    },
    concreteCount(order) {
      if (!Array.isArray(order?.addresses) || order.addresses.length < 1)
        return "-";

      let sum = 0;

      order.addresses.forEach(address => {
        if (typeof address?.parameters?.c_dem === "number")
          sum += address.parameters.c_dem;
      });

      return sum;
    },
    polesLeftCount(order) {
      let wooden = this.woodenCount(order);
      let concrete = this.concreteCount(order);

      let all = 0;

      if (Number.isInteger(wooden)) all += wooden;
      if (Number.isInteger(concrete)) all += concrete;

      let done = order?.poles_done_count;

      let result = "-";

      if (Number.isInteger(done)) result = all - done;
      if (Number.isInteger(result) && result < 0) result = "0 (" + result + ")";

      return result;
    }
  }
};
</script>
<style></style>
