<template>
  <div class="form-group">
    <label :class="titleCssClass">{{ title }}</label>
    <select
      onmousedown="(function(e){ e.preventDefault(); })(event, this)"
      onkeypress="(function(e){ e.preventDefault(); })(event, this)"
      class="form-control m-b"
      @click="onShowModal"
      :required="required"
      :disabled="disabled"
      v-model="displayText"
    >
      <option disabled :value="null">{{ nullText }}</option>
      <option>{{ displayText }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "modal-input-field",
  props: {
    title: {
      type: String,
      default: ""
    },
    displayText: {
      type: String,
      default: "-"
    },
    nullText: {
      type: String,
      default: "-"
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    titleCssClass: {
      type: String,
      default: ""
    }
  },
  methods: {
    onShowModal() {
      this.$emit("showModal");
    }
  }
};
</script>

<style scoped></style>
