<template>
  <div class="ibox ">
    <div v-if="showHeader" class="ibox-title">
      <h5>Akcje</h5>
    </div>
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <actions-table-filter-and-search
        ref="actionsTableFilterAndSearchChildComponent"
        :longerSearchBar="longerSearchBar"
        :userFilterEnabled="userFilterEnabled"
        :hideWorkerFilter="true"
        @fetchData="onFetchDataWithFiltersRequest"
      />

      <table-elements-info
        :metaObject="actionsObject ? actionsObject.meta : null"
      />

      <div
        v-if="
          actionsObject && actionsObject.data && actionsObject.data.length > 0
        "
        class="table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th v-if="columns.orderEbp">Zlecenie</th>
              <th v-if="columns.user">Pracownik</th>
              <th v-if="columns.actionType">Typ</th>
              <th v-if="columns.note">Notatka</th>
              <th v-if="columns.amount_w">Liczba w</th>
              <th v-if="columns.amount_c">Liczba c</th>
              <th v-if="columns.amount_s">Liczba s</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="action in actionsObject.data" :key="action.id">
              <td v-if="columns.orderEbp">
                {{
                  action.order && action.order.ebp != null
                    ? action.order.ebp
                    : "-"
                }}
              </td>
              <td v-if="columns.user" style="white-space: nowrap;">
                <span
                  v-if="
                    action.user &&
                      action.user.firstName != null &&
                      action.user.lastName != null
                  "
                >
                  {{ action.user.firstName + " " + action.user.lastName }}
                </span>
                <span v-else>-</span>
              </td>
              <td v-if="columns.actionType">
                {{
                  action.type && action.type.name != null
                    ? action.type.name
                    : "-"
                }}
              </td>
              <td v-if="columns.note">
                {{ action.note != null ? action.note : "-" }}
              </td>
              <td v-if="columns.amount_w">
                {{ action.amount_w != null ? action.amount_w : "-" }}
              </td>
              <td v-if="columns.amount_c">
                {{ action.amount_c != null ? action.amount_c : "-" }}
              </td>
              <td v-if="columns.amount_s">
                {{ action.amount_s != null ? action.amount_s : "-" }}
              </td>
              <td style="white-space: nowrap;">
                <div class="float-right">
                  <button
                    v-if="showHideOnMapButton"
                    class="btn btn-outline btn-primary btn-xs"
                    type="button"
                    @click="onToggleMapVisibilityClick(action)"
                    onclick="event.stopPropagation()"
                  >
                    <span v-if="action.map_display">Ukryj na mapie</span>
                    <span v-else>Pokaż na mapie</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="actionsObject.error">
        <server-error-panel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6 offset-xl-3">
          <pagination
            v-if="actionsObject && actionsObject.data"
            align="center"
            :data="actionsObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></pagination>
        </div>

        <div class="col-xl-3">
          <pagination-count-picker
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import { PAGINATION_LIMIT } from "@/helpers/appConstants.js";
import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";
import ActionsTableFilterAndSearch from "@/components/actions/ActionsTableFilterAndSearch";
import paginationCountPickerMixin from "@/mixins/paginationCountPickerMixin.js";
import ServerErrorPanel from "@/components/utils/ServerErrorPanel";
import TableElementsInfo from "@/components/utils/TableElementsInfo";
import ActionsTableColumnsConfiguration from "@/tablesConfigurationClasses/ActionsTableColumnConfiguration.js";

const RepositoryActions = RepositoryFactory.get("actions");

export default {
  name: "actions-table",
  components: {
    ActionsTableFilterAndSearch,
    ServerErrorPanel,
    TableElementsInfo
  },
  mixins: [paginationCountPickerMixin],
  props: {
    filter: {
      type: Object,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    longerSearchBar: {
      type: Boolean,
      default: false
    },
    userFilterEnabled: {
      type: Boolean,
      default: true
    },
    columns: {
      type: ActionsTableColumnsConfiguration,
      default() {
        return new ActionsTableColumnsConfiguration();
      }
    },
    showHideOnMapButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      actionsObject: {},
      searchBox: "",
      currentSort: { by: null, direction: null },
      PAGINATION_LIMIT: PAGINATION_LIMIT,
      userFilter: null,
      currentlyLoadedPage: null
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(page = 1) {
      this.isLoading = true;

      RepositoryActions.getActionsObject(
        page,
        this.itemsPerPage,
        this.searchBox,
        this.currentSort.by,
        this.currentSort.direction,
        this.filter,
        this.userFilter
      )
        .then(data => {
          this.actionsObject = data;
          this.$refs.actionsTableFilterAndSearchChildComponent?.updateAppliedFilters();
          this.currentlyLoadedPage = page;
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    sortBy(newSortBy) {
      //set direction
      this.currentSort.direction = getSortingDirection(
        this.currentSort,
        newSortBy
      );

      //set by
      this.currentSort.by = newSortBy;

      //ask for data
      this.fetchData();
    },
    onFetchDataWithFiltersRequest({ search, filter }) {
      this.searchBox = search;
      this.userFilter = filter;
      this.fetchData();
    },
    onToggleMapVisibilityClick(action) {
      this.toggleActionVisibility(action);
    },
    async toggleActionVisibility(action) {
      var response = await RepositoryActions.toggleActionVisibility(
        action.id,
        action.map_display == 1 ? false : true
      );

      handleResponseWithMessages(
        this,
        response,
        "Akcja została " +
          (action.map_display == 1 ? "ukryta" : "pokazana") +
          ".",
        () => {
          this.$emit("actionVisibilityChanged");
        }
      );
    },
    reloadCurrentPage() {
      this.fetchData(this.currentlyLoadedPage ?? 1);
    }
  }
};
</script>
<style></style>
