<template>
  <div class="ibox">
    <div class="ibox-title pr-3">
      <div class="row">
        <div
          class="col-12"
          style="display: flex; justify-content: space-between"
        >
          <h5 class="mt-2" style="flex-basis: 60%; word-break: break-word">
            Szczegóły zlecenia:
            {{ order && order.order_number ? order.order_number : "-" }}
          </h5>

          <div v-if="order" style="flex-basis: 40%;">
            <button
              class="btn btn-outline btn-danger btn-xs float-right mt-1"
              @click="onDeleteOrderButtonClicked"
            >
              Usuń
            </button>
            <button
              class="btn btn-outline btn-info btn-xs float-right mr-1 mt-1"
              @click="editOrder"
            >
              Edytuj
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="ibox-content no-padding border-left-right"
      :class="{ 'sk-loading': isLoading }"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <cluster-map-component
        :centerLat="centerLat"
        :centerLng="centerLng"
        :markers="markersForMap"
        :addScreenShooter="true"
      />
    </div>

    <div
      class="ibox-content profile-content"
      :class="{ 'sk-loading': isLoading }"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <table class="mt-4 mb-4 equal-columns-table">
        <tr>
          <td>Status:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order && order.order_status && order.order_status.color != null
                  ? 'background-color:' + order.order_status.color
                  : ''
              "
            >
              {{
                order && order.order_status && order.order_status.name != null
                  ? order.order_status.name
                  : "-"
              }}</span
            >
            <button
              type="button"
              @click="showModal(MODAL_ORDER_STATUSES_TABLE)"
              class="btn btn-xs btn-outline btn-link"
            >
              Zmień
            </button>
          </td>
        </tr>
        <tr v-if="order && order.order_status && order.order_status.id === 2">
          <td>Etap realizacji:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order &&
                order.implementation_phase &&
                order.implementation_phase.color != null
                  ? 'background-color:' + order.implementation_phase.color
                  : ''
              "
            >
              {{
                order &&
                order.implementation_phase &&
                order.implementation_phase.name != null
                  ? order.implementation_phase.name
                  : "-"
              }}</span
            >
            <button
              type="button"
              @click="showModal(MODAL_ORDER_REALIZATION_PHASES_TABLE)"
              class="btn btn-xs btn-outline btn-link"
            >
              Zmień
            </button>
          </td>
        </tr>
        <tr
          v-if="
            order &&
              order.orderType &&
              (order.orderType.id === 2 || order.orderType.id === 3) &&
              order.order_status &&
              order.order_status.id === 2
          "
        >
          <td>Etap geodezji:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order &&
                order.geodesy_phase &&
                order.geodesy_phase.color != null
                  ? 'background-color:' + order.geodesy_phase.color
                  : ''
              "
            >
              {{
                order && order.geodesy_phase && order.geodesy_phase.name != null
                  ? order.geodesy_phase.name
                  : "-"
              }}</span
            >
            <button
              type="button"
              @click="showModal(MODAL_ORDER_GEODETIC_PHASES_TABLE)"
              class="btn btn-xs btn-outline btn-link"
            >
              Zmień
            </button>
          </td>
        </tr>
        <tr>
          <td>Typ:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order && order.orderType && order.orderType.color != null
                  ? 'background-color:' + order.orderType.color
                  : ''
              "
            >
              {{
                order && order.orderType && order.orderType.name != null
                  ? order.orderType.name
                  : "-"
              }}</span
            >
          </td>
        </tr>
        <tr>
          <td>Priorytet:</td>
          <td>
            <i
              v-if="order && order.priority === 1"
              class="fa fa-exclamation ml-1"
              style="color: red; font-size: 20px"
            ></i>
            <span v-else>-</span>
          </td>
        </tr>
        <tr>
          <td>Rodzaj zlecenia:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order && order.direction && order.direction.color != null
                  ? 'background-color:' + order.direction.color
                  : ''
              "
            >
              {{
                order && order.direction && order.direction.name != null
                  ? order.direction.name
                  : "-"
              }}</span
            >
          </td>
        </tr>
        <tr>
          <td>Przesunięcie:</td>
          <td>
            <i
              v-if="order && order.deadline_offset"
              class="fa fa-long-arrow-right text-info"
              style="font-size: 23px;"
            />
            <span v-else>-</span>
          </td>
        </tr>
        <tr>
          <td>Notatka:</td>
          <td>
            <img
              v-if="
                order &&
                  order.note_actions_count &&
                  order.note_actions_count > 0
              "
              src="/static/img/exclamation_mark_circle_icon.png"
              alt="Notatka"
              style="width: 22px; height: 22px; padding-right: 0px"
            />
            <span v-else>-</span>
          </td>
        </tr>
        <tr>
          <td>Wartość zamówienia:</td>
          <td>{{ order && order.cost ? order.cost : "-" }} zł</td>
        </tr>
        <tr>
          <td>Numer zlecenia:</td>
          <td>{{ order && order.order_number ? order.order_number : "-" }}</td>
        </tr>
        <tr>
          <td>Numer EBP:</td>
          <td>{{ order && order.ebp ? order.ebp : "-" }}</td>
        </tr>
        <tr>
          <td>Region:</td>
          <td>
            {{
              order && order.region && order.region.name
                ? order.region.name
                : "-"
            }}
          </td>
        </tr>
        <tr>
          <td>Powiat:</td>
          <td>
            {{
              order && order.powiat && order.powiat.name
                ? order.powiat.name
                : "-"
            }}
          </td>
        </tr>
        <tr>
          <td>Adres:</td>
          <td>{{ order && order.address ? order.address : "-" }}</td>
        </tr>
        <tr>
          <td>Opis:</td>
          <td>{{ order && order.description ? order.description : "-" }}</td>
        </tr>
        <tr>
          <td>Do demontażu:</td>
          <td>{{ order && order.target ? order.target : "-" }}</td>
        </tr>
        <tr>
          <td>Koordynator:</td>
          <td>
            <span
              v-if="
                order &&
                  order.coordinator &&
                  order.coordinator.id != null &&
                  order.coordinator.firstName != null &&
                  order.coordinator.lastName != null
              "
            >
              <a
                @click="goToUserDetails(order.coordinator.id)"
                :class="'text-navy'"
              >
                {{
                  order.coordinator.firstName + " " + order.coordinator.lastName
                }}
              </a>
            </span>
            <span v-else>-</span>
          </td>
        </tr>
        <tr>
          <td>Telefon koordynatora:</td>
          <td>
            {{
              order && order.coordinator && order.coordinator.phone
                ? order.coordinator.phone
                : "-"
            }}
          </td>
        </tr>
        <tr>
          <td>Telefon właściciela:</td>
          <td>{{ order && order.owner_phone ? order.owner_phone : "-" }}</td>
        </tr>
        <tr>
          <td>Pracownik:</td>
          <td>
            <span
              v-if="
                order &&
                  order.workers &&
                  Array.isArray(order.workers) &&
                  order.workers.length > 0 &&
                  order.workers[order.workers.length - 1].id != null &&
                  order.workers[order.workers.length - 1].firstName != null &&
                  order.workers[order.workers.length - 1].lastName != null
              "
            >
              <a
                @click="
                  goToUserDetails(order.workers[order.workers.length - 1].id)
                "
                :class="'text-navy'"
              >
                {{
                  order.workers[order.workers.length - 1].firstName +
                    " " +
                    order.workers[order.workers.length - 1].lastName
                }}
              </a>
            </span>
            <span v-else>-</span>
          </td>
        </tr>
        <tr>
          <td>Data wpływu zlecenia:</td>
          <td>
            {{
              order && order.receive_date
                ? getDateWithoutTime(order.receive_date)
                : "-"
            }}
          </td>
        </tr>
        <tr>
          <td>Termin realizacji:</td>
          <td>
            {{
              order && order.deadline ? getDateWithoutTime(order.deadline) : "-"
            }}
          </td>
        </tr>
        <tr>
          <td>Nowy termin realizacji:</td>
          <td>
            {{
              order && order.deadline_offset
                ? getDateWithoutTime(order.deadline_offset)
                : "-"
            }}
          </td>
        </tr>
        <tr>
          <td>Opóźnienia:</td>
          <td>{{ ordersDelayDays }}</td>
        </tr>
        <tr>
          <td>Termin realizacji aktualizacji geodezyjnej:</td>
          <td>
            {{
              order && order.surveyor_deadline
                ? getDateWithoutTime(order.surveyor_deadline)
                : "-"
            }}
          </td>
        </tr>
      </table>

      <div v-if="order && Array.isArray(order.addresses)">
        <h3 class="text-center mb-4">Zestawy słupów</h3>
        <poles-table-set-for-order-details
          v-for="address in order.addresses"
          :key="address.id"
          :address="address"
        />
      </div>

      <div class="user-button mt-2" v-if="order && order.stopDatetime">
        <button
          class="btn btn-outline btn-info btn-sm btn-block"
          @click="onReopenOrderButtonClicked"
        >
          Otwórz zlecenie
        </button>
      </div>
    </div>
    <modal-base ref="modalBase">
      <modal-body>
        <template v-slot:title>
          {{ modal.title }}
        </template>
        <template v-slot:body>
          <component
            :is="modal.component"
            @on-completed="onModalCompleted($event)"
            :key="modal.key"
            v-bind="modal.properties"
          ></component>
        </template>
        <template v-slot:footer>
          <button
            type="button"
            class="btn btn-outline btn-danger"
            @click="onModalRejected"
          >
            Usuń wybór
          </button>
        </template>
      </modal-body>
    </modal-base>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import {
  getDateWithoutTime,
  getLocalFormattedDateAndTime
} from "@/helpers/dateFormatHelper.js";
import ClusterMapComponent from "@/components/map/ClusterMapComponent";
import modalTablesMixin from "@/mixins/modalTablesMixin.js";
import {
  MODAL_ORDER_STATUSES_TABLE,
  MODAL_ORDER_REALIZATION_PHASES_TABLE,
  MODAL_ORDER_GEODETIC_PHASES_TABLE
} from "@/helpers/modalConstants.js";
import PolesTableSetForOrderDetails from "./PolesTableSetForOrderDetails.vue";
import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";

const RepositoryOrders = RepositoryFactory.get("orders");

export default {
  name: "order-details-general",
  mixins: [modalTablesMixin],
  components: {
    ClusterMapComponent,
    PolesTableSetForOrderDetails
  },
  props: {
    id: Number
  },
  data() {
    return {
      isLoading: false,
      order: null
    };
  },
  computed: {
    markersForMap() {
      if (
        this.order?.first_pole_position?.lat &&
        this.order?.first_pole_position?.lon
      )
        return [
          {
            lat: this.order.first_pole_position.lat,
            lng: this.order.first_pole_position.lon,
            caption: "Lokalizacja pierwszego słupa",
            draggable: false
          }
        ];

      return [
        {
          lat: this.order?.geotimestamp?.lat
            ? Number.parseFloat(this.order.geotimestamp.lat)
            : null,
          lng: this.order?.geotimestamp?.lon
            ? Number.parseFloat(this.order.geotimestamp.lon)
            : null,
          caption: "Lokalizacja zlecenia",
          draggable: false
        }
      ];
    },
    centerLat() {
      if (this.order?.first_pole_position?.lat)
        return this.order?.first_pole_position?.lat;

      return this.order?.geotimestamp?.lat
        ? Number.parseFloat(this.order.geotimestamp.lat)
        : null;
    },
    centerLng() {
      if (this.order?.first_pole_position?.lon)
        return this.order?.first_pole_position?.lon;

      return this.order?.geotimestamp?.lon
        ? Number.parseFloat(this.order.geotimestamp.lon)
        : null;
    },
    ordersDelayDays() {
      if (this.order?.delayDays == null) return "-";
      else if (this.order.delayDays <= 0) return "brak";

      if (this.order.delayDays === 1)
        return this.order.delayDays.toString() + " dzień";
      else return this.order.delayDays.toString() + " dni";
    }
  },
  watch: {
    id() {
      this.getOrderDetails();
    },
    isLoading() {
      this.$emit("onIsLoadingChanged", this.isLoading);
    },
    order() {
      this.$emit("onOrderLoaded", this.order);
    }
  },
  created() {
    this.getOrderDetails();
  },
  methods: {
    getLocalFormattedDateAndTime,
    getDateWithoutTime,
    async getOrderDetails() {
      this.isLoading = true;
      this.order = await RepositoryOrders.getOrderDetailsGeneral(this.id);

      this.isLoading = false;
    },
    goToUserDetails(id) {
      if (id == null) return;

      this.$router.push({
        name: "userDetails",
        params: { id }
      });
    },
    onReopenOrderButtonClicked() {
      this.$swal
        .fire({
          title: "Ponowne otwarcie zlecenia",
          text: "Czy na pewno chcesz otworzyć zlecenie?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) {
            this.reopenOrder();
          }
        });
    },
    async reopenOrder() {
      var response = await RepositoryOrders.reopenOrder(this.order?.id);

      handleResponseWithMessages(this, response, "Otwarto zlecenie.", () => {
        this.$emit("refresh");
      });
    },
    editOrder() {
      this.$router.push({
        name: "orderAddEdit",
        params: { id: this.id }
      });
    },
    onDeleteOrderButtonClicked() {
      this.$swal
        .fire({
          title: "Usunięcie zlecenia",
          text: "Czy na pewno chcesz usunąć zlecenie?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          cancelButtonColor: "#f8ac59",
          confirmButtonText: "Tak",
          cancelButtonText: "Nie",
          customClass: {
            title: "swal2-title"
          }
        })
        .then(result => {
          if (result.value) {
            this.deleteOrder();
          }
        });
    },
    async deleteOrder() {
      var response = await RepositoryOrders.deleteOrder(this.order?.id);

      handleResponseWithMessages(this, response, "Usunięto zlecenie.", () => {
        this.$router.push({
          name: "ordersTable"
        });
      });
    },
    onModalCompleted(data) {
      this.$refs.modalBase.hide();

      switch (this.modal.type) {
        case MODAL_ORDER_STATUSES_TABLE:
          this.changeOrderStatus(data);
          break;
        case MODAL_ORDER_REALIZATION_PHASES_TABLE:
          this.changeOrderRealizationPhase(data);
          break;
        case MODAL_ORDER_GEODETIC_PHASES_TABLE:
          this.changeOrderGeodeticPhase(data);
          break;
      }
    },
    onModalRejected() {
      this.$refs.modalBase.hide();
    },
    async changeOrderStatus(newStatus) {
      var response = await RepositoryOrders.changeOrderStatus(
        this.order?.id,
        newStatus?.id
      );

      handleResponseWithMessages(
        this,
        response,
        "Status został zmieniony.",
        () => {
          this.order.order_status = newStatus;
        }
      );
    },
    async changeOrderRealizationPhase(newRealizationPhase) {
      var response = await RepositoryOrders.changeOrderRealizationPhase(
        this.order?.id,
        newRealizationPhase?.id
      );

      handleResponseWithMessages(
        this,
        response,
        "Etap realizacji został zmieniony.",
        () => {
          this.order.implementation_phase = newRealizationPhase;
        }
      );
    },
    async changeOrderGeodeticPhase(newGeodeticPhase) {
      var response = await RepositoryOrders.changeOrderGeodeticPhase(
        this.order?.id,
        newGeodeticPhase?.id
      );

      handleResponseWithMessages(
        this,
        response,
        "Etap geodezji został zmieniony.",
        () => {
          this.order.geodesy_phase = newGeodeticPhase;
        }
      );
    }
  }
};
</script>

<style scoped>
td {
  padding-bottom: 0.75rem;
}

tr :first-child {
  text-align: right;
  padding-right: 0.5rem;
}

.equal-columns-table td:nth-child(1) {
  /* width: auto; */
  /* white-space:nowrap; */
  /* width: 50%; */
  vertical-align: text-top;
}

.equal-columns-table td:nth-child(2) {
  /* width: auto; */
  overflow-wrap: break-word;
}

.equal-columns-table {
  width: 100%;
  table-layout: fixed;
}
</style>
