<template>
  <div class="passwordBox animated fadeInDown">
    <div class="row">
      <div class="col-md-12">
        <div class="ibox-content">
          <h2 class="font-bold">Zapomniałeś hasło?</h2>

          <p>
            Wpisz adres email, aby otrzymać link resetujący hasło.
          </p>

          <form-error-panel :errors="formErrors" />

          <div class="row">
            <div class="col-lg-12">
              <form class="m-t" @submit="submitForm">
                <div class="form-group">
                  <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    placeholder="Wpisz email"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                </div>

                <div
                  v-if="isLoading"
                  class="sk-spinner sk-spinner-circle mb-3 mt-3"
                >
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>

                <button
                  type="submit"
                  class="btn btn-primary block full-width m-b"
                >
                  Wyślij link
                </button>
                <div class="text-center" style="font-size:xx-small;">
                  Jeśli nie otrzymasz maila w ciągu kilku minut, sprawdź folder
                  ze spamem.
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-6">
        DKK Development
      </div>
      <div class="col-md-6 text-right">
        <small>© 2020</small>
      </div>
    </div>
  </div>
</template>

<script>
import FormErrorPanel from "@/components/utils/FormErrorPanel";
import { RepositoryFactory } from "@/data/repositoryFactory.js";

const RepositoryAuth = RepositoryFactory.get("auth");

export default {
  name: "view-forgotten-password",
  components: {
    FormErrorPanel
  },
  data() {
    return {
      formErrors: [],
      email: null,
      isLoading: false
    };
  },
  methods: {
    submitForm: async function(e) {
      e.preventDefault();

      this.isLoading = true;
      var response = await RepositoryAuth.sendResetPasswordLink(this.email);

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (response) this.formErrors = response;
        else {
          this.$toastr.Add({
            msg: "Link został wysłany.",
            progressbar: false,
            timeout: 3000,
            type: "success"
          });

          this.$router.push({ name: "login" });
        }
      }

      this.isLoading = false;
    }
  }
};
</script>

<style scoped></style>
