<template>
  <div class="ibox ">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <slot v-if="globalFilterPanel" name="globalFilterPanel"></slot>

      <orders-table-filter-and-search
        v-else
        ref="ordersTableFilterAndSearchChildComponent"
        :longerSearchBar="longerSearchBar"
        :userFilterEnabled="userFilterEnabled"
        :hideWorkerFilter="true"
        @fetchData="onFetchDataWithFiltersRequest"
        :initialFilter="initialUserFilter"
      />

      <table-elements-info
        :metaObject="ordersObject ? ordersObject.meta : null"
      />

      <div
        v-if="ordersObject && ordersObject.data && ordersObject.data.length > 0"
        class="table-responsive"
      >
        <table class="table table-striped first-column-fixed-table">
          <thead>
            <tr>
              <th style="white-space: nowrap;">
                Numer zlecenia
              </th>
              <th style="white-space: nowrap; text-align: center;">
                Rodzaj zlecenia (typ)
              </th>
              <th
                @click="sortBy(sortingOptions.TARGET)"
                style="cursor: pointer; white-space: nowrap;"
              >
                Słupy do likwidacji <span v-html="targetSortIcon" />
              </th>
              <th style="white-space: nowrap; text-align: center;">
                Status zlecenia
              </th>
              <th style="white-space: nowrap; text-align: center;">
                Status demontażu
              </th>
              <th style="text-align: center;">
                Zdemontowane drewniane
              </th>
              <th style="text-align: center;">
                Zdemontowane betonowe
              </th>
              <th style="white-space: nowrap;">Notatka</th>
              <th style="text-align: center;">
                Zgłoszonych do zebrania
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in ordersObject.data"
              :key="order.id"
              @click="selectableItems ? pickItem(order) : showDetails(order)"
              style="cursor: pointer;"
            >
              <td style="white-space: nowrap;">
                {{
                  order && order.order_number != null ? order.order_number : "-"
                }}
              </td>
              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order && order.orderType && order.orderType.color != null
                      ? 'background-color:' + order.orderType.color
                      : ''
                  "
                  :data-toggle="
                    order && order.orderType && order.orderType.name
                      ? 'tooltip'
                      : ''
                  "
                  :title="
                    order && order.orderType && order.orderType.name
                      ? order.orderType.name
                      : ''
                  "
                >
                  {{
                    order && order.orderType && order.orderType.name != null
                      ? getShorthandIfExists(order.orderType.name)
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap;">
                {{ order && order.target != null ? order.target : "-" }}
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order &&
                    order.order_status &&
                    order.order_status.color != null
                      ? 'background-color:' + order.order_status.color
                      : ''
                  "
                >
                  {{
                    order &&
                    order.order_status &&
                    order.order_status.name != null
                      ? order.order_status.name
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap; text-align: center;">
                <span
                  class="label label-primary"
                  :style="
                    order &&
                    order.disassembly_status &&
                    order.disassembly_status.color != null
                      ? 'background-color:' + order.disassembly_status.color
                      : ''
                  "
                >
                  {{
                    order &&
                    order.disassembly_status &&
                    order.disassembly_status.name != null
                      ? order.disassembly_status.name
                      : "-"
                  }}</span
                >
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.poles_disassemblied_w != null
                    ? order.poles_disassemblied_w
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.poles_disassemblied_c != null
                    ? order.poles_disassemblied_c
                    : "-"
                }}
              </td>

              <td
                v-if="
                  order &&
                    order.note_actions_count &&
                    order.note_actions_count > 0
                "
                style="white-space: nowrap; text-align: center;"
              >
                <img
                  src="/static/img/exclamation_mark_circle_icon.png"
                  alt="Notatka"
                  width="22"
                  height="22"
                />
              </td>
              <td v-else style="white-space: nowrap;">
                <span>-</span>
              </td>

              <td style="white-space: nowrap;">
                {{
                  order && order.poles_to_gather != null
                    ? order.poles_to_gather
                    : "-"
                }}
              </td>

              <td style="white-space: nowrap;">
                <div class="float-right">
                  <button
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="
                      selectableItems ? pickItem(order) : showDetails(order)
                    "
                    onclick="event.stopPropagation()"
                  >
                    <span v-if="selectableItems">Wybierz</span>
                    <span v-else>Pokaż</span>
                  </button>
                  <button
                    v-if="editButton"
                    class="btn btn-outline btn-primary btn-xs"
                    type="button"
                    @click="edit(order)"
                    onclick="event.stopPropagation()"
                  >
                    Edytuj
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="ordersObject.error">
        <server-error-panel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6 offset-xl-3">
          <pagination
            v-if="ordersObject && ordersObject.data"
            align="center"
            :data="ordersObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></pagination>
        </div>

        <div class="col-xl-3">
          <pagination-count-picker
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import {
  ordersSortingOptions as sortingOptions,
  getSortingIcon,
  getSortingDirection
} from "@/helpers/sortTableHelper.js";
import { PAGINATION_LIMIT } from "@/helpers/appConstants.js";
import {
  getLocalFormattedDateAndTime,
  getDateWithoutTime
} from "@/helpers/dateFormatHelper.js";
import paginationCountPickerMixin from "@/mixins/paginationCountPickerMixin.js";
import OrdersTableFilterAndSearch from "@/components/orders/OrdersTableFilterAndSearch";
import ServerErrorPanel from "@/components/utils/ServerErrorPanel";
import { getShorthandIfExists } from "@/helpers/valueShorthandHelper.js";
import TableElementsInfo from "@/components/utils/TableElementsInfo";

const RepositoryOrders = RepositoryFactory.get("orders");

export default {
  name: "orders-table-for-user",
  components: {
    OrdersTableFilterAndSearch,
    ServerErrorPanel,
    TableElementsInfo
  },
  mixins: [paginationCountPickerMixin],
  props: {
    filter: {
      type: Object,
      default: null
    },
    selectableItems: {
      type: Boolean,
      default: false
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    longerSearchBar: {
      type: Boolean,
      default: false
    },
    editButton: {
      type: Boolean,
      default: false
    },
    userFilterEnabled: {
      type: Boolean,
      default: true
    },
    initialUserFilter: {
      type: Object,
      default: null
    },
    globalFilterPanel: {
      type: Boolean,
      default: false
    },
    filterFromParent: {
      type: Object,
      default: null
    },
    searchFromParent: {
      type: String,
      default: ""
    }
    // initialSort: {
    //   type: Object,
    //   default: null
    // }
  },
  data() {
    return {
      isLoading: false,
      ordersObject: {},
      searchBox: "",
      currentSort: { by: null, direction: null },
      PAGINATION_LIMIT: PAGINATION_LIMIT,
      userFilter: null
    };
  },
  computed: {
    sortingOptions: () => sortingOptions,
    costSortIcon() {
      return getSortingIcon(this.currentSort, sortingOptions.COST);
    },
    targetSortIcon() {
      return getSortingIcon(this.currentSort, sortingOptions.TARGET);
    }
  },
  created() {
    if (this.initialUserFilter != null)
      this.userFilter = this.initialUserFilter;

    //if (this.initialSort != null) this.currentSort = this.initialSort;

    this.fetchData();
  },
  methods: {
    getLocalFormattedDateAndTime,
    getDateWithoutTime,
    getShorthandIfExists,
    fetchData(page = 1) {
      this.isLoading = true;

      RepositoryOrders.getOrdersObject(
        page,
        this.itemsPerPage,
        this.globalFilterPanel ? this.searchFromParent : this.searchBox,
        this.currentSort.by,
        this.currentSort.direction,
        this.filter,
        this.globalFilterPanel ? this.filterFromParent : this.userFilter
      )
        .then(data => {
          this.ordersObject = data;
          this.$emit("afterSuccessfulFetch");
          this.$refs.ordersTableFilterAndSearchChildComponent?.updateAppliedFilters();
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
          //eslint-disable-next-line no-undef
          $('[data-toggle="tooltip"]').tooltip({ placement: "right" });
        });
    },
    sortBy(newSortBy) {
      //set direction
      this.currentSort.direction = getSortingDirection(
        this.currentSort,
        newSortBy
      );

      //set by
      this.currentSort.by = newSortBy;

      //ask for data
      this.fetchData();
    },
    showDetails(order) {
      let routeData = this.$router.resolve({
        name: "orderDetails",
        params: { id: order.id }
      });
      window.open(routeData.href, "_blank");
    },
    edit(order) {
      this.$router.push({
        name: "orderAddEdit",
        params: { id: order.id }
      });
    },
    onFetchDataWithFiltersRequest({ search, filter }) {
      this.searchBox = search;
      this.userFilter = filter;
      this.fetchData();
    },
    pickItem(order) {
      this.$emit("on-completed", order);
    }
  }
};
</script>
<style scoped>
.first-column-fixed-table th:first-child,
td:first-child {
  position: sticky;
  left: 0px;
}

.first-column-fixed-table > tbody > tr:nth-child(odd) > td {
  background-color: rgb(242, 242, 242);
}

.first-column-fixed-table > tbody > tr:nth-child(even) > td,
.first-column-fixed-table > thead > tr:first-child > th {
  background-color: white;
}
</style>
