export default {
  state: {
    mapState: localStorage.getItem("mapState")
  },
  getters: {
    mapRefreshKey: state => {
      let mapState = state.mapState ? JSON.parse(state.mapState) : {};

      if (mapState === null) return 0;

      return mapState.refreshKey !== null ? mapState.refreshKey : 0;
    }
  },
  mutations: {
    incrementMapRefreshKey(state) {
      let mapState = state.mapState ? JSON.parse(state.mapState) : {};

      mapState.refreshKey++;
      state.mapState = JSON.stringify(mapState);
      localStorage.setItem("mapState", state.mapState);
    }
  },
  actions: {
    reloadMap(context) {
      context.commit("incrementMapRefreshKey");
    }
  }
};
