<template>
  <div class="row">
    <div class="ibox col-lg-8 pr-1">
      <div
        class="ibox-content"
        :class="{ 'sk-loading': isLoading }"
        style="border-style: none"
      >
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <cluster-map-component
          :centerLat="marker.lat"
          :centerLng="marker.lng"
          :markers="[marker]"
          :refreshable="true"
          height="700px"
          @on-marker-dragged="onMarkerDragged"
        />
      </div>
    </div>

    <div class="ibox col-lg-4 pl-1">
      <div
        class="ibox-content profile-content pl-4"
        :class="{ 'sk-loading': isLoading }"
        style="border-style: none"
      >
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <form @submit="onDataUpload">
          <div class="row">
            <div class="col-12" style="border-bottom: 1px dashed #1ab394;">
              <div class="form-group">
                <label>Liczba słupów drewnianych</label>
                <select
                  class="form-control m-b"
                  v-if="
                    actionTypesObject && Array.isArray(actionTypesObject.data)
                  "
                  v-model="actionObject.actionTypeId"
                  required
                >
                  <option disabled :value="null">Wybierz typ akcji</option>
                  <option
                    v-for="actionType in actionTypesObject.data"
                    :key="actionType.id"
                    :value="actionType.id"
                    >{{ actionType.name }}</option
                  >
                </select>
              </div>
              <div class="form-group">
                <label>Współrzędne geograficzne</label>
                <input
                  type="text"
                  placeholder="Wpisz współrzędne geograficzne akcji"
                  v-model="coordsInputField"
                  class="form-control"
                  required
                />
                <label
                  v-html="coordsInputFieldInfoHtml"
                  class="mt-2"
                  style="color: red"
                  v-if="coordsInputFieldInfo"
                ></label>
              </div>
              <div class="form-group">
                <label>Liczba słupów drewnianych</label>
                <input
                  :disabled="actionObject.actionTypeId == 5"
                  type="number"
                  @mousewheel.prevent=""
                  placeholder="Wpisz liczbę słupów drewnianych"
                  v-model.number="actionObject.amount.w"
                  class="form-control"
                  required
                  min="0"
                  max="999999999"
                />
              </div>
              <div class="form-group">
                <label>Liczba słupów betonowych</label>
                <input
                  :disabled="actionObject.actionTypeId == 5"
                  type="number"
                  @mousewheel.prevent=""
                  placeholder="Wpisz liczbę słupów betonowych"
                  v-model.number="actionObject.amount.c"
                  class="form-control"
                  required
                  min="0"
                  max="999999999"
                />
              </div>
              <div class="form-group">
                <label>Liczba elementów typu szczudła</label>
                <input
                  :disabled="actionObject.actionTypeId == 5"
                  type="number"
                  @mousewheel.prevent=""
                  placeholder="Wpisz liczbę elementów typu szczudła"
                  v-model.number="actionObject.amount.s"
                  class="form-control"
                  required
                  min="0"
                  max="999999999"
                />
              </div>
              <div class="form-group">
                <label>Notatka</label>
                <textarea
                  class="form-control"
                  style="width: 100%; height: 100px;"
                  placeholder="Wpisz notatkę"
                  maxlength="500"
                  v-model="actionObject.note"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <files-for-actions
                :filesArray="files"
                @fileDeleted="onFileDeleted"
              />
            </div>

            <div class="col-12">
              <h5>Wczytaj pliki</h5>
              <drop-area
                id="dropArea2"
                class="dropArea"
                caption="Upuść plik tutaj albo kliknij 'Wybierz plik' poniżej"
                fileInputId="fileInputField2"
                @onFileChanged="
                  onDropAreaFileChanged($event, 'fileInputField2')
                "
              />
              <input
                id="fileInputField2"
                name="file"
                type="file"
                class="mt-2"
                @change="onFileChanged($event, 'fileInputField2')"
              />
              <button class="btn btn-primary mt-2 float-right" type="submit">
                Prześlij dane
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ClusterMapComponent from "@/components/map/ClusterMapComponent";
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import DropArea from "@/components/utils/DropArea";
import FilesForActions from "@/components/actions/FilesForActions";
import {
  getDateWithoutTime,
  getLocalFormattedDateAndTime
} from "@/helpers/dateFormatHelper.js";
import { FILE_MAX_SIZE } from "@/helpers/appConstants.js";

const RepositoryActions = RepositoryFactory.get("actions");
const RepositoryDictionaries = RepositoryFactory.get("dictionaries");

export default {
  name: "action-add-edit",
  components: {
    ClusterMapComponent,
    FilesForActions,
    DropArea
  },
  props: {
    orderContractor: {
      type: Object,
      default: null
    },
    orderLocation: {
      type: Object,
      default: () => {
        return {
          lat: 50.29044,
          lng: 21.42891
        };
      }
    }
  },
  data() {
    return {
      isLoading: false,
      actionObject: {
        position: {
          lat: null,
          lng: null
        },
        amount: {
          w: null,
          c: null,
          s: null
        },
        note: null,
        actionTypeId: null
      },
      marker: null,
      actionTypesObject: null,
      files: [],
      FILE_MAX_SIZE: FILE_MAX_SIZE,
      coordsInputField: null,
      coordsInputFieldInfo: null
    };
  },
  computed: {
    coordsInputFieldInfoHtml() {
      return this.coordsInputFieldInfo;
    }
  },
  watch: {
    actionObject: {
      deep: true,
      handler() {
        if (this.actionObject?.actionTypeId === 5) {
          this.actionObject.amount.w = 0;
          this.actionObject.amount.c = 0;
          this.actionObject.amount.s = 0;
        }

        this.coordsInputField =
          this.actionObject?.position?.lat +
          "," +
          this.actionObject?.position?.lng;
      }
    },
    coordsInputField() {
      var pattern = /^\d+(\.\d+)*, *\d+(\.\d+)*$/;

      var result = pattern.test(this.coordsInputField);

      if (!result) {
        this.coordsInputFieldInfo =
          "Niepoprawny format. Przykład: 50.3276,21.5210.";
        return;
      }

      let lat = this.coordsInputField
        .toString()
        .substring(0, this.coordsInputField.toString().indexOf(","));

      let lng = this.coordsInputField
        .toString()
        .substring(
          this.coordsInputField.toString().indexOf(",") + 1,
          this.coordsInputField.toString().length
        );

      let numericLat = Number(lat);
      let numericLng = Number(lng);

      if (
        numericLat < -90 ||
        numericLat > 90 ||
        numericLng < -180 ||
        numericLng > 180
      ) {
        this.coordsInputFieldInfo = "";
        if (numericLat < -90 || numericLat > 90)
          this.coordsInputFieldInfo =
            "Nieprawidłowa wartość szerokości geograficznej.";

        if (numericLng < -180 || numericLng > 180) {
          if (this.coordsInputFieldInfo != "")
            this.coordsInputFieldInfo += "<br/>";
          this.coordsInputFieldInfo +=
            "Nieprawidłowa wartość długości geograficznej.";
        }

        return;
      }

      if (lat.endsWith("0") || lng.endsWith("0")) {
        this.coordsInputFieldInfo = "Współrzędne nie mogą kończyć się cyfrą 0.";
        return;
      }

      this.coordsInputFieldInfo = null;
      this.onMarkerDragged({
        lat: numericLat,
        lng: numericLng
      });
    }
  },
  created() {
    this.loadActionTypesList();
    this.actionObject.position.lat = this.orderLocation.lat;
    this.actionObject.position.lng = this.orderLocation.lng;
    this.prepareMarker();
  },
  methods: {
    getLocalFormattedDateAndTime,
    getDateWithoutTime,
    ...mapActions({
      reloadMap: "reloadMap"
    }),
    async loadActionTypesList() {
      this.isLoading = true;

      RepositoryDictionaries.getDictionaryElementsObject(
        1,
        100,
        "",
        "",
        "",
        "actiontype"
      )
        .then(data => {
          this.actionTypesObject = data;
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    prepareMarker() {
      this.marker = new Object();

      this.marker = {
        lat: this.actionObject?.position?.lat ?? this.orderLocation.lat,
        lng: this.actionObject?.position?.lng ?? this.orderLocation.lng,
        draggable: true,
        selected: false
      };
    },
    onMarkerDragged(marker) {
      this.actionObject.position = marker;
      this.prepareMarker();
    },
    onDataUpload(e) {
      e.preventDefault();
      this.addAction();
    },
    async addAction() {
      this.isLoading = true;

      let creationDate = this.getDateWithoutTime(
        this.getLocalFormattedDateAndTime(new Date().toJSON())
      );

      var response = await RepositoryActions.addAction(
        this.orderContractor?.id_order,
        this.orderContractor?.id,
        this.orderContractor?.user?.id,
        this.actionObject,
        creationDate
      );

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (
          Array.isArray(response) &&
          response.length > 0 &&
          response[0].id_global != null
        ) {
          this.files.forEach(async file => {
            await this.sendFile(
              response[0].id_global,
              file.file,
              this.orderContractor?.user?.id,
              creationDate
            );
          });

          this.$toastr.Add({
            msg: "Dodawanie akcji zakończone.",
            progressbar: false,
            timeout: 3000,
            type: "success"
          });
          this.$emit("hideModal");
          this.$emit("refresh");
        }
      }

      this.isLoading = false;
    },
    onFileChanged(e, fileInputFieldId) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length) {
        let fileSize = files[0].size / 1024 / 1024;

        if (fileSize > this.FILE_MAX_SIZE) {
          this.$toastr.Add({
            msg: `Zbyt duży rozmiar pliku<br/> (maks. ${this.FILE_MAX_SIZE} MB)`,
            progressbar: false,
            timeout: 3000,
            type: "warning"
          });
        } else this.files.push({ id: this.getNewIdForFile(), file: files[0] });

        let fileInput = document.getElementById(fileInputFieldId);
        if (fileInput) fileInput.value = "";
      }
    },
    onDropAreaFileChanged(file, fileInputFieldId) {
      let fileSize = file.size / 1024 / 1024;

      if (fileSize > this.FILE_MAX_SIZE) {
        this.$toastr.Add({
          msg: `Zbyt duży rozmiar pliku<br/> (maks. ${this.FILE_MAX_SIZE} MB)`,
          progressbar: false,
          timeout: 3000,
          type: "warning"
        });
      } else this.files.push({ id: this.getNewIdForFile(), file: file });

      let fileInput = document.getElementById(fileInputFieldId);
      if (fileInput) fileInput.value = "";
    },
    getNewIdForFile() {
      if (!Array.isArray(this.files) || this.files.length < 1) return 0;

      let ids = this.files.map(x => x.id);
      let max = Math.max(...ids);
      return max + 1;
    },
    onFileDeleted(id) {
      this.files = this.files.filter(x => x.id !== id);
    },
    async sendFile(actionId, file, userId, creationDate) {
      var response = await RepositoryActions.addFileToAction(
        this.orderContractor?.id_order,
        actionId,
        userId,
        file,
        creationDate
      );

      if (response?.serverError) {
        this.$toastr.Add({
          msg: "Wystąpił błąd serwera. Spróbuj ponownie.",
          progressbar: false,
          timeout: 3000,
          type: "error"
        });
      } else {
        if (response) {
          let msg = "Coś poszło nie tak.";
          if (Array.isArray(response)) {
            msg = "";
            response.forEach(element => {
              msg += element + ". ";
            });
          }
          this.$toastr.Add({
            msg,
            progressbar: false,
            timeout: 3000,
            type: "warning"
          });
        } else {
          this.$toastr.Add({
            msg: "Plik został dodany.",
            progressbar: false,
            timeout: 3000,
            type: "success"
          });

          this.$emit("refreshFiles");
        }
      }
    }
  }
};
</script>

<style scoped></style>
