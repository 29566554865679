<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>
      <div class="row">
        <div
          :class="
            longerSearchBar ? 'col-sm-4 offset-sm-8' : 'col-sm-3 offset-sm-9'
          "
        >
          <div class="input-group mb-3">
            <input
              placeholder="Szukaj.."
              type="text"
              class="form-control form-control-sm"
              v-model="searchBox"
              v-on:keyup.enter="search"
            />
            <span class="input-group-append">
              <button
                @click="search"
                type="button"
                class="btn btn-sm btn-primary"
              >
                <i class="fa fa-search"></i> Szukaj
              </button>
            </span>
          </div>
        </div>
      </div>

      <table-elements-info
        :metaObject="contractorsObject ? contractorsObject.meta : null"
      />

      <div
        v-if="
          contractorsObject &&
            contractorsObject.data &&
            contractorsObject.data.length > 0
        "
        class="table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Status</th>
              <th style="white-space: nowrap">Punkty zbioru</th>
              <th
                @click="sortBy(sortingOptions.FIRST_NAME)"
                style="cursor: pointer; white-space: nowrap;"
              >
                Imię <span v-html="firstNameSortIcon" />
              </th>
              <th
                @click="sortBy(sortingOptions.LAST_NAME)"
                style="cursor: pointer; white-space: nowrap;"
              >
                Nazwisko <span v-html="lastNameSortIcon" />
              </th>
              <th style="white-space: nowrap">Realizacja</th>
              <th style="white-space: nowrap">Do wykonania</th>
              <th style="white-space: nowrap">Zdemontował</th>
              <th style="white-space: nowrap">Zd. mechanicznie</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="contractor in contractorsObject.data"
              :key="contractor.id"
              @click="goToOrderContractorsDetails(contractor.id)"
              style="cursor: pointer;"
            >
              <td style="white-space: nowrap;">
                <span
                  class="label label-primary"
                  :style="
                    contractor &&
                    contractor.status &&
                    contractor.status.color != null
                      ? 'background-color:' + contractor.status.color
                      : ''
                  "
                >
                  {{
                    contractor &&
                    contractor.status &&
                    contractor.status.name != null
                      ? contractor.status.name
                      : "-"
                  }}</span
                >
              </td>
              <td>
                <span
                  v-if="
                    contractor &&
                      contractor.had_transports != null &&
                      contractor.had_transports > 0
                  "
                >
                  <i class="fa fa-check text-navy" /> ({{
                    contractor.had_transports
                  }})
                </span>
                <span v-else>-</span>
              </td>
              <td style="white-space: nowrap">
                {{
                  contractor &&
                  contractor.user &&
                  contractor.user.firstName != null
                    ? contractor.user.firstName
                    : "-"
                }}
              </td>
              <td style="white-space: nowrap">
                {{
                  contractor &&
                  contractor.user &&
                  contractor.user.lastName != null
                    ? contractor.user.lastName
                    : "-"
                }}
              </td>
              <td style="white-space: nowrap">
                {{
                  contractor &&
                  contractor.start &&
                  contractor.start.date != null
                    ? getDateWithoutTime(contractor.start.date)
                    : ""
                }}
                -
                {{
                  contractor && contractor.stop && contractor.stop.date != null
                    ? getDateWithoutTime(contractor.stop.date)
                    : ""
                }}
              </td>
              <td>
                {{
                  contractor && contractor.target != null
                    ? contractor.target
                    : "-"
                }}
              </td>
              <td>
                {{
                  contractor && contractor.general_amount != null
                    ? contractor.general_amount
                    : "-"
                }}
              </td>
              <td>
                {{
                  contractor && contractor.mech_amount != null
                    ? contractor.mech_amount
                    : "-"
                }}
              </td>
              <td>
                <div class="float-right">
                  <span v-if="showDefaultButtons">
                    <button
                      class="btn btn-outline btn-primary btn-xs mr-2"
                      type="button"
                      @click="goToOrderContractorsDetails(contractor.id)"
                      onclick="event.stopPropagation()"
                    >
                      Pokaż szczegóły
                    </button>
                  </span>
                  <slot name="buttons" :contractor="contractor"></slot>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="contractorsObject && contractorsObject.error">
        <server-error-panel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">{{ noItemsText }}</p>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6 offset-xl-3">
          <pagination
            v-if="contractorsObject && contractorsObject.data"
            align="center"
            :data="contractorsObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></pagination>
        </div>

        <div class="col-xl-3">
          <pagination-count-picker
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositoryFactory.js";
import {
  orderContractorsSortingOptions as sortingOptions,
  getSortingIcon,
  getSortingDirection
} from "@/helpers/sortTableHelper.js";
import { getDateWithoutTime } from "@/helpers/dateFormatHelper.js";
import { PAGINATION_LIMIT } from "@/helpers/appConstants.js";
import paginationCountPickerMixin from "@/mixins/paginationCountPickerMixin.js";
import ServerErrorPanel from "@/components/utils/ServerErrorPanel";
import TableElementsInfo from "@/components/utils/TableElementsInfo";

const RepositoryOrderContractors = RepositoryFactory.get("orderContractors");

export default {
  name: "order-contractors-table",
  components: {
    ServerErrorPanel,
    TableElementsInfo
  },
  mixins: [paginationCountPickerMixin],
  props: {
    filter: {
      type: Object,
      default: null
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    longerSearchBar: {
      type: Boolean,
      default: false
    },
    noItemsText: {
      type: String,
      default: "Brak elementów do wyświetlenia"
    },
    showDefaultButtons: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: false,
      contractorsObject: {},
      searchBox: "",
      currentSort: { by: null, direction: null },
      PAGINATION_LIMIT: PAGINATION_LIMIT
    };
  },
  computed: {
    sortingOptions: () => sortingOptions,
    firstNameSortIcon() {
      return getSortingIcon(this.currentSort, sortingOptions.FIRST_NAME);
    },
    lastNameSortIcon() {
      return getSortingIcon(this.currentSort, sortingOptions.LAST_NAME);
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    getDateWithoutTime,
    fetchData(page = 1) {
      this.isLoading = true;

      RepositoryOrderContractors.getOrderContractorsObject(
        page,
        this.itemsPerPage,
        this.searchBox,
        this.currentSort.by,
        this.currentSort.direction,
        this.filter
      )
        .then(data => {
          this.contractorsObject = data;
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false;
        });
    },
    search() {
      this.fetchData();
    },
    sortBy(newSortBy) {
      //set direction
      this.currentSort.direction = getSortingDirection(
        this.currentSort,
        newSortBy
      );

      //set by
      this.currentSort.by = newSortBy;

      //ask for data
      this.fetchData();
    },
    goToOrderContractorsDetails(id) {
      this.$router.push({
        name: "orderContractorDetails",
        params: { id }
      });
    }
  }
};
</script>
<style></style>
