export default {
  USERS_LIST: [{ name: "Użytkownicy" }],
  USER_DETAILS: [
    { name: "Użytkownicy", link: "/users" },
    { name: "Szczegóły użytkownika" }
  ],
  USER_ADD: [
    { name: "Użytkownicy", link: "/users" },
    { name: "Nowy użytkownik" }
  ],
  USER_EDIT: [
    { name: "Użytkownicy", link: "/users" },
    { name: "Edycja użytkownika" }
  ],
  ORDERS_LIST: [{ name: "Zlecenia" }],
  ORDER_DETAILS: [
    { name: "Zlecenia", link: "/orders" },
    { name: "Szczegóły zlecenia" }
  ],
  ORDER_ADD: [{ name: "Zlecenia", link: "/orders" }, { name: "Nowe zlecenie" }],
  ORDER_EDIT: [
    { name: "Zlecenia", link: "/orders" },
    { name: "Edycja zlecenia" }
  ],
  ORDER_CONTRACTOR_DETAILS: [
    { name: "Zlecenia", link: "/orders" },
    { name: "Szczegóły zlecenia", link: "/orders/details/#orderId" },
    { name: "Szczegóły demontażu" }
  ],
  POLES_LIST: [{ name: "Słupy" }],
  POLE_DETAILS: [
    { name: "Słupy", link: "/poles" },
    { name: "Szczegóły słupa" }
  ],
  DICTIONARIES: [{ name: "Słowniki" }],
  POLE_ADD: [
    { name: "Zlecenia", link: "/orders" },
    { name: "Szczegóły zlecenia", link: "/orders/details/#orderId" },
    { name: "Nowy słup" }
  ],
  TRANSPORT_MODULE: [{ name: "Transport" }],
  SINGLE_ACTION_GALLERY: [{ name: "Galeria akcji" }]
};
